import { thingInfoFragment } from './fragments/thingInfoFragment';
import { valueChainInfoFragment } from './fragments/valueChainInfoFragment';
import { valueChainElementInfoFragment } from './fragments/valueChainElementInfoFragment';
import { componentInfoFragment } from './fragments/componentInfoFragment';
import { accountInfoFragment } from './fragments/accountInfoFragment';

export const query = `query thing($thingId: String!){
    thing(thingId: $thingId){
        ${thingInfoFragment}
        valueChain{
            ${valueChainInfoFragment}
            elements{
                ${valueChainElementInfoFragment}
                comps{
                ${componentInfoFragment}
                }
            }
            account{
                ${accountInfoFragment}
            }
        }
    }
}`;
