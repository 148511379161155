<template>
  <div class="margin-top-lg text-align--center signup-confirmation">
    <h3>Thank you!</h3>
    <br />
    <md-button class="md-primary" :href="appUrl"> <md-icon>exit_to_app</md-icon>Go back to Assure+ Go App </md-button>
  </div>
</template>

<script>
export default {
  computed: {
    appUrl() {
      const url = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
      return url + '/fs/login';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/_signup-confirmation.scss';
</style>
