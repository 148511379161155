import { taskInfoFragment, taskLibraryWithConfigInfoFragment } from './fragments/taskInfoFragment';
import { accountInfoFragment } from './fragments/accountInfoFragment';

export const query = `query tasks($organizationId: String! $search: String $page: Int $perPage: Int $sort: String $filters: String){
  organizationTasks(organizationId: $organizationId, page:$page, perPage:$perPage, sort:$sort, search:$search, filters: $filters){
    totalItems
    totalPages
    items{
      account{
        ${accountInfoFragment}
      }
      taskLibrary {
        ${taskLibraryWithConfigInfoFragment}
      }
      ${taskInfoFragment}
    }
  }
}`;
