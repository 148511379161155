import Vue from 'vue';

Vue.directive('document-click', {
  bind(el, binding) {
    el.addEventListener(
      'click',
      () => {
        if (document) document.body.dispatchEvent(new Event('click', { bubbles: true }));
      },
      true,
    );
  },
});
