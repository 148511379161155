<template>
  <not-found v-if="showNotFound" :text-key="'FSOrganization'"></not-found>
</template>

<script>
import { organizationApi } from '@/api/http/api';
import NotFound from '@/pages/NotFound';
export default {
  components: {
    NotFound,
  },
  data() {
    return {
      showNotFound: false,
    };
  },
  async mounted() {
    const orgId = this.$router.currentRoute.params.orgId;
    const interactionId = this.$router.currentRoute.params.interactionId;
    const organization = orgId ? (await organizationApi.get(orgId)).data || null : null;
    if (interactionId && organization) {
      this.$router.replace({
        name: 'OrganizationInteractions',
        params: { id: organization.slug },
        query: { interactionSearch: interactionId },
      });
    } else {
      this.showNotFound = true;
    }
  },
};
</script>
