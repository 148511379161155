<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$auth.handleAuthentication().then(() => {
      let route = { path: '/' };

      try {
        let args = window.location.search;
        let url = decodeURIComponent(args.slice(args.indexOf('=') + 1));
        route = { path: url };
      } catch (e) {
        route = { name: 'Home' };
      }

      this.$nextTick(() => {
        this.$router.replace(route);
      });
    });
  },
};
</script>
