import settings from '@/settings';
import * as Optimizely from '@optimizely/optimizely-sdk';
import store from '@/store';

Optimizely.setLogLevel('warn');

const clientInstance = settings.optimizelyKey ? Optimizely.createInstance({ sdkKey: settings.optimizelyKey }) : false;

const getUserId = function () {
  const account = store.getters['core/accountInfo'];
  return account ? `account-id-${String(account.id)}` : null;
};

const getAttributes = function () {
  const account = store.getters['core/accountInfo'];
  if (!account) return {};
  return {
    'rfider-portal-account-email': String(account.email),
  };
};

export { clientInstance, getUserId, getAttributes };
