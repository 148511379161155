import settings from '@/settings';

const sizes: { [key: string]: string } = {
  S: '64',
  M: '128',
  L: '256',
  O: '',
};

const GetFilePath = function (p: string, size: string = 'M') {
  if (!p) return p;
  const path = p;
  const gcpPublicBucketUri = settings.gcpPublicBucketUri;

  if (gcpPublicBucketUri && p.indexOf(gcpPublicBucketUri) === 0) {
    p = p.slice(gcpPublicBucketUri.length + 1);
  }

  if (/^((http|https|ftp):\/\/)/.test(p)) {
    return p;
  }

  if (/^((http|https|ftp):\/\/)/.test(path)) {
    return path;
  }

  let extension: string = '';
  const s: string = String(sizes[size]);

  //case 1 - return path as it if coming from old interaction folder images or ask for origin images
  let pathArray = [];
  pathArray = path.split('/');
  if ((pathArray.length && pathArray[0] === 'interactions' && pathArray[1].indexOf('org_') === -1) || s === '') {
    return `${gcpPublicBucketUri}/${path}`;
  }

  //case 2 - return image thumbnails only if extension is jpg/jpeg/png/gif
  extension = String(path.split('.').pop()).toLowerCase();
  if (extension.length && s.length) {
    if (['jpg', 'jpeg', 'gif', 'png'].indexOf(extension) > -1) {
      let newPath = '';
      newPath = path.substring(0, path.length - extension.length - 1) + `@thumb_${s}.` + extension;
      return `${gcpPublicBucketUri}/${newPath}`;
    }
  } else {
    //case 3 return path as it is
    return `${gcpPublicBucketUri}/${path}`;
  }
};

function getFilePathExtension(fileName: string) {
  return fileName ? fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2) : '';
}

const FileExtensions = {
  doc: ['doc', 'docx', 'odt', 'rtf', 'tex', 'txt', 'wpd', 'csv'],
  pdf: ['pdf'],
  video: ['3g2', '3gp', 'avi', 'flv', 'h264', 'm4v', 'mkv', 'mov', 'mp4', 'mpg', 'mpeg', 'rm', 'swf', 'vob', 'wmv'],
  audio: ['aif', 'cda', 'mid', 'midi', 'mp3', 'mpa', 'ogg', 'wav', 'wma', 'wpl'],
  xls: ['ods', 'xls', 'xlsm', 'xlsx'],
  image: ['jpg', 'jpe', 'jif', 'jfif', 'jfi', 'jpeg', 'png', 'gif', 'tif', 'tiff', 'bmp', 'dib', 'webp', 'heif', 'heic', 'svg'],
};

const CustomValidationRuleBetweenNumbers = (value: any, { min, max } = {} as any) => {
  const numberInBetween = Number(min) <= value && Number(max) >= value;
  return numberInBetween;
};

export { GetFilePath, getFilePathExtension, FileExtensions, CustomValidationRuleBetweenNumbers };
