import { organizationInfoFragment } from './fragments/organizationInfoFragment';
import { organizationItemStatusFragment } from './fragments/organizationItemStatusFragment';

export const query = `query organization($slug: String!){
    organizationBySlug(organizationSlug: $slug){
        ${organizationInfoFragment}
        itemStatuses{
            items {
                ${organizationItemStatusFragment}
            }
            perPage
            totalItems
            totalPages
        }
    }
}`;
