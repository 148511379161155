const pt = {
  common: {
    labels: {
      selected: 'SELECIONADO:',
      searchByName: 'Procura por nome',
      itemStatusMatches: 'Mostrar apenas quando o status do item corresponder',
      showTask: 'Deixe em branco para sempre mostrar esta tarefa',
      emailAddress: 'Endereço de e-mail',
      name: 'Nome',
      emailAddressWithComma: 'Endereços de e-mail (separados por vírgulas)',
      emailSubject: 'Assunto do e-mail (EX:. {task} - {name} para {thing} com status:{status})',
      endpointURL: 'URL do terminal',
      securityHmacKey: 'Segurança (chave HMAC)',
      apiKey: 'Chave API',
      organizationId: 'ID da organização',
      xmlMessage: 'Mensagem XML',
      createYourFirst: 'Crie seu primeiro',
      valueChain: 'cadeia de valor.',
      category: 'categoria.',
      supportProfile: 'Perfil de suporte.',
      task: 'tarefa.',
      template: 'modelo.',
      description: 'Descrição',
      learnMoreLink: 'Saiba mais links',
      image: 'Imagem',
      displayImage: 'Exibir imagem',
      chainElementIcon: 'Ícone do Elemento da Cadeia',
      transportationMethod: 'Método de Transporte',
      gln: 'GLN (Número de Localização Global)',
      status: 'Status',
      thing: 'Coisa',
      selectDateRange: 'Selecione o intervalo de datas',
      searchBy: 'Procurar por',
      itemImage: 'Imagem do item',
      uploadAttachment: 'Carregar anexo',
      addInteractionTo: 'Adicionar interação a',
      interactionName: 'Nome da interação',
      steps: 'Degraus',
      displayName: 'Nome de exibição',
      title: 'Título',
      attachments: 'Anexos',
      currentStatus: 'Status atual',
      country: 'País',
      language: 'Linguagem',
      items: 'Itens',
      interactions: 'Interações',
      tasks: 'Tarefas',
      product: 'produtos',
      item: 'item',
      selectLanguage: 'Selecione o idioma',
      addBatch: 'Adicionar lote',
      editBatch: 'Editar lote',
      filters: 'Filtros',
      unknown: 'Desconhecido',
      from: 'a partir de',
      learnMore: 'Saber mais',
      search: 'Procurar',
      url: 'URL',
      addTask: 'Adicionar tarefa',
      at: 'no',
    },
    buttons: {
      save: 'Salvar',
      cancel: 'Cancelar',
      finish: 'Terminar',
      submit: 'Enviar',
      delete: 'Excluir',
      remove: 'Remover',
      task: 'Tarefa',
      ok: 'OK',
      logout: 'Sair',
      decline: 'Declínio',
      accept: 'Aceitar',
      inviteUsers: 'Convidar usuários',
      send: 'Mandar',
      addMore: 'Adicione mais',
      createAnAction: 'Criar uma ação',
      removeIntegration: 'Remover integração',
      continue: 'Continuar',
      update: 'Atualizar',
      back: 'Voltar',
      next: 'Próximo',
      close: 'Fechar',
      upload: 'Carregar',
      create: 'Crio',
      clear: 'Claro',
      apply: 'Aplicar',
      viewInDashboard: 'Ver no painel',
      confirm: 'confirme',
      yes: 'sim',
      no: 'Não',
      configure: 'Configurar',
    },
    menus: {
      things: 'Coisas',
      items: 'Itens',
      interactions: 'Interações',
      reportingAndAnalytics: 'Relatórios e análises',
      templates: 'Modelos',
      settings: 'Configurações',
      tasks: 'Tarefas',
      users: 'Comercial',
      advancedSettings: 'Configurações avançadas',
      valueChains: 'Cadeias de valor',
      organisationalSettings: 'Configurações organizacionais',
      language: 'Linguagem',
      catalogue: 'Catálogo',
    },
    menuItems: {
      edit: 'Editar',
      delete: 'Excluir',
      remove: 'Remover',
      manageConfiguration: 'Gerenciar configuração',
      setAsDefault: 'Definir como padrão',
      setAsPublic: 'Definir como público',
      setAsPrivate: 'Definir como privado',
      manage: 'Gerenciar',
      duplicate: 'Duplicado',
      viewContent: 'Ver conteúdo',
      viewSettings: 'Configurações de exibição',
      displayToPublic: 'Exibir ao público',
      hideFromPublic: 'Ocultar do público',
      disable: 'Desativar',
      enable: 'Habilitar',
      viewConditions: 'Ver condições',
      manageConditions: 'Gerenciar condições',
      viewDisplayConditions: 'Ver condições de exibição',
      manageDisplayConditions: 'Gerenciar condições de exibição',
      change: 'Mudar',
      displayForBatch: 'Exibir para lote',
      viewOrUpdateClassification: 'Visualizar/atualizar classificação',
      displayInPublicPage: 'Exibir na página pública',
      suspended: 'Suspenso',
      unsuspend: 'Cancelar suspensão',
      suspend: 'Suspender',
      invitationDeclined: 'Convite recusado',
      invitationPending: 'Convite pendente',
      invitationExpired: 'Convite expirado',
      invitationAccepted: 'Convite aceito',
      setAsMember: 'Definir como membro',
      setAsAdmin: 'Definir como administrador',
      disablePortalAccess: 'Desabilitar o acesso ao portal',
      enablePortalAccess: 'Ativar acesso ao portal',
      resendInvitation: 'Reenviar convite',
      deleteUser: 'Deletar usuário',
      deactivate: 'Desativar',
      activate: 'Ativar',
      preview: 'Visualizar',
      customiseName: 'Personalizar nome',
      customiseImage: 'Personalizar imagem',
      addAttachment: 'Juntar anexo',
      changeStatus: 'Alterar status',
      removePersonalSupportProfile: 'Remover perfil de suporte pessoal',
      createBatch: 'Criar lote',
      editBatch: 'Editar lote',
      deleteBatch: 'Excluir lote',
      copyToClipboard: 'Copiar para área de transferência',
      openPublicPage: 'Abrir página pública',
      createProduct: 'Criar produto',
      pagePosition: 'Posição da página',
      uploadImage: 'Enviar Imagem',
      deleteImage: 'Excluir imagem',
      updateFromClipboard: 'Atualizar da área de transferência',
      viewCodeDetails: 'Ver detalhes do código',
      updateDisplayData: 'Atualizar dados relatados',
      hide: 'Esconder',
      unhide: 'Mostrar',
    },
    pagination: {
      text: 'Mostrando {from} a {to} de {total} entradas',
    },
    tabs: {
      general: 'Em geral',
      categories: 'Categorias',
      support: 'Apoiar',
      tasks: 'Tarefas',
      users: 'Comercial',
      integrationActions: 'Ações de Integração',
      valueChain: 'Cadeias de valor',
      chart: 'Gráfico',
      map: 'Mapa',
      documents: 'Documentos',
    },
    messages: {
      invitationExpired: 'Convite expirado',
      notFoundInvitation: 'Convite não encontrado',
      userAlreadyExists: 'Os usuários já existem',
      nameAlreadyTaken: 'Nome já utilizado',
      InvalidContent: 'Conteúdo inválido',
      PayloadTooLarge: 'Entidade de solicitação muito grande',
      noSearchResult: 'Desculpe, não encontramos nenhum resultado correspondente',
      noneSelected: 'Nada selecionado.',
      noneAvailable: 'Ainda não há Tarefas criadas para esta organização.',
      noConfigurableAttributes: 'Nenhum atributo configurável disponível para esta tarefa.',
      noInvitation: 'Não há convites pendentes',
      defaultValueChain: 'O elemento padrão existente só pode ser alterado selecionando um novo elemento como padrão em sua tela de edição.',
      fileSizeError: 'O tamanho máximo do arquivo para upload de arquivo para o Assure+ Portal é 10 MB. Faça o upload de um arquivo de até 10 MB.',
      submitError: 'Por favor preencha todos os campos obrigatórios',
      inviteSent: 'Convites enviados',
      inviteError: 'Não foi possível enviar convites',
      saved: 'salvou',
      deleted: 'deletado',
      duplicated: 'duplicado',
      createThing: 'Coisa criada',
      createThingError: 'Não foi possível criar a coisa',
      duplicateThingError: 'A coisa não pode ser duplicada',
      updateThing: 'A coisa foi duplicada',
      updateThingError: 'Não foi possível atualizar a coisa',
      loadTypeError: 'Não foi possível carregar {type}',
      deleteTypeError: 'Não foi possível excluir {type}',
      duplicateTypeError: 'Não foi possível duplicar',
      createTask: 'A tarefa foi adicionada',
      createTaskError: 'Não foi possível adicionar tarefa',
      updateTask: 'A tarefa foi atualizada',
      updateTaskError: 'Não foi possível atualizar a tarefa',
      taskError: 'Não foi possível criar uma tarefa',
      createSupportProfile: 'O perfil de suporte foi adicionado',
      createSupportProfileError: 'Não foi possível adicionar o perfil de suporte',
      updateSupportProfile: 'O perfil de suporte foi atualizado',
      updateSupportProfileError: 'Não foi possível atualizar o perfil de suporte',
      deleteSupportProfile: 'O perfil de suporte foi excluído',
      deleteSupportProfileError: 'Não foi possível criar o perfil de suporte',
      supportProfile: 'O perfil de suporte foi criado',
      supportProfileError: 'Não foi possível criar um perfil de suporte',
      createTeam: 'Equipe criada',
      createTeamError: 'Não foi possível criar a equipe',
      saveTeam: 'Equipe salva',
      saveTeamError: 'Não foi possível salvar a equipe',
      renameTeam: 'Equipe renomeada',
      renameTeamError: 'Não foi possível renomear a equipe',
      teamStatus: 'Status da equipe alterado',
      teamStatusError: 'Não foi possível alterar o status da equipe',
      resendInvite: 'Reenviar convite',
      resendInviteError: 'Não foi possível reenviar o convite',
      revokeInvite: 'Revogar convite',
      revokeInviteError: 'Não foi possível revogar o convite',
      revokeAccess: 'Acesso revogado',
      revokeAccessError: 'Não foi possível revogar o acesso',
      suspendAccess: 'Suspenso',
      suspendAccessError: 'Não foi possível alterar para suspender',
      accountChange: 'Conta alterada',
      accountChangeAdminError: 'Não foi possível mudar para administrador',
      accountChangeOwnerError: 'Não foi possível alterar para proprietário',
      accountChangeUserError: 'Não foi possível alterar para usuário',
      inviteUser: 'Usuário(s) convidado(s)',
      inviteUserError: 'Não foi possível convidar usuários',
      declineInvitation: 'Recusou o convite',
      declineInvitationError: 'Não foi possível recusar o convite ({message})',
      acceptInvitation: 'Aceitou o convite',
      acceptInvitationError: 'Não foi possível aceitar o convite ({message})',
      deleteUser: 'Usuário excluído',
      deleteUserError: 'Não foi possível excluir o usuário',
      updateTemplate: 'O modelo foi atualizado',
      updateTemplateError: 'Não foi possível atualizar o modelo',
      deleteTemplate: 'O modelo foi excluído',
      deleteTemplateError: 'Não foi possível excluir o modelo',
      createTemplate: 'O modelo foi criado',
      createTemplateError: 'Não foi possível criar um modelo',
      deleteBatch: 'Lote excluído',
      deleteBatchError: 'O lote foi usado. Não foi possível excluir o Lote.',
      updateBatch: 'O lote foi atualizado',
      createBatch: 'Lote criado',
      createBatchError: 'O ID do lote já existe. Não foi possível criar um Lote.',
      identificationCode: 'Código de identificação foi criado',
      validateIdentifierError: 'Não foi possível validar um identificador',
      addFileError: 'Por favor, adicione um arquivo',
      genericError: 'Erro genérico',
      createValueChain: 'A cadeia de valor foi criada',
      createValueChainError: 'Não foi possível criar uma cadeia de valor',
      updateValueChain: 'A cadeia de valor foi atualizada',
      updateValueChainError: 'Não foi possível atualizar a cadeia de valor',
      deleteValueChain: 'A cadeia de valor foi excluída',
      deleteValueChainError: 'Não foi possível excluir a cadeia de valor',
      updateCategory: 'A categoria foi atualizada',
      updateCategoryError: 'Não foi possível atualizar a categoria',
      deleteCategory: 'A categoria foi excluída',
      deleteCategoryError: 'Não foi possível excluir a categoria',
      CreateCategory: 'A categoria foi criada',
      createCategoryError: 'Não foi possível criar a categoria',
    },
    validation: {
      minNumMaxNum: 'Mínimo {0} Máx. {1} caracteres',
      maxNum: 'Máximo de {0} caracteres',
    },
    uploadFile: {
      maxSize: '(Tamanho máximo 10 MB)',
    },
  },
  organization: {
    myOrganizations: 'Minhas organizações',
    viewAllOrganizations: 'Ver todas as organizações',
  },
  interaction: {
    batchRecord: 'Registro de lote',
    unknownId: 'ID DESCONHECIDO',
    externalId: 'ID EXTERNO',
    modal: {
      eventClassification: 'Classificação do evento',
      unclassified: 'Não classificado',
      default: 'Predefinição',
    },
    filters: {
      notice: 'Perceber',
      alert: 'Alerta',
      warning: 'Aviso',
    },
    hidden: 'Escondido',
  },

  interaction_action_0: 'Usuário notificado:',
  interaction_action_1: 'Status do item alterado para:',
  interaction_action_2: 'O perfil de suporte do item foi atribuído.',
  interaction_action_3: 'O perfil de suporte do item foi removido.',
  interaction_action_4: 'Notificação:',
  interaction_action_5: 'Aviso:',
  interaction_action_6: 'Alerta:',
  interaction_administrative_title: 'Mudança administrativa',
  task_steps: {
    status_check: 'O status atual do item é: {status}',
  },
  item_status: '{status}',
  interaction_collections_title: 'Coleção - {texto}',
  interaction_collection_added_title: 'Adicionado a uma coleção - {text}',
  step_collection_fields: 'Campos:',
  publicPage: {
    positioning: {
      before: 'Antes de',
      after: 'Depois de',
      top: 'Topo',
      bottom: 'Inferior',
    },
    components: {
      longDescription: 'longa descrição',
      attachments: 'Anexos',
      attributes: 'Atributos',
      services: 'Serviços',
      gallery: 'Galeria',
      certifications: 'Certificações',
      video: 'Vídeo',
      history: 'História',
      supportProfile: 'Perfil de suporte',
      fs1TaskPanel: 'Painel de Tarefas FS1',
      fs1About: 'FS1 Sobre',
      fs1FactsPanel: 'Painel de Fatos FS1',
      fs1ResourcesPanel: 'Painel de Recursos FS1',
      events: 'Eventos',
    },
  },
  OrganisationalSettings: {
    header: 'Configurações da organização',
    components: {
      general: 'Em geral',
      branding: 'Marca',
    },
    general: {
      name: 'Nome',
      businessType: 'Tipo de Negócio',
      OrganizationLogo: 'Logo da Organização',
      PublicPageLoadingImage: 'Imagem de carregamento de página pública',
    },
  },
  tasks: {
    tableHeader: {
      name: 'Nome da tarefa',
      usedAsDiscovery: 'Tarefa de descoberta',
      interactions: 'Contagem de uso',
      things: 'Alocações',
      createdAt: 'Criado em',
      createdBy: 'Criado por',
      displayConditions: 'Condições de exibição',
    },
    taskModal: {
      headerCreate: 'Criar uma tarefa',
      headerEdit: 'Editar tarefa',
      components: {
        selectTask: 'Selecionar tarefa',
      },
    },
  },
  categories: {
    title: 'Categoria',
    noDataMessage1: 'Criar uma categoria',
    tableHeaders: {
      name: 'Nome',
      defaultTemplate: 'Modelo padrão',
      createdAt: 'Criado em',
      createdBy: 'Criado por',
    },
    editCategoryModal: {
      createCategory: 'Crie uma categoria',
      editCategory: 'Editar uma categoria',

      labels: {
        name: 'Nome da Categoria',
        standard: 'Padrão de Identificação',
        identifier: 'Identificador de categoria',
        description: 'Descrição',
        image: 'Imagem da categoria',
        defaultTemplate: 'Cada produto criado para esta categoria será definido para este modelo por padrão.',
      },
    },
  },
  supportProfile: {
    title: 'Perfil de suporte',
    addSupportProfile: 'Adicionar perfil de suporte',
    createMessage1: 'Criar um perfil de suporte',
    tableHeaders: {
      name: 'Nome',
      content: 'Contente',
      displayConditions: 'Condições de exibição',
      contactMethods: 'Métodos de contato',
    },
    editSupportProfileModal: {
      createSupportProfile: 'Criar perfil de suporte',
      editSupportProfile: 'Editar perfil de suporte',
      labels: {
        name: 'NOME',
        headerText: 'TEXTO DO CABEÇALHO',
        mainText: 'TEXTO PRINCIPAL',
        mainContent: 'CONTEÚDO PRINCIPAL',
        showExpanded: 'MOSTRAR EXPANDIDO',
        phone: 'TELEFONE',
        email: 'O EMAIL',
        imagery: 'IMAGENS',
        primary: 'PRIMÁRIO',
        secondary: 'SECUNDÁRIO',
        kmlMapType: 'Tipo de mapa KML',
        kmlLink: 'link KML',
        kmlFile: 'Arquivo KML',
      },
    },
  },
  templates: {
    title: 'Modelo',
    tableHeaders: {
      name: 'Nome',
      createdAt: 'Criado em',
      createdBy: 'Criado por',
      assignedCategories: 'Categorias atribuídas',
    },
    editTemplateModal: {
      createTemplate: 'Criar um modelo',
      editTemplate: 'Editar um modelo',
      labels: {
        templateName: 'Nome do modelo',
        templateDescription: 'Descrição do modelo',
        templateImage: 'Imagem do modelo',
      },
    },
    templateCreatedModal: {
      title: 'Modelo criado',
      message: 'Modelo criado com sucesso, deseja gerenciar agora e adicionar atributos, tarefas, imagens e outros conteúdos?',
      later: 'Faça isso depois',
      now: 'Gerenciar agora',
    },
    templateCreatedProductModal: {
      title: 'Produto criado',
      message: 'Produto criado com sucesso, você deseja gerenciar agora e adicionar atributos, tarefas, imagens e mais conteúdo?',
    },
  },
  user: {
    viewInvitations: 'Ver convites',
    inviteUsers: 'Convidar usuários',
    to: 'para',
    userHeaders: {
      name: 'Nome',
      email: 'E-mail',
      role: 'Função',
      access: 'Acesso',
      status: 'Status',
    },
    userType: {
      admin: 'Administrador',
      member: 'Membro',
    },
  },
  webhooks: {
    webhookHeaders: {
      name: 'Nome',
      createdAt: 'Criado em',
      createdBy: 'Criado por',
    },
    webhookModal: {
      headerCreate: 'ação para começar a compartilhar as interações de sua organização com membros da equipe, outros sistemas e terceiros confiáveis.',
      components: {
        emailSettings: 'Configurações de e-mail',
        webhookSettings: 'Configurações do webhook',
        integrations: 'Integrações',
        thingsAndTasks: 'Coisas e tarefas',
      },
    },
    actions: 'Ações',
    editAction: 'Editar ação',
  },
  valueChain: {
    headerCreate: 'Cadeia de valor',
    headerEdit: 'Editar uma cadeia de valor',
    editAChainElement: 'Editar um elemento de cadeia',
    createAChainElement: 'Criar um elemento de cadeia',
    headerDescription: 'Selecione um modelo para ajudá-lo a começar.',
    organisationActivity: 'Atividade da Organização',
    components: {
      miscellaneousContent: 'Conteúdo diverso',
      logisticsAndEnvironment: 'Logística e Meio Ambiente',
      generalInformation: 'Informações gerais',
    },
    tableHeaders: {
      creationDate: 'Data de criação',
      createdBy: 'Criado por',
    },
    templates: {
      justTheBasics: {
        name: 'Apenas o básico',
        description: 'A cadeia de valor padrão usada no Assure+.',
      },
      beverage: {
        name: 'Bebida',
        description:
          'A capacidade de rastrear e rastrear produtos de bebidas em toda a cadeia de suprimentos agora está sendo impulsionada pelo consumidor. Eles querem saber a origem de todos os produtos até o campo onde os ingredientes são cultivados e as instalações que produzem os materiais de apoio e suprimentos de embalagem.',
      },
      coffee: {
        name: 'Café',
        description: 'Antes de chegar à sua xícara matinal, os grãos de café viajam por uma complexa cadeia de suprimentos global.',
      },
      honey: {
        name: 'Mel',
        description: 'O mel percorre uma complexa cadeia de suprimentos global, da colmeia ao consumidor.',
      },
      porterValueChain: {
        name: 'Cadeia de Valor do Porteiro',
        description:
          'Em vez de olhar para departamentos ou tipos de custos contábeis, a Cadeia de Valor de Porter se concentra em sistemas e como as entradas são transformadas nas saídas compradas pelos consumidores.',
      },
      usaidCommodity: {
        name: 'Mercadoria USAid',
        description:
          'O processo começa quando os pedidos de alimentos são feitos à USAID, que solicita propostas de contratos e adquire produtos alimentares para pedidos aprovados. As mercadorias são então enviadas dos fornecedores para os pontos de transporte ou instalações de armazenamento em diferentes nós logísticos da cadeia de abastecimento, incluindo portos dos EUA, portos de descarga no exterior e armazéns de pré-posicionamento da USAID e dos EUA. transferidos para organizações parceiras, que são então responsáveis pela distribuição dos produtos alimentícios usando suas próprias redes de parceiros de transporte e distribuição, bem como seus próprios sistemas de rastreamento de informações.',
      },
      wine: {
        name: 'Vinho',
        description:
          'A cadeia de valor do vinho consiste em cinco grandes fases de produção: preparação da vinha, produção da uva (viticultura), produção do vinho (vinicultura), logística e distribuição, marketing e vendas.',
      },
    },
  },
  documents: {
    title: 'Documentos',
    labels: {
      documentName: 'Nome do Documento',
      gdtiNumber: 'Número GDTI',
      valueChainSteps: 'Etapa da Cadeia de Valor',
      publicRecord: 'Registro público',
      dateModified: 'Data modificada',
    },
    valueChainSteps: {
      Unclassified: 'Não classificado',
    },
  },
  products: {
    tableHeaders: {
      name: 'Nome',
      category: 'Categoria',
      tasks: 'Tarefas atribuídas',
      interactions: 'Eventos gravados',
      items: 'Itens',
    },
    menuOptions: {
      manage: 'Gerenciar',
      duplicate: 'Duplicado',
      updateFromClipboard: 'Atualizar da área de transferência',
    },
    bulkActions: {
      addTasks: 'Adicionar tarefas',
      removeTasks: 'Remover tarefas',
      addValueChain: 'Adicionar Cadeia de Valor',
      addSupportProfile: 'Adicionar perfil de suporte',
      removeSupportProfile: 'Remover perfil de suporte',
    },
    productCreatedModal: {
      title: 'Produto criado',
      message: 'Produto criado com sucesso, você deseja gerenciar agora e adicionar atributos, tarefas, imagens e mais conteúdo?',
      later: 'Faça isso depois',
      now: 'Gerenciar agora',
    },
    title: 'Produtos',
  },
  collaborations: {
    header: {
      step: 'Degrau',
      title: 'Atualizar da área de transferência',
    },
    subTitles: {
      general: 'Em geral',
      productInfo: 'informação do produto',
      attributes: 'Atributos',
      productDocuments: 'Documentos',
      itemAndBatchDocuments: 'Documentos de Item e Lote',
      images: 'Imagens',
      videos: 'Vídeos',
      eventHistory: 'Histórico de eventos',
      contentTransferSummary: 'Resumo da transferência de conteúdo',
    },
    descriptiveContent: {
      noData: 'Não há informações gerais disponíveis.',
      description: 'As Informações Gerais serão transferidas em nível de produto e serão adicionadas a todos os lotes.',
      target: 'Alvo',
      source: 'Fonte',
      labels: {
        name: 'Nome',
        shortDescription: 'Pequena descrição',
        longDescription: 'longa descrição',
      },
      longDescriptionButtons: {
        append: 'Acrescentar',
        replace: 'Substituir',
      },
    },
    attributes: {
      noData: 'Não há atributos disponíveis.',
      description: 'Os atributos serão transferidos em um nível de produto e serão adicionados a todos os lotes.',
      warningMessage: 'Os Atributos Selecionados serão aplicados a todos os lotes do produto',
    },
    itemAndBatchDocuments: {
      noData: 'Não há documentos disponíveis.',
      warningMessage: 'Documentos selecionados serão adicionados apenas ao respectivo item de destino ou lote do produto',
    },
    images: {
      noData: 'Não há imagens disponíveis.',
      description: 'As imagens serão transferidas em um nível de produto e serão adicionadas a todos os lotes.',
      selectAllButton: 'Selecionar tudo',
    },
    videos: {
      noData: 'Não há vídeos disponíveis.',
      description: 'Os vídeos serão transferidos no nível do produto e serão adicionados a todos os lotes.',
      selectAllButton: 'Selecionar tudo',
    },
    eventHistory: {
      noData: 'Não há tarefas disponíveis com conteúdo público.',
      noItemBatch: 'Não há item ou lote disponível para armazenar eventos.',
      labels: {
        name: 'Nome',
        classification: 'Classificação',
      },
    },
    confirmationScreen: {
      noData: 'Nenhum conteúdo selecionado.',
      message: 'Atualizando da área de transferência...',
      successfulMessage: 'Atualização da área de transferência bem-sucedida',
    },
  },
  item: {
    filters: {
      doNotUse: 'Não use',
      allClear: 'Tudo limpo',
      normalPrecaution: 'Precaução Normal',
      reconsiderUse: 'Reconsiderar o uso',
      increasedCaution: 'Atenção redobrada',
      unknownId: 'ID desconhecido',
      itemOk: 'Item OK',
      ready: 'Preparar',
      active: 'Ativo',
      resultPending: 'Resultado pendente',
      completedPositive: 'Positivo Concluído',
      completedNegative: 'Negativo concluído',
      completedInconclusive: 'Concluído Inconclusivo',
      itemLocked: 'Item bloqueado',
      itemUnlocked: 'Item desbloqueado',
      unused: 'Não usado',
      returned: 'Retornou',
      readyFilled: 'Pronto preenchido',
      readyPacked: 'Pronto Embalado',
      withCustomer: 'Com Cliente',
      issueRaised: 'Problema levantado',
      pickupRequested: 'Solicitação de retirada',
    },
  },
  thing: {
    menus: {
      about: 'Sobre',
      discovery: 'Descoberta',
      public: 'Público',
      tasks: 'Tarefas',
      support: 'Apoiar',
    },
    about: {
      general: 'Em geral',
      category: 'Categoria',
      documentation: 'Documentação',
      attributes: 'Atributos',
      certifications: 'Certificações',
      miscellaneous: 'Diversos',
      valueChains: 'Cadeias de valor',
      gallery: 'Galeria',
      advancedSettings: 'Configurações avançadas',
      changeACategory: 'Alterar uma categoria',
      changeAValueChain: 'Alterar uma cadeia de valor',

      certificationsLabel: {
        addCertification: 'Adicionar certificação',
        editCertification: 'Editar certificação',
      },
    },
    public: {
      generalSettings: 'Configurações Gerais',
      enablePublicContent: 'Ativar conteúdo público',
      itemCheck: 'Verificação de itens',
      usedAsDiscovery: 'Tarefa de descoberta',
      advancedSettings: 'Configurações avançadas',
      rfiderAuthenticityCheck: 'Verificação de autenticidade do Assure+',
      basicInformation: 'Informação básica',
      brandExperience: 'Experiência de marca',
      links: 'Links',
      miscellaneous: 'Diversos',
      attributes: 'Atributos',
      services: 'Serviços',
      documents: 'Documentos',
      gallery: 'Galeria',
      certifications: 'Certificações',
      videos: 'Vídeos',
      longDescription: 'longa descrição',
      supportProfile: 'Perfil de suporte',
      itemHistory: 'Histórico de itens',
      none: 'Nenhum',
      heroImage: 'Imagem do herói',
      sectionImage: 'Imagem da seção',
      heroOverlayImage: 'Imagem de sobreposição de herói',
      privacyPolicy: 'Política de Privacidade',
      productInfo: 'Informação do produto',
      learnMore: 'Saber mais',
      shopping: 'Compras',
      section: 'Seção',
      position: 'Posição',
      enableAttributes: 'Ativar atributos',
      attributeVisibility: 'Visibilidade do atributo',
      enableAttachments: 'Ativar anexos',
      enableCertifications: 'Ativar certificações',
      allowProfles: 'Permitir perfis pessoais dos membros da equipe',
      surviceLabels: {
        enableServices: 'Ativar serviços',
        serviceVisibility: 'Visibilidade do serviço',
        basic: 'Básico',
        off: 'Fora',
        peerToPeer: 'Pessoa para pessoa',
      },
      itemHistoryLabels: {
        enableItemHistory: 'Ativar histórico de itens',
        displayTemplate: 'Modelo de exibição',
        events: 'Eventos',
        data: 'Dados',
        images: 'Imagens',
        eventGallery: 'Galeria de eventos',
        enableEventGallery: 'Ativar Galeria de Eventos',
      },
      switch: {
        confirmTextEnable: 'Tem certeza de que deseja habilitar?',
        confirmTextDisable: 'Tem certeza de que deseja desativar?',
        confirmButtonEnable: 'Sim, habilite!',
        confirmButtonDisable: 'Sim, desative-o!&#39;',
      },
      enablePublicLink: 'Ativar link público',
      attributeLabels: {
        editAttribute: 'Editar atributo',
        addAttribute: 'Adicionar atributo',
        label: 'Rótulo',
        value: 'Valor',
        type: 'Tipo',
      },
    },
  },
  filter: {
    createdByMe: 'Criado por mim',
  },
  swal: {
    duplicate: 'Duplicando',
    confirmTitle: 'Tem certeza?',
    warningMessage: 'Você não será capaz de reverter isso!',
    confirmButtonDelete: 'Sim, exclua!',
    confirmButtonRemove: 'Sim, remova-o!',
    confirmButtonDuplicate: 'Sim, duplicar!',
    confirmButtonReset: 'Sim, redefini-lo!',
    confirmButtonResend: 'Sim, reenvie!',
    confirmButtonDecline: 'Sim, recuse!',
    confirmButtonAccept: 'Sim, aceite!',
    keepIt: 'Não, guarde',
    userText: 'Você precisará convidá-los novamente.',
    associationError: 'Associações não podem ser criadas!',
    template: {
      confirmButtonText: 'Sim, crie um novo produto!',
      createProduct: 'Criando um produto',
      successMessage: 'Um novo produto foi criado a partir deste modelo',
      errorMessage: 'Novo produto não pode ser criado a partir deste modelo',
      successMessage1: 'O modelo foi duplicado',
    },
    products: {
      successMessage: 'O produto foi duplicado',
      errorMessage: 'O produto não pode ser duplicado',
      createProductError: 'Novo produto não pode ser criado a partir deste modelo',
    },
    valueChain: {
      successMessage: 'A cadeia de valor foi duplicada',
      errorMessage: 'Não foi possível duplicar a cadeia de valor',
      successMessageThingAbout: 'A cadeia de valor deste produto foi alterada',
      errorMessageThingAbout: 'Não foi possível alterar uma cadeia de valor para este produto',
      thingValueChainText: 'Recomendado que isso não seja alterado.',
      lastChance: 'Última chance',
      lastChanceText: 'Quaisquer dados coletados até este ponto não serão exibidos na visão da cadeia de valor. Essa alteração não pode ser desfeita.',
    },
    user: {
      successMessage: 'Reenviar o convite ao usuário',
      errorMessage: 'Não foi possível reenviar o convite ao usuário',
      successMessage1: 'Usuário atualizado',
      errorMessage1: 'Não foi possível atualizar o usuário',
    },
    invitations: {
      titleDecline: 'Recusar convite?',
      titleAccept: 'Aceitar convite?',
      text: 'Você está convidado a participar',
    },
    category: {
      successMessageThingAbout: 'A categoria deste produto foi alterada',
      errorMessageThingAbout: 'Não foi possível alterar uma categoria para este produto',
      thingCategoryText: 'Recomendado que isso não seja alterado.',
      lastChance: 'Última chance',
      lastChanceText: 'Essa alteração não pode ser desfeita.',
    },
    document: {
      createDocument: 'O documento foi criado',
      createDocumentError: 'Não foi possível criar um documento',
      updateDocument: 'O documento foi atualizado',
      updateDocumentError: 'Não foi possível atualizar o documento',
      deleteDocument: 'O documento foi excluído',
      deleteDocumentError: 'Não foi possível excluir o documento',
    },
    task: {
      text: 'Você está prestes a sair da configuração da tarefa sem salvar suas alterações. Todas as alterações não salvas serão perdidas. Você deseja continuar?',
      deleteTask: 'A tarefa foi excluída',
      updateTask: 'A tarefa foi atualizada',
      updateTaskError: 'Não foi possível atualizar a tarefa',
      createTask: 'A tarefa foi criada',
      createTaskTitle: 'Criado com sucesso!',
      createTaskText: 'Deseja abrir sua configuração?',
      labels: {
        configuration: 'Configuração',
      },
    },
    interaction: {
      createSuccess: 'Interação criada',
      createError: 'Não foi possível criar uma interação.',
      mapLoadMessage: 'Erro de carregamento',
      mapLoadError: 'Não foi possível carregar as interações no mapa',
      updateSuccess: 'A interação foi atualizada',
      updateError: 'A interação não pode ser atualizada',
      deleteSuccess: 'A interação foi excluída',
      deleteError: 'A interação não pode ser excluída.',
    },
    item: {
      itemsLimitError: 'O limite para opções de entrada é de apenas {itemsLimit} itens.',
      removeSupportProfile: {
        title: 'Tem certeza?',
        success: 'O perfil de suporte pessoal removido',
        error: 'Não foi possível remover o perfil de suporte pessoal',
      },
    },
    miscellaneous: {
      noImageError: 'Por favor, adicione uma imagem',
      undefinedNotificationType: 'Escolha um dos tipos de notificação',
    },
    webhooks: {
      deleteAction: 'A ação foi excluída',
      deleteActionError: 'Não foi possível excluir a ação',
      updateActionStatus: 'O status da ação foi atualizado',
      updateActionStatusError: 'Não foi possível atualizar o status da ação',
      updateAction: 'A ação foi atualizada',
      updateActionError: 'Não foi possível atualizar a ação',
      createWebhook: 'Webhook criado',
    },
  },
  invitations: {
    title: 'Convites',
    records: 'Registros',
  },
  languages: {
    english: 'English',
    german: 'Deutsch',
    spanish: 'Español',
    japanese: '日本',
    korean: '한국인',
    dutch: 'Nederlands',
    portuguese: 'Português',
    chinese: '中国人',
    chineseTraditional: '中國人',
  },
  editMiscellaneous: {
    editMiscellaneous: 'Editar Diversos',
    addMiscellaneous: 'Adicionar Diversos',
    editVideo: 'Editar vídeo',
    addVideo: 'Adicionar vídeo',
    editOptions: 'Editar opções',
    addOptions: 'Adicionar opções',
    labels: {
      embeddedLink: 'APENAS LINK INCORPORADO',
      videoEmbed: 'CÓDIGO DE INCORPORAÇÃO DO VÍDEO',
      codeInjection: 'Injeção de código',
      imageLink: 'LINK DA IMAGEM',
    },
  },
  discovery: {
    labels: {
      batchId: 'ID do lote',
      batchName: 'Nome do lote',
      createdBy: 'Criado por',
      itemCount: 'Contagem de itens',
      used: '% Usado',
    },
    buttons: {
      identificationMethod: 'Método de identificação',
      createBatch: 'Criar lote',
      deactivate: 'Desativar',
      activate: 'Ativar',
      delete: 'Excluir',
    },
    maximumItemsPerBatch: 'Máximo de itens por lote',
    batches: 'Lotes',
    beta: 'Beta',
    item: 'Item',
    product: 'produtos',
    selectIdentificationMethod: 'Selecione o método de identificação',
    message: 'O Assure+ Unique ID (RFUID) é um identificador gerado pelo sistema de itens gerenciados na plataforma Assure+.',
  },
  dashboard: {
    dateRange: {
      today: 'Hoje',
      lastSevenDays: 'Últimos 7 dias',
      lastWeek: 'Semana Anterior',
      lastThirtyDays: 'Últimos 30 dias',
      lastMonth: 'Mês passado',
      lastTwelvemonths: 'Últimos 12 meses',
      custom: 'Personalizado',
    },
    chart: {
      today: 'Hoje',
      allTime: 'Tempo todo',
      totalInteractions: 'Interações totais',
      totalTasks: 'Total de Tarefas',
      totalServices: 'Serviços totais',
      noData: 'Sem dados',
      overallStatusBreakdown: 'Detalhamento geral do status',
    },
  },
  displayConditionsRules: {
    onlyAdmin: 'Permissões do usuário',
    or: 'ou',
    andAlso: 'E também',
    addYourFirstCondition: 'Adicione sua primeira condição',
    useSeparator: 'Use vírgula como separador',
    useDashSeparator: 'Use traço (-) como separador',
    rfuidItemId: 'ID do item RFUID',
    rfuidBatchId: 'ID do Lote RFUID',
    rfuidSequenceNumber: 'Número de sequência de RFUID',
    itemStatus: 'Status do item',
    userCountry: 'País do usuário',
    userLanguage: 'Idioma do usuário',
    conditions: {
      stringEquals: 'String é igual',
      stringNotEquals: 'String não é igual',
      includedIn: 'Incluído em',
      notIncludedIn: 'Não incluído em',
      numberEquals: 'Número é igual',
      numberNotEquals: 'Número não é igual',
      numberGreaterThan: 'Número maior que',
      numberLessThan: 'Número menor que',
      between: 'entre',
    },
    countries: {
      afghanistan: 'Afeganistão',
      alandIslands: 'Ilhas Aland',
      albania: 'Albânia',
      algeria: 'Argélia',
      americanSamoa: 'Samoa Americana',
      andorra: 'Andorra',
      angola: 'Angola',
      anguilla: 'Anguila',
      antarctica: 'Antártica',
      antigua: 'Antígua e Barbuda',
      argentina: 'Argentina',
      armenia: 'Armênia',
      aruba: 'Aruba',
      australia: 'Austrália',
      austria: 'Áustria',
      azerbaijan: 'Azerbaijão',
      bahamas: 'Bahamas',
      bahrain: 'Bahrein',
      bangladesh: 'Bangladesh',
      barbados: 'Barbados',
      belarus: 'Bielorrússia',
      belgium: 'Bélgica',
      belize: 'Belize',
      benin: 'Benim',
      bermuda: 'Bermudas',
      bhutan: 'Butão',
      bolivia: 'Bolívia',
      bonaire: 'Bonaire',
      bosnia: 'Bósnia e Herzegovina',
      botswana: 'Botsuana',
      bouvet: 'Ilha Bouvet',
      brazil: 'Brasil',
      britishIndian: 'Território Britânico do Oceano Índico',
      brunei: 'Brunei Darussalam',
      bulgaria: 'Bulgária',
      burkina: 'Burkina Faso',
      burundi: 'Burundi',
      cambodia: 'Camboja',
      cameroon: 'Camarões',
      canada: 'Canadá',
      capeVerde: 'cabo Verde',
      caymanIslands: 'Ilhas Cayman',
      centralAfricanRepublic: 'República Centro-Africana',
      chad: 'Chade',
      chile: 'Chile',
      china: 'China',
      christmasIsland: 'Ilha do Natal',
      cocos: 'Ilhas Cocos (Keeling)',
      colombia: 'Colômbia',
      comoros: 'Comores',
      congo: 'Congo',
      congoDemocratic: 'Congo',
      cookIslands: 'Ilhas Cook',
      costaRica: 'Costa Rica',
      coteIvo: 'Costa do Marfim',
      croatia: 'Croácia',
      cuba: 'Cuba',
      curacao: 'Curaçao',
      cyprus: 'Chipre',
      czechRepublic: 'República Checa',
      denmark: 'Dinamarca',
      djibouti: 'Djibuti',
      dominica: 'Dominica',
      dominicanRepublic: 'República Dominicana',
      ecuador: 'Equador',
      egypt: 'Egito',
      elSalvador: 'O salvador',
      equatorialGuinea: 'Guiné Equatorial',
      eritrea: 'Eritreia',
      estonia: 'Estônia',
      ethiopia: 'Etiópia',
      falkland: 'Ilhas Malvinas (Malvinas)',
      faroeIslands: 'ilhas Faroe',
      fiji: 'Fiji',
      finland: 'Finlândia',
      france: 'França',
      frenchGuiana: 'Guiana Francesa',
      frenchPolynesia: 'Polinésia Francesa',
      frenchSouthern: 'Territórios do Sul da França',
      gabon: 'Gabão',
      gambia: 'Gâmbia',
      georgia: 'Geórgia',
      germany: 'Alemanha',
      ghana: 'Gana',
      gibraltar: 'Gibraltar',
      greece: 'Grécia',
      greenland: 'Groenlândia',
      grenada: 'Granada',
      guadeloupe: 'Guadalupe',
      guam: 'Guam',
      guatemala: 'Guatemala',
      guernsey: 'Guernsey',
      guinea: 'Guiné',
      guineaBissau: 'Guiné-Bissau',
      guyana: 'Guiana',
      haiti: 'Haiti',
      heardIsland: 'Ilha Heard e Ilhas McDonald',
      holySee: 'Santa Sé (Estado da Cidade do Vaticano)',
      honduras: 'Honduras',
      hongKong: 'Hong Kong',
      hungary: 'Hungria',
      iceland: 'Islândia',
      india: 'Índia',
      indonesia: 'Indonésia',
      iran: 'Irã',
      iraq: 'Iraque',
      ireland: 'Irlanda',
      isleOfMan: 'Ilha de Man',
      israel: 'Israel',
      italy: 'Itália',
      jamaica: 'Jamaica',
      japan: 'Japão',
      jersey: 'Jersey',
      jordan: 'Jordânia',
      kazakhstan: 'Cazaquistão',
      kenya: 'Quênia',
      kiribati: 'Kiribati',
      korea: 'Coréia',
      koreaRepublic: 'Coréia',
      kuwait: 'Kuwait',
      kyrgyzstan: 'Quirguistão',
      lao: 'Trabalho',
      latvia: 'Letônia',
      lebanon: 'Líbano',
      lesotho: 'Lesoto',
      liberia: 'Libéria',
      libya: 'Líbia',
      liechtenstein: 'Listenstaine',
      lithuania: 'Lituânia',
      luxembourg: 'Luxemburgo',
      macao: 'Macau',
      macedonia: 'Macedônia',
      madagascar: 'Madagáscar',
      malawi: 'Malawi',
      malaysia: 'Malásia',
      maldives: 'Maldivas',
      mali: 'Mali',
      malta: 'Malta',
      marshall: 'Ilhas Marshall',
      martinique: 'Martinica',
      mauritania: 'Mauritânia',
      mauritius: 'Maurício',
      mayotte: 'Maiote',
      mexico: 'México',
      micronesia: 'Micronésia',
      moldova: 'Moldávia',
      monaco: 'Mônaco',
      mongolia: 'Mongólia',
      montenegro: 'Montenegro',
      montserrat: 'Montserrat',
      morocco: 'Marrocos',
      mozambique: 'Moçambique',
      myanmar: 'Mianmar',
      namibia: 'Namíbia',
      nauru: 'Nauru',
      nepal: 'Nepal',
      netherlands: 'Holanda',
      newCaledonia: 'Nova Caledônia',
      newZealand: 'Nova Zelândia',
      nicaragua: 'Nicarágua',
      niger: 'Níger',
      nigeria: 'Nigéria',
      niue: 'Niue',
      norfolk: 'Ilha Norfolk',
      northern: 'Ilhas Marianas do Norte',
      norway: 'Noruega',
      oman: 'Omã',
      pakistan: 'Paquistão',
      palau: 'Palau',
      palestinian: 'Território Palestino, Ocupado',
      panama: 'Panamá',
      papua: 'Papua Nova Guiné',
      paraguay: 'Paraguai',
      peru: 'Peru',
      philippines: 'Filipinas',
      pitcairn: 'Pitcairn',
      poland: 'Polônia',
      portugal: 'Portugal',
      puertoRico: 'Porto Rico',
      qatar: 'Catar',
      reunion: 'Reunião',
      romania: 'Romênia',
      russian: 'Federação Russa',
      rwanda: 'Ruanda',
      saintBarth: 'São Bartolomeu',
      saintHelena: 'Santa Helena',
      saintKitts: 'São Cristóvão e Nevis',
      saintLucia: 'Santa Lúcia',
      saintMartin: 'São Martinho (parte francesa)',
      saintPierre: 'São Pedro e Miquelon',
      saintVincent: 'São Vicente e Granadinas',
      samoa: 'Samoa',
      sanMarino: 'São Marinho',
      saoTome: 'São Tomé e Príncipe',
      saudiArabia: 'Arábia Saudita',
      senegal: 'Senegal',
      serbia: 'Sérvia',
      seychelles: 'Seicheles',
      sierra: 'Serra Leoa',
      singapore: 'Cingapura',
      sintMaarten: 'Sint Maarten (parte holandesa)',
      slovakia: 'Eslováquia',
      slovenia: 'Eslovênia',
      solomon: 'Ilhas Salomão',
      somalia: 'Somália',
      southAfrica: 'África do Sul',
      southGeorgia: 'Ilhas Geórgia do Sul e Sandwich do Sul',
      southSudan: 'Sudão do Sul',
      spain: 'Espanha',
      sriLanka: 'Sri Lanka',
      sudan: 'Sudão',
      suriname: 'Suriname',
      svalbard: 'Svalbard e Jan Mayen',
      swaziland: 'Suazilândia',
      sweden: 'Suécia',
      switzerland: 'Suíça',
      syrian: 'República Árabe da Síria',
      taiwan: 'Taiwan',
      tajikistan: 'Tajiquistão',
      tanzania: 'Tanzânia',
      thailand: 'Tailândia',
      timorLeste: 'Timor-Leste',
      togo: 'Ir',
      tokelau: 'Toquelau',
      tonga: 'Tonga',
      trinidad: 'Trindade e Tobago',
      tunisia: 'Tunísia',
      turkey: 'Peru',
      turkmenistan: 'Turcomenistão',
      turksAndCaicosIslands: 'Ilhas Turcas e Caicos',
      tuvalu: 'Tuvalu',
      uganda: 'Uganda',
      ukraine: 'Ucrânia',
      uae: 'Emirados Árabes Unidos',
      uk: 'Reino Unido',
      usa: 'Estados Unidos',
      usMinor: 'Ilhas Menores Distantes dos Estados Unidos',
      uruguay: 'Uruguai',
      uzbekistan: 'Uzbequistão',
      vanuatu: 'Vanuatu',
      venezuelaBolivarian: 'Venezuela',
      vietnam: 'Vietnã',
      virginIslandsBritish: 'Ilhas Virgens',
      virginIslandsUS: 'Ilhas Virgens',
      wallisAndFutuna: 'Wallis e Futuna',
      westernSahara: 'Saara Ocidental',
      yemen: 'Iémen',
      zambia: 'Zâmbia',
      zimbabwe: 'Zimbábue',
    },
    languages: {
      ab: 'abecásio',
      aa: 'Longe',
      af: 'afrikaans',
      ak: 'Vontade',
      sq: 'albanês',
      am: 'amárico',
      ar: 'árabe',
      an: 'aragonês',
      hy: 'armênio',
      as: 'Assamês',
      av: 'Avarico',
      ae: 'Avestan',
      ay: 'Aymara',
      az: 'azerbaijano',
      bm: 'Bambara',
      ba: 'Bashkir',
      eu: 'basco',
      be: 'bielorrusso',
      bn: 'bengali',
      bh: 'Línguas bihari',
      bi: 'Bislama',
      bs: 'bósnio',
      br: 'bretão',
      bg: 'búlgaro',
      my: 'birmanês',
      ca: 'Catalão; valenciano',
      km: 'Khmer Central',
      ch: 'Chamorro',
      ce: 'checheno',
      ny: 'Chichewa; Chewa; O mar',
      zhCn: 'Chinês simplificado)',
      zhTw: 'Chinês tradicional)',
      cu: 'Igreja eslava',
      cv: 'Chuvash',
      kw: 'Cornualha',
      co: 'corso',
      cr: 'acreditam',
      hr: 'croata',
      cs: 'tcheco',
      da: 'dinamarquês',
      dv: 'Divehi',
      nl: 'Holandês; flamengo',
      dz: 'Dzongkha',
      en: 'inglês',
      eo: 'esperanto',
      et: 'estoniano',
      ee: 'Ovelha',
      fo: 'Feroês',
      fj: 'fijiano',
      fi: 'finlandês',
      fr: 'francês',
      ff: 'Fula',
      gd: 'gaélico',
      gl: 'galego',
      lg: 'Dobro',
      ka: 'georgiano',
      de: 'alemão',
      el: 'grego',
      gn: 'Guarani',
      gu: 'Gujarati',
      ht: 'haitiano',
      ha: 'Hauçá',
      he: 'hebraico',
      hz: 'Herero',
      hi: 'Não.',
      ho: 'Hiri Motu',
      hu: 'húngaro',
      is: 'islandês',
      io: 'Perdido',
      ig: 'ibo',
      id: 'indonésio',
      iu: 'Inuktitut',
      ik: 'Inupiaq',
      ga: 'irlandês',
      it: 'italiano',
      ja: 'japonês',
      jv: 'javanês',
      kl: 'groenlandês',
      kn: 'canarês',
      kr: 'Kanuri',
      ks: 'Caxemira',
      kk: 'cazaque',
      ki: 'Kikuyu; Gikuyu',
      rw: 'Kinyarwanda',
      ky: 'quirguiz; Quirguistão',
      kv: 'Vamos',
      kg: 'Congo',
      ko: 'coreano',
      kj: 'Animais; Na carne',
      ku: 'curdo',
      lo: 'Trabalho',
      la: 'latim',
      lv: 'letão',
      li: 'Limburger',
      ln: 'Lingala',
      lt: 'lituano',
      lu: 'Luba-Katanga',
      lb: 'luxemburguês',
      mk: 'macedônio',
      mg: 'malgaxe',
      ms: 'malaio',
      ml: 'malaiala',
      mt: 'maltês',
      gv: 'Manx',
      mi: 'maori',
      mr: 'Marathi',
      mh: 'marshallês',
      mn: 'mongol',
      na: 'Nauru',
      nv: 'Navajo; Navajo',
      ng: 'Ndonga',
      ne: 'Nepalês',
      se: 'Sami do Norte',
      no: 'norueguês',
      oj: 'Ojibwa',
      or: 'Oriya',
      om: 'Oromo',
      os: 'ossétia; ossético',
      pi: 'páli',
      pa: 'Punjabi; Punjabi',
      fa: 'persa',
      pl: 'polonês',
      ptBr: 'português',
      ps: 'Empurre para; pashto',
      qu: 'quíchua',
      ro: 'romena',
      rm: 'romanche',
      rn: 'Rodadas',
      ru: 'russo',
      sm: 'samoano',
      sg: 'Sango',
      sa: 'sânscrito',
      sc: 'sardo',
      sr: 'sérvio',
      sn: 'Shona',
      ii: 'Sichuan Yi; Nuosu',
      sd: 'Sindi',
      si: 'cingalês; cingalês',
      sk: 'eslovaco',
      sl: 'esloveno',
      so: 'somali',
      st: 'Sotão, Sul',
      es: 'espanhol',
      su: 'sundanês',
      sw: 'suaíli',
      ss: 'Swati',
      sv: 'sueco',
      tl: 'Tagalo',
      ty: 'taitiano',
      tg: 'tadjique',
      ta: 'tâmil',
      tt: 'tártaro',
      te: 'Telugu',
      th: 'tailandês',
      bo: 'tibetano',
      ti: 'Tigrinya',
      to: 'Tonga',
      ts: 'Tsonga',
      tn: 'Tswana',
      tr: 'turco',
      tk: 'turcomano',
      tw: 'Dois',
      ug: 'uigur; Uigur',
      uk: 'ucraniano',
      ur: 'urdu',
      uz: 'uzbeque',
      ve: 'Venda',
      vi: 'vietnamita',
      vo: 'Volapuk',
      wa: 'valão',
      cy: 'galês',
      fy: 'Frísio Ocidental',
      wo: 'Wolof',
      xh: 'Xhosa',
      yi: 'iídiche',
      yo: 'iorubá',
      za: 'Zhuang; Chuang',
      zu: 'zulu',
    },
  },
  itemSummary: {
    title: 'Informações do código do item',
    batchId: 'ID do lote',
    product: 'produtos',
    sequence: 'Seqüência',
    checksum: 'Soma de verificação',
    warning:
      'A verificação de autenticidade está ativada para este item. O acesso a partir do portal pode acionar protocolos de alerta de autenticidade, como atualização de status e aviso nas verificações subsequentes.',
    hideUrl: 'ocultar URL',
    showUrl: 'mostrar URL',
    copy: 'Copiar',
    itemUrlCopied: 'URL do item copiado.',
    productName: 'Nome do Produto',
    itemId: 'Código do Item',
    itemStatus: 'Status do Item',
    itemName: 'Nome do Item',
    batchName: 'Nome do Lote',
  },
  updateReportedData: {
    locationTitle: 'Localização',
    dateTimeTitle: 'Data e hora',
    dateTimeInteraction: 'Data de interação',
    dateTimeReported: 'Data reportada',
    reset: 'Redefinir',
    locationInteraction: 'Local de interação',
    locationReported: 'Local informado',
    reportedData: 'Dados relatados',
  },
};
export default pt;
