const zh = {
  common: {
    labels: {
      selected: '已选择：',
      searchByName: '按名称搜索',
      itemStatusMatches: '仅在项目状态匹配时显示',
      showTask: '留空以始终显示此任务',
      emailAddress: '电子邮件地址',
      name: '姓名',
      emailAddressWithComma: '电子邮件地址（逗号分隔）',
      emailSubject: '电子邮件主题（例如：{task} - {thing} 的 {name}，状态为：{status}）',
      endpointURL: '端点网址',
      securityHmacKey: '安全性（HMAC 密钥）',
      apiKey: 'ApiKey',
      organizationId: '组织 ID',
      xmlMessage: 'XML 消息',
      createYourFirst: '创建你的第一个',
      valueChain: '价值链。',
      category: '类别。',
      supportProfile: '支持配置文件。',
      task: '任务。',
      template: '模板。',
      description: '描述',
      learnMoreLink: '了解更多链接',
      image: '图片',
      displayImage: '显示图像',
      chainElementIcon: '链元素图标',
      transportationMethod: '运输方式',
      gln: 'GLN（全球位置号码）',
      status: '状态',
      thing: '事物',
      selectDateRange: '选择日期范围',
      searchBy: '搜索方式',
      itemImage: '物品图片',
      uploadAttachment: '上传附件',
      addInteractionTo: '将交互添加到',
      interactionName: '交互名称',
      steps: '脚步',
      displayName: '显示名称',
      title: '标题',
      attachments: '附件',
      currentStatus: '当前状态',
      country: '国家',
      language: '语言',
      items: '项目',
      interactions: '互动',
      tasks: '任务',
      product: '产品',
      item: '物品',
      selectLanguage: '选择语言',
      addBatch: '添加批次',
      editBatch: '编辑批次',
      filters: '过滤器',
      unknown: '未知',
      from: '从',
      learnMore: '了解更多',
      search: '搜索',
      url: '网址',
      addTask: '添加任务',
      at: '在',
    },
    buttons: {
      save: '保存',
      cancel: '取消',
      finish: '结束',
      submit: '提交',
      delete: '删除',
      remove: '消除',
      task: '任务',
      ok: '好的',
      logout: '登出',
      decline: '衰退',
      accept: '接受',
      inviteUsers: '邀请用户',
      send: '发送',
      addMore: '添加更多',
      createAnAction: '创建一个动作',
      removeIntegration: '删除集成',
      continue: '继续',
      update: '更新',
      back: '后退',
      next: '下一个',
      close: '关闭',
      upload: '上传',
      create: '创建',
      clear: '清除',
      apply: '申请',
      viewInDashboard: '在仪表板中查看',
      confirm: '确认',
      yes: '是的',
      no: '不',
      configure: '配置',
    },
    menus: {
      things: '事物',
      items: '项目',
      interactions: '互动',
      reportingAndAnalytics: '报告与分析',
      templates: '模板',
      settings: '设置',
      tasks: '任务',
      users: '用户',
      advancedSettings: '高级设置',
      valueChains: '价值链',
      organisationalSettings: '组织设置',
      language: '语言',
      catalogue: '目录',
    },
    menuItems: {
      edit: '编辑',
      delete: '删除',
      remove: '消除',
      manageConfiguration: '管理配置',
      setAsDefault: '设为默认',
      setAsPublic: '设为公开',
      setAsPrivate: '设置为私人',
      manage: '管理',
      duplicate: '复制',
      viewContent: '查看内容',
      viewSettings: '查看设置',
      displayToPublic: '向公众展示',
      hideFromPublic: '隐藏在公众面前',
      disable: '禁用',
      enable: '使能够',
      viewConditions: '查看条件',
      manageConditions: '管理条件',
      viewDisplayConditions: '查看显示条件',
      manageDisplayConditions: '管理显示条件',
      change: '改变',
      displayForBatch: '批次显示',
      viewOrUpdateClassification: '查看/更新分类',
      displayInPublicPage: '在公共页面中显示',
      suspended: '暂停',
      unsuspend: '取消暂停',
      suspend: '暂停',
      invitationDeclined: '邀请被拒绝',
      invitationPending: '邀请待处理',
      invitationExpired: '邀请已过期',
      invitationAccepted: '接受邀请',
      setAsMember: '设为会员',
      setAsAdmin: '设置为管理员',
      disablePortalAccess: '禁用门户访问',
      enablePortalAccess: '启用门户访问',
      resendInvitation: '重新发送邀请',
      deleteUser: '删除用户',
      deactivate: '停用',
      activate: '启用',
      preview: '预习',
      customiseName: '自定义名称',
      customiseImage: '自定义图像',
      addAttachment: '添加附件',
      changeStatus: '更改状态',
      removePersonalSupportProfile: '删除个人支持资料',
      createBatch: '创建批次',
      editBatch: '编辑批次',
      deleteBatch: '删除批次',
      copyToClipboard: '复制到剪贴板',
      openPublicPage: '打开公共页面',
      createProduct: '创建产品',
      pagePosition: '页面位置',
      uploadImage: '上传图片',
      deleteImage: '删除图像',
      updateFromClipboard: '从剪贴板更新',
      viewCodeDetails: '查看代码详细信息',
      updateDisplayData: '更新报告的数据',
      hide: '隐藏',
      unhide: '取消隐藏',
    },
    pagination: {
      text: '显示 {from} 到 {to} 个条目，共 {total} 个条目',
    },
    tabs: {
      general: '一般的',
      categories: '类别',
      support: '支持',
      tasks: '任务',
      users: '用户',
      integrationActions: '集成操作',
      valueChain: '价值链',
      chart: '图表',
      map: '地图',
      documents: '文件',
    },
    messages: {
      invitationExpired: '邀请已过期',
      notFoundInvitation: '未找到邀请',
      userAlreadyExists: '用户已存在',
      nameAlreadyTaken: '姓名已被占用',
      InvalidContent: '无效内容',
      PayloadTooLarge: '请求的实体太大',
      noSearchResult: '抱歉，我们找不到任何匹配的结果',
      noneSelected: '未选择任何内容。',
      noneAvailable: '尚未为此组织创建任务。',
      noConfigurableAttributes: '此任务没有可用的可配置属性。',
      noInvitation: '没有待处理的邀请',
      defaultValueChain: '现有的默认元素只能通过从其编辑屏幕中选择一个新元素作为默认元素来更改。',
      fileSizeError: '上传文件到 Assure+ Portal 的最大文件大小为 10MB。请上传不超过 10MB 的文件。',
      submitError: '请填写所有必填字段',
      inviteSent: '已发送邀请',
      inviteError: '无法发送邀请',
      saved: '已保存',
      deleted: '已删除',
      duplicated: '重复',
      createThing: '创建的东西',
      createThingError: '无法创建事物',
      duplicateThingError: '东西不能复制',
      updateThing: '东西被复制了',
      updateThingError: '无法更新事物',
      loadTypeError: '无法加载 {type}',
      deleteTypeError: '无法删除 {type}',
      duplicateTypeError: '无法复制',
      createTask: '已添加任务',
      createTaskError: '无法添加任务',
      updateTask: '任务已更新',
      updateTaskError: '无法更新任务',
      taskError: '无法创建任务',
      createSupportProfile: '支持配置文件已添加',
      createSupportProfileError: '无法添加支持配置文件',
      updateSupportProfile: '支持资料已更新',
      updateSupportProfileError: '无法更新支持配置文件',
      deleteSupportProfile: '支持配置文件已被删除',
      deleteSupportProfileError: '无法创建支持配置文件',
      supportProfile: '支持配置文件已创建',
      supportProfileError: '无法创建支持配置文件',
      createTeam: '团队创建',
      createTeamError: '无法创建团队',
      saveTeam: '团队已保存',
      saveTeamError: '无法保存团队',
      renameTeam: '团队更名',
      renameTeamError: '无法重命名团队',
      teamStatus: '团队状态已更改',
      teamStatusError: '无法更改团队状态',
      resendInvite: '邀请重新发送',
      resendInviteError: '无法重新发送邀请',
      revokeInvite: '邀请撤销',
      revokeInviteError: '无法撤消邀请',
      revokeAccess: '访问被撤销',
      revokeAccessError: '无法撤消访问权限',
      suspendAccess: '暂停',
      suspendAccessError: '无法更改为暂停',
      accountChange: '帐户已更改',
      accountChangeAdminError: '无法更改为管理员',
      accountChangeOwnerError: '无法更改为所有者',
      accountChangeUserError: '无法更改为用户',
      inviteUser: '受邀用户',
      inviteUserError: '无法邀请用户',
      declineInvitation: '拒绝了邀请',
      declineInvitationError: '无法拒绝邀请（{message}）',
      acceptInvitation: '接受邀请',
      acceptInvitationError: '无法接受邀请（{message}）',
      deleteUser: '用户已删除',
      deleteUserError: '无法删除用户',
      updateTemplate: '模板已更新',
      updateTemplateError: '无法更新模板',
      deleteTemplate: '模板已删除',
      deleteTemplateError: '无法删除模板',
      createTemplate: '模板已创建',
      createTemplateError: '无法创建模板',
      deleteBatch: '批量删除',
      deleteBatchError: '批处理已使用。无法删除批次。',
      updateBatch: '批次已更新',
      createBatch: '批量创建',
      createBatchError: '批次 ID 已存在。无法创建批次。',
      identificationCode: '识别码已创建',
      validateIdentifierError: '无法验证标识符',
      addFileError: '请添加文件',
      genericError: '一般错误',
      createValueChain: '价值链已经建立',
      createValueChainError: '无法创建价值链',
      updateValueChain: '价值链已更新',
      updateValueChainError: '无法更新价值链',
      deleteValueChain: '价值链已被删除',
      deleteValueChainError: '无法删除价值链',
      updateCategory: '类别已更新',
      updateCategoryError: '无法更新类别',
      deleteCategory: '类别已删除',
      deleteCategoryError: '无法删除类别',
      CreateCategory: '类别已创建',
      createCategoryError: '无法创建类别',
    },
    validation: {
      minNumMaxNum: '最少 {0} 最多 {1} 个字符',
      maxNum: '最多 {0} 个字符',
    },
    uploadFile: {
      maxSize: '（最大大小 10MB）',
    },
  },
  organization: {
    myOrganizations: '我的组织',
    viewAllOrganizations: '查看所有组织',
  },
  interaction: {
    batchRecord: '批次记录',
    unknownId: '未知 ID',
    externalId: '外部 ID',
    modal: {
      eventClassification: '事件分类',
      unclassified: '未分类',
      default: '默认',
    },
    filters: {
      notice: '注意',
      alert: '警报',
      warning: '警告',
    },
    hidden: '隐',
  },

  interaction_action_0: '用户通知：',
  interaction_action_1: '项目状态更改为：',
  interaction_action_2: '已分配项目支持配置文件。',
  interaction_action_3: '项目支持配置文件已被删除。',
  interaction_action_4: '通知：',
  interaction_action_5: '警告：',
  interaction_action_6: '警报：',
  interaction_administrative_title: '行政变更',
  task_steps: {
    status_check: '当前项目状态为：{status}',
  },
  item_status: '{状态}',
  interaction_collections_title: '集合 - {文本}',
  interaction_collection_added_title: '添加到集合 - {text}',
  step_collection_fields: '领域：',
  publicPage: {
    positioning: {
      before: '前',
      after: '后',
      top: '最佳',
      bottom: '底部',
    },
    components: {
      longDescription: '详细描述',
      attachments: '附件',
      attributes: '属性',
      services: '服务',
      gallery: '画廊',
      certifications: '认证',
      video: '视频',
      history: '历史',
      supportProfile: '支持简介',
      fs1TaskPanel: 'FS1 任务面板',
      fs1About: 'FS1 关于',
      fs1FactsPanel: 'FS1 事实小组',
      fs1ResourcesPanel: 'FS1 资源面板',
      events: '活动',
    },
  },
  OrganisationalSettings: {
    header: '组织设置',
    components: {
      general: '一般的',
      branding: '品牌推广',
    },
    general: {
      name: '姓名',
      businessType: '业务类型',
      OrganizationLogo: '组织标志',
      PublicPageLoadingImage: '公共页面加载图片',
    },
  },
  tasks: {
    tableHeader: {
      name: '任务名称',
      usedAsDiscovery: '发现任务',
      interactions: '使用次数',
      things: '分配',
      createdAt: '创建于',
      createdBy: '由...制作',
      displayConditions: '显示条件',
    },
    taskModal: {
      headerCreate: '创建任务',
      headerEdit: '编辑任务',
      components: {
        selectTask: '选择任务',
      },
    },
  },
  categories: {
    title: '类别',
    noDataMessage1: '创建类别',
    tableHeaders: {
      name: '姓名',
      defaultTemplate: '默认模板',
      createdAt: '创建于',
      createdBy: '由...制作',
    },
    editCategoryModal: {
      createCategory: '创建一个类别',
      editCategory: '编辑类别',

      labels: {
        name: '分类名称',
        standard: '识别标准',
        identifier: '类别标识符',
        description: '描述',
        image: '类别图像',
        defaultTemplate: '默认情况下，为此类别创建的每个产品都将设置为此模板。',
      },
    },
  },
  supportProfile: {
    title: '支持简介',
    addSupportProfile: '添加支持配置文件',
    createMessage1: '创建支持资料',
    tableHeaders: {
      name: '姓名',
      content: '内容',
      displayConditions: '显示条件',
      contactMethods: '联系方式',
    },
    editSupportProfileModal: {
      createSupportProfile: '创建支持配置文件',
      editSupportProfile: '编辑支持资料',
      labels: {
        name: '姓名',
        headerText: '标题文本',
        mainText: '正文',
        mainContent: '主要内容',
        showExpanded: '展开显示',
        phone: '电话',
        email: '电子邮件',
        imagery: '图像',
        primary: '基本的',
        secondary: '中学',
        kmlMapType: 'KML 地图类型',
        kmlLink: 'KML 链接',
        kmlFile: '文件',
      },
    },
  },
  templates: {
    title: '模板',
    tableHeaders: {
      name: '姓名',
      createdAt: '创建于',
      createdBy: '由...制作',
      assignedCategories: '分配的类别',
    },
    editTemplateModal: {
      createTemplate: '创建模板',
      editTemplate: '编辑模板',
      labels: {
        templateName: '模板名称',
        templateDescription: '模板说明',
        templateImage: '模板图像',
      },
    },
    templateCreatedModal: {
      title: '已创建模板',
      message: '模板创建成功，您想现在管理并添加属性、任务、图像和更多内容吗？',
      later: '以后再做',
      now: '立即管理',
    },
    templateCreatedProductModal: {
      title: '产品创建',
      message: '产品创建成功，您希望现在管理并添加属性、任务、图像和更多内容吗？',
    },
  },
  user: {
    viewInvitations: '查看邀请',
    inviteUsers: '邀请用户',
    to: '到',
    userHeaders: {
      name: '姓名',
      email: '电子邮件',
      role: '角色',
      access: '使用权',
      status: '状态',
    },
    userType: {
      admin: '行政',
      member: '成员',
    },
  },
  webhooks: {
    webhookHeaders: {
      name: '姓名',
      createdAt: '创建于',
      createdBy: '由...制作',
    },
    webhookModal: {
      headerCreate: '开始与团队成员、其他系统和受信任的第三方共享您的组织的交互的操作。',
      components: {
        emailSettings: '电子邮件设置',
        webhookSettings: '网络挂钩设置',
        integrations: '集成',
        thingsAndTasks: '事物和任务',
      },
    },
    actions: '行动',
    editAction: '编辑动作',
  },
  valueChain: {
    headerCreate: '价值链',
    headerEdit: '编辑价值链',
    editAChainElement: '编辑链元素',
    createAChainElement: '创建链元素',
    headerDescription: '选择一个模板来帮助您入门。',
    organisationActivity: '组织活动',
    components: {
      miscellaneousContent: '杂项内容',
      logisticsAndEnvironment: '物流与环境',
      generalInformation: '一般信息',
    },
    tableHeaders: {
      creationDate: '创立日期',
      createdBy: '由...制作',
    },
    templates: {
      justTheBasics: {
        name: '只是基础',
        description: 'Assure+ 中使用的默认价值链。',
      },
      beverage: {
        name: '饮料',
        description: '现在，消费者正在推动通过整个供应链跟踪和追溯饮料产品的能力。他们想知道所有产品的来源，回到原料种植地以及生产配套材料和包装用品的设施。',
      },
      coffee: {
        name: '咖啡',
        description: '在到达您的早晨杯子之前，咖啡豆要经过复杂的全球供应链。',
      },
      honey: {
        name: '蜜糖',
        description: '蜂蜜通过复杂的全球供应链从蜂箱到消费者。',
      },
      porterValueChain: {
        name: '波特价值链',
        description: '波特的价值链不是关注部门或会计成本类型，而是关注系统，以及输入如何转化为消费者购买的输出。',
      },
      usaidCommodity: {
        name: '美国国际开发署商品',
        description:
          '该过程从向美国国际开发署提出食品申请开始，美国国际开发署为获得批准的申请征求合同投标并采购食品商品。然后将商品从供应商发送到供应链不同物流节点的运输点或仓库设施，包括美国港口、国外卸货港以及美国和国际 USAID 预置仓库。转移给合作伙伴组织，这些组织随后负责使用他们自己的运输和分销合作伙伴网络以及他们自己的信息跟踪系统来分销食品。',
      },
      wine: {
        name: '葡萄酒',
        description: '葡萄酒价值链包括五个广泛的生产阶段：葡萄园准备、葡萄生产（葡萄栽培）、葡萄酒生产（葡萄栽培）、物流、分销、营销和销售。',
      },
    },
  },
  documents: {
    title: '文件',
    labels: {
      documentName: '文件名称',
      gdtiNumber: 'GDTI 编号',
      valueChainSteps: '价值链步骤',
      publicRecord: '公共记录',
      dateModified: '修改日期',
    },
    valueChainSteps: {
      Unclassified: '未分类',
    },
  },
  products: {
    tableHeaders: {
      name: '姓名',
      category: '类别',
      tasks: '分配的任务',
      interactions: '记录的事件',
      items: '项目',
    },
    menuOptions: {
      manage: '管理',
      duplicate: '复制',
      updateFromClipboard: '从剪贴板更新',
    },
    bulkActions: {
      addTasks: '添加任务',
      removeTasks: '删除任务',
      addValueChain: '添加价值链',
      addSupportProfile: '添加支持配置文件',
      removeSupportProfile: '删除支持资料',
    },
    productCreatedModal: {
      title: '产品创建',
      message: '产品创建成功，您希望现在管理并添加属性、任务、图像和更多内容吗？',
      later: '以后再做',
      now: '立即管理',
    },
    title: '产品',
  },
  collaborations: {
    header: {
      step: '步',
      title: '从剪贴板更新',
    },
    subTitles: {
      general: '一般的',
      productInfo: '产品信息',
      attributes: '属性',
      productDocuments: '文件',
      itemAndBatchDocuments: '物料和批次文件',
      images: '图片',
      videos: '视频',
      eventHistory: '事件历史',
      contentTransferSummary: '内容传输摘要',
    },
    descriptiveContent: {
      noData: '没有可用的一般信息。',
      description: '一般信息将在产品级别传输，并将添加到所有批次。',
      target: '目标',
      source: '资源',
      labels: {
        name: '姓名',
        shortDescription: '简短的介绍',
        longDescription: '详细描述',
      },
      longDescriptionButtons: {
        append: '附加',
        replace: '代替',
      },
    },
    attributes: {
      noData: '没有可用的属性。',
      description: '属性将在产品级别转移，并将添加到所有批次。',
      warningMessage: '选定的属性将应用于所有批次的产品',
    },
    itemAndBatchDocuments: {
      noData: '没有可用的文件。',
      warningMessage: '选定的文档将仅添加到相应的目标项目或产品批次',
    },
    images: {
      noData: '没有可用的图像。',
      description: '图像将在产品级别传输，并将添加到所有批次。',
      selectAllButton: '全选',
    },
    videos: {
      noData: '没有可用的视频。',
      description: '视频将在产品级别上传输，并将添加到所有批次中。',
      selectAllButton: '全选',
    },
    eventHistory: {
      noData: '公共内容没有可用的任务。',
      noItemBatch: '没有可用于存储事件的项目或批次。',
      labels: {
        name: '姓名',
        classification: '分类',
      },
    },
    confirmationScreen: {
      noData: '未选择任何内容。',
      message: '从剪贴板更新...',
      successfulMessage: '从剪贴板更新成功',
    },
  },
  item: {
    filters: {
      doNotUse: '不使用',
      allClear: '全清',
      normalPrecaution: '正常预防',
      reconsiderUse: '重新考虑使用',
      increasedCaution: '更加谨慎',
      unknownId: '身份不明',
      itemOk: '项目确定',
      ready: '准备好',
      active: '积极的',
      resultPending: '结果待定',
      completedPositive: '完成阳性',
      completedNegative: '已完成否定',
      completedInconclusive: '已完成 不确定',
      itemLocked: '物品锁定',
      itemUnlocked: '物品解锁',
      unused: '没用过',
      returned: '回来',
      readyFilled: '已装满',
      readyPacked: '准备好包装',
      withCustomer: '与客户',
      issueRaised: '提出的问题',
      pickupRequested: '要求取件',
    },
  },
  thing: {
    menus: {
      about: '关于',
      discovery: '发现',
      public: '民众',
      tasks: '任务',
      support: '支持',
    },
    about: {
      general: '一般的',
      category: '类别',
      documentation: '文档',
      attributes: '属性',
      certifications: '认证',
      miscellaneous: '各种各样的',
      valueChains: '价值链',
      gallery: '画廊',
      advancedSettings: '高级设置',
      changeACategory: '更改类别',
      changeAValueChain: '改变价值链',

      certificationsLabel: {
        addCertification: '添加认证',
        editCertification: '编辑认证',
      },
    },
    public: {
      generalSettings: '通用设置',
      enablePublicContent: '启用公共内容',
      itemCheck: '项目检查',
      usedAsDiscovery: '发现任务',
      advancedSettings: '高级设置',
      rfiderAuthenticityCheck: 'Assure+ 真实性检查',
      basicInformation: '基本信息',
      brandExperience: '品牌体验',
      links: '链接',
      miscellaneous: '各种各样的',
      attributes: '属性',
      services: '服务',
      documents: '文件',
      gallery: '画廊',
      certifications: '认证',
      videos: '视频',
      longDescription: '详细描述',
      supportProfile: '支持简介',
      itemHistory: '项目历史',
      none: '没有',
      heroImage: '英雄形象',
      sectionImage: '剖面图',
      heroOverlayImage: '英雄叠加图像',
      privacyPolicy: '隐私政策',
      productInfo: '产品信息',
      learnMore: '了解更多',
      shopping: '购物',
      section: '部分',
      position: '位置',
      enableAttributes: '启用属性',
      attributeVisibility: '属性可见性',
      enableAttachments: '启用附件',
      enableCertifications: '启用认证',
      allowProfles: '允许团队成员的个人资料',
      surviceLabels: {
        enableServices: '启用服务',
        serviceVisibility: '服务可见性',
        basic: '基本的',
        off: '离开',
        peerToPeer: '点对点',
      },
      itemHistoryLabels: {
        enableItemHistory: '启用项目历史记录',
        displayTemplate: '显示模板',
        events: '活动',
        data: '数据',
        images: '图片',
        eventGallery: '活动画廊',
        enableEventGallery: '启用事件库',
      },
      switch: {
        confirmTextEnable: '您确定要启用吗？',
        confirmTextDisable: '您确定要禁用吗？',
        confirmButtonEnable: '是的，启用它！',
        confirmButtonDisable: '是的，禁用它！',
      },
      enablePublicLink: '启用公共链接',
      attributeLabels: {
        editAttribute: '编辑属性',
        addAttribute: '添加属性',
        label: '标签',
        value: '价值',
        type: '类型',
      },
    },
  },
  filter: {
    createdByMe: '由我创建',
  },
  swal: {
    duplicate: '复制',
    confirmTitle: '你确定吗？',
    warningMessage: '您将无法还原此内容！',
    confirmButtonDelete: '是的，删除它！',
    confirmButtonRemove: '是的，删除它！',
    confirmButtonDuplicate: '是的，复制它！',
    confirmButtonReset: '是的，重置它！',
    confirmButtonResend: '是的，重新发送！',
    confirmButtonDecline: '对，拒绝！',
    confirmButtonAccept: '是的，接受！',
    keepIt: '不，留着',
    userText: '您需要再次邀请他们。',
    associationError: '无法创建关联！',
    template: {
      confirmButtonText: '是的，创造一个新产品！',
      createProduct: '创建产品',
      successMessage: '已从此模板创建了新产品',
      errorMessage: '无法从此模板创建新产品',
      successMessage1: '模板已复制',
    },
    products: {
      successMessage: '产品已重复',
      errorMessage: '产品不能重复',
      createProductError: '无法从此模板创建新产品',
    },
    valueChain: {
      successMessage: '价值链已被复制',
      errorMessage: '无法复制价值链',
      successMessageThingAbout: '该产品的价值链已经改变',
      errorMessageThingAbout: '无法更改此产品的价值链',
      thingValueChainText: '建议不要更改。',
      lastChance: '最后的机会',
      lastChanceText: '到目前为止收集的任何数据都不会显示在价值链视图中。此更改无法撤消。',
    },
    user: {
      successMessage: '向用户重新发送邀请',
      errorMessage: '无法向用户重新发送邀请',
      successMessage1: '用户已更新',
      errorMessage1: '无法更新用户',
    },
    invitations: {
      titleDecline: '拒绝邀请？',
      titleAccept: '接受邀请？',
      text: '邀请您加入',
    },
    category: {
      successMessageThingAbout: '此产品的类别已更改',
      errorMessageThingAbout: '无法更改此产品的类别',
      thingCategoryText: '建议不要更改。',
      lastChance: '最后的机会',
      lastChanceText: '此更改无法撤消。',
    },
    document: {
      createDocument: '文档已创建',
      createDocumentError: '无法创建文档',
      updateDocument: '文档已更新',
      updateDocumentError: '无法更新文档',
      deleteDocument: '文档已被删除',
      deleteDocumentError: '无法删除文档',
    },
    task: {
      text: '您即将退出任务配置而不保存更改。所有未保存的更改都将丢失。你要继续吗？',
      deleteTask: '任务已删除',
      updateTask: '任务已更新',
      updateTaskError: '无法更新任务',
      createTask: '任务已创建',
      createTaskTitle: '创建成功！',
      createTaskText: '要打开它的配置吗？',
      labels: {
        configuration: '配置',
      },
    },
    interaction: {
      createSuccess: '已创建交互',
      createError: '无法创建交互。',
      mapLoadMessage: '加载错误',
      mapLoadError: '无法在地图上加载交互',
      updateSuccess: '互动已更新',
      updateError: '无法更新交互',
      deleteSuccess: '已删除互动',
      deleteError: '无法删除交互。',
    },
    item: {
      itemsLimitError: '输入选项的限制只有 {itemsLimit} 个项目。',
      removeSupportProfile: {
        title: '你确定吗？',
        success: '已删除个人支持资料',
        error: '无法删除个人支持资料',
      },
    },
    miscellaneous: {
      noImageError: '请添加图片',
      undefinedNotificationType: '请选择其中一种通知类型',
    },
    webhooks: {
      deleteAction: '操作已删除',
      deleteActionError: '无法删除操作',
      updateActionStatus: '操作状态已更新',
      updateActionStatusError: '无法更新操作状态',
      updateAction: '动作已更新',
      updateActionError: '无法更新操作',
      createWebhook: '已创建 Webhook',
    },
  },
  invitations: {
    title: '邀请函',
    records: '记录',
  },
  languages: {
    english: 'English',
    german: 'Deutsch',
    spanish: 'Español',
    japanese: '日本',
    korean: '한국인',
    dutch: 'Nederlands',
    portuguese: 'Português',
    chinese: '中国人',
    chineseTraditional: '中國人',
  },
  editMiscellaneous: {
    editMiscellaneous: '编辑杂项',
    addMiscellaneous: '添加杂项',
    editVideo: '编辑视频',
    addVideo: '添加视频',
    editOptions: '编辑选项',
    addOptions: '添加选项',
    labels: {
      embeddedLink: '仅限嵌入式链接',
      videoEmbed: '视频嵌入代码',
      codeInjection: '代码注入',
      imageLink: '图片链接',
    },
  },
  discovery: {
    labels: {
      batchId: '批次编号',
      batchName: '批次名称',
      createdBy: '由...制作',
      itemCount: '项目计数',
      used: '％ 用过的',
    },
    buttons: {
      identificationMethod: '鉴别方法',
      createBatch: '创建批次',
      deactivate: '停用',
      activate: '启用',
      delete: '删除',
    },
    maximumItemsPerBatch: '每批次的最大项目数',
    batches: '批次',
    beta: '贝塔',
    item: '物品',
    product: '产品',
    selectIdentificationMethod: '选择识别方法',
    message: 'Assure+ 唯一 ID (RFID) 是系统生成的在 Assure+ 平台上管理的项目的标识符。',
  },
  dashboard: {
    dateRange: {
      today: '今天',
      lastSevenDays: '过去 7 天',
      lastWeek: '上个星期',
      lastThirtyDays: '过去 30 天',
      lastMonth: '上个月',
      lastTwelvemonths: '过去 12 个月',
      custom: '风俗',
    },
    chart: {
      today: '今天',
      allTime: '整天',
      totalInteractions: '总互动',
      totalTasks: '总任务',
      totalServices: '总服务',
      noData: '没有数据',
      overallStatusBreakdown: '整体状态细分',
    },
  },
  displayConditionsRules: {
    onlyAdmin: '用户权限',
    or: '要么',
    andAlso: '并且',
    addYourFirstCondition: '添加您的第一个条件',
    useSeparator: '使用逗号作为分隔符',
    useDashSeparator: '使用破折号 (-) 作为分隔符',
    rfuidItemId: 'RFUID 项目 ID',
    rfuidBatchId: 'RFUID 批次 ID',
    rfuidSequenceNumber: 'RFUID 序列号',
    itemStatus: '项目状态',
    userCountry: '用户国家',
    userLanguage: '用户语言',
    conditions: {
      stringEquals: '字符串等于',
      stringNotEquals: '字符串不等于',
      includedIn: '包括在',
      notIncludedIn: '不包括在',
      numberEquals: '数等于',
      numberNotEquals: '数不等于',
      numberGreaterThan: '数大于',
      numberLessThan: '数小于',
      between: '之间',
    },
    countries: {
      afghanistan: '阿富汗',
      alandIslands: '奥兰群岛',
      albania: '阿尔巴尼亚',
      algeria: '阿尔及利亚',
      americanSamoa: '美属萨摩亚',
      andorra: '安道尔',
      angola: '安哥拉',
      anguilla: '安圭拉',
      antarctica: '南极洲',
      antigua: '安提瓜和巴布达',
      argentina: '阿根廷',
      armenia: '亚美尼亚',
      aruba: '阿鲁巴岛',
      australia: '澳大利亚',
      austria: '奥地利',
      azerbaijan: '阿塞拜疆',
      bahamas: '巴哈马',
      bahrain: '巴林',
      bangladesh: '孟加拉国',
      barbados: '巴巴多斯',
      belarus: '白俄罗斯',
      belgium: '比利时',
      belize: '伯利兹',
      benin: '贝宁',
      bermuda: '百慕大',
      bhutan: '不丹',
      bolivia: '玻利维亚',
      bonaire: '博内尔岛',
      bosnia: '波斯尼亚和黑塞哥维那（简称：波黑',
      botswana: '博茨瓦纳',
      bouvet: '布维岛',
      brazil: '巴西',
      britishIndian: '英属印度洋领地',
      brunei: '文莱达鲁萨兰国',
      bulgaria: '保加利亚',
      burkina: '布基纳法索',
      burundi: '布隆迪',
      cambodia: '柬埔寨',
      cameroon: '喀麦隆',
      canada: '加拿大',
      capeVerde: '佛得角',
      caymanIslands: '开曼群岛',
      centralAfricanRepublic: '中非共和国',
      chad: '乍得',
      chile: '智利',
      china: '中国',
      christmasIsland: '圣诞岛',
      cocos: '科科斯（基林）群岛',
      colombia: '哥伦比亚',
      comoros: '科摩罗',
      congo: '刚果',
      congoDemocratic: '刚果',
      cookIslands: '库克群岛',
      costaRica: '哥斯达黎加',
      coteIvo: '象牙海岸',
      croatia: '克罗地亚',
      cuba: '古巴',
      curacao: '库拉索',
      cyprus: '塞浦路斯',
      czechRepublic: '捷克共和国',
      denmark: '丹麦',
      djibouti: '吉布提',
      dominica: '多米尼克',
      dominicanRepublic: '多明尼加共和国',
      ecuador: '厄瓜多尔',
      egypt: '埃及',
      elSalvador: '救星',
      equatorialGuinea: '赤道几内亚',
      eritrea: '厄立特里亚',
      estonia: '爱沙尼亚',
      ethiopia: '埃塞俄比亚',
      falkland: '福克兰群岛（马尔维纳斯）',
      faroeIslands: '法罗群岛',
      fiji: '斐济',
      finland: '芬兰',
      france: '法国',
      frenchGuiana: '法属圭亚那',
      frenchPolynesia: '法属波利尼西亚',
      frenchSouthern: '法属南部领地',
      gabon: '加蓬',
      gambia: '冈比亚',
      georgia: '乔治亚州',
      germany: '德国',
      ghana: '加纳',
      gibraltar: '直布罗陀',
      greece: '希腊',
      greenland: '格陵兰',
      grenada: '格林纳达',
      guadeloupe: '瓜德罗普',
      guam: '关岛',
      guatemala: '危地马拉',
      guernsey: '根西岛',
      guinea: '几内亚',
      guineaBissau: '几内亚比绍',
      guyana: '圭亚那',
      haiti: '海地',
      heardIsland: '赫德岛和麦克唐纳群岛',
      holySee: '圣座（梵蒂冈城邦）',
      honduras: '洪都拉斯',
      hongKong: '香港',
      hungary: '匈牙利',
      iceland: '冰岛',
      india: '印度',
      indonesia: '印度尼西亚',
      iran: '伊朗',
      iraq: '伊拉克',
      ireland: '爱尔兰',
      isleOfMan: '马恩岛',
      israel: '以色列',
      italy: '意大利',
      jamaica: '牙买加',
      japan: '日本',
      jersey: '球衣',
      jordan: '约旦',
      kazakhstan: '哈萨克斯坦',
      kenya: '肯尼亚',
      kiribati: '基里巴斯',
      korea: '韩国',
      koreaRepublic: '韩国',
      kuwait: '科威特',
      kyrgyzstan: '吉尔吉斯斯坦',
      lao: '劳动',
      latvia: '拉脱维亚',
      lebanon: '黎巴嫩',
      lesotho: '莱索托',
      liberia: '利比里亚',
      libya: '利比亚',
      liechtenstein: '列支敦士登',
      lithuania: '立陶宛',
      luxembourg: '卢森堡',
      macao: '澳门',
      macedonia: '马其顿',
      madagascar: '马达加斯加',
      malawi: '马拉维',
      malaysia: '马来西亚',
      maldives: '马尔代夫',
      mali: '马里',
      malta: '马耳他',
      marshall: '马绍尔群岛',
      martinique: '马提尼克岛',
      mauritania: '毛里塔尼亚',
      mauritius: '毛里求斯',
      mayotte: '马约特岛',
      mexico: '墨西哥',
      micronesia: '密克罗尼西亚',
      moldova: '摩尔达维亚',
      monaco: '摩纳哥',
      mongolia: '蒙古',
      montenegro: '黑山',
      montserrat: '蒙特塞拉特',
      morocco: '摩洛哥',
      mozambique: '莫桑比克',
      myanmar: '缅甸',
      namibia: '纳米比亚',
      nauru: '瑙鲁',
      nepal: '尼泊尔',
      netherlands: '荷兰',
      newCaledonia: '新喀里多尼亚',
      newZealand: '新西兰',
      nicaragua: '尼加拉瓜',
      niger: '尼日尔',
      nigeria: '尼日利亚',
      niue: '纽埃',
      norfolk: '诺福克岛',
      northern: '北马里亚纳群岛',
      norway: '挪威',
      oman: '阿曼',
      pakistan: '巴基斯坦',
      palau: '帕劳',
      palestinian: '巴勒斯坦领土，被占领',
      panama: '巴拿马',
      papua: '巴布亚新几内亚',
      paraguay: '巴拉圭',
      peru: '秘鲁',
      philippines: '菲律宾',
      pitcairn: '皮特凯恩',
      poland: '波兰',
      portugal: '葡萄牙',
      puertoRico: '波多黎各',
      qatar: '卡塔尔',
      reunion: '会议',
      romania: '罗马尼亚',
      russian: '俄罗斯联邦',
      rwanda: '卢旺达',
      saintBarth: '圣巴泰勒米',
      saintHelena: '圣赫勒拿',
      saintKitts: '圣基茨和尼维斯',
      saintLucia: '圣卢西亚',
      saintMartin: '圣马丁（法语部分）',
      saintPierre: '圣皮埃尔和密克隆',
      saintVincent: '圣文森特和格林纳丁斯',
      samoa: '萨摩亚',
      sanMarino: '圣马力诺',
      saoTome: '圣多美和普林西比',
      saudiArabia: '沙特阿拉伯',
      senegal: '塞内加尔',
      serbia: '塞尔维亚',
      seychelles: '塞舌尔',
      sierra: '塞拉利昂',
      singapore: '新加坡',
      sintMaarten: '圣马丁（荷兰部分）',
      slovakia: '斯洛伐克',
      slovenia: '斯洛文尼亚',
      solomon: '所罗门群岛',
      somalia: '索马里',
      southAfrica: '南非',
      southGeorgia: '南乔治亚岛和南桑威奇群岛',
      southSudan: '南苏丹',
      spain: '西班牙',
      sriLanka: '斯里兰卡',
      sudan: '苏丹',
      suriname: '苏里南',
      svalbard: '斯瓦尔巴和扬马延',
      swaziland: '斯威士兰',
      sweden: '瑞典',
      switzerland: '瑞士',
      syrian: '阿拉伯叙利亚共和国',
      taiwan: '台湾',
      tajikistan: '塔吉克斯坦',
      tanzania: '坦桑尼亚',
      thailand: '泰国',
      timorLeste: '东帝汶',
      togo: '多哥',
      tokelau: '托克劳',
      tonga: '汤加',
      trinidad: '特立尼达和多巴哥',
      tunisia: '突尼斯',
      turkey: '火鸡',
      turkmenistan: '土库曼斯坦',
      turksAndCaicosIslands: '特克斯和凯科斯群岛',
      tuvalu: '图瓦卢',
      uganda: '乌干达',
      ukraine: '乌克兰',
      uae: '阿拉伯联合酋长国',
      uk: '英国',
      usa: '美国',
      usMinor: '美国本土外小岛屿',
      uruguay: '乌拉圭',
      uzbekistan: '乌兹别克斯坦',
      vanuatu: '瓦努阿图',
      venezuelaBolivarian: '委内瑞拉',
      vietnam: '越南',
      virginIslandsBritish: '维尔京群岛',
      virginIslandsUS: '维尔京群岛',
      wallisAndFutuna: '瓦利斯和富图纳',
      westernSahara: '撒哈拉沙漠西部',
      yemen: '也门',
      zambia: '赞比亚',
      zimbabwe: '津巴布韦',
    },
    languages: {
      ab: '阿布哈兹语',
      aa: '远方',
      af: '南非荷兰语',
      ak: '将要',
      sq: '阿尔巴尼亚语',
      am: '阿姆哈拉语',
      ar: '阿拉伯',
      an: '阿拉贡语',
      hy: '亚美尼亚语',
      as: '阿萨姆语',
      av: '阿瓦里克',
      ae: '阿维斯坦',
      ay: '艾马拉',
      az: '阿塞拜疆',
      bm: '班巴拉',
      ba: '巴什基尔',
      eu: '巴斯克',
      be: '白俄罗斯语',
      bn: '孟加拉',
      bh: '比哈里语',
      bi: '比斯拉马',
      bs: '波斯尼亚语',
      br: '布列塔尼',
      bg: '保加利亚语',
      my: '缅甸语',
      ca: '加泰罗尼亚语；巴伦西亚语',
      km: '中央高棉',
      ch: '查莫罗',
      ce: '车臣',
      ny: '奇切瓦；切瓦；海',
      zhCn: '简体中文）',
      zhTw: '中国传统的）',
      cu: '教会斯拉夫语',
      cv: '楚瓦什语',
      kw: '康沃尔语',
      co: '科西嘉岛',
      cr: '相信',
      hr: '克罗地亚语',
      cs: '捷克语',
      da: '丹麦语',
      dv: '迪维希',
      nl: '荷兰语；佛兰芒语',
      dz: '宗喀',
      en: '英语',
      eo: '世界语',
      et: '爱沙尼亚语',
      ee: '母羊',
      fo: '法罗语',
      fj: '斐济',
      fi: '芬兰',
      fr: '法语',
      ff: '富拉',
      gd: '盖尔语',
      gl: '加利西亚语',
      lg: '双倍的',
      ka: '格鲁吉亚语',
      de: '德语',
      el: '希腊语',
      gn: '瓜拉尼',
      gu: '古吉拉特语',
      ht: '海地',
      ha: '豪萨',
      he: '希伯来语',
      hz: '赫雷罗',
      hi: '不。',
      ho: '希里·莫图',
      hu: '匈牙利',
      is: '冰岛的',
      io: '走了',
      ig: '伊博语',
      id: '印度尼西亚',
      iu: '因纽特语',
      ik: '因努皮亚克',
      ga: '爱尔兰语',
      it: '意大利语',
      ja: '日本人',
      jv: '爪哇语',
      kl: '格陵兰语',
      kn: '卡纳达语',
      kr: '卡努里',
      ks: '克什米尔语',
      kk: '哈萨克语',
      ki: '基库尤；吉库尤',
      rw: '基尼亚卢旺达语',
      ky: '吉尔吉斯;吉尔吉斯斯坦',
      kv: '快点',
      kg: '刚果',
      ko: '韩国人',
      kj: '动物;在肉体中',
      ku: '库尔德',
      lo: '劳动',
      la: '拉丁',
      lv: '拉脱维亚语',
      li: '林堡',
      ln: '林加拉语',
      lt: '立陶宛语',
      lu: '卢巴-加丹加',
      lb: '卢森堡语',
      mk: '马其顿语',
      mg: '马尔加什',
      ms: '马来语',
      ml: '马拉雅拉姆语',
      mt: '马耳他语',
      gv: '马恩岛',
      mi: '毛利人',
      mr: '马拉地语',
      mh: '马绍尔语',
      mn: '蒙',
      na: '瑙鲁',
      nv: '纳瓦霍人；纳瓦霍',
      ng: '恩东加',
      ne: '尼泊尔语',
      se: '北萨米人',
      no: '挪威',
      oj: '奥吉布瓦',
      or: '奥里亚语',
      om: '奥罗莫',
      os: '奥塞梯;骨科',
      pi: '巴利语',
      pa: '旁遮普语；旁遮普语',
      fa: '波斯语',
      pl: '抛光',
      ptBr: '葡萄牙语',
      ps: '普什图;普什图语',
      qu: '盖丘亚语',
      ro: '罗马尼亚语',
      rm: '罗曼什语',
      rn: '回合',
      ru: '俄语',
      sm: '萨摩亚',
      sg: '三五',
      sa: '梵文',
      sc: '撒丁岛',
      sr: '塞尔维亚',
      sn: '绍纳',
      ii: 'Sichuan Yi; Nuosu',
      sd: '信德语',
      si: '僧伽罗语；僧伽罗语',
      sk: '斯洛伐克语',
      sl: '斯洛文尼亚语',
      so: '索马里',
      st: '南部的索托',
      es: '西班牙语',
      su: '巽他语',
      sw: '斯瓦希里语',
      ss: '斯瓦蒂',
      sv: '瑞典',
      tl: '他加禄语',
      ty: '大溪地',
      tg: '塔吉克',
      ta: '泰米尔语',
      tt: '鞑靼人',
      te: '泰卢固语',
      th: '泰国',
      bo: '藏',
      ti: '提格里尼亚',
      to: '汤加',
      ts: '特松加',
      tn: '茨瓦纳',
      tr: '土耳其',
      tk: '土库曼',
      tw: '二',
      ug: '维吾尔;维吾尔族',
      uk: '乌克兰',
      ur: '乌尔都语',
      uz: '乌兹别克语',
      ve: '销售',
      vi: '越南语',
      vo: '沃拉普克',
      wa: '瓦隆',
      cy: '威尔士语',
      fy: '西弗里斯兰语',
      wo: '沃洛夫',
      xh: '科萨语',
      yi: '意第绪语',
      yo: '约鲁巴语',
      za: 'Zhuang; Chuang',
      zu: '祖鲁语',
    },
  },
  itemSummary: {
    title: '项目代码信息',
    batchId: '批次编号',
    product: '产品',
    sequence: '顺序',
    checksum: '校验和',
    warning: '此项目已激活真伪检查。从门户访问可能会触发真实性警报协议，例如状态更新和后续扫描警告。',
    hideUrl: '隐藏网址',
    showUrl: '显示网址',
    copy: '复制',
    itemUrlCopied: '已复制项目 URL。',
    productName: '产品名称',
    itemId: '商品编号',
    itemStatus: '项目状态',
    itemName: '项目名称',
    batchName: '批次名称',
  },
  updateReportedData: {
    locationTitle: '地点',
    dateTimeTitle: '日期和时间',
    dateTimeInteraction: '互动日期',
    dateTimeReported: '报告日期',
    reset: '重置',
    locationInteraction: '交互位置',
    locationReported: '报告位置',
    reportedData: '报告数据',
  },
};
export default zh;
