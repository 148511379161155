const zhTw = {
  common: {
    labels: {
      selected: '已選擇：',
      searchByName: '按名稱搜索',
      itemStatusMatches: '僅在項目狀態匹配時顯示',
      showTask: '留空以始終顯示此任務',
      emailAddress: '電子郵件地址',
      name: '姓名',
      emailAddressWithComma: '電子郵件地址（逗號分隔）',
      emailSubject: '電子郵件主題（例如：{task} - {thing} 的 {name}，狀態為：{status}）',
      endpointURL: '端點網址',
      securityHmacKey: '安全性（HMAC 密鑰）',
      apiKey: 'ApiKey',
      organizationId: '組織 ID',
      xmlMessage: 'XML 消息',
      createYourFirst: '創建你的第一個',
      valueChain: '價值鏈。',
      category: '類別。',
      supportProfile: '支持配置文件。',
      task: '任務。',
      template: '模板。',
      description: '描述',
      learnMoreLink: '了解更多鏈接',
      image: '圖片',
      displayImage: '顯示圖像',
      chainElementIcon: '鏈元素圖標',
      transportationMethod: '運輸方式',
      gln: 'GLN（全球位置號碼）',
      status: '狀態',
      thing: '事物',
      selectDateRange: '選擇日期範圍',
      searchBy: '搜索方式',
      itemImage: '物品圖片',
      uploadAttachment: '上傳附件',
      addInteractionTo: '將交互添加到',
      interactionName: '交互名稱',
      steps: '腳步',
      displayName: '顯示名稱',
      title: '標題',
      attachments: '附件',
      currentStatus: '當前狀態',
      country: '國家',
      language: '語言',
      items: '項目',
      interactions: '互動',
      tasks: '任務',
      product: '產品',
      item: '物品',
      selectLanguage: '選擇語言',
      addBatch: '添加批次',
      editBatch: '編輯批次',
      filters: '過濾器',
      unknown: '未知',
      from: '從',
      learnMore: '了解更多',
      search: '搜索',
      url: '網址',
      addTask: '添加任務',
      at: '在',
    },
    buttons: {
      save: '保存',
      cancel: '取消',
      finish: '結束',
      submit: '提交',
      delete: '刪除',
      remove: '消除',
      task: '任務',
      ok: '好的',
      logout: '登出',
      decline: '衰退',
      accept: '接受',
      inviteUsers: '邀請用戶',
      send: '發送',
      addMore: '添加更多',
      createAnAction: '創建一個動作',
      removeIntegration: '刪除集成',
      continue: '繼續',
      update: '更新',
      back: '後退',
      next: '下一個',
      close: '關閉',
      upload: '上傳',
      create: '創建',
      clear: '清除',
      apply: '申請',
      viewInDashboard: '在儀表板中查看',
      confirm: '確認',
      yes: '是的',
      no: '不',
      configure: '配置',
    },
    menus: {
      things: '事物',
      items: '項目',
      interactions: '互動',
      reportingAndAnalytics: '報告與分析',
      templates: '模板',
      settings: '設置',
      tasks: '任務',
      users: '用戶',
      advancedSettings: '高級設置',
      valueChains: '價值鏈',
      organisationalSettings: '組織設置',
      language: '語言',
      catalogue: '目錄',
    },
    menuItems: {
      edit: '編輯',
      delete: '刪除',
      remove: '消除',
      manageConfiguration: '管理配置',
      setAsDefault: '設為默認',
      setAsPublic: '設為公開',
      setAsPrivate: '設置為私人',
      manage: '管理',
      duplicate: '複製',
      viewContent: '查看內容',
      viewSettings: '查看設置',
      displayToPublic: '向公眾展示',
      hideFromPublic: '隱藏在公眾面前',
      disable: '禁用',
      enable: '使能夠',
      viewConditions: '查看條件',
      manageConditions: '管理條件',
      viewDisplayConditions: '查看顯示條件',
      manageDisplayConditions: '管理顯示條件',
      change: '改變',
      displayForBatch: '批次顯示',
      viewOrUpdateClassification: '查看/更新分類',
      displayInPublicPage: '在公共頁面中顯示',
      suspended: '暫停',
      unsuspend: '取消暫停',
      suspend: '暫停',
      invitationDeclined: '邀請被拒絕',
      invitationPending: '邀請待處理',
      invitationExpired: '邀請已過期',
      invitationAccepted: '接受邀請',
      setAsMember: '設為會員',
      setAsAdmin: '設置為管理員',
      disablePortalAccess: '禁用門戶訪問',
      enablePortalAccess: '啟用門戶訪問',
      resendInvitation: '重新發送邀請',
      deleteUser: '刪除用戶',
      deactivate: '停用',
      activate: '啟用',
      preview: '預習',
      customiseName: '自定義名稱',
      customiseImage: '自定義圖像',
      addAttachment: '添加附件',
      changeStatus: '更改狀態',
      removePersonalSupportProfile: '刪除個人支持資料',
      createBatch: '創建批次',
      editBatch: '編輯批次',
      deleteBatch: '刪除批次',
      copyToClipboard: '複製到剪貼板',
      openPublicPage: '打開公共頁面',
      createProduct: '創建產品',
      pagePosition: '頁面位置',
      uploadImage: '上傳圖片',
      deleteImage: '刪除圖像',
      updateFromClipboard: '從剪貼板更新',
      viewCodeDetails: '查看代碼詳細信息',
      updateDisplayData: '更新報告的數據',
      hide: '隱藏',
      unhide: '取消隱藏',
    },
    pagination: {
      text: '顯示 {from} 到 {to} 個條目，共 {total} 個條目',
    },
    tabs: {
      general: '一般的',
      categories: '類別',
      support: '支持',
      tasks: '任務',
      users: '用戶',
      integrationActions: '集成操作',
      valueChain: '價值鏈',
      chart: '圖表',
      map: '地圖',
      documents: '文件',
    },
    messages: {
      invitationExpired: '邀請已過期',
      notFoundInvitation: '未找到邀請',
      userAlreadyExists: '用戶已存在',
      nameAlreadyTaken: '姓名已被佔用',
      InvalidContent: '無效內容',
      PayloadTooLarge: '請求的實體太大',
      noSearchResult: '抱歉，我們找不到任何匹配的結果',
      noneSelected: '未選擇任何內容。',
      noneAvailable: '尚未為此組織創建任務。',
      noConfigurableAttributes: '此任務沒有可用的可配置屬性。',
      noInvitation: '沒有待處理的邀請',
      defaultValueChain: '現有的默認元素只能通過從其編輯屏幕中選擇一個新元素作為默認元素來更改。',
      fileSizeError: '上傳文件到 Assure+ Portal 的最大文件大小為 10MB。請上傳不超過 10MB 的文件。',
      submitError: '請填寫所有必填字段',
      inviteSent: '已發送邀請',
      inviteError: '無法發送邀請',
      saved: '已保存',
      deleted: '已刪除',
      duplicated: '重複',
      createThing: '創建的東西',
      createThingError: '無法創建事物',
      duplicateThingError: '東西不能複制',
      updateThing: '東西被複製了',
      updateThingError: '無法更新事物',
      loadTypeError: '無法加載 {type}',
      deleteTypeError: '無法刪除 {type}',
      duplicateTypeError: '無法複製',
      createTask: '已添加任務',
      createTaskError: '無法添加任務',
      updateTask: '任務已更新',
      updateTaskError: '無法更新任務',
      taskError: '無法創建任務',
      createSupportProfile: '支持配置文件已添加',
      createSupportProfileError: '無法添加支持配置文件',
      updateSupportProfile: '支持資料已更新',
      updateSupportProfileError: '無法更新支持配置文件',
      deleteSupportProfile: '支持配置文件已被刪除',
      deleteSupportProfileError: '無法創建支持配置文件',
      supportProfile: '支持配置文件已創建',
      supportProfileError: '無法創建支持配置文件',
      createTeam: '團隊創建',
      createTeamError: '無法創建團隊',
      saveTeam: '團隊已保存',
      saveTeamError: '無法保存團隊',
      renameTeam: '團隊更名',
      renameTeamError: '無法重命名團隊',
      teamStatus: '團隊狀態已更改',
      teamStatusError: '無法更改團隊狀態',
      resendInvite: '邀請重新發送',
      resendInviteError: '無法重新發送邀請',
      revokeInvite: '邀請撤銷',
      revokeInviteError: '無法撤消邀請',
      revokeAccess: '訪問被撤銷',
      revokeAccessError: '無法撤消訪問權限',
      suspendAccess: '暫停',
      suspendAccessError: '無法更改為暫停',
      accountChange: '帳戶已更改',
      accountChangeAdminError: '無法更改為管理員',
      accountChangeOwnerError: '無法更改為所有者',
      accountChangeUserError: '無法更改為用戶',
      inviteUser: '受邀用戶',
      inviteUserError: '無法邀請用戶',
      declineInvitation: '拒絕了邀請',
      declineInvitationError: '無法拒絕邀請（{message}）',
      acceptInvitation: '接受邀請',
      acceptInvitationError: '無法接受邀請（{message}）',
      deleteUser: '用戶已刪除',
      deleteUserError: '無法刪除用戶',
      updateTemplate: '模板已更新',
      updateTemplateError: '無法更新模板',
      deleteTemplate: '模板已刪除',
      deleteTemplateError: '無法刪除模板',
      createTemplate: '模板已創建',
      createTemplateError: '無法創建模板',
      deleteBatch: '批量刪除',
      deleteBatchError: '批處理已使用。無法刪除批次。',
      updateBatch: '批次已更新',
      createBatch: '批量創建',
      createBatchError: '批次 ID 已存在。無法創建批次。',
      identificationCode: '識別碼已創建',
      validateIdentifierError: '無法驗證標識符',
      addFileError: '請添加文件',
      genericError: '一般錯誤',
      createValueChain: '價值鏈已經建立',
      createValueChainError: '無法創建價值鏈',
      updateValueChain: '價值鏈已更新',
      updateValueChainError: '無法更新價值鏈',
      deleteValueChain: '價值鏈已被刪除',
      deleteValueChainError: '無法刪除價值鏈',
      updateCategory: '類別已更新',
      updateCategoryError: '無法更新類別',
      deleteCategory: '類別已刪除',
      deleteCategoryError: '無法刪除類別',
      CreateCategory: '類別已創建',
      createCategoryError: '無法創建類別',
    },
    validation: {
      minNumMaxNum: '最少 {0} 最多 {1} 個字符',
      maxNum: '最多 {0} 個字符',
    },
    uploadFile: {
      maxSize: '（最大大小 10MB）',
    },
  },
  organization: {
    myOrganizations: '我的組織',
    viewAllOrganizations: '查看所有組織',
  },
  interaction: {
    batchRecord: '批次記錄',
    unknownId: '未知 ID',
    externalId: '外部 ID',
    modal: {
      eventClassification: '事件分類',
      unclassified: '未分類',
      default: '默認',
    },
    filters: {
      notice: '注意',
      alert: '警報',
      warning: '警告',
    },
    hidden: '隱',
  },

  interaction_action_0: '用戶通知：',
  interaction_action_1: '項目狀態更改為：',
  interaction_action_2: '已分配項目支持配置文件。',
  interaction_action_3: '項目支持配置文件已被刪除。',
  interaction_action_4: '通知：',
  interaction_action_5: '警告：',
  interaction_action_6: '警報：',
  interaction_administrative_title: '行政變更',
  task_steps: {
    status_check: '當前項目狀態為：{status}',
  },
  item_status: '{狀態}',
  interaction_collections_title: '集合 - {文本}',
  interaction_collection_added_title: '添加到集合 - {text}',
  step_collection_fields: '領域：',
  publicPage: {
    positioning: {
      before: '前',
      after: '後',
      top: '最佳',
      bottom: '底部',
    },
    components: {
      longDescription: '詳細描述',
      attachments: '附件',
      attributes: '屬性',
      services: '服務',
      gallery: '畫廊',
      certifications: '認證',
      video: '視頻',
      history: '歷史',
      supportProfile: '支持簡介',
      fs1TaskPanel: 'FS1 任務面板',
      fs1About: 'FS1 關於',
      fs1FactsPanel: 'FS1 事實小組',
      fs1ResourcesPanel: 'FS1 資源面板',
      events: '活動',
    },
  },
  OrganisationalSettings: {
    header: '組織設置',
    components: {
      general: '一般的',
      branding: '品牌推廣',
    },
    general: {
      name: '姓名',
      businessType: '業務類型',
      OrganizationLogo: '組織標誌',
      PublicPageLoadingImage: '公共頁面加載圖片',
    },
  },
  tasks: {
    tableHeader: {
      name: '任務名稱',
      usedAsDiscovery: '發現任務',
      interactions: '使用次數',
      things: '分配',
      createdAt: '創建於',
      createdBy: '由...製作',
      displayConditions: '顯示條件',
    },
    taskModal: {
      headerCreate: '創建任務',
      headerEdit: '編輯任務',
      components: {
        selectTask: '選擇任務',
      },
    },
  },
  categories: {
    title: '類別',
    noDataMessage1: '創建類別',
    tableHeaders: {
      name: '姓名',
      defaultTemplate: '默認模板',
      createdAt: '創建於',
      createdBy: '由...製作',
    },
    editCategoryModal: {
      createCategory: '創建一個類別',
      editCategory: '編輯類別',

      labels: {
        name: '分類名稱',
        standard: '識別標準',
        identifier: '類別標識符',
        description: '描述',
        image: '類別圖像',
        defaultTemplate: '默認情況下，為此類別創建的每個產品都將設置為此模板。',
      },
    },
  },
  supportProfile: {
    title: '支持簡介',
    addSupportProfile: '添加支持配置文件',
    createMessage1: '創建支持資料',
    tableHeaders: {
      name: '姓名',
      content: '內容',
      displayConditions: '顯示條件',
      contactMethods: '聯繫方式',
    },
    editSupportProfileModal: {
      createSupportProfile: '創建支持配置文件',
      editSupportProfile: '編輯支持資料',
      labels: {
        name: '姓名',
        headerText: '標題文本',
        mainText: '正文',
        mainContent: '主要內容',
        showExpanded: '展開顯示',
        phone: '電話',
        email: '電子郵件',
        imagery: '圖像',
        primary: '基本的',
        secondary: '中學',
        kmlMapType: 'KML 地图类型',
        kmlLink: 'KML 链接',
        kmlFile: '文件',
      },
    },
  },
  templates: {
    title: '模板',
    tableHeaders: {
      name: '姓名',
      createdAt: '創建於',
      createdBy: '由...製作',
      assignedCategories: '分配的類別',
    },
    editTemplateModal: {
      createTemplate: '創建模板',
      editTemplate: '編輯模板',
      labels: {
        templateName: '模板名稱',
        templateDescription: '模闆說明',
        templateImage: '模板圖像',
      },
    },
    templateCreatedModal: {
      title: '已創建模板',
      message: '模板創建成功，您想現在管理並添加屬性、任務、圖像和更多內容嗎？',
      later: '以後再做',
      now: '立即管理',
    },
    templateCreatedProductModal: {
      title: '產品創建',
      message: '產品創建成功，您希望現在管理並添加屬性、任務、圖像和更多內容嗎？',
    },
  },
  user: {
    viewInvitations: '查看邀請',
    inviteUsers: '邀請用戶',
    to: '到',
    userHeaders: {
      name: '姓名',
      email: '電子郵件',
      role: '角色',
      access: '使用權',
      status: '狀態',
    },
    userType: {
      admin: '行政',
      member: '成員',
    },
  },
  webhooks: {
    webhookHeaders: {
      name: '姓名',
      createdAt: '創建於',
      createdBy: '由...製作',
    },
    webhookModal: {
      headerCreate: '開始與團隊成員、其他系統和受信任的第三方共享您的組織的交互的操作。',
      components: {
        emailSettings: '電子郵件設置',
        webhookSettings: '網絡掛鉤設置',
        integrations: '集成',
        thingsAndTasks: '事物和任務',
      },
    },
    actions: '行動',
    editAction: '編輯動作',
  },
  valueChain: {
    headerCreate: '價值鏈',
    headerEdit: '編輯價值鏈',
    editAChainElement: '編輯鏈元素',
    createAChainElement: '創建鏈元素',
    headerDescription: '選擇一個模板來幫助您入門。',
    organisationActivity: '組織活動',
    components: {
      miscellaneousContent: '雜項內容',
      logisticsAndEnvironment: '物流與環境',
      generalInformation: '一般信息',
    },
    tableHeaders: {
      creationDate: '創立日期',
      createdBy: '由...製作',
    },
    templates: {
      justTheBasics: {
        name: '只是基礎',
        description: 'Assure+ 中使用的默認價值鏈。',
      },
      beverage: {
        name: '飲料',
        description: '現在，消費者正在推動通過整個供應鏈跟踪和追溯飲料產品的能力。他們想知道所有產品的來源，回到原料種植地以及生產配套材料和包裝用品的設施。',
      },
      coffee: {
        name: '咖啡',
        description: '在到達您的早晨杯子之前，咖啡豆要經過複雜的全球供應鏈。',
      },
      honey: {
        name: '蜜糖',
        description: '蜂蜜通過複雜的全球供應鏈從蜂箱到消費者。',
      },
      porterValueChain: {
        name: '波特價值鏈',
        description: '波特的價值鏈不是關注部門或會計成本類型，而是關注系統，以及輸入如何轉化為消費者購買的輸出。',
      },
      usaidCommodity: {
        name: '美國國際開發署商品',
        description:
          '該過程從向美國國際開發署提出食品申請開始，美國國際開發署為獲得批准的申請徵求合同投標並採購食品商品。然後將商品從供應商發送到供應鏈不同物流節點的運輸點或倉庫設施，包括美國港口、國外卸貨港以及美國和國際 USAID 預置倉庫。轉移給合作夥伴組織，這些組織隨後負責使用他們自己的運輸和分銷合作夥伴網絡以及他們自己的信息跟踪系統來分銷食品。',
      },
      wine: {
        name: '葡萄酒',
        description: '葡萄酒價值鏈包括五個廣泛的生產階段：葡萄園準備、葡萄生產（葡萄栽培）、葡萄酒生產（葡萄栽培）、物流、分銷、營銷和銷售。',
      },
    },
  },
  documents: {
    title: '文件',
    labels: {
      documentName: '文件名稱',
      gdtiNumber: 'GDTI 編號',
      valueChainSteps: '價值鏈步驟',
      publicRecord: '公共記錄',
      dateModified: '修改日期',
    },
    valueChainSteps: {
      Unclassified: '未分類',
    },
  },
  products: {
    tableHeaders: {
      name: '姓名',
      category: '類別',
      tasks: '分配的任務',
      interactions: '記錄的事件',
      items: '項目',
    },
    menuOptions: {
      manage: '管理',
      duplicate: '複製',
      updateFromClipboard: '從剪貼板更新',
    },
    bulkActions: {
      addTasks: '添加任務',
      removeTasks: '刪除任務',
      addValueChain: '添加價值鏈',
      addSupportProfile: '添加支持配置文件',
      removeSupportProfile: '刪除支持資料',
    },
    productCreatedModal: {
      title: '產品創建',
      message: '產品創建成功，您希望現在管理並添加屬性、任務、圖像和更多內容嗎？',
      later: '以後再做',
      now: '立即管理',
    },
    title: '產品',
  },
  collaborations: {
    header: {
      step: '步',
      title: '從剪貼板更新',
    },
    subTitles: {
      general: '一般的',
      productInfo: '產品信息',
      attributes: '屬性',
      productDocuments: '文件',
      itemAndBatchDocuments: '物料和批次文件',
      images: '圖片',
      videos: '視頻',
      eventHistory: '事件歷史',
      contentTransferSummary: '內容傳輸摘要',
    },
    descriptiveContent: {
      noData: '沒有可用的一般信息。',
      description: '一般信息將在產品級別傳輸，並將添加到所有批次。',
      target: '目標',
      source: '資源',
      labels: {
        name: '姓名',
        shortDescription: '簡短的介紹',
        longDescription: '詳細描述',
      },
      longDescriptionButtons: {
        append: '附加',
        replace: '代替',
      },
    },
    attributes: {
      noData: '沒有可用的屬性。',
      description: '屬性將在產品級別轉移，並將添加到所有批次。',
      warningMessage: '選定的屬性將應用於所有批次的產品',
    },
    itemAndBatchDocuments: {
      noData: '沒有可用的文件。',
      warningMessage: '選定的文檔將僅添加到相應的目標項目或產品批次',
    },
    images: {
      noData: '沒有可用的圖像。',
      description: '圖像將在產品級別傳輸，並將添加到所有批次。',
      selectAllButton: '全選',
    },
    videos: {
      noData: '沒有可用的視頻。',
      description: '視頻將在產品級別上傳輸，並將添加到所有批次中。',
      selectAllButton: '全選',
    },
    eventHistory: {
      noData: '公共內容沒有可用的任務。',
      noItemBatch: '沒有可用於存儲事件的項目或批次。',
      labels: {
        name: '姓名',
        classification: '分類',
      },
    },
    confirmationScreen: {
      noData: '未選擇任何內容。',
      message: '從剪貼板更新...',
      successfulMessage: '從剪貼板更新成功',
    },
  },
  item: {
    filters: {
      doNotUse: '不使用',
      allClear: '全清',
      normalPrecaution: '正常預防',
      reconsiderUse: '重新考慮使用',
      increasedCaution: '更加謹慎',
      unknownId: '身份不明',
      itemOk: '項目確定',
      ready: '準備好',
      active: '積極的',
      resultPending: '結果待定',
      completedPositive: '完成陽性',
      completedNegative: '已完成否定',
      completedInconclusive: '已完成 不確定',
      itemLocked: '物品鎖定',
      itemUnlocked: '物品解鎖',
      unused: '沒用過',
      returned: '回來',
      readyFilled: '已裝滿',
      readyPacked: '準備好包裝',
      withCustomer: '與客戶',
      issueRaised: '提出的問題',
      pickupRequested: '要求取件',
    },
  },
  thing: {
    menus: {
      about: '關於',
      discovery: '發現',
      public: '民眾',
      tasks: '任務',
      support: '支持',
    },
    about: {
      general: '一般的',
      category: '類別',
      documentation: '文檔',
      attributes: '屬性',
      certifications: '認證',
      miscellaneous: '各種各樣的',
      valueChains: '價值鏈',
      gallery: '畫廊',
      advancedSettings: '高級設置',
      changeACategory: '更改類別',
      changeAValueChain: '改變價值鏈',

      certificationsLabel: {
        addCertification: '添加認證',
        editCertification: '編輯認證',
      },
    },
    public: {
      generalSettings: '通用設置',
      enablePublicContent: '啟用公共內容',
      itemCheck: '項目檢查',
      usedAsDiscovery: '發現任務',
      advancedSettings: '高級設置',
      rfiderAuthenticityCheck: 'Assure+ 真實性檢查',
      basicInformation: '基本信息',
      brandExperience: '品牌體驗',
      links: '鏈接',
      miscellaneous: '各種各樣的',
      attributes: '屬性',
      services: '服務',
      documents: '文件',
      gallery: '畫廊',
      certifications: '認證',
      videos: '視頻',
      longDescription: '詳細描述',
      supportProfile: '支持簡介',
      itemHistory: '項目歷史',
      none: '沒有',
      heroImage: '英雄形象',
      sectionImage: '剖面圖',
      heroOverlayImage: '英雄疊加圖像',
      privacyPolicy: '隱私政策',
      productInfo: '產品信息',
      learnMore: '了解更多',
      shopping: '購物',
      section: '部分',
      position: '位置',
      enableAttributes: '啟用屬性',
      attributeVisibility: '屬性可見性',
      enableAttachments: '啟用附件',
      enableCertifications: '啟用認證',
      allowProfles: '允許團隊成員的個人資料',
      surviceLabels: {
        enableServices: '啟用服務',
        serviceVisibility: '服務可見性',
        basic: '基本的',
        off: '離開',
        peerToPeer: '點對點',
      },
      itemHistoryLabels: {
        enableItemHistory: '啟用項目歷史記錄',
        displayTemplate: '顯示模板',
        events: '活動',
        data: '數據',
        images: '圖片',
        eventGallery: '活動畫廊',
        enableEventGallery: '啟用事件庫',
      },
      switch: {
        confirmTextEnable: '您確定要啟用嗎？',
        confirmTextDisable: '您確定要禁用嗎？',
        confirmButtonEnable: '是的，啟用它！',
        confirmButtonDisable: '是的，禁用它！',
      },
      enablePublicLink: '啟用公共鏈接',
      attributeLabels: {
        editAttribute: '編輯屬性',
        addAttribute: '添加屬性',
        label: '標籤',
        value: '價值',
        type: '類型',
      },
    },
  },
  filter: {
    createdByMe: '由我創建',
  },
  swal: {
    duplicate: '複製',
    confirmTitle: '你確定嗎？',
    warningMessage: '您將無法還原此內容！',
    confirmButtonDelete: '是的，刪除它！',
    confirmButtonRemove: '是的，刪除它！',
    confirmButtonDuplicate: '是的，複製它！',
    confirmButtonReset: '是的，重置它！',
    confirmButtonResend: '是的，重新發送！',
    confirmButtonDecline: '對，拒絕！',
    confirmButtonAccept: '是的，接受！',
    keepIt: '不，留著',
    userText: '您需要再次邀請他們。',
    associationError: '無法創建關聯！',
    template: {
      confirmButtonText: '是的，創造一個新產品！',
      createProduct: '創建產品',
      successMessage: '已從此模板創建了新產品',
      errorMessage: '無法從此模板創建新產品',
      successMessage1: '模板已復制',
    },
    products: {
      successMessage: '產品已重複',
      errorMessage: '產品不能重複',
      createProductError: '無法從此模板創建新產品',
    },
    valueChain: {
      successMessage: '價值鏈已被複製',
      errorMessage: '無法複製價值鏈',
      successMessageThingAbout: '該產品的價值鏈已經改變',
      errorMessageThingAbout: '無法更改此產品的價值鏈',
      thingValueChainText: '建議不要更改。',
      lastChance: '最後的機會',
      lastChanceText: '到目前為止收集的任何數據都不會顯示在價值鏈視圖中。此更改無法撤消。',
    },
    user: {
      successMessage: '向用戶重新發送邀請',
      errorMessage: '無法向用戶重新發送邀請',
      successMessage1: '用戶已更新',
      errorMessage1: '無法更新用戶',
    },
    invitations: {
      titleDecline: '拒絕邀請？',
      titleAccept: '接受邀請？',
      text: '邀請您加入',
    },
    category: {
      successMessageThingAbout: '此產品的類別已更改',
      errorMessageThingAbout: '無法更改此產品的類別',
      thingCategoryText: '建議不要更改。',
      lastChance: '最後的機會',
      lastChanceText: '此更改無法撤消。',
    },
    document: {
      createDocument: '文檔已創建',
      createDocumentError: '無法創建文檔',
      updateDocument: '文檔已更新',
      updateDocumentError: '無法更新文檔',
      deleteDocument: '文檔已被刪除',
      deleteDocumentError: '無法刪除文檔',
    },
    task: {
      text: '您即將退出任務配置而不保存更改。所有未保存的更改都將丟失。你要繼續嗎？',
      deleteTask: '任務已刪除',
      updateTask: '任務已更新',
      updateTaskError: '無法更新任務',
      createTask: '任務已創建',
      createTaskTitle: '創建成功！',
      createTaskText: '要打開它的配置嗎？',
      labels: {
        configuration: '配置',
      },
    },
    interaction: {
      createSuccess: '已創建交互',
      createError: '無法創建交互。',
      mapLoadMessage: '加載錯誤',
      mapLoadError: '無法在地圖上加載交互',
      updateSuccess: '互動已更新',
      updateError: '無法更新交互',
      deleteSuccess: '已刪除互動',
      deleteError: '無法刪除交互。',
    },
    item: {
      itemsLimitError: '輸入選項的限制只有 {itemsLimit} 個項目。',
      removeSupportProfile: {
        title: '你確定嗎？',
        success: '已刪除個人支持資料',
        error: '無法刪除個人支持資料',
      },
    },
    miscellaneous: {
      noImageError: '請添加圖片',
      undefinedNotificationType: '請選擇其中一種通知類型',
    },
    webhooks: {
      deleteAction: '操作已刪除',
      deleteActionError: '無法刪除操作',
      updateActionStatus: '操作狀態已更新',
      updateActionStatusError: '無法更新操作狀態',
      updateAction: '動作已更新',
      updateActionError: '無法更新操作',
      createWebhook: '已創建 Webhook',
    },
  },
  invitations: {
    title: '邀請函',
    records: '記錄',
  },
  languages: {
    english: 'English',
    german: 'Deutsch',
    spanish: 'Español',
    japanese: '日本',
    korean: '한국인',
    dutch: 'Nederlands',
    portuguese: 'Português',
    chinese: '中国人',
    chineseTraditional: '中國人',
  },
  editMiscellaneous: {
    editMiscellaneous: '編輯雜項',
    addMiscellaneous: '添加雜項',
    editVideo: '編輯視頻',
    addVideo: '添加視頻',
    editOptions: '編輯選項',
    addOptions: '添加選項',
    labels: {
      embeddedLink: '僅限嵌入式鏈接',
      videoEmbed: '視頻嵌入代碼',
      codeInjection: '代碼注入',
      imageLink: '圖片鏈接',
    },
  },
  discovery: {
    labels: {
      batchId: '批次編號',
      batchName: '批次名稱',
      createdBy: '由...製作',
      itemCount: '項目計數',
      used: '％ 用過的',
    },
    buttons: {
      identificationMethod: '鑑別方法',
      createBatch: '創建批次',
      deactivate: '停用',
      activate: '啟用',
      delete: '刪除',
    },
    maximumItemsPerBatch: '每批次的最大項目數',
    batches: '批次',
    beta: '貝塔',
    item: '物品',
    product: '產品',
    selectIdentificationMethod: '選擇識別方法',
    message: 'Assure+ 唯一 ID (RFID) 是系統生成的在 Assure+ 平台上管理的項目的標識符。',
  },
  dashboard: {
    dateRange: {
      today: '今天',
      lastSevenDays: '過去 7 天',
      lastWeek: '上個星期',
      lastThirtyDays: '過去 30 天',
      lastMonth: '上個月',
      lastTwelvemonths: '過去 12 個月',
      custom: '風俗',
    },
    chart: {
      today: '今天',
      allTime: '整天',
      totalInteractions: '總互動',
      totalTasks: '總任務',
      totalServices: '總服務',
      noData: '沒有數據',
      overallStatusBreakdown: '整體狀態細分',
    },
  },
  displayConditionsRules: {
    onlyAdmin: '用戶權限',
    or: '要么',
    andAlso: '並且',
    addYourFirstCondition: '添加您的第一個條件',
    useSeparator: '使用逗號作為分隔符',
    useDashSeparator: '使用破折号 (-) 作为分隔符',
    rfuidItemId: 'RFUID 項目 ID',
    rfuidBatchId: 'RFUID 批次 ID',
    rfuidSequenceNumber: 'RFUID 序列號',
    itemStatus: '項目狀態',
    userCountry: '用戶國家',
    userLanguage: '用戶語言',
    conditions: {
      stringEquals: '字符串等於',
      stringNotEquals: '字符串不等於',
      includedIn: '包括在',
      notIncludedIn: '不包括在',
      numberEquals: '數等於',
      numberNotEquals: '數不等於',
      numberGreaterThan: '數大於',
      numberLessThan: '數小於',
      between: '之间',
    },
    countries: {
      afghanistan: '阿富汗',
      alandIslands: '奧蘭群島',
      albania: '阿爾巴尼亞',
      algeria: '阿爾及利亞',
      americanSamoa: '美屬薩摩亞',
      andorra: '安道爾',
      angola: '安哥拉',
      anguilla: '安圭拉',
      antarctica: '南極洲',
      antigua: '安提瓜和巴布達',
      argentina: '阿根廷',
      armenia: '亞美尼亞',
      aruba: '阿魯巴島',
      australia: '澳大利亞',
      austria: '奧地利',
      azerbaijan: '阿塞拜疆',
      bahamas: '巴哈馬',
      bahrain: '巴林',
      bangladesh: '孟加拉國',
      barbados: '巴巴多斯',
      belarus: '白俄羅斯',
      belgium: '比利時',
      belize: '伯利茲',
      benin: '貝寧',
      bermuda: '百慕大',
      bhutan: '不丹',
      bolivia: '玻利維亞',
      bonaire: '博內爾島',
      bosnia: '波斯尼亞和黑塞哥維那（簡稱：波黑',
      botswana: '博茨瓦納',
      bouvet: '布維島',
      brazil: '巴西',
      britishIndian: '英屬印度洋領地',
      brunei: '文萊達魯薩蘭國',
      bulgaria: '保加利亞',
      burkina: '布基納法索',
      burundi: '布隆迪',
      cambodia: '柬埔寨',
      cameroon: '喀麥隆',
      canada: '加拿大',
      capeVerde: '佛得角',
      caymanIslands: '開曼群島',
      centralAfricanRepublic: '中非共和國',
      chad: '乍得',
      chile: '智利',
      china: '中國',
      christmasIsland: '聖誕島',
      cocos: '科科斯（基林）群島',
      colombia: '哥倫比亞',
      comoros: '科摩羅',
      congo: '剛果',
      congoDemocratic: '剛果',
      cookIslands: '庫克群島',
      costaRica: '哥斯達黎加',
      coteIvo: '象牙海岸',
      croatia: '克羅地亞',
      cuba: '古巴',
      curacao: '庫拉索',
      cyprus: '塞浦路斯',
      czechRepublic: '捷克共和國',
      denmark: '丹麥',
      djibouti: '吉布提',
      dominica: '多米尼克',
      dominicanRepublic: '多明尼加共和國',
      ecuador: '厄瓜多爾',
      egypt: '埃及',
      elSalvador: '救星',
      equatorialGuinea: '赤道幾內亞',
      eritrea: '厄立特里亞',
      estonia: '愛沙尼亞',
      ethiopia: '埃塞俄比亞',
      falkland: '福克蘭群島（馬爾維納斯）',
      faroeIslands: '法羅群島',
      fiji: '斐濟',
      finland: '芬蘭',
      france: '法國',
      frenchGuiana: '法屬圭亞那',
      frenchPolynesia: '法屬波利尼西亞',
      frenchSouthern: '法屬南部領地',
      gabon: '加蓬',
      gambia: '岡比亞',
      georgia: '喬治亞州',
      germany: '德國',
      ghana: '加納',
      gibraltar: '直布羅陀',
      greece: '希臘',
      greenland: '格陵蘭',
      grenada: '格林納達',
      guadeloupe: '瓜德羅普',
      guam: '關島',
      guatemala: '危地馬拉',
      guernsey: '根西島',
      guinea: '幾內亞',
      guineaBissau: '幾內亞比紹',
      guyana: '圭亞那',
      haiti: '海地',
      heardIsland: '赫德島和麥克唐納群島',
      holySee: '聖座（梵蒂岡城邦）',
      honduras: '洪都拉斯',
      hongKong: '香港',
      hungary: '匈牙利',
      iceland: '冰島',
      india: '印度',
      indonesia: '印度尼西亞',
      iran: '伊朗',
      iraq: '伊拉克',
      ireland: '愛爾蘭',
      isleOfMan: '馬恩島',
      israel: '以色列',
      italy: '意大利',
      jamaica: '牙買加',
      japan: '日本',
      jersey: '球衣',
      jordan: '約旦',
      kazakhstan: '哈薩克斯坦',
      kenya: '肯尼亞',
      kiribati: '基里巴斯',
      korea: '韓國',
      koreaRepublic: '韓國',
      kuwait: '科威特',
      kyrgyzstan: '吉爾吉斯斯坦',
      lao: '勞動',
      latvia: '拉脫維亞',
      lebanon: '黎巴嫩',
      lesotho: '萊索托',
      liberia: '利比里亞',
      libya: '利比亞',
      liechtenstein: '列支敦士登',
      lithuania: '立陶宛',
      luxembourg: '盧森堡',
      macao: '澳門',
      macedonia: '馬其頓',
      madagascar: '馬達加斯加',
      malawi: '馬拉維',
      malaysia: '馬來西亞',
      maldives: '馬爾代夫',
      mali: '馬里',
      malta: '馬耳他',
      marshall: '馬紹爾群島',
      martinique: '馬提尼克島',
      mauritania: '毛里塔尼亞',
      mauritius: '毛里求斯',
      mayotte: '馬約特島',
      mexico: '墨西哥',
      micronesia: '密克羅尼西亞',
      moldova: '摩爾達維亞',
      monaco: '摩納哥',
      mongolia: '蒙古',
      montenegro: '黑山',
      montserrat: '蒙特塞拉特',
      morocco: '摩洛哥',
      mozambique: '莫桑比克',
      myanmar: '緬甸',
      namibia: '納米比亞',
      nauru: '瑙魯',
      nepal: '尼泊爾',
      netherlands: '荷蘭',
      newCaledonia: '新喀裡多尼亞',
      newZealand: '新西蘭',
      nicaragua: '尼加拉瓜',
      niger: '尼日爾',
      nigeria: '尼日利亞',
      niue: '紐埃',
      norfolk: '諾福克島',
      northern: '北馬里亞納群島',
      norway: '挪威',
      oman: '阿曼',
      pakistan: '巴基斯坦',
      palau: '帕勞',
      palestinian: '巴勒斯坦領土，被佔領',
      panama: '巴拿馬',
      papua: '巴布亞新幾內亞',
      paraguay: '巴拉圭',
      peru: '秘魯',
      philippines: '菲律賓',
      pitcairn: '皮特凱恩',
      poland: '波蘭',
      portugal: '葡萄牙',
      puertoRico: '波多黎各',
      qatar: '卡塔爾',
      reunion: '會議',
      romania: '羅馬尼亞',
      russian: '俄羅斯聯邦',
      rwanda: '盧旺達',
      saintBarth: '聖巴泰勒米',
      saintHelena: '聖赫勒拿',
      saintKitts: '聖基茨和尼維斯',
      saintLucia: '聖盧西亞',
      saintMartin: '聖馬丁（法語部分）',
      saintPierre: '聖皮埃爾和密克隆',
      saintVincent: '聖文森特和格林納丁斯',
      samoa: '薩摩亞',
      sanMarino: '聖馬力諾',
      saoTome: '聖多美和普林西比',
      saudiArabia: '沙特阿拉伯',
      senegal: '塞內加爾',
      serbia: '塞爾維亞',
      seychelles: '塞舌爾',
      sierra: '塞拉利昂',
      singapore: '新加坡',
      sintMaarten: '聖馬丁（荷蘭部分）',
      slovakia: '斯洛伐克',
      slovenia: '斯洛文尼亞',
      solomon: '所羅門群島',
      somalia: '索馬里',
      southAfrica: '南非',
      southGeorgia: '南喬治亞島和南桑威奇群島',
      southSudan: '南蘇丹',
      spain: '西班牙',
      sriLanka: '斯里蘭卡',
      sudan: '蘇丹',
      suriname: '蘇里南',
      svalbard: '斯瓦爾巴和揚馬延',
      swaziland: '斯威士蘭',
      sweden: '瑞典',
      switzerland: '瑞士',
      syrian: '阿拉伯敘利亞共和國',
      taiwan: '台灣',
      tajikistan: '塔吉克斯坦',
      tanzania: '坦桑尼亞',
      thailand: '泰國',
      timorLeste: '東帝汶',
      togo: '多哥',
      tokelau: '托克勞',
      tonga: '湯加',
      trinidad: '特立尼達和多巴哥',
      tunisia: '突尼斯',
      turkey: '火雞',
      turkmenistan: '土庫曼斯坦',
      turksAndCaicosIslands: '特克斯和凱科斯群島',
      tuvalu: '圖瓦盧',
      uganda: '烏干達',
      ukraine: '烏克蘭',
      uae: '阿拉伯聯合酋長國',
      uk: '英國',
      usa: '美國',
      usMinor: '美國本土外小島嶼',
      uruguay: '烏拉圭',
      uzbekistan: '烏茲別克斯坦',
      vanuatu: '瓦努阿圖',
      venezuelaBolivarian: '委內瑞拉',
      vietnam: '越南',
      virginIslandsBritish: '維爾京群島',
      virginIslandsUS: '維爾京群島',
      wallisAndFutuna: '瓦利斯和富圖納',
      westernSahara: '撒哈拉沙漠西部',
      yemen: '也門',
      zambia: '贊比亞',
      zimbabwe: '津巴布韋',
    },
    languages: {
      ab: '阿布哈茲語',
      aa: '遠方',
      af: '南非荷蘭語',
      ak: '將要',
      sq: '阿爾巴尼亞語',
      am: '阿姆哈拉語',
      ar: '阿拉伯',
      an: '阿拉貢語',
      hy: '亞美尼亞語',
      as: '阿薩姆語',
      av: '阿瓦里克',
      ae: '阿維斯坦',
      ay: '艾馬拉',
      az: '阿塞拜疆',
      bm: '班巴拉',
      ba: '巴什基爾',
      eu: '巴斯克',
      be: '白俄羅斯語',
      bn: '孟加拉',
      bh: '比哈里語',
      bi: '比斯拉馬',
      bs: '波斯尼亞語',
      br: '布列塔尼',
      bg: '保加利亞語',
      my: '緬甸語',
      ca: '加泰羅尼亞語；巴倫西亞語',
      km: '中央高棉',
      ch: '查莫羅',
      ce: '車臣',
      ny: '奇切瓦；切瓦；海',
      zhCn: '簡體中文）',
      zhTw: '中國傳統的）',
      cu: '教會斯拉夫語',
      cv: '楚瓦什語',
      kw: '康沃爾語',
      co: '科西嘉島',
      cr: '相信',
      hr: '克羅地亞語',
      cs: '捷克語',
      da: '丹麥語',
      dv: '迪維希',
      nl: '荷蘭語；佛蘭芒語',
      dz: '宗喀',
      en: '英語',
      eo: '世界語',
      et: '愛沙尼亞語',
      ee: '母羊',
      fo: '法羅語',
      fj: '斐濟',
      fi: '芬蘭',
      fr: '法語',
      ff: '富拉',
      gd: '蓋爾語',
      gl: '加利西亞語',
      lg: '雙倍的',
      ka: '格魯吉亞語',
      de: '德語',
      el: '希臘語',
      gn: '瓜拉尼',
      gu: '古吉拉特語',
      ht: '海地',
      ha: '豪薩',
      he: '希伯來語',
      hz: '赫雷羅',
      hi: '不。',
      ho: '希里·莫圖',
      hu: '匈牙利',
      is: '冰島的',
      io: '走了',
      ig: '伊博語',
      id: '印度尼西亞',
      iu: '因紐特語',
      ik: '因努皮亞克',
      ga: '愛爾蘭語',
      it: '意大利語',
      ja: '日本人',
      jv: '爪哇語',
      kl: '格陵蘭語',
      kn: '卡納達語',
      kr: '卡努里',
      ks: '克什米爾語',
      kk: '哈薩克語',
      ki: '基庫尤；吉庫尤',
      rw: '基尼亞盧旺達語',
      ky: '吉爾吉斯;吉爾吉斯斯坦',
      kv: '快點',
      kg: '剛果',
      ko: '韓國人',
      kj: '動物;在肉體中',
      ku: '庫爾德',
      lo: '勞動',
      la: '拉丁',
      lv: '拉脫維亞語',
      li: '林堡',
      ln: '林加拉語',
      lt: '立陶宛語',
      lu: '盧巴-加丹加',
      lb: '盧森堡語',
      mk: '馬其頓語',
      mg: '馬爾加什',
      ms: '馬來語',
      ml: '馬拉雅拉姆語',
      mt: '馬耳他語',
      gv: '馬恩島',
      mi: '毛利人',
      mr: '馬拉地語',
      mh: '馬紹爾語',
      mn: '蒙',
      na: '瑙魯',
      nv: '納瓦霍人；納瓦霍',
      ng: '恩東加',
      ne: '尼泊爾語',
      se: '北薩米人',
      no: '挪威',
      oj: '奧吉布瓦',
      or: '奧里亞語',
      om: '奧羅莫',
      os: '奧塞梯;骨科',
      pi: '巴利語',
      pa: '旁遮普語；旁遮普語',
      fa: '波斯語',
      pl: '拋光',
      ptBr: '葡萄牙語',
      ps: '普什圖;普什圖語',
      qu: '蓋丘亞語',
      ro: '羅馬尼亞語',
      rm: '羅曼什語',
      rn: '回合',
      ru: '俄語',
      sm: '薩摩亞',
      sg: '三五',
      sa: '梵文',
      sc: '撒丁島',
      sr: '塞爾維亞',
      sn: '紹納',
      ii: 'Sichuan Yi; Nuosu',
      sd: '信德語',
      si: '僧伽羅語；僧伽羅語',
      sk: '斯洛伐克語',
      sl: '斯洛文尼亞語',
      so: '索馬里',
      st: '南部的索托',
      es: '西班牙語',
      su: '巽他語',
      sw: '斯瓦希里語',
      ss: '斯瓦蒂',
      sv: '瑞典',
      tl: '他加祿語',
      ty: '大溪地',
      tg: '塔吉克',
      ta: '泰米爾語',
      tt: '韃靼人',
      te: '泰盧固語',
      th: '泰國',
      bo: '藏',
      ti: '提格里尼亞',
      to: '湯加',
      ts: '特松加',
      tn: '茨瓦納',
      tr: '土耳其',
      tk: '土庫曼',
      tw: '二',
      ug: '維吾爾;維吾爾族',
      uk: '烏克蘭',
      ur: '烏爾都語',
      uz: '烏茲別克語',
      ve: '銷售',
      vi: '越南語',
      vo: '沃拉普克',
      wa: '瓦隆',
      cy: '威爾士語',
      fy: '西弗里斯蘭語',
      wo: '沃洛夫',
      xh: '科薩語',
      yi: '意第緒語',
      yo: '約魯巴語',
      za: 'Zhuang; Chuang',
      zu: '祖魯語',
    },
  },
  itemSummary: {
    title: '項目代碼信息',
    batchId: '批次編號',
    product: '產品',
    sequence: '順序',
    checksum: '校驗和',
    warning: '此項目已激活真偽檢查。從門戶訪問可能會觸發真實性警報協議，例如狀態更新和後續掃描警告。',
    hideUrl: '隱藏網址',
    showUrl: '顯示網址',
    copy: '複製',
    itemUrlCopied: '已復制項目 URL。',
    productName: '產品名稱',
    itemId: '商品編號',
    itemStatus: '項目狀態',
    itemName: '項目名稱',
    batchName: '批次名稱',
  },
  updateReportedData: {
    locationTitle: '地點',
    dateTimeTitle: '日期和時間',
    dateTimeInteraction: '互動日期',
    dateTimeReported: '報告日期',
    reset: '重置',
    locationInteraction: '交互位置',
    locationReported: '報告位置',
    reportedData: '報告數據',
  },
};
export default zhTw;
