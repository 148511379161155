import Vue from 'vue';
import Vuex from 'vuex';
import ModuleCore from '@/store/modules/core';
import ModuleAuth from '@/store/modules/auth';
import ModuleOrganization from '@/store/modules/organization';
import ModuleInteractions from '@/store/modules/interactions';
import ModuleNotification from '@/store/modules/notification';
import ModuleThings from '@/store/modules/things';
import ModuleVersion from '@/store/modules/version';
import ModuleComponentDisplayRules from '@/store/modules/components/displayRules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: ModuleAuth as any,
    core: ModuleCore,
    organization: ModuleOrganization,
    interactions: ModuleInteractions,
    notification: ModuleNotification,
    things: ModuleThings,
    version: ModuleVersion,
    componentDisplayRules: ModuleComponentDisplayRules,
  },
});
