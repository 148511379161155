import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';
import gql from 'graphql-tag';
import { setContext } from 'apollo-link-context';
import settings from '@/settings';
import jwtMiddleware from './middleware/jwt';

// Create a new Middleware Link using setContext
const middlewareLink = setContext(request => jwtMiddleware());

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const operationsNoError = ['taskById'];

  if (graphQLErrors && operationsNoError.indexOf(operation.operationName) === -1) {
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      let msg = message;
      try {
        msg = extensions ? extensions.responseBody.message : message;
        if (!msg) {
          msg = message;
        }
      } catch (err) {
        msg = message;
      }

      // Condition created to avoid display errors between entities relationships
      if (path && path.length === 1) {
        // EventBus.$emit('notify::error', msg);
      }

      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: `${settings.apiUrlGql}`,
  fetch: <any>fetch,
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  cache,
  fetch: fetch,
  //link
  link: ApolloLink.from([errorLink, middlewareLink, httpLink]),
} as any);

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    // apollo options applied to all queries in components
    $query: {
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
  },
});

export const apolloDefaultClient = apolloProvider.defaultClient;

export const GraphqlFastQuery = (query: string, options?: any) => {
  return apolloDefaultClient.query({
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    ...options,
    query: gql(query),
  });
};
