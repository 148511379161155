import VueRouter from 'vue-router';
import routes from './routes';
import store from '@/store';
import { EventBus, Events } from '@/utils/EventBus';
import { getRouteFailedPermissions } from '@/utils/Guards';
import { accountApi } from '@/api/http/api';

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: routes,
  linkExactActiveClass: 'nav-item active',
});

let hasLoggedin = false;

router.beforeEach(async (to, from, next) => {
  const $auth = (router.app as any).$auth;
  const loggedin = $auth.isAuthenticated();
  const isProtectedPage = to.matched.length && (typeof to.matched[to.matched.length - 1].meta.noAuth != 'boolean' || to.matched[to.matched.length - 1].meta.noAuth !== true);

  if (isProtectedPage && !loggedin) {
    // if we are trying to accept an invite we will need to validate if the current invite needs to to access login or signup. Also get the email address to prepopulate
    if (to.name === 'Invitations' && to.query && to.query.inviteToken) {
      let email = null;
      let isAccount = false;
      const token = to.query.inviteToken;

      // call api to retrieve data by inviteToken and populate following variables to provide a better redirect
      await accountApi
        .getInvitation(String(token))
        .then(response => {
          const invitation = response.data;
          isAccount = invitation.isAccount;
          email = invitation.emailAddress;
        })
        .catch(err => {
          isAccount = true;
          console.error(err);
        });

      if (isAccount) {
        $auth.login(to.fullPath, email);
      } else {
        $auth.signup(to.fullPath, email);
      }
    } else {
      $auth.login(to.fullPath);
    }

    next(false);
    return;
  }

  if (loggedin) {
    await store.dispatch('core/setAccount');
  }

  if (loggedin && !hasLoggedin) {
    hasLoggedin = true;
    EventBus.$emit(Events.authenticationLogin);
  } else if (loggedin && hasLoggedin) {
    EventBus.$emit(Events.routerChangePage);
  }

  next();
});

router.afterEach(async (to, from) => {
  if (document) document.body.dispatchEvent(new Event('click', { bubbles: true })); // trigger document click

  //if it's a new version
  if ((store as any).state.version.hasNewVersion) {
    setTimeout(() => {
      if (location) location.reload();
    }, 500);
  }

  const orgBaseRoute = to.matched.find(r => r.path === '/org/:id');

  if (orgBaseRoute !== undefined) {
    const orgSlug = to.params.id;
    const currentOrganization = store.getters['core/currentOrganizationInfo'];

    if (!currentOrganization || currentOrganization.slug !== orgSlug) {
      try {
        await store.dispatch('core/setCurrentOrganization', { slug: orgSlug });
        await store.dispatch('core/setLanguage', { lang: null });
        await store.dispatch('interactions/setInteractionStatuses', {});
      } catch (err) {
        console.error(err);
      }
    }
  }

  await store.dispatch('core/setFailedRoutePermissions', getRouteFailedPermissions(to));
});

export default router;
