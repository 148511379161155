import { accountInfoFragment } from './fragments/accountInfoFragment';
import { organizationInfoFragment } from './fragments/organizationInfoFragment';

export const query = `query account($page: Int $perPage: Int){
    myaccount{
        ${accountInfoFragment}
        organizations(page:$page perPage:$perPage){
            totalItems
            items{
                ${organizationInfoFragment}
            }
        }
    }
}`;
