import Vue from 'vue';
import store from '@/store';
import VueI18n from 'vue-i18n';
import Messages from '../lang/index';

Vue.use(VueI18n);

// language support setup
const i18n = new VueI18n({
  fallbackLocale: 'en',
  messages: Messages,
});

export default i18n;
