<template>
  <div class="vld-parent">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
    <template v-if="noOrgs">
      <not-found :text-key="'NoOrganizationExist'" :size="'l'"></not-found>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from 'vue-loading-overlay';
import NotFound from '@/pages/NotFound';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  data() {
    return {
      isLoading: true,
      noOrgs: false,
    };
  },
  components: {
    Loading,
    NotFound,
  },
  computed: {
    ...mapGetters({
      account: 'core/accountInfo',
    }),
  },
  mounted() {
    if (this.account) {
      this.isLoading = false;
      let route = null;
      let cookieVal = this.$cookies.get(this.account.id + '-last_organization_slug');

      cookieVal = cookieVal || this.$cookies.get('last_organization_slug');

      if (this.account.organizations && this.account.organizations.items.length) {
        let selectedIndex = -1;

        if (cookieVal) {
          // need to try and find old cookie value after upgrade.
          this.account.organizations.items.forEach((org, index) => {
            if (selectedIndex < 0 && org.slug === cookieVal) {
              selectedIndex = index;
            }
          });
        }

        selectedIndex = selectedIndex < 0 ? 0 : selectedIndex;

        route = {
          name: 'OrganizationInteractions',
          params: {
            id: this.account.organizations.items[selectedIndex].slug,
          },
        };
      }

      if (route) {
        this.$router.replace(route);
      } else {
        this.noOrgs = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.vld-parent {
  ::v-deep svg {
    width: 128px !important;
    height: 128px !important;
    stroke: #0b7ee4 !important;
  }
}
</style>
