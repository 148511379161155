import store from '@/store';

const Permissions = {
  anyone: '*',
  portal_access: 'portal:access',
  accounts_read: 'accounts:read',
  accounts_list: 'accounts:list',
  accounts_create: 'accounts:create',
  accounts_update: 'accounts:update',
  accounts_delete: 'accounts:delete',
  organizations_read: 'organizations:read',
  organizations_list: 'organizations:list',
  organizations_create: 'organizations:create',
  organizations_update: 'organizations:update',
  organizations_delete: 'organizations:delete',
  organizations_accounts_create: 'organizations_accounts:create',
  organizations_accounts_read: 'organizations_accounts:read',
  organizations_accounts_list: 'organizations_accounts:list',
  organizations_accounts_update: 'organizations_accounts:update',
  organizations_accounts_delete: 'organizations_accounts:delete',
  teams_read: 'teams:read',
  teams_list: 'teams:list',
  teams_create: 'teams:create',
  teams_invite: 'teams:invite',
  teams_update: 'teams:update',
  teams_delete: 'teams:delete',
  webhooks_create: 'webhooks:create',
  webhooks_read: 'webhooks:read',
  webhooks_list: 'webhooks:list',
  webhooks_update: 'webhooks:update',
  webhooks_delete: 'webhooks:delete',
  things_create: 'things:create',
  things_read: 'things:read',
  things_list: 'things:list',
  things_update: 'things:update',
  things_delete: 'things:delete',
  things_discover: 'things:discover',
  vcodegroups_create: 'vcodegroups:create',
  vcodegroups_read: 'vcodegroups:read',
  vcodegroups_list: 'vcodegroups:list',
  identification_codes_read: 'identificationCodes:read',
  identification_codes_list: 'identificationCodes:list',
  identification_codes_create: 'identificationCodes:create',
  identification_codes_update: 'identificationCodes:update',
  identification_codes_delete: 'identificationCodes:delete',
  batches_read: 'batches:read',
  batches_list: 'batches:list',
  batches_create: 'batches:create',
  batches_update: 'batches:update',
  batches_delete: 'batches:delete',
  tasks_read: 'tasks:read',
  tasks_list: 'tasks:list',
  tasks_create: 'tasks:create',
  tasks_update: 'tasks:update',
  tasks_delete: 'tasks:delete',
  task_thing_create: 'task_things:create',
  task_thing_update: 'task_things:update',
  task_thing_delete: 'task_things:delete',
  execution_read: 'executions:read',
  execution_list: 'executions:list',
  execution_create: 'executions:create',
  execution_update: 'executions:update',
  execution_process: 'executions:process',
  execution_complete: 'executions:complete',
  interactions_read: 'interactions:read',
  interactions_list: 'interactions:list',
  interactions_create: 'interactions:create',
  interactions_update: 'interactions:update',
  interactions_delete: 'interactions:delete',
  categories_read: 'categories:read',
  categories_list: 'categories:list',
  categories_create: 'categories:create',
  categories_update: 'categories:update',
  categories_delete: 'categories:delete',
  items_read: 'items:read',
  items_list: 'items:list',
  items_create: 'items:create',
  items_update: 'items:update',
  items_delete: 'items:delete',
  tasks_library_read: 'tasksLibrary:read',
  tasks_library_list: 'tasksLibrary:list',
  tasks_library_create: 'tasksLibrary:create',
  tasks_library_update: 'tasksLibrary:update',
  tasks_library_delete: 'tasksLibrary:delete',
  components_create: 'components:create',
  components_read: 'components:read',
  components_list: 'components:list',
  components_update: 'components:update',
  components_delete: 'components:delete',
};

const getRouteFailedPermissions = function (to: any) {
  const failedPermissions: string[] = [];
  let requiredPermissions: string[] = [];

  to.matched.forEach((r: any) => {
    if (r.meta.permissions) {
      requiredPermissions = requiredPermissions.concat(r.meta.permissions);
    }
  });

  const permissions = store.getters['core/permissions'] as string[];

  requiredPermissions.forEach(perm => {
    if (permissions.indexOf(perm) === -1) {
      failedPermissions.push(perm);
    }
  });

  return failedPermissions;
};

export { Permissions, getRouteFailedPermissions };
