export const valueChainElementInfoFragment = `
id
enabled
name
description
isOrganizationActivity
isDefault
icon
image
transportationMethod
gln
order
createdAt
`;
