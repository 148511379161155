const nl = {
  common: {
    labels: {
      selected: 'GESELECTEERD:',
      searchByName: 'Zoek op naam',
      itemStatusMatches: 'Alleen weergeven als de itemstatus overeenkomt',
      showTask: 'Laat leeg om deze taak altijd te tonen',
      emailAddress: 'E-mailadres',
      name: 'Naam',
      emailAddressWithComma: 'E-mailadressen (gescheiden door komma&#39;s)',
      emailSubject: 'E-mailonderwerp (EX:. {task} - {name} voor {thing} met status:{status})',
      endpointURL: 'Eindpunt-URL',
      securityHmacKey: 'Beveiliging (HMAC-sleutel)',
      apiKey: 'API sleutel',
      organizationId: 'Organisatie-ID',
      xmlMessage: 'XML-bericht',
      createYourFirst: 'Maak je eerste',
      valueChain: 'waardeketen.',
      category: 'categorie.',
      supportProfile: 'Ondersteuningsprofiel.',
      task: 'taak.',
      template: 'sjabloon.',
      description: 'Beschrijving',
      learnMoreLink: 'Meer informatie link',
      image: 'Afbeelding',
      displayImage: 'Beeld weergeven',
      chainElementIcon: 'Pictogram kettingelement',
      transportationMethod: 'Transportmethode:',
      gln: 'GLN (algemeen locatienummer)',
      status: 'Toestand',
      thing: 'Ding',
      selectDateRange: 'Selecteer datumbereik',
      searchBy: 'Zoek met',
      itemImage: 'Artikelafbeelding:',
      uploadAttachment: 'Bijlage uploaden',
      addInteractionTo: 'Interactie toevoegen aan',
      interactionName: 'Interactienaam',
      steps: 'Stappen',
      displayName: 'Weergavenaam',
      title: 'Titel',
      attachments: 'Bijlagen',
      currentStatus: 'Huidige status',
      country: 'Land',
      language: 'Taal',
      items: 'Artikelen',
      interactions: 'Interacties',
      tasks: 'Taken',
      product: 'Product',
      item: 'item',
      selectLanguage: 'Selecteer taal',
      addBatch: 'Batch toevoegen',
      editBatch: 'Batch bewerken',
      filters: 'Filters',
      unknown: 'Onbekend',
      from: 'van',
      learnMore: 'Leer meer',
      search: 'Zoekopdracht',
      url: 'URL',
      addTask: 'Voeg taak toe',
      at: 'Bij',
    },
    buttons: {
      save: 'Opslaan',
      cancel: 'Annuleren',
      finish: 'Finish',
      submit: 'Indienen',
      delete: 'Verwijderen',
      remove: 'Verwijderen',
      task: 'Taak',
      ok: 'Oke',
      logout: 'Uitloggen',
      decline: 'Afwijzen',
      accept: 'Aanvaarden',
      inviteUsers: 'Gebruikers uitnodigen',
      send: 'Versturen',
      addMore: 'Voeg meer toe',
      createAnAction: 'Een actie maken',
      removeIntegration: 'Integratie verwijderen',
      continue: 'Doorgaan',
      update: 'Bijwerken',
      back: 'Rug',
      next: 'Volgende',
      close: 'Dichtbij',
      upload: 'Uploaden',
      create: 'Creëren',
      clear: 'Duidelijk',
      apply: 'Van toepassing zijn',
      viewInDashboard: 'Bekijk in dashboard',
      confirm: 'Bevestigen',
      yes: 'Ja',
      no: 'Nee',
      configure: 'Configureren',
    },
    menus: {
      things: 'Dingen',
      items: 'Artikelen',
      interactions: 'Interacties',
      reportingAndAnalytics: 'Rapportage en analyse',
      templates: 'Sjablonen',
      settings: 'Instellingen',
      tasks: 'Taken',
      users: 'Gebruikers',
      advancedSettings: 'Geavanceerde instellingen',
      valueChains: 'Waardeketens',
      organisationalSettings: 'Organisatie-instellingen',
      language: 'Taal',
      catalogue: 'Catalogus',
    },
    menuItems: {
      edit: 'Bewerking',
      delete: 'Verwijderen',
      remove: 'Verwijderen',
      manageConfiguration: 'Configuratie beheren',
      setAsDefault: 'Instellen als standaard',
      setAsPublic: 'Instellen als openbaar',
      setAsPrivate: 'Instellen als privé',
      manage: 'Beheren',
      duplicate: 'Duplicaat',
      viewContent: 'Inhoud bekijken',
      viewSettings: 'Laat instellingen zien',
      displayToPublic: 'Weergeven aan publiek',
      hideFromPublic: 'Verbergen voor publiek',
      disable: 'Uitzetten',
      enable: 'Inschakelen',
      viewConditions: 'Bekijk voorwaarden',
      manageConditions: 'Voorwaarden beheren',
      viewDisplayConditions: 'Weergavevoorwaarden bekijken',
      manageDisplayConditions: 'Weergavevoorwaarden beheren',
      change: 'Wijziging',
      displayForBatch: 'Weergave voor batch',
      viewOrUpdateClassification: 'Classificatie bekijken/bijwerken',
      displayInPublicPage: 'Weergeven op openbare pagina',
      suspended: 'Opgeschort',
      unsuspend: 'Opschorten',
      suspend: 'Opschorten',
      invitationDeclined: 'Uitnodiging geweigerd',
      invitationPending: 'Uitnodiging in behandeling',
      invitationExpired: 'Uitnodiging verlopen',
      invitationAccepted: 'Uitnodiging geaccepteerd',
      setAsMember: 'Instellen als lid',
      setAsAdmin: 'Instellen als beheerder',
      disablePortalAccess: 'Portaltoegang uitschakelen',
      enablePortalAccess: 'Portaltoegang inschakelen',
      resendInvitation: 'Verstuur de uitnodiging opnieuw',
      deleteUser: 'Verwijder gebruiker',
      deactivate: 'Deactiveren',
      activate: 'Activeren',
      preview: 'Voorbeeld',
      customiseName: 'Naam aanpassen:',
      customiseImage: 'Pas afbeelding aan',
      addAttachment: 'Voeg bijlage toe',
      changeStatus: 'Verander status',
      removePersonalSupportProfile: 'Persoonlijk ondersteuningsprofiel verwijderen',
      createBatch: 'Batch maken',
      editBatch: 'Batch bewerken',
      deleteBatch: 'Batch verwijderen',
      copyToClipboard: 'Kopieer naar klembord',
      openPublicPage: 'Openbare pagina openen',
      createProduct: 'Product maken',
      pagePosition: 'Paginapositie',
      uploadImage: 'Afbeelding uploaden',
      deleteImage: 'Verwijder afbeelding',
      updateFromClipboard: 'Update vanaf klembord',
      viewCodeDetails: 'Bekijk codedetails',
      updateDisplayData: 'Gerapporteerde gegevens bijwerken',
      hide: 'Verbergen',
      unhide: 'Zichtbaar maken',
    },
    pagination: {
      text: 'Toont {van} tot {tot} van {totaal} inzendingen',
    },
    tabs: {
      general: 'Algemeen',
      categories: 'Categorieën',
      support: 'Steun',
      tasks: 'Taken',
      users: 'Gebruikers',
      integrationActions: 'Integratieacties',
      valueChain: 'Waardeketens',
      chart: 'Grafiek',
      map: 'Kaart',
      documents: 'Documenten',
    },
    messages: {
      invitationExpired: 'Uitnodiging verlopen',
      notFoundInvitation: 'Uitnodiging niet gevonden',
      userAlreadyExists: 'Gebruiker(s) bestaan al',
      nameAlreadyTaken: 'Naam al in gebruik',
      InvalidContent: 'Ongeldige inhoud',
      PayloadTooLarge: 'Verzoek entiteit te groot',
      noSearchResult: 'Sorry, we konden geen overeenkomende resultaten vinden',
      noneSelected: 'Niets geselecteerd.',
      noneAvailable: 'Er zijn nog geen taken aangemaakt voor deze organisatie.',
      noConfigurableAttributes: 'Er zijn geen configureerbare kenmerken beschikbaar voor deze taak.',
      noInvitation: 'Er zijn geen openstaande uitnodigingen',
      defaultValueChain: 'Het bestaande standaardelement kan alleen worden gewijzigd door een nieuw element als standaardelement te selecteren in het bewerkingsscherm.',
      fileSizeError: 'De maximale bestandsgrootte voor het uploaden van bestanden naar Assure+ Portal is 10 MB. Upload een bestand van maximaal 10 MB.',
      submitError: 'Vul a.u.b. alle verplichte velden in',
      inviteSent: 'Uitnodigingen verzonden',
      inviteError: 'Kan geen uitnodigingen verzenden',
      saved: 'gered',
      deleted: 'verwijderd',
      duplicated: 'gedupliceerd',
      createThing: 'Ding gemaakt',
      createThingError: 'Kan ding niet maken',
      duplicateThingError: 'Ding kan niet worden gedupliceerd',
      updateThing: 'Ding is gedupliceerd',
      updateThingError: 'Kon het ding niet updaten',
      loadTypeError: 'Kan {type} niet laden',
      deleteTypeError: 'Kan {type} niet verwijderen',
      duplicateTypeError: 'Kan niet dupliceren',
      createTask: 'Taak is toegevoegd',
      createTaskError: 'Kan taak niet toevoegen',
      updateTask: 'Taak is bijgewerkt',
      updateTaskError: 'Kan de taak niet bijwerken',
      taskError: 'Kan geen taak maken',
      createSupportProfile: 'Ondersteuningsprofiel is toegevoegd',
      createSupportProfileError: 'Kan ondersteuningsprofiel niet toevoegen',
      updateSupportProfile: 'Ondersteuningsprofiel is bijgewerkt',
      updateSupportProfileError: 'Kan het ondersteuningsprofiel niet bijwerken',
      deleteSupportProfile: 'Ondersteuningsprofiel is verwijderd',
      deleteSupportProfileError: 'Kan geen ondersteuningsprofiel maken',
      supportProfile: 'Ondersteuningsprofiel is aangemaakt',
      supportProfileError: 'Kan geen ondersteuningsprofiel maken',
      createTeam: 'Team gemaakt',
      createTeamError: 'Kan team niet maken',
      saveTeam: 'Team gered',
      saveTeamError: 'Kan team niet opslaan',
      renameTeam: 'Team hernoemd',
      renameTeamError: 'Kan teamnaam niet wijzigen',
      teamStatus: 'Teamstatus gewijzigd',
      teamStatusError: 'Kan teamstatus niet wijzigen',
      resendInvite: 'Nodig opnieuw uit',
      resendInviteError: 'Kan uitnodiging niet opnieuw verzenden',
      revokeInvite: 'Uitnodigen intrekken',
      revokeInviteError: 'Kan uitnodiging niet intrekken',
      revokeAccess: 'Toegang ingetrokken',
      revokeAccessError: 'Kan toegang niet intrekken',
      suspendAccess: 'Opgeschort',
      suspendAccessError: 'Kan niet wijzigen om op te schorten',
      accountChange: 'Account gewijzigd',
      accountChangeAdminError: 'Kan niet wijzigen naar beheerder',
      accountChangeOwnerError: 'Kan niet veranderen in eigenaar',
      accountChangeUserError: 'Kan niet veranderen naar gebruiker',
      inviteUser: 'Gebruiker(s) uitgenodigd',
      inviteUserError: 'Kan gebruiker(s) niet uitnodigen',
      declineInvitation: 'Weigerde de uitnodiging',
      declineInvitationError: 'Kan de uitnodiging niet weigeren ({message})',
      acceptInvitation: 'De uitnodiging geaccepteerd',
      acceptInvitationError: 'Kan de uitnodiging niet accepteren ({message})',
      deleteUser: 'Gebruiker verwijderd',
      deleteUserError: 'Kan gebruiker niet verwijderen',
      updateTemplate: 'Sjabloon is bijgewerkt',
      updateTemplateError: 'Kan de sjabloon niet bijwerken',
      deleteTemplate: 'Sjabloon is verwijderd',
      deleteTemplateError: 'Kan de sjabloon niet verwijderen',
      createTemplate: 'Sjabloon is gemaakt',
      createTemplateError: 'Kan geen sjabloon maken',
      deleteBatch: 'Batch verwijderd',
      deleteBatchError: 'Partij is gebruikt. Kan de batch niet verwijderen.',
      updateBatch: 'Batch is bijgewerkt',
      createBatch: 'Batch aangemaakt',
      createBatchError: 'Batch-ID bestaat al. Kan geen batch maken.',
      identificationCode: 'Identificatiecode is aangemaakt',
      validateIdentifierError: 'Kan een ID niet valideren',
      addFileError: 'Voeg een bestand toe',
      genericError: 'Algemene fout',
      createValueChain: 'Waardeketen is gecreëerd',
      createValueChainError: 'Kan geen waardeketen maken',
      updateValueChain: 'Waardeketen is bijgewerkt',
      updateValueChainError: 'Kon de waardeketen niet updaten',
      deleteValueChain: 'Waardeketen is verwijderd',
      deleteValueChainError: 'Kan de waardeketen niet verwijderen',
      updateCategory: 'Categorie is bijgewerkt',
      updateCategoryError: 'Kan de categorie niet updaten',
      deleteCategory: 'Categorie is verwijderd',
      deleteCategoryError: 'Kan de categorie niet verwijderen',
      CreateCategory: 'Categorie is aangemaakt',
      createCategoryError: 'Kan de categorie niet maken',
    },
    validation: {
      minNumMaxNum: 'Min {0} Max {1} tekens',
      maxNum: 'Maximaal {0} tekens',
    },
    uploadFile: {
      maxSize: '(Max. grootte 10 MB)',
    },
  },
  organization: {
    myOrganizations: 'Mijn organisaties',
    viewAllOrganizations: 'Bekijk alle organisaties',
  },
  interaction: {
    batchRecord: 'Batchrecord',
    unknownId: 'ONBEKENDE ID',
    externalId: 'EXTERNE ID',
    modal: {
      eventClassification: 'Evenementclassificatie',
      unclassified: 'Niet geclassificeerd',
      default: 'Standaard',
    },
    filters: {
      notice: 'Merk op',
      alert: 'Waarschuwing',
      warning: 'Waarschuwing',
    },
    hidden: 'Verborgen',
  },

  interaction_action_0: 'Gebruiker op de hoogte:',
  interaction_action_1: 'Artikelstatus gewijzigd in:',
  interaction_action_2: 'Artikelondersteuningsprofiel is toegewezen.',
  interaction_action_3: 'Artikelondersteuningsprofiel is verwijderd.',
  interaction_action_4: 'Kennisgeving:',
  interaction_action_5: 'Waarschuwing:',
  interaction_action_6: 'Waarschuwing:',
  interaction_administrative_title: 'Administratieve wijziging',
  task_steps: {
    status_check: 'Huidige itemstatus is: {status}',
  },
  item_status: '{toestand}',
  interaction_collections_title: 'Collectie - {tekst}',
  interaction_collection_added_title: 'Toegevoegd aan een collectie - {text}',
  step_collection_fields: 'Velden:',
  publicPage: {
    positioning: {
      before: 'Voordat',
      after: 'Na',
      top: 'Bovenkant',
      bottom: 'Onderkant',
    },
    components: {
      longDescription: 'lange beschrijving',
      attachments: 'Bijlagen',
      attributes: 'attributen',
      services: 'Diensten',
      gallery: 'Galerij',
      certifications: 'Certificeringen',
      video: 'Video',
      history: 'Geschiedenis',
      supportProfile: 'Ondersteuningsprofiel',
      fs1TaskPanel: 'FS1 Taakpaneel',
      fs1About: 'FS1 Over',
      fs1FactsPanel: 'FS1 Feitenpaneel',
      fs1ResourcesPanel: 'FS1 Bronnenpaneel',
      events: 'Evenementen',
    },
  },
  OrganisationalSettings: {
    header: 'Organisatie-instellingen',
    components: {
      general: 'Algemeen',
      branding: 'branding',
    },
    general: {
      name: 'Naam',
      businessType: 'Soort bedrijf',
      OrganizationLogo: 'Organisatie Logo',
      PublicPageLoadingImage: 'Openbare pagina afbeelding laden',
    },
  },
  tasks: {
    tableHeader: {
      name: 'Opdrachtnaam',
      usedAsDiscovery: 'Ontdekkingstaak',
      interactions: 'Gebruikstelling',
      things: 'Toewijzingen',
      createdAt: 'Gemaakt bij',
      createdBy: 'Gemaakt door',
      displayConditions: 'Weergavevoorwaarden',
    },
    taskModal: {
      headerCreate: 'Een taak maken',
      headerEdit: 'Taak bewerken',
      components: {
        selectTask: 'Selecteer Taak',
      },
    },
  },
  categories: {
    title: 'Categorie',
    noDataMessage1: 'Maak een categorie',
    tableHeaders: {
      name: 'Naam',
      defaultTemplate: 'Standaardsjabloon',
      createdAt: 'Gemaakt bij',
      createdBy: 'Gemaakt door',
    },
    editCategoryModal: {
      createCategory: 'Maak een categorie',
      editCategory: 'Een categorie bewerken',

      labels: {
        name: 'categorie naam',
        standard: 'Identificatie Standaard',
        identifier: 'Categorie-ID',
        description: 'Beschrijving',
        image: 'Categorie afbeelding',
        defaultTemplate: 'Elk product dat voor deze categorie is gemaakt, wordt standaard ingesteld op deze sjabloon.',
      },
    },
  },
  supportProfile: {
    title: 'Ondersteuningsprofiel',
    addSupportProfile: 'Ondersteuningsprofiel toevoegen',
    createMessage1: 'Een ondersteuningsprofiel maken',
    tableHeaders: {
      name: 'Naam',
      content: 'Inhoud',
      displayConditions: 'Weergavevoorwaarden',
      contactMethods: 'Contactmethoden',
    },
    editSupportProfileModal: {
      createSupportProfile: 'Ondersteuningsprofiel maken',
      editSupportProfile: 'Ondersteuningsprofiel bewerken',
      labels: {
        name: 'NAAM',
        headerText: 'KOPTEKST',
        mainText: 'HOOFDTEKST',
        mainContent: 'BELANGRIJKSTE INHOUD',
        showExpanded: 'TOON UITGEBREID',
        phone: 'TELEFOON',
        email: 'E-MAIL',
        imagery: 'AFBEELDING',
        primary: 'PRIMAIRE',
        secondary: 'ONDERGESCHIKT',
        kmlMapType: 'KML-kaarttype',
        kmlLink: 'KML-koppeling',
        kmlFile: 'KML-bestand',
      },
    },
  },
  templates: {
    title: 'Sjabloon',
    tableHeaders: {
      name: 'Naam',
      createdAt: 'Gemaakt bij',
      createdBy: 'Gemaakt door',
      assignedCategories: 'Toegewezen categorieën',
    },
    editTemplateModal: {
      createTemplate: 'Een sjabloon maken',
      editTemplate: 'Een sjabloon bewerken',
      labels: {
        templateName: 'Sjabloonnaam',
        templateDescription: 'Sjabloonbeschrijving',
        templateImage: 'Sjabloonafbeelding',
      },
    },
    templateCreatedModal: {
      title: 'Sjabloon gemaakt',
      message: 'Sjabloon succesvol aangemaakt. Wilt u nu beheren en attributen, taken, afbeeldingen en andere inhoud toevoegen?',
      later: 'Doe het later',
      now: 'Nu beheren',
    },
    templateCreatedProductModal: {
      title: 'Product gemaakt',
      message: 'Product met succes gemaakt, wilt u nu beheren en attributen, taken, afbeeldingen en andere inhoud toevoegen?',
    },
  },
  user: {
    viewInvitations: 'Uitnodigingen bekijken',
    inviteUsers: 'Gebruikers uitnodigen',
    to: 'naar',
    userHeaders: {
      name: 'Naam',
      email: 'E-mail',
      role: 'Rol',
      access: 'Toegang',
      status: 'Toestand',
    },
    userType: {
      admin: 'beheerder',
      member: 'Lid',
    },
  },
  webhooks: {
    webhookHeaders: {
      name: 'Naam',
      createdAt: 'Gemaakt bij',
      createdBy: 'Gemaakt door',
    },
    webhookModal: {
      headerCreate: 'actie om de interacties van uw organisatie met teamleden, andere systemen en vertrouwde derde partijen te gaan delen.',
      components: {
        emailSettings: 'Email instellingen',
        webhookSettings: 'Webhook-instellingen',
        integrations: 'Integraties',
        thingsAndTasks: 'Dingen en taken',
      },
    },
    actions: 'Acties',
    editAction: 'Actie bewerken',
  },
  valueChain: {
    headerCreate: 'Waardeketen',
    headerEdit: 'Een waardeketen bewerken',
    editAChainElement: 'Een ketenelement bewerken',
    createAChainElement: 'Een kettingelement maken',
    headerDescription: 'Selecteer een sjabloon om u op weg te helpen.',
    organisationActivity: 'Organisatieactiviteit',
    components: {
      miscellaneousContent: 'Diverse inhoud',
      logisticsAndEnvironment: 'Logistiek &amp; Milieu',
      generalInformation: 'Algemene informatie',
    },
    tableHeaders: {
      creationDate: 'Aanmaakdatum',
      createdBy: 'Gemaakt door',
    },
    templates: {
      justTheBasics: {
        name: 'Alleen de basis',
        description: 'De standaard waardeketen die wordt gebruikt in Assure+.',
      },
      beverage: {
        name: 'Drank',
        description:
          'De mogelijkheid om drankproducten door de hele toeleveringsketen te volgen en te traceren, wordt nu gestuurd door de consument. Ze willen de oorsprong van alle producten weten terug naar het veld waar de ingrediënten worden verbouwd en de faciliteiten die de ondersteunende materialen en verpakkingsbenodigdheden produceren.',
      },
      coffee: {
        name: 'Koffie',
        description: 'Voordat het bij je ochtendkopje komt, reizen koffiebonen door een complexe wereldwijde toeleveringsketen.',
      },
      honey: {
        name: 'Honing',
        description: 'Honing reist door een complexe wereldwijde toeleveringsketen van de bijenkorf naar de consument.',
      },
      porterValueChain: {
        name: 'Porter Waardeketen',
        description:
          'In plaats van te kijken naar afdelingen of boekhoudkundige kostensoorten, concentreert Porter&#39;s Value Chain zich op systemen en hoe inputs worden veranderd in de outputs die door consumenten worden gekocht.',
      },
      usaidCommodity: {
        name: 'USAid Commodity',
        description:
          'Het proces begint wanneer voedselverzoeken worden ingediend bij USAID, die contractbiedingen aanvraagt en voedselproducten aanschaft voor goedgekeurde verzoeken. De goederen worden vervolgens van leveranciers naar transportpunten of magazijnfaciliteiten op verschillende logistieke knooppunten van de toeleveringsketen gestuurd, waaronder Amerikaanse havens, buitenlandse loshavens en Amerikaanse en internationale USAID-voorpositioneringsmagazijnen. Op een bepaald punt in het proces worden de voedselgoederen overgedragen aan partnerorganisaties, die vervolgens verantwoordelijk zijn voor de distributie van de voedselproducten met behulp van hun eigen transport- en distributiepartnernetwerken, evenals hun eigen informatievolgsystemen.',
      },
      wine: {
        name: 'Wijn',
        description:
          'De wijnwaardeketen bestaat uit vijf brede productiefasen: wijngaardvoorbereiding, druivenproductie (wijnbouw), wijnproductie (wijnbouw), logistiek en distributie, marketing en verkoop.',
      },
    },
  },
  documents: {
    title: 'Documenten',
    labels: {
      documentName: 'Document Naam',
      gdtiNumber: 'GDTI-nummer',
      valueChainSteps: 'Waardeketenstap',
      publicRecord: 'Openbaar register',
      dateModified: 'Datum gewijzigd',
    },
    valueChainSteps: {
      Unclassified: 'Niet geclassificeerd',
    },
  },
  products: {
    tableHeaders: {
      name: 'Naam',
      category: 'Categorie',
      tasks: 'Toegewezen taken',
      interactions: 'Opgenomen gebeurtenissen',
      items: 'Artikelen',
    },
    menuOptions: {
      manage: 'Beheren',
      duplicate: 'Duplicaat',
      updateFromClipboard: 'Update vanaf klembord',
    },
    bulkActions: {
      addTasks: 'Taken toevoegen',
      removeTasks: 'Taken verwijderen',
      addValueChain: 'Waardeketen toevoegen',
      addSupportProfile: 'Ondersteuningsprofiel toevoegen',
      removeSupportProfile: 'Ondersteuningsprofiel verwijderen',
    },
    productCreatedModal: {
      title: 'Product gemaakt',
      message: 'Product met succes gemaakt, wilt u nu beheren en attributen, taken, afbeeldingen en andere inhoud toevoegen?',
      later: 'Doe het later',
      now: 'Nu beheren',
    },
    title: 'Producten',
  },
  collaborations: {
    header: {
      step: 'Stap',
      title: 'Update vanaf klembord',
    },
    subTitles: {
      general: 'Algemeen',
      productInfo: 'Productinformatie',
      attributes: 'attributen',
      productDocuments: 'Documenten',
      itemAndBatchDocuments: 'Artikel- en batchdocumenten',
      images: 'Afbeeldingen',
      videos: 'Videos',
      eventHistory: 'Gebeurtenisgeschiedenis',
      contentTransferSummary: 'Samenvatting inhoudsoverdracht',
    },
    descriptiveContent: {
      noData: 'Er is geen algemene informatie beschikbaar.',
      description: 'Algemene informatie wordt op productniveau overgedragen en aan alle batches toegevoegd.',
      target: 'Doel',
      source: 'Bron',
      labels: {
        name: 'Naam',
        shortDescription: 'korte beschrijving',
        longDescription: 'lange beschrijving',
      },
      longDescriptionButtons: {
        append: 'toevoegen',
        replace: 'Vervangen',
      },
    },
    attributes: {
      noData: 'Er zijn geen attributen beschikbaar.',
      description: 'Attributen worden op productniveau overgedragen en aan alle batches toegevoegd.',
      warningMessage: 'Geselecteerde attributen worden toegepast op alle batches van het product',
    },
    itemAndBatchDocuments: {
      noData: 'Er zijn geen documenten aanwezig.',
      warningMessage: 'Geselecteerde documenten worden alleen toegevoegd aan het respectievelijke doelitem of de batch van het product',
    },
    images: {
      noData: 'Er zijn geen afbeeldingen beschikbaar.',
      description: 'Afbeeldingen worden op productniveau overgedragen en aan alle batches toegevoegd.',
      selectAllButton: 'Selecteer alles',
    },
    videos: {
      noData: "Er zijn geen video's beschikbaar.",
      description: "Video's worden op productniveau overgedragen en aan alle batches toegevoegd.",
      selectAllButton: 'Selecteer alles',
    },
    eventHistory: {
      noData: 'Er zijn geen taken beschikbaar met openbare inhoud.',
      noItemBatch: 'Er is geen item of batch beschikbaar om evenementen op te slaan.',
      labels: {
        name: 'Naam',
        classification: 'Classificatie',
      },
    },
    confirmationScreen: {
      noData: 'Geen inhoud geselecteerd.',
      message: 'Bijwerken vanaf Klembord...',
      successfulMessage: 'Update vanaf klembord geslaagd',
    },
  },
  item: {
    filters: {
      doNotUse: 'Gebruik niet',
      allClear: 'Alles duidelijk',
      normalPrecaution: 'Normale voorzorg:',
      reconsiderUse: 'Gebruik heroverwegen',
      increasedCaution: 'Verhoogde voorzichtigheid',
      unknownId: 'onbekende ID',
      itemOk: 'Artikel Ok',
      ready: 'Klaar',
      active: 'Actief',
      resultPending: 'Resultaat in behandeling',
      completedPositive: 'Positief afgerond',
      completedNegative: 'Negatief voltooid',
      completedInconclusive: 'Voltooid Onbeslist',
      itemLocked: 'Artikel vergrendeld',
      itemUnlocked: 'Artikel ontgrendeld',
      unused: 'ongebruikt',
      returned: 'Geretourneerd',
      readyFilled: 'Klaar Gevuld',
      readyPacked: 'Klaar verpakt',
      withCustomer: 'Met klant',
      issueRaised: 'Probleem aan de orde gesteld',
      pickupRequested: 'Ophalen aangevraagd',
    },
  },
  thing: {
    menus: {
      about: 'Over',
      discovery: 'Ontdekking',
      public: 'Openbaar',
      tasks: 'Taken',
      support: 'Steun',
    },
    about: {
      general: 'Algemeen',
      category: 'Categorie',
      documentation: 'Documentatie',
      attributes: 'attributen',
      certifications: 'Certificeringen',
      miscellaneous: 'Diversen',
      valueChains: 'Waardeketens',
      gallery: 'Galerij',
      advancedSettings: 'Geavanceerde instellingen',
      changeACategory: 'Een categorie wijzigen',
      changeAValueChain: 'Verander een waardeketen',

      certificationsLabel: {
        addCertification: 'Certificering toevoegen',
        editCertification: 'Certificering bewerken',
      },
    },
    public: {
      generalSettings: 'Algemene instellingen',
      enablePublicContent: 'Openbare inhoud inschakelen',
      itemCheck: 'Artikelcontrole',
      usedAsDiscovery: 'Ontdekkingstaak',
      advancedSettings: 'Geavanceerde instellingen',
      rfiderAuthenticityCheck: 'Assure+-authenticiteitscontrole',
      basicInformation: 'Basis informatie',
      brandExperience: 'Merk ervaring',
      links: 'Links',
      miscellaneous: 'Diversen',
      attributes: 'attributen',
      services: 'Diensten',
      documents: 'Documenten',
      gallery: 'Galerij',
      certifications: 'Certificeringen',
      videos: 'Videos',
      longDescription: 'lange beschrijving',
      supportProfile: 'Ondersteuningsprofiel',
      itemHistory: 'Artikelgeschiedenis',
      none: 'Geen',
      heroImage: 'Heldenafbeelding',
      sectionImage: 'Sectie Afbeelding',
      heroOverlayImage: 'Heldenoverlay-afbeelding',
      privacyPolicy: 'Privacybeleid',
      productInfo: 'Product informatie',
      learnMore: 'Leer meer',
      shopping: 'Boodschappen doen',
      section: 'Sectie',
      position: 'Positie',
      enableAttributes: 'Attributen inschakelen',
      attributeVisibility: 'Attribuutzichtbaarheid',
      enableAttachments: 'Bijlagen inschakelen',
      enableCertifications: 'Certificeringen inschakelen',
      allowProfles: 'Persoonlijke profielen van teamleden toestaan',
      surviceLabels: {
        enableServices: 'Services inschakelen',
        serviceVisibility: 'Servicezichtbaarheid',
        basic: 'Basis',
        off: 'Uit',
        peerToPeer: 'Peer naar peer',
      },
      itemHistoryLabels: {
        enableItemHistory: 'Artikelgeschiedenis inschakelen',
        displayTemplate: 'Weergavesjabloon',
        events: 'Evenementen',
        data: 'Gegevens',
        images: 'Afbeeldingen',
        eventGallery: 'Evenementengalerij',
        enableEventGallery: 'Evenementengalerij inschakelen',
      },
      switch: {
        confirmTextEnable: 'Weet je zeker dat je wilt inschakelen?',
        confirmTextDisable: 'Weet je zeker dat je wilt uitschakelen?',
        confirmButtonEnable: 'Ja, schakel het in!',
        confirmButtonDisable: 'Ja, schakel het uit!&#39;',
      },
      enablePublicLink: 'Openbare link inschakelen',
      attributeLabels: {
        editAttribute: 'Attribuut bewerken',
        addAttribute: 'Attribuut toevoegen',
        label: 'Label',
        value: 'Waarde',
        type: 'Type',
      },
    },
  },
  filter: {
    createdByMe: 'Gecreëerd door mij',
  },
  swal: {
    duplicate: 'dupliceren',
    confirmTitle: 'Weet je het zeker?',
    warningMessage: 'U kunt dit niet terugdraaien!',
    confirmButtonDelete: 'Ja, verwijder het!',
    confirmButtonRemove: 'Ja, verwijder het!',
    confirmButtonDuplicate: 'Ja, dupliceer het!',
    confirmButtonReset: 'Ja, reset het!',
    confirmButtonResend: 'Ja, stuur het opnieuw!',
    confirmButtonDecline: 'Ja, weiger het!',
    confirmButtonAccept: 'Ja, accepteer het!',
    keepIt: 'Nee, hou het',
    userText: 'U moet ze opnieuw uitnodigen.',
    associationError: 'Er kunnen geen associaties worden gemaakt!',
    template: {
      confirmButtonText: 'Ja, maak een nieuw product aan!',
      createProduct: 'Een product maken',
      successMessage: 'Er is een nieuw product gemaakt op basis van deze sjabloon',
      errorMessage: 'Er kan geen nieuw product worden gemaakt op basis van deze sjabloon',
      successMessage1: 'Sjabloon is gedupliceerd',
    },
    products: {
      successMessage: 'Product is gedupliceerd',
      errorMessage: 'Product kan niet worden gedupliceerd',
      createProductError: 'Er kan geen nieuw product worden gemaakt op basis van deze sjabloon',
    },
    valueChain: {
      successMessage: 'Waardeketen is gedupliceerd',
      errorMessage: 'Kon de waardeketen niet dupliceren',
      successMessageThingAbout: 'Waardeketen voor dit product is gewijzigd',
      errorMessageThingAbout: 'Kan een waardeketen voor dit product niet wijzigen',
      thingValueChainText: 'Aanbevolen dat dit niet wordt gewijzigd.',
      lastChance: 'Laatste kans',
      lastChanceText: 'Alle gegevens die tot nu toe zijn verzameld, worden niet weergegeven in de waardeketenweergave. Deze wijziging kan niet ongedaan worden gemaakt.',
    },
    user: {
      successMessage: 'Verstuur de uitnodiging opnieuw naar de gebruiker',
      errorMessage: 'Kan de uitnodiging niet opnieuw naar de gebruiker verzenden',
      successMessage1: 'Gebruiker bijgewerkt',
      errorMessage1: 'Kan gebruiker niet updaten',
    },
    invitations: {
      titleDecline: 'Uitnodiging weigeren?',
      titleAccept: 'Uitnodiging accepteren?',
      text: 'Je bent uitgenodigd om mee te doen',
    },
    category: {
      successMessageThingAbout: 'Categorie voor dit product is gewijzigd',
      errorMessageThingAbout: 'Kan een categorie voor dit product niet wijzigen',
      thingCategoryText: 'Aanbevolen dat dit niet wordt gewijzigd.',
      lastChance: 'Laatste kans',
      lastChanceText: 'Deze wijziging kan niet ongedaan worden gemaakt.',
    },
    document: {
      createDocument: 'Document is aangemaakt',
      createDocumentError: 'Kan geen document maken',
      updateDocument: 'Document is bijgewerkt',
      updateDocumentError: 'Kon het document niet bijwerken',
      deleteDocument: 'Document is verwijderd',
      deleteDocumentError: 'Kon het document niet verwijderen',
    },
    task: {
      text: 'U staat op het punt de taakconfiguratie af te sluiten zonder uw wijzigingen op te slaan. Alle niet-opgeslagen wijzigingen gaan verloren. Wil je verder gaan?',
      deleteTask: 'Taak is verwijderd',
      updateTask: 'Taak is bijgewerkt',
      updateTaskError: 'Kan de taak niet bijwerken',
      createTask: 'Taak is aangemaakt',
      createTaskTitle: 'Met succes gemaakt!',
      createTaskText: 'Wilt u de configuratie openen?',
      labels: {
        configuration: 'Configuratie',
      },
    },
    interaction: {
      createSuccess: 'Interactie gemaakt',
      createError: 'Kan geen interactie maken.',
      mapLoadMessage: 'Laadfout',
      mapLoadError: 'Kan de interacties op de kaart niet laden',
      updateSuccess: 'Interactie is bijgewerkt',
      updateError: 'Interactie kan niet worden bijgewerkt',
      deleteSuccess: 'Interactie is verwijderd',
      deleteError: 'Interactie kan niet worden verwijderd.',
    },
    item: {
      itemsLimitError: 'De limiet voor invoeropties is alleen {itemsLimit} items.',
      removeSupportProfile: {
        title: 'Weet je het zeker?',
        success: 'Het persoonlijke ondersteuningsprofiel is verwijderd',
        error: 'Kan persoonlijk ondersteuningsprofiel niet verwijderen',
      },
    },
    miscellaneous: {
      noImageError: 'Voeg een afbeelding toe',
      undefinedNotificationType: 'Kies een van de meldingstypes',
    },
    webhooks: {
      deleteAction: 'Actie is verwijderd',
      deleteActionError: 'Kan de actie niet verwijderen',
      updateActionStatus: 'Actiestatus is bijgewerkt',
      updateActionStatusError: 'Kan de actiestatus niet bijwerken',
      updateAction: 'Actie is bijgewerkt',
      updateActionError: 'Kan de actie niet updaten',
      createWebhook: 'Webhook gemaakt',
    },
  },
  invitations: {
    title: 'Uitnodigingen',
    records: 'Records',
  },
  languages: {
    english: 'English',
    german: 'Deutsch',
    spanish: 'Español',
    japanese: '日本',
    korean: '한국인',
    dutch: 'Nederlands',
    portuguese: 'Português',
    chinese: '中国人',
    chineseTraditional: '中國人',
  },
  editMiscellaneous: {
    editMiscellaneous: 'Diversen bewerken',
    addMiscellaneous: 'Diversen toevoegen',
    editVideo: 'Bewerk video',
    addVideo: 'Voeg video toe',
    editOptions: 'Opties bewerken',
    addOptions: 'Opties toevoegen',
    labels: {
      embeddedLink: 'ALLEEN INGEBEDDE LINK',
      videoEmbed: 'VIDEO INSLUIT CODE',
      codeInjection: 'Code-injectie',
      imageLink: 'AFBEELDINGSLINK',
    },
  },
  discovery: {
    labels: {
      batchId: 'Batch-ID',
      batchName: 'Batchnaam:',
      createdBy: 'Gemaakt door',
      itemCount: 'Aantal voorwerpen',
      used: '% Gebruikt',
    },
    buttons: {
      identificationMethod: 'Identificatiemethode:',
      createBatch: 'Batch maken',
      deactivate: 'Deactiveren',
      activate: 'Activeren',
      delete: 'Verwijderen',
    },
    maximumItemsPerBatch: 'Maximum aantal stuks per batch',
    batches: 'batches',
    beta: 'bèta',
    item: 'Item',
    product: 'Product',
    selectIdentificationMethod: 'Selecteer identificatiemethode',
    message: 'De Assure+ Unique ID (RFUID) is een door het systeem gegenereerde identificatie van items die worden beheerd op het Assure+-platform.',
  },
  dashboard: {
    dateRange: {
      today: 'Vandaag',
      lastSevenDays: 'Laatste 7 dagen',
      lastWeek: 'Vorige week',
      lastThirtyDays: 'Laatste 30 dagen',
      lastMonth: 'Vorige maand',
      lastTwelvemonths: 'Afgelopen 12 maanden',
      custom: 'Aangepast',
    },
    chart: {
      today: 'Vandaag',
      allTime: 'Altijd',
      totalInteractions: 'Totaal aantal interacties',
      totalTasks: 'Totaal aantal taken',
      totalServices: 'Totaal diensten',
      noData: 'Geen gegevens',
      overallStatusBreakdown: 'Algemene statusuitsplitsing',
    },
  },
  displayConditionsRules: {
    onlyAdmin: 'Gebruikersrechten',
    or: 'of',
    andAlso: 'En ook',
    addYourFirstCondition: 'Voeg uw eerste voorwaarde toe',
    useSeparator: 'Gebruik komma als scheidingsteken',
    useDashSeparator: 'Gebruik een streepje (-) als scheidingsteken',
    rfuidItemId: 'RFUID Artikel-ID',
    rfuidBatchId: 'RFUID Batch-ID',
    rfuidSequenceNumber: 'RFUID-volgnummer:',
    itemStatus: 'Artikelstatus',
    userCountry: 'Land van gebruiker',
    userLanguage: 'Gebruikerstaal',
    conditions: {
      stringEquals: 'String is gelijk aan',
      stringNotEquals: 'Tekenreeks is niet gelijk aan',
      includedIn: 'Inbegrepen',
      notIncludedIn: 'Niet inbegrepen in',
      numberEquals: 'Getal is gelijk aan',
      numberNotEquals: 'Getal is niet gelijk aan',
      numberGreaterThan: 'Getal groter dan',
      numberLessThan: 'Getal kleiner dan',
      between: 'tussen',
    },
    countries: {
      afghanistan: 'Afghanistan',
      alandIslands: 'Aland-eilanden',
      albania: 'Albanië',
      algeria: 'Algerije',
      americanSamoa: 'Amerikaans Samoa',
      andorra: 'Andorra',
      angola: 'Angola',
      anguilla: 'Anguilla',
      antarctica: 'Antarctica',
      antigua: 'Antigua en Barbuda',
      argentina: 'Argentinië',
      armenia: 'Armenië',
      aruba: 'Aruba',
      australia: 'Australië',
      austria: 'Oostenrijk',
      azerbaijan: 'Azerbeidzjan',
      bahamas: 'Bahamas',
      bahrain: 'Bahrein',
      bangladesh: 'Bangladesh',
      barbados: 'Barbados',
      belarus: 'Wit-Rusland',
      belgium: 'België',
      belize: 'Belize',
      benin: 'Benin',
      bermuda: 'Bermuda',
      bhutan: 'Bhutan',
      bolivia: 'Bolivia',
      bonaire: 'Bonaire',
      bosnia: 'Bosnië-Herzegovina',
      botswana: 'Botswana',
      bouvet: 'Bouvet-eiland',
      brazil: 'Brazilië',
      britishIndian: 'Brits-Indisch oceaan gebied',
      brunei: 'Brunei Darussalam',
      bulgaria: 'Bulgarije',
      burkina: 'Burkina Faso',
      burundi: 'Burundi',
      cambodia: 'Cambodja',
      cameroon: 'Kameroen',
      canada: 'Canada',
      capeVerde: 'Kaapverdië',
      caymanIslands: 'Kaaiman Eilanden',
      centralAfricanRepublic: 'Centraal Afrikaanse Republiek',
      chad: 'Tsjaad',
      chile: 'Chili',
      china: 'China',
      christmasIsland: 'Kersteiland',
      cocos: 'Cocos (Keeling) Eilanden',
      colombia: 'Colombia',
      comoros: 'Comoren',
      congo: 'Congo',
      congoDemocratic: 'Congo',
      cookIslands: 'Cook Eilanden',
      costaRica: 'Costa Rica',
      coteIvo: 'Ivoorkust',
      croatia: 'Kroatië',
      cuba: 'Cuba',
      curacao: 'Curacao',
      cyprus: 'Cyprus',
      czechRepublic: 'Tsjechische Republiek',
      denmark: 'Denemarken',
      djibouti: 'Djibouti',
      dominica: 'Dominica',
      dominicanRepublic: 'Dominicaanse Republiek',
      ecuador: 'Ecuador',
      egypt: 'Egypte',
      elSalvador: 'De redder',
      equatorialGuinea: 'Equatoriaal-Guinea',
      eritrea: 'Eritrea',
      estonia: 'Estland',
      ethiopia: 'Ethiopië',
      falkland: 'Falklandeilanden (Malvinas)',
      faroeIslands: 'Faeröer',
      fiji: 'Fiji',
      finland: 'Finland',
      france: 'Frankrijk',
      frenchGuiana: 'Frans Guyana',
      frenchPolynesia: 'Frans-Polynesië',
      frenchSouthern: 'Franse zuidelijke gebieden',
      gabon: 'Gabon',
      gambia: 'Gambia',
      georgia: 'Georgië',
      germany: 'Duitsland',
      ghana: 'Ghana',
      gibraltar: 'Gibraltar',
      greece: 'Griekenland',
      greenland: 'Groenland',
      grenada: 'Grenada',
      guadeloupe: 'Guadeloupe',
      guam: 'Guam',
      guatemala: 'Guatemala',
      guernsey: 'Guernsey',
      guinea: 'Guinea',
      guineaBissau: 'Guinee-Bissau',
      guyana: 'Guyana',
      haiti: 'Haïti',
      heardIsland: 'Heard- en McDonaldeilanden',
      holySee: 'Heilige Stoel (Vaticaanstad)',
      honduras: 'Honduras',
      hongKong: 'Hongkong',
      hungary: 'Hongarije',
      iceland: 'IJsland',
      india: 'India',
      indonesia: 'Indonesië',
      iran: 'Iran',
      iraq: 'Irak',
      ireland: 'Ierland',
      isleOfMan: 'eiland Man',
      israel: 'Israël',
      italy: 'Italië',
      jamaica: 'Jamaica',
      japan: 'Japan',
      jersey: 'Jersey',
      jordan: 'Jordanië',
      kazakhstan: 'Kazachstan',
      kenya: 'Kenia',
      kiribati: 'Kiribati',
      korea: 'Korea',
      koreaRepublic: 'Korea',
      kuwait: 'Koeweit',
      kyrgyzstan: 'Kirgizië',
      lao: 'Werk',
      latvia: 'Letland',
      lebanon: 'Libanon',
      lesotho: 'Lesotho',
      liberia: 'Liberia',
      libya: 'Libië',
      liechtenstein: 'Liechtenstein',
      lithuania: 'Litouwen',
      luxembourg: 'Luxemburg',
      macao: 'Macau',
      macedonia: 'Macedonië',
      madagascar: 'Madagascar',
      malawi: 'Malawi',
      malaysia: 'Maleisië',
      maldives: 'Maldiven',
      mali: 'Mali',
      malta: 'Malta',
      marshall: 'Marshall eilanden',
      martinique: 'Martinique',
      mauritania: 'Mauritanië',
      mauritius: 'Mauritius',
      mayotte: 'Mayotte',
      mexico: 'Mexico',
      micronesia: 'Micronesië',
      moldova: 'Moldavië',
      monaco: 'Monaco',
      mongolia: 'Mongolië',
      montenegro: 'Montenegro',
      montserrat: 'Montserrat',
      morocco: 'Marokko',
      mozambique: 'Mozambique',
      myanmar: 'Myanmar',
      namibia: 'Namibië',
      nauru: 'Nauru',
      nepal: 'Nepal',
      netherlands: 'Nederland',
      newCaledonia: 'Nieuw-Caledonië',
      newZealand: 'Nieuw-Zeeland',
      nicaragua: 'Nicaragua',
      niger: 'Niger',
      nigeria: 'Nigeria',
      niue: 'Niue',
      norfolk: 'Norfolkeiland',
      northern: 'noordelijke Mariana eilanden',
      norway: 'Noorwegen',
      oman: 'Oman',
      pakistan: 'Pakistan',
      palau: 'Palau',
      palestinian: 'Palestijnse Gebieden, Bezet',
      panama: 'Panama',
      papua: 'Papoea-Nieuw-Guinea',
      paraguay: 'Paraguay',
      peru: 'Peru',
      philippines: 'Filippijnen',
      pitcairn: 'Pitcairn',
      poland: 'Polen',
      portugal: 'Portugal',
      puertoRico: 'Puerto Rico',
      qatar: 'Qatar',
      reunion: 'Ontmoeting',
      romania: 'Roemenië',
      russian: 'Russische Federatie',
      rwanda: 'Rwanda',
      saintBarth: 'Sint-Bartholomeus',
      saintHelena: 'Sint Helena',
      saintKitts: 'Saint Kitts en Nevis',
      saintLucia: 'Sint Lucia',
      saintMartin: 'Sint Maarten (Frans gedeelte)',
      saintPierre: 'Saint Pierre en Miquelon',
      saintVincent: 'Saint Vincent en de Grenadines',
      samoa: 'Samoa',
      sanMarino: 'San Marino',
      saoTome: 'Sao Tomé en Principe',
      saudiArabia: 'Saoedi-Arabië',
      senegal: 'Senegal',
      serbia: 'Servië',
      seychelles: 'Seychellen',
      sierra: 'Sierra Leone',
      singapore: 'Singapore',
      sintMaarten: 'Sint Maarten (Nederlands deel)',
      slovakia: 'Slowakije',
      slovenia: 'Slovenië',
      solomon: 'Solomon eilanden',
      somalia: 'Somalië',
      southAfrica: 'Zuid-Afrika',
      southGeorgia: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
      southSudan: 'Zuid Soedan',
      spain: 'Spanje',
      sriLanka: 'Sri Lanka',
      sudan: 'Soedan',
      suriname: 'Suriname',
      svalbard: 'Svalbard en Jan Mayen',
      swaziland: 'Swaziland',
      sweden: 'Zweden',
      switzerland: 'Zwitserland',
      syrian: 'Syrische Arabische Republiek',
      taiwan: 'Taiwan',
      tajikistan: 'Tadzjikistan',
      tanzania: 'Tanzania',
      thailand: 'Thailand',
      timorLeste: 'Oost Timor',
      togo: 'Gaan',
      tokelau: 'Tokelau',
      tonga: 'Tonga',
      trinidad: 'Trinidad en Tobago',
      tunisia: 'Tunesië',
      turkey: 'kalkoen',
      turkmenistan: 'Turkmenistan',
      turksAndCaicosIslands: 'Turks- en Caicoseilanden',
      tuvalu: 'Tuvalu',
      uganda: 'Oeganda',
      ukraine: 'Oekraïne',
      uae: 'Verenigde Arabische Emiraten',
      uk: 'Verenigd Koninkrijk',
      usa: 'Verenigde Staten',
      usMinor: 'Kleine afgelegen eilanden van de Verenigde Staten',
      uruguay: 'Uruguay',
      uzbekistan: 'Oezbekistan',
      vanuatu: 'Vanuatu',
      venezuelaBolivarian: 'Venezuela',
      vietnam: 'Vietnam',
      virginIslandsBritish: 'maagden Eilanden',
      virginIslandsUS: 'maagden Eilanden',
      wallisAndFutuna: 'Wallis en Futuna',
      westernSahara: 'Westelijke Sahara',
      yemen: 'Jemen',
      zambia: 'Zambia',
      zimbabwe: 'Zimbabwe',
    },
    languages: {
      ab: 'Abchazisch',
      aa: 'ver weg',
      af: 'Afrikaans',
      ak: 'Zullen',
      sq: 'Albanees',
      am: 'Amhaars',
      ar: 'Arabisch',
      an: 'Aragonees',
      hy: 'Armeens',
      as: 'Assamees',
      av: 'hebzuchtig',
      ae: 'Avestan',
      ay: 'Aymara',
      az: 'Azerbeidzjaans',
      bm: 'Bambara',
      ba: 'Bashkir',
      eu: 'baskisch',
      be: 'Wit-Russisch',
      bn: 'Bengaals',
      bh: 'Bihari-talen',
      bi: 'Bislama',
      bs: 'Bosnisch',
      br: 'Breton',
      bg: 'Bulgaars',
      my: 'Birmees',
      ca: 'Catalaans; Valenciaans',
      km: 'Centraal Khmer',
      ch: 'Chamorro',
      ce: 'Tsjetsjeens',
      ny: 'Chichewa; kauw; De zee',
      zhCn: 'Versimpeld Chinees)',
      zhTw: 'Chinese traditionele)',
      cu: 'Kerkslavisch',
      cv: 'Tsjoevasj',
      kw: 'Cornish',
      co: 'Corsicaans',
      cr: 'van mening zijn',
      hr: 'Kroatisch',
      cs: 'Tsjechisch',
      da: 'Deens',
      dv: 'duikhi',
      nl: 'Nederlands; Vlaams',
      dz: 'Dzongkha',
      en: 'Engels',
      eo: 'Esperanto',
      et: 'Ests',
      ee: 'Ooi',
      fo: 'Faeröers',
      fj: 'Fiji',
      fi: 'Fins',
      fr: 'Frans',
      ff: 'Fulah',
      gd: 'Gaelic',
      gl: 'Galicisch',
      lg: 'Dubbele',
      ka: 'Georgisch',
      de: 'Duits',
      el: 'Grieks',
      gn: 'Guarani',
      gu: 'Gujarati',
      ht: 'Haïtiaans',
      ha: 'Hausa',
      he: 'Hebreeuws',
      hz: 'Herero',
      hi: 'Nee.',
      ho: 'Hiri Motu',
      hu: 'Hongaars',
      is: 'IJslands',
      io: 'Weg',
      ig: 'Igbo',
      id: 'Indonesisch',
      iu: 'Inuktitut',
      ik: 'Inupiaq',
      ga: 'Iers',
      it: 'Italiaans',
      ja: 'Japans',
      jv: 'Javaans',
      kl: 'Groenlands',
      kn: 'Kannada',
      kr: 'Kanuri',
      ks: 'Kasjmir',
      kk: 'Kazachs',
      ki: 'Kikuyu; Gikuyu',
      rw: 'Kinyarwanda',
      ky: 'Kirgizisch; Kirgizië',
      kv: 'Kom op',
      kg: 'Congo',
      ko: 'Koreaans',
      kj: 'Dieren; In het vlees',
      ku: 'Koerdisch',
      lo: 'Werk',
      la: 'Latijns',
      lv: 'Lets',
      li: 'Limburger',
      ln: 'Lingala',
      lt: 'Litouws',
      lu: 'Luba-Katanga',
      lb: 'Luxemburgs',
      mk: 'Macedonisch',
      mg: 'Madagaskar-',
      ms: 'Maleis-',
      ml: 'Malayalam',
      mt: 'Maltees',
      gv: 'Manx',
      mi: 'Maori-',
      mr: 'Marathi',
      mh: 'Marshallees',
      mn: 'Mongools',
      na: 'Nauru',
      nv: 'Navajo; Navaho',
      ng: 'Ndonga',
      ne: 'Nepalees',
      se: 'Noord-Samic',
      no: 'Noors',
      oj: 'Ojibwa',
      or: 'Oriya',
      om: 'Oromo',
      os: 'Ossetische; Ossetisch',
      pi: 'Pali',
      pa: 'Punjabi; Punjabi',
      fa: 'Perzisch',
      pl: 'Pools',
      ptBr: 'Portugees',
      ps: 'Duwen naar; Pasjtoe',
      qu: 'Quechua',
      ro: 'Roemeense',
      rm: 'Reto-Romaans',
      rn: 'rondes',
      ru: 'Russisch',
      sm: 'Samoaans',
      sg: 'Sango',
      sa: 'Sanskriet-',
      sc: 'Sardinisch',
      sr: 'Servisch',
      sn: 'Shona',
      ii: 'Sichuan Yi; Nuosu',
      sd: 'Sindhi',
      si: 'Singalees; Singalees',
      sk: 'Slowaaks',
      sl: 'Sloveens',
      so: 'Somalisch',
      st: 'Sotho, Zuidelijk',
      es: 'Spaans',
      su: 'Soendanees',
      sw: 'Swahili',
      ss: 'Swati',
      sv: 'Zweeds',
      tl: 'Tagalog',
      ty: 'Tahitiaans',
      tg: 'Tadzjiekse',
      ta: 'Tamil',
      tt: 'Tataars',
      te: 'Telugu',
      th: 'Thais',
      bo: 'Tibetaans',
      ti: 'Tigrinya',
      to: 'Tonga',
      ts: 'Tsonga',
      tn: 'Tswana',
      tr: 'Turks',
      tk: 'Turkmeens',
      tw: 'Twee',
      ug: 'Oeigoerse; Oeigoerse',
      uk: 'Oekraïens',
      ur: 'Urdu',
      uz: 'Oezbeeks',
      ve: 'Uitverkoop',
      vi: 'Vietnamees',
      vo: 'Volapuk',
      wa: 'Waals',
      cy: 'Welsh',
      fy: 'West-Fries',
      wo: 'Wolof',
      xh: 'Xhosa',
      yi: 'Jiddisch',
      yo: 'Yoruba',
      za: 'Zhuang; Chuang',
      zu: 'Zulu',
    },
  },
  itemSummary: {
    title: 'Artikelcode-informatie:',
    batchId: 'Batch-ID',
    product: 'Product',
    sequence: 'Reeks',
    checksum: 'controlesom',
    warning:
      'Authenticiteitscontrole is geactiveerd voor dit item. Toegang via de portal kan authenticiteitswaarschuwingsprotocollen activeren, zoals statusupdates en waarschuwingen bij volgende scans.',
    hideUrl: 'URL verbergen',
    showUrl: 'toon URL',
    copy: 'Kopiëren',
    itemUrlCopied: 'Artikel-URL gekopieerd.',
    productName: 'Productnaam',
    itemId: 'Item Id',
    itemStatus: 'Artikelstatus',
    itemName: 'Itemnaam',
    batchName: 'Batchnaam:',
  },
  updateReportedData: {
    locationTitle: 'Plaats',
    dateTimeTitle: 'Datum en Tijd',
    dateTimeInteraction: 'Interactiedatum',
    dateTimeReported: 'Gerapporteerde datum',
    reset: 'Resetten',
    locationInteraction: 'Interactie Locatie',
    locationReported: 'Gerapporteerde locatie',
    reportedData: 'Gerapporteerde gegevens',
  },
};
export default nl;
