import Vue from 'vue';
const EventBus = new Vue();

const Events = {
  authenticationLogin: 'authentication:login',
  authenticationLogout: 'authentication:logout',
  routerChangePage: 'router:changePage',
  taskStart: 'task:start',
  taskExecute: 'task:execute',
  reloadOrganization: 'realtime::organization',
  identificationCodeValidate: 'identificationCode::validate',
  saveJob: 'job:save',
};

export { Events, EventBus };
