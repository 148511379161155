import { organizationInfoFragment } from './fragments/organizationInfoFragment';

export const query = `query organizations($page: Int $perPage: Int $sort: String $search: String){
    organizations(page:$page perPage:$perPage sort:$sort search:$search){
        totalItems
        items{
            ${organizationInfoFragment}
        }
    }
}`;
