<template>
  <div>Logged out</div>
</template>

<script>
export default {
  mounted() {
    this.$router.replace({ name: 'Home' });
  },
};
</script>
