import Vue from 'vue';
import store from '@/store';
import { GraphqlFastQuery } from '@/api/graphql';
import { query as accountQuery } from '@/graphql/myAccount';
import { query as organizationSlugQuery } from '@/graphql/organizationBySlug';
import { IDtoAccount, IDtoOrganization } from '@/store/dto';
import { authApi, organizationApi } from '@/api/http/api';
import { query as organizationAccountsQuery } from '@/graphql/organizationAccounts';
import i18n from '@/i18n';

interface IState {
  account: IDtoAccount | null;
  currentOrganization: IDtoOrganization | null;
  currentOrganizationError: boolean;
  permissions: string[];
  failedRoutepermissions: string[];
  currentLanguage: string;
  currentClipboardContent: string | null;
}

const state: IState = {
  account: null,
  currentOrganization: null,
  currentOrganizationError: false,
  permissions: [],
  failedRoutepermissions: [],
  currentLanguage: 'en',
  currentClipboardContent: null,
};

const mutations = {
  SET_ACCOUNT: function (state: IState, payload: IDtoAccount) {
    Vue.set(state, 'account', payload);
  },
  SET_CURRENT_ORGANIZATION: function (state: IState, payload: IDtoOrganization) {
    Vue.set(state, 'currentOrganization', payload);
  },
  SET_CURRENT_ORGANIZATION_ERROR: function (state: IState, payload: boolean) {
    Vue.set(state, 'currentOrganizationError', payload);
  },
  SET_PERMISSIONS: function (state: IState, payload: any) {
    Vue.set(state, 'permissions', payload);
  },
  SET_FAILED_ROUTE_PERMISSIONS: function (state: IState, payload: string[]) {
    Vue.set(state, 'failedRoutepermissions', payload);
  },
  SET_CURRENT_LANGUAGE: function (state: IState, payload: string[]) {
    Vue.set(state, 'currentLanguage', payload);
  },
};

const actions = {
  setAccount: async function (context: any, payload: any) {
    const account = (context.state as IState).account;
    const force = payload && payload.force === true;
    if (account && !force) return;
    const variables = {
      page: 1,
      perPage: 5,
    };
    try {
      const response = await GraphqlFastQuery(accountQuery, {
        fetchPolicy: 'no-cache',
        variables: variables,
      });
      context.commit('SET_ACCOUNT', response.data.myaccount);

      try {
        // we need to set initial organization list with what is coming from account response.
        context.commit('organization/SET_ORGANIZATIONS', response.data.myaccount.organizations, { root: true });
      } catch (err) {
        console.error(err);
      }
    } catch (e) {
      console.log(e);
    }
  },

  setCurrentOrganization: async function (context: any, payload: any) {
    const slug = payload ? payload.slug : null;
    let org: IDtoOrganization | null = null;

    context.commit('SET_CURRENT_ORGANIZATION_ERROR', false);

    if (slug === false) {
      context.commit('SET_CURRENT_ORGANIZATION_ERROR', true);
    }

    if (slug) {
      context.commit('SET_CURRENT_ORGANIZATION', null);

      const response = await GraphqlFastQuery(organizationSlugQuery, {
        fetchPolicy: 'no-cache', // this response returns "itemStatuses" property"
        variables: {
          slug: String(slug),
        },
      });

      org = response.data.organizationBySlug;

      if (!org) {
        context.commit('SET_CURRENT_ORGANIZATION_ERROR', true);
      }

      if (org && org.itemStatuses && org.itemStatuses.items) {
        org.itemStatuses.items.push({
          value: -1,
          name: 'Unknown ID',
        });
      }
    }

    await store.dispatch('auth/setOrgJWT', org ? { orgId: org.id } : null);

    context.commit('SET_CURRENT_ORGANIZATION', org);

    //if (org && typeof org.id !== 'undefined') {
    if (org || !slug) {
      return org;
    } else {
      throw new Error();
    }
  },

  setPermissions: async function (context: any, payload: any) {
    let permissions = payload;
    if (!payload) {
      const response = await authApi.getPermissions();
      permissions = response.data.permissions;
    }
    context.commit('SET_PERMISSIONS', permissions);
    return permissions;
  },

  setFailedRoutePermissions: async function (context: any, payload: string[]) {
    context.commit('SET_FAILED_ROUTE_PERMISSIONS', payload);
  },

  setLanguage: async function (context: any, payload: any) {
    let language = payload ? payload.lang : null;
    const currentAccountInfo: any = (context.state as IState).account;
    const currentOrgInfo: any = (context.state as IState).currentOrganization;
    let users = [];
    const variables = {
      page: 1,
      perPage: 10,
      search: `${currentAccountInfo.firstName || ''}`,
      sort: 'name_0',
      organizationId: String(currentOrgInfo.id),
    };
    let currentAccountOrganization = null;

    try {
      const firstPage = 1;
      const firstResponse = await GraphqlFastQuery(organizationAccountsQuery, {
        variables: variables,
      });
      users = firstResponse.data.organizationAccounts.items;

      if (Array.isArray(users)) {
        currentAccountOrganization = users.find(user => user.account && user.account.id === currentAccountInfo.id);
      }

      if (!currentAccountOrganization) {
        for (let i = firstPage + 1; i <= firstResponse.data.organizationAccounts.totalPages; i++) {
          const response = await GraphqlFastQuery(organizationAccountsQuery, {
            variables: {
              ...variables,
              page: i,
            },
          });
          users = users.concat(response.data.organizationAccounts.items);

          if (Array.isArray(users)) {
            currentAccountOrganization = users.find(user => user.account && user.account.id === currentAccountInfo.id);
          }

          if (currentAccountOrganization) break;
        }
      }
    } catch (err) {
      console.error(err);
    }

    if (language) {
      await organizationApi.updateAccount(String(currentOrgInfo.id), String(currentAccountOrganization.id), {
        portalLanguage: language,
      });
    } else {
      language = currentAccountOrganization.portalLanguage || 'en';
    }
    context.commit('SET_CURRENT_LANGUAGE', language);
    i18n.locale = language;
  },
};

const getters = {
  accountInfo: function (state: IState, getters: any) {
    return state.account;
  },
  currentOrganizationInfo: function (state: IState, getters: any) {
    return state.currentOrganization;
  },
  currentOrganizationError: function (state: IState, getters: any) {
    return state.currentOrganizationError;
  },
  permissions: function (state: IState, getters: any) {
    return state.permissions;
  },
  failedRoutePermissions: function (state: IState, getters: any) {
    return state.failedRoutepermissions;
  },
  currentLanguage: function (state: IState, getters: any) {
    return state.currentLanguage;
  },
};

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
