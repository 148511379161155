import Vue from 'vue';
import store from '@/store';
import i18n from '@/i18n';
import momentTimezone from 'moment-timezone';
import { MomentInput } from 'moment-timezone';
import { GetFilePath } from './helpers';

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CONTENT_BLOCK_TYPES = {
  generalContent: {
    title: 'General Content',
    icon: '/img/icons/content-block-type-general-content.png',
  },
  codeInjection: {
    title: 'Code Injection',
    icon: '/img/icons/content-block-type-code-injection.png',
  },
  embeddedVideo: {
    title: 'Embedded Video',
    icon: '/img/icons/content-block-type-video.png',
  },
  collection: {
    title: 'Collection',
    icon: '/img/icons/content-block-type-collection.png',
  },
  kml: {
    title: 'KML',
    icon: '/img/icons/content-block-type-kml.png',
  },
};

const getItemStatus = (val: string) => {
  const currentOrganization = (store.state as any).core.currentOrganization;
  const itemStatuses = currentOrganization && currentOrganization.itemStatuses && currentOrganization.itemStatuses.items ? currentOrganization.itemStatuses.items : [];

  const words = val.split('_');
  const value = words[words.length - 1];
  const itemStatus = itemStatuses.find((i: any) => i.value == value);

  return itemStatus ? itemStatus.name : '';
};

(Vue as any).mixin({
  methods: {
    capitalize: (s: string) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    uppercase: (s: string) => {
      if (typeof s !== 'string') return '';
      return s.toUpperCase();
    },
    displayTimeZone: (data: MomentInput, dateFormat: string) => {
      return momentTimezone(data).tz(browserTimezone).format(dateFormat);
    },
    shortText(str: string, length: number = 0) {
      let ret = str.trim();
      if (length && ret.length > length) {
        ret = str.slice(0, length) + '...';
      }
      return ret;
    },
    mixinGetFilePath: GetFilePath,
    getUserInfo(item: any, key: string = 'account') {
      if (!item || !item[key]) return '';
      const name = [item[key].firstName, item[key].lastName].filter(v => !!v).join(' ');
      return name || (item[key].email ? item[key].email : '');
    },
    getStatus(val: string) {
      if (val.includes('task_steps.status_check')) {
        return i18n.t('task_steps.status_check', {
          status: getItemStatus(val),
        });
      } else if (val.includes('item_status')) {
        return i18n.t('item_status', {
          status: getItemStatus(val),
        });
      }

      return false;
    },
    getTranslatedText(val: string) {
      const key = val.includes('|+|') ? val.split('|+|').shift() || '' : val;
      const value = val.includes('|+|') ? val.split('|+|').pop() : null;
      return i18n.t(key.trim(), value ? { text: value.trim() } : {});
    },
    getItemStatusTranslated(filter: string) {
      const filterVariable = filter.toLowerCase().replace(/\s+(.)/g, function (match, group) {
        return group.toUpperCase();
      });

      return i18n.te(`item.filters.${filterVariable}`) ? i18n.t(`item.filters.${filterVariable}`) : filter;
    },
    extractLocationData(details: any) {
      let locationData = {};
      const components = details.components || null;
      const locationDetails = !components
        ? []
        : components
            .filter((element: any) => {
              return element.types.includes('political');
            })
            .slice(-2);

      if (locationDetails.length > 0) {
        const city = locationDetails[0] ? locationDetails[0].long_name : null;
        const country = locationDetails[1] ? locationDetails[1].long_name : null;
        if (city) {
          locationData = city;
          if (country) {
            locationData = `${city}, ${country}`;
          }
        }
      } else {
        locationData = 'Unknown Location';
      }

      return locationData;
    },
    strippedHtml(str: string) {
      return str.replace(/<\/?[^>]+>/gi, '');
    },
    getComponentType(item: any) {
      if (!item) return { title: null, icon: null };
      if (item.collectionsInfo && item.collectionsInfo.length) {
        return CONTENT_BLOCK_TYPES.collection;
      } else if (item.component.embeddedLink) {
        return CONTENT_BLOCK_TYPES.embeddedVideo;
      } else if (item.component.codeInjection) {
        return CONTENT_BLOCK_TYPES.codeInjection;
      } else if (item.component.kmlLink) {
        return CONTENT_BLOCK_TYPES.kml;
      } else {
        return CONTENT_BLOCK_TYPES.generalContent;
      }
    },
    getLayoutStyles() {
      return {
        breakpointPhone: 480,
        breakpointTablet: 768,
        breakpointDesktop: 992,
        breakpointLgDesktop: 1200,
        breakpointBigDesktop: 1600,
      };
    },
  },
});

Vue.mixin({
  computed: {
    hasPermissions() {
      const permissions = store.getters['core/permissions'] as string[];

      return (requiredPermissions: string[]) => {
        const failedPermissions: string[] = [];

        requiredPermissions.forEach(perm => {
          if (permissions.indexOf(perm) === -1) {
            failedPermissions.push(perm);
          }
        });

        return failedPermissions.length === 0;
      };
    },
  },
});
