const en = {
  common: {
    labels: {
      selected: 'SELECTED: ',
      searchByName: 'Search by name',
      itemStatusMatches: 'Only show when item status matches',
      showTask: 'Leave empty to always show this task',
      emailAddress: 'Email address',
      name: 'Name',
      emailAddressWithComma: 'Email Addresses (comma separated)',
      emailSubject: 'Email Subject (EX:. {task} - {name} for {thing} with status:{status})',
      endpointURL: 'Endpoint URL',
      securityHmacKey: 'Security (HMAC key)',
      apiKey: 'ApiKey',
      organizationId: 'Organization ID',
      xmlMessage: 'XML Message',
      createYourFirst: 'Create your first ',
      valueChain: ' value chain.',
      category: ' category.',
      supportProfile: ' Support Profile.',
      task: ' task.',
      template: 'template.',
      description: 'Description',
      learnMoreLink: 'Learn more link',
      image: 'Image ',
      displayImage: 'Display Image ',
      chainElementIcon: 'Chain Element Icon ',
      transportationMethod: 'Transportation Method',
      gln: 'GLN (Global Location Number)',
      status: 'Status',
      thing: 'Thing',
      selectDateRange: 'Select date range',
      searchBy: 'Search By',
      itemImage: 'Item Image ',
      uploadAttachment: 'Upload Attachment',
      addInteractionTo: 'Add Interaction to ',
      interactionName: 'Interaction Name',
      steps: 'Steps',
      displayName: 'Display Name',
      title: 'Title',
      attachments: 'Attachments',
      currentStatus: 'Current Status',
      country: 'Country',
      language: 'Language',
      items: 'Items',
      interactions: 'Interactions',
      tasks: 'Tasks',
      product: 'product',
      item: 'item',
      selectLanguage: 'Select Language',
      addBatch: 'Add Batch',
      editBatch: 'Edit Batch',
      filters: 'Filters',
      unknown: 'Unknown',
      from: 'from',
      learnMore: 'Learn more',
      search: 'Search',
      url: 'URL',
      addTask: 'Add Task',
      at: 'at',
      publicOnly: 'Public Only',
      fromOrganizationLibrary: 'From Organization Library',
      jobs: 'Jobs',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
      finish: 'Finish',
      submit: 'Submit',
      delete: 'Delete',
      remove: 'Remove',
      task: 'Task',
      ok: 'OK',
      logout: 'Logout',
      decline: 'Decline',
      accept: 'Accept',
      inviteUsers: 'Invite Users',
      send: 'Send',
      addMore: 'Add more',
      createAnAction: 'Create an Action',
      removeIntegration: 'Remove Integration',
      continue: 'Continue',
      update: 'Update',
      back: 'Back',
      next: 'Next',
      close: 'Close',
      upload: 'Upload',
      create: 'Create',
      clear: 'Clear',
      apply: 'Apply',
      viewInDashboard: 'View in dashboard',
      confirm: 'Confirm',
      yes: 'Yes',
      no: 'No',
      configure: 'Configure',
    },
    menus: {
      things: 'Things',
      items: 'Items',
      interactions: 'Interactions',
      reportingAndAnalytics: 'Reporting & Analytics',
      templates: 'Templates',
      settings: 'Settings',
      tasks: 'Tasks',
      users: 'Users',
      advancedSettings: 'Advanced Settings',
      valueChains: 'Value Chains',
      organisationalSettings: 'Organizational Settings',
      language: 'Language',
      catalogue: 'Catalogue',
      templatesAndContent: 'Templates & Content',
      jobs: 'Jobs',
    },
    menuItems: {
      edit: 'Edit',
      delete: 'Delete',
      remove: 'Remove',
      manageConfiguration: 'Manage Configuration',
      setAsDefault: 'Set as Default',
      setAsPublic: 'Set as Public',
      setAsPrivate: 'Set as Private',
      manage: 'Manage',
      duplicate: 'Duplicate',
      download: 'Download',
      viewContent: 'View Content',
      viewSettings: 'View Settings',
      displayToPublic: 'Display to Public',
      hideFromPublic: 'Hide From Public',
      resetSecretKey: 'Reset Secret Key',
      disable: 'Disable',
      enable: 'Enable',
      viewConditions: 'View Conditions',
      manageConditions: 'Manage Conditions',
      viewDisplayConditions: 'View Display Conditions',
      viewPagePosition: 'View Page Position',
      manageDisplayConditions: 'Manage Display Conditions',
      change: 'Change',
      displayForBatch: 'Display for batch',
      viewOrUpdateClassification: 'View/Update Classification',
      displayInPublicPage: 'Display in public page',
      suspended: 'Suspended',
      unsuspend: 'Unsuspend',
      suspend: 'Suspend',
      invitationDeclined: 'Invitation declined',
      invitationPending: 'Invitation Pending',
      invitationExpired: 'Invitation Expired',
      invitationAccepted: 'Invitation Accepted',
      setAsMember: 'Set as Member',
      setAsAdmin: 'Set as Admin',
      disablePortalAccess: 'Disable portal access',
      enablePortalAccess: 'Enable portal access',
      resendInvitation: 'Resend invitation',
      deleteUser: 'Delete user',
      deactivate: 'Deactivate',
      activate: 'Activate',
      preview: 'Preview',
      customiseName: 'Customize Name',
      customiseImage: 'Customize Image',
      addAttachment: 'Add Attachment',
      changeStatus: 'Change Status',
      manageStatus: 'Manage Status',
      qrCode: 'QR Code',
      digitalLabel: 'Digital Label',
      copyUrl: 'copy URL',
      editName: 'Edit Name',
      editImage: 'Edit Image',
      removePersonalSupportProfile: 'Remove Personal Support Profile',
      performTask: 'Perform Task',
      createBatch: 'Create Batch',
      editBatch: 'Edit Batch',
      deleteBatch: 'Delete Batch',
      copyToClipboard: 'Copy to Clipboard',
      openPublicPage: 'Open Public Page',
      createProduct: 'Create Product',
      pagePosition: 'Page Position',
      uploadImage: 'Upload Image',
      deleteImage: 'Delete Image',
      downloadImage: 'Download Image',
      updateFromClipboard: 'Update from Clipboard',
      viewCodeDetails: 'View Code Details',
      updateDisplayData: 'Update Reported Data',
      downloadAsPdf: 'Download as PDF',
      hide: 'Hide',
      unhide: 'Unhide',
      createNew: 'Create New',
      selectFromLibrary: 'Select From Library',
      setDefaultPagePosition: 'Set Default Page Position',
      createAJob: 'Create A Job',
      createJob: 'Create Job',
      openJob: 'Open Job',
      createItems: 'Create Items',
      viewInteractions: 'View interactions',
    },
    pagination: {
      text: 'Showing {from} to {to} of {total} entries',
    },
    tabs: {
      general: 'General',
      categories: 'Categories',
      support: 'Support',
      tasks: 'Tasks',
      users: 'Users',
      integrationActions: 'Integration Actions',
      valueChain: 'Value Chains',
      chart: 'Chart',
      map: 'Map',
      documents: 'Documents',
      templates: 'Templates',
      contentBlocks: 'Content Blocks',
      accessTokens: 'Access Tokens',
      jobs: 'Jobs',
    },
    messages: {
      invitationExpired: 'Invitation expired',
      notFoundInvitation: 'Invitation not found',
      userAlreadyExists: 'User(s) already exist',
      nameAlreadyTaken: 'Name already taken',
      InvalidContent: 'Invalid content',
      PayloadTooLarge: 'Request entity too large',
      noSearchResult: "Sorry, we couldn't find any results matching",
      noneSelected: 'Nothing selected.',
      noneAvailable: 'There are no Tasks created for this organization yet.',
      noConfigurableAttributes: 'No configurable attributes available for this task.',
      noInvitation: 'There are no pending invitations',
      defaultValueChain: 'The existing default element can only be changed by selecting a new element as the default from its edit screen.',
      fileSizeError: 'The maximum file size for uploading file to Assure+ Portal is 10MB. Please upload file up to 10MB.',
      submitError: 'Please fill all the required fields',
      inviteSent: 'Invites sent',
      inviteError: 'Could not send invites',
      saved: 'saved',
      deleted: 'deleted',
      duplicated: 'duplicated',
      createThing: 'Thing created',
      createThingError: 'Could not create Thing',
      duplicateThingError: 'Thing cannot be duplicated',
      updateThing: 'Thing has been duplicated',
      updateThingError: 'Could not update the Thing',
      loadTypeError: 'Could not load {type}',
      deleteTypeError: 'Could not delete {type}',
      duplicateTypeError: 'Could not duplicate',
      createTask: 'Task has been added',
      createTaskError: 'Could not add task',
      updateTask: 'Task has been Updated',
      updateTaskError: 'Could not update the task',
      taskError: 'Could not create a Task',
      createSupportProfile: 'Support Profile has been added',
      createSupportProfileError: 'Could not add Support Profile',
      updateSupportProfile: 'Support Profile has been updated',
      updateSupportProfileError: 'Could not update the Support Profile',

      updateContentBlock: 'Content Block has been updated',
      updateContentBlockError: 'Could not update the Content Block',
      deleteSupportProfile: 'Support Profile has been deleted',
      deleteSupportProfileError: 'Could not delete Support Profile',
      deleteContentBlock: 'Content Block(s) has been deleted',
      deleteContentBlockError: 'Could not delete Content Block(s)',

      supportProfile: 'Support Profile has been created',
      contentBlock: 'Content Block has been created',
      contentBlockError: 'Could not create a Content Block',
      supportProfileError: 'Could not create a Support Profile',
      createTeam: 'Team created',
      createTeamError: 'Could not create Team',
      saveTeam: 'Team saved',
      saveTeamError: 'Could not save Team',
      renameTeam: 'Team renamed',
      renameTeamError: 'Could not rename Team',
      teamStatus: 'Team status changed',
      teamStatusError: 'Could not change Team Status',
      resendInvite: 'Invite resend',
      resendInviteError: 'Could not resend invite',
      revokeInvite: 'Invite revoke',
      revokeInviteError: 'Could not revoke invite',
      revokeAccess: 'Access revoked',
      revokeAccessError: 'Could not revoke access',
      suspendAccess: 'Suspended',
      suspendAccessError: 'Could not change to suspend',
      accountChange: 'Account changed',
      accountChangeAdminError: 'Could not change to admin',
      accountChangeOwnerError: 'Could not change to owner',
      accountChangeUserError: 'Could not change to user',
      inviteUser: 'User(s) invited',
      inviteUserError: 'Could not invite user(s)',
      declineInvitation: 'Declined the invitation',
      declineInvitationError: 'Could not decline the invitation ({message})',
      acceptInvitation: 'Accepted the invitation',
      acceptInvitationError: 'Could not accept the invitation ({message})',
      deleteUser: 'User deleted',
      deleteUserError: 'Could not delete user',
      createProduct: 'Product has been created',
      createProductError: 'Could not create a Product',
      updateProduct: 'Product has been updated',
      updateProductError: 'Could not update the Product',
      createAccessToken: 'Access Token has been created',
      createAccessTokenError: 'Could not create a AccessToken',
      updateAccessToken: 'Access Token has been updated',
      updateAccessTokenError: 'Could not update the Access Token',
      deleteAccessToken: 'Access Token has been deleted',
      deleteAccessTokenError: 'Could not delete the Access Token',
      resetSecretKey: 'Access Key has been updated',
      resetSecretKeyError: 'Could not update the Access Key',
      createJob: 'Job has been created',
      createJobError: 'Could not create a Job',
      updateJob: 'Job has been updated',
      updateJobError: 'Could not update the Job',
      updateJobStatus: 'Job Status has been updated',
      updateJobStatusError: 'Could not update the Job Status',
      jobExistsError: 'Job already exists for this Item',
      deleteJob: 'Job has been deleted',
      deleteJobError: 'Could not delete the Job',
      updateTemplate: 'Template has been updated',
      updateTemplateError: 'Could not update the Template',
      deleteTemplate: 'Template has been deleted',
      deleteTemplateError: 'Could not delete the Template',
      createTemplate: 'Template has been created',
      createTemplateError: 'Could not create a Template',
      deleteBatch: 'Batch deleted',
      deleteBatchError: 'Batch has been used. Could not delete the Batch.',
      updateBatch: 'Batch has been Updated',
      createBatch: 'Batch created',
      createBatchError: 'Batch ID already exists. Could not create a Batch.',
      identificationCode: 'Identification Code has been created',
      validateIdentifierError: 'Could not validate a Identifier',
      addFileError: 'Please add a file',
      genericError: 'Generic error',
      createValueChain: 'Value chain has been created',
      createValueChainError: 'Could not create a Value chain',
      updateValueChain: 'Value chain has been updated',
      updateValueChainError: 'Could not update the Value chain',
      deleteValueChain: 'Value chain has been deleted',
      deleteValueChainError: 'Could not delete the Value chain',
      updateCategory: 'Category has been updated',
      updateCategoryError: 'Could not update the Category',
      deleteCategory: 'Category has been deleted',
      deleteCategoryError: 'Could not delete the Category',
      CreateCategory: 'Category has been created',
      createCategoryError: 'Could not create the Category',
      updateItemName: 'Item name updated',
      updateItemNameError: 'Could not update item name',
      updateItemStatus: 'Item status updated',
      updateItemStatusError: 'Could not update item status',
    },
    validation: {
      minNumMaxNum: 'Min {0} Max {1} characters',
      maxNum: 'Max {0} characters',
    },
    uploadFile: {
      maxSize: '(Max size 10MB)',
    },
  },
  organization: {
    myOrganizations: 'My Organizations',
    viewAllOrganizations: 'View all organizations',
  },
  interaction: {
    batchRecord: 'Batch record',
    unknownId: 'UNKNOWN ID',
    externalId: 'EXTERNAL ID',
    modal: {
      eventClassification: 'Event Classification',
      unclassified: 'Unclassified',
      default: 'Default',
    },
    filters: {
      notice: 'Notice',
      alert: 'Alert',
      warning: 'Warning',
    },
    hidden: 'Hidden',
  },

  interaction_action_0: 'User Notified: ',
  interaction_action_1: 'Item status changed to: ',
  interaction_action_2: 'Item support profile has been assigned.',
  interaction_action_3: 'Item support profile has been removed.',
  interaction_action_4: 'Notification: ',
  interaction_action_5: 'Warning: ',
  interaction_action_6: 'Alert: ',
  interaction_administrative_title: 'Administrative Change',
  task_steps: {
    status_check: `Current item status is: {status}`,
  },
  item_status: `{status}`,
  interaction_collections_title: 'Collection - {text}',
  interaction_collection_added_title: 'Added to a Collection - {text}',
  step_collection_fields: 'Fields:',
  publicPage: {
    subHeading: {
      publicPage: 'Public Page',
      FS1App: 'FS1 App',
    },
    positioning: {
      before: 'Before',
      after: 'After',
      top: 'Top',
      bottom: 'Bottom',
    },
    components: {
      longDescription: 'Long Description',
      attachments: 'Attachments',
      documents: 'Documents',
      attributes: 'Attributes',
      services: 'Services',
      gallery: 'Gallery',
      certifications: 'Certifications',
      video: 'Video',
      history: 'History',
      supportProfile: 'Support Profile',
      fs1TaskPanel: 'FS1 Task Panel',
      fs1About: 'FS1 About',
      fs1FactsPanel: 'FS1 Facts Panel',
      fs1ResourcesPanel: 'FS1 Resources Panel',
      events: 'Events',
    },
  },
  OrganisationalSettings: {
    header: 'Organization Settings',
    components: {
      general: 'General',
      branding: 'Branding',
    },
    general: {
      name: 'Name',
      businessType: 'Business type',
      OrganizationLogo: 'Organization Logo ',
      PublicPageLoadingImage: 'Public Page Loading Image ',
    },
  },
  tasks: {
    tableHeader: {
      name: 'Task Name',
      usedAsDiscovery: 'Discovery Task',
      interactions: 'Usage Count',
      things: 'Allocations',
      createdAt: 'Created At',
      createdBy: 'Created By',
      displayConditions: 'Display Conditions',
    },
    taskModal: {
      headerCreate: 'Create a Task',
      headerEdit: 'Edit Task',
      components: {
        selectTask: 'Select Task',
      },
    },
  },
  jobs: {
    title: 'Jobs',
    job: 'Job',
    createJob: 'Create a job',
    editJob: 'Edit a Job',
    tableHeaders: {
      jobCode: 'Job Code',
      jobName: 'Job Name',
      thingItem: 'Thing Item',
      status: 'Status',
      dateDue: 'Date Due',
      dateAssigned: 'Date Assigned',
      assignedTo: 'Assigned To',
    },
    editJobModal: {
      labels: {
        name: 'Job Name',
        dueDate: 'Due Date',
        expiryDate: 'Expiry Date',
        highPriority: 'High Priority',
        sharableLink: 'Sharable Link',
      },
    },
  },
  categories: {
    title: 'Category',
    noDataMessage1: 'Create a Category',
    tableHeaders: {
      name: 'Name',
      defaultTemplate: 'Default template',
      createdAt: 'Created At',
      createdBy: 'Created By',
    },
    editCategoryModal: {
      createCategory: 'Create a category',
      editCategory: 'Edit a category',

      labels: {
        name: 'Category Name',
        standard: 'Identification Standard',
        identifier: 'Category Identifier',
        description: 'Description',
        image: 'Category Image',
        defaultTemplate: 'Each product created for this category will be set to this template by default.',
      },
    },
  },
  supportProfile: {
    title: 'Support Profile',
    addSupportProfile: 'Add Support Profile',
    createMessage1: 'Create a Support Profile',
    tableHeaders: {
      name: 'Name',
      content: 'Content',
      displayConditions: 'Display Conditions',
      contactMethods: 'Contact Methods',
    },
    editSupportProfileModal: {
      createSupportProfile: 'Create Support Profile',
      editSupportProfile: 'Edit Support Profile',
      labels: {
        name: 'NAME',
        headerText: 'HEADER TEXT',
        mainText: 'MAIN TEXT',
        mainContent: 'MAIN CONTENT',
        showExpanded: 'SHOW EXPANDED',
        phone: 'PHONE',
        email: 'EMAIL',
        imagery: 'IMAGERY',
        primary: 'PRIMARY',
        secondary: 'SECONDARY',
        collection: 'Collection',
        kmlMapType: 'KML Map Type',
        kmlLink: 'KML Link',
        kmlFile: 'KML File',
      },
    },
  },
  templates: {
    title: 'Template',
    tableHeaders: {
      name: 'Name',
      createdAt: 'Created At',
      createdBy: 'Created By',
      assignedCategories: 'Assigned Categories',
    },
    editTemplateModal: {
      createTemplate: 'Create a template',
      editTemplate: 'Edit a template',
      labels: {
        templateName: 'Template Name',
        templateDescription: 'Template Description',
        templateImage: 'Template Image',
      },
    },
    templateCreatedModal: {
      title: 'Template Created',
      message: 'Template Created Successfully, do you wish to manage now and add attributes, tasks, imagery and further content?',
      later: 'Do It Later',
      now: 'Manage Now',
    },
    templateCreatedProductModal: {
      title: 'Product Created',
      message: 'Product Created Successfully, do you wish to manage now and add attributes, tasks, imagery and further content?',
    },
  },
  content: {
    title: 'Content',
    addVideo: 'Add Video',
    contentBlock: 'Content Block',
    createContent: 'Create Content Block',
    contentBlocks: 'Content Blocks',
    addContentBlock: 'Add Content Block',
    editContentBlock: 'Edit Content Block',
    pagePosition: 'Page Position',
    addContentBlocksToProduct: 'Add Content Blocks To Product',
    allAvailableProducts: 'All {val} Available Products Selected from Library.',
    createContentBlock: 'Create a Content Block',
    editContentBlockModal: {
      displayTemplate: 'Default Display Template',
      extendedGallery: 'Extended Gallery',
      listGallery: 'List',
      compactedGallery: 'Compacted Gallery',
      transitionDuration: 'Transition Duration(seconds)',
      collectionElements: 'Collection Elements',
      productsSelected: 'Product(s) Selected',
      allowSwitchMode: 'Allow Switching of Template',
      displayOrgFilter: 'Display Organizational Filter',
      orgFilterBkg: 'Organizational Filter Background Hex Code',
      defaultSort: 'Default Sort',
      sortByOrgName: 'Organization Name',
      sortByProductName: 'Product Name',
    },
    bulkActions: {
      assignTo: 'Assign To',
      removeFrom: 'Remove From',
      selectedMessage: '{val} Content Block(s) Selected',
      selectAllMessage: 'Select all {val} Content Block(s) from library.',
      allSelectedMessage: 'All {val} Content Block(s) Selected from Library.',
      allSelectedFromThisPage: ' All {val} on this page selected.',
      clearSelection: ' Clear selection',
      selectedContent: 'Selected Content',
      displayType: 'Display Type',
      selectedContentBlocks: 'Selected Content Block(s)',
      targetProducts: 'Target Product(s)',
    },
    setPagePosition: 'Set Page Position',
    message: {
      notFound: 'There are no Content Blocks created on your organization.',
      notFoundProducts: 'There are no Products available.',
      removeFromProducts: ' Your selected content blocks will be removed from these products, press finish to proceed.',
      assignToProducts:
        ' Your selected content blocks will be added to these products, press finish to proceed. Organization content blocks will only be added to the public page of target products.',
      addContentSelectType: "Confirm whether you'd like to display the content to your team, the public or both?",
      removeContentSelectType: "Confirm whether you'd like to remove the content from your team, the public or both?",
      removedMessage: 'Content Block(s) Successfully Removed from {val} Product(s)',
      assignedMessage: 'Content Block(s) Successfully Added to {val} Product(s)',
      removingContents: 'Removing Content Block(s) from selected Products',
      addingContents: 'Adding Content Block(s) to selected Products',
      duplicated: 'Content duplicated',
    },
    tableHeaders: {
      name: 'Name',
      componentType: 'Component Type',
      displayConditions: 'Display Conditions',
    },
  },
  user: {
    viewInvitations: 'View Invitations',
    inviteUsers: 'Invite Users',
    to: 'to',
    userHeaders: {
      name: 'Name',
      email: 'Email',
      role: 'Role',
      access: 'Access',
      status: 'Status',
    },
    userType: {
      admin: 'Admin',
      member: 'Member',
    },
  },
  webhooks: {
    webhookHeaders: {
      name: 'Name',
      createdAt: 'Created At',
      createdBy: 'Created By',
    },
    webhookModal: {
      headerCreate: " action to start sharing your organization's interactions with team members, other systems and trusted third parties.",
      components: {
        emailSettings: 'Email Settings',
        webhookSettings: 'Webhook Settings',
        integrations: 'Integrations',
        thingsAndTasks: 'Things and Tasks',
      },
    },
    actions: 'Actions',
    editAction: 'Edit Action',
  },
  accessTokens: {
    title: 'Access Token',
    createAccessToken: 'Create a access token',
    create: 'Create Access Token',
    edit: 'Edit Access Token',
    editAccessTokenModal: {
      labels: {
        name: 'Name',
        clientId: 'Client Id',
        accessTokenType: 'Access Token Type',
      },
    },
    tableHeaders: {
      name: 'Name',
      dateModified: 'Date Modified',
      restrictions: 'Restrictions',
    },
  },
  valueChain: {
    headerCreate: 'Value Chain',
    headerEdit: 'Edit a Value Chain',
    editAChainElement: 'Edit a Chain Element',
    createAChainElement: 'Create a Chain Element',
    headerDescription: 'Select a template to help you get started.',
    organisationActivity: 'Organization Activity',
    components: {
      miscellaneousContent: 'Content Blocks',
      logisticsAndEnvironment: 'Logistics & Environment',
      generalInformation: 'General Information',
    },
    tableHeaders: {
      creationDate: 'Creation Date',
      createdBy: 'Created By',
    },
    templates: {
      justTheBasics: {
        name: 'Just the basics',
        description: 'The default value chain used in Assure+.',
      },
      beverage: {
        name: 'Beverage',
        description:
          'The ability to track and trace beverage products through the entire supply chain is now being driven by the consumer. They want to know the origin of all products back to the field where the ingredients are grown and the facilities that produce the supporting materials and packaging supplies.',
      },
      coffee: {
        name: 'Coffee',
        description: 'Before it gets to your morning cup, coffee beans travel through a complex global supply chain.',
      },
      honey: {
        name: 'Honey',
        description: 'Honey travels through a complex global supply chain from the beehive to the consumer.',
      },
      porterValueChain: {
        name: 'Porter Value Chain',
        description:
          "Rather than looking at departments or accounting cost types, Porter's Value Chain focuses on systems, and how inputs are changed into the outputs purchased by consumers.",
      },
      usaidCommodity: {
        name: 'USAid Commodity',
        description:
          'The process starts when food requests are made to USAID, who solicits contract bids and procures food commodities for approved requests. The commodities are then sent from suppliers to transport points or warehouse facilities at different logistical nodes of the supply chain, including U.S. ports, foreign discharge ports, and U.S. and international USAID prepositioning warehouses.. At some point in the process, the food commodities are transferred to partner organizations, who are then responsible for distributing the food commodities using their own transport and distribution partner networks, as well as their own information tracking systems.',
      },
      wine: {
        name: 'Wine',
        description:
          'The wine value chain consists of five broad production stages: vineyard preparation, grape production (viticulture), wine production (viniculture), logistics, and distribution, marketing and sales.',
      },
    },
  },
  documents: {
    title: 'Documents',
    labels: {
      documentName: 'Document Name',
      gdtiNumber: 'GDTI Number',
      valueChainSteps: 'Value Chain Step',
      publicRecord: 'Public Record',
      dateModified: 'Date Modified',
    },
    valueChainSteps: {
      Unclassified: 'Unclassified',
    },
  },
  products: {
    editProductModal: {
      labels: {
        name: 'Product Name',
        description: 'Product Description',
        image: 'Product Image',
      },
      editPoduct: 'Edit Product',
      createProduct: 'Create a product',
    },
    tableHeaders: {
      name: 'Name',
      category: 'Category',
      tasks: 'Assigned Tasks',
      interactions: 'Recorded Events',
      items: 'Items',
    },
    menuOptions: {
      manage: 'Manage',
      duplicate: 'Duplicate',
      updateFromClipboard: 'Update from Clipboard',
    },
    bulkActions: {
      addTasks: 'Add Tasks',
      removeTasks: 'Remove Tasks',
      addValueChain: 'Add Value Chain',
      changeValueChain: 'Change Value Chain',
      addSupportProfile: 'Add Support Profile',
      removeSupportProfile: 'Remove Support Profile',
      addContentBlock: 'Add Content Block',
      removeContentBlock: 'Remove Content Block',
    },
    productCreatedModal: {
      title: 'Product Created',
      message: 'Product Created Successfully, do you wish to manage now and add attributes, tasks, imagery and further content?',
      later: 'Do It Later',
      now: 'Manage Now',
    },
    productDuplicatedModal: {
      title: 'Product Duplicated',
      message: 'Product Duplicated Successfully, do you wish to manage now and add attributes, tasks, imagery and further content?',
      later: 'Do It Later',
      now: 'Manage Now',
    },
    title: 'Products',
    products: 'Product(s)',
  },
  collaborations: {
    contentNotValid: 'You can only update from content copied from 3rd party item scans.',
    header: {
      step: 'Step',
      title: 'Update from Clipboard',
    },
    subTitles: {
      general: 'General',
      productInfo: 'Product Information',
      attributes: 'Attributes',
      productDocuments: 'Documents',
      itemAndBatchDocuments: 'Item and Batch Documents',
      images: 'Images',
      videos: 'Videos',
      eventHistory: 'Event History',
      contentTransferSummary: 'Content Transfer Summary',
    },
    descriptiveContent: {
      noData: 'There is no general information available.',
      description: 'General Information will transferred on a product level and will be added to all batches.',
      target: 'Target',
      source: 'Source',
      labels: {
        name: 'Name',
        shortDescription: 'Short Description',
        longDescription: 'Long Description',
      },
      longDescriptionButtons: {
        append: 'Append',
        replace: 'Replace',
      },
    },
    attributes: {
      noData: 'There are no attributes available.',
      description: 'Attributes will transferred on a product level and will be added to all batches.',
      warningMessage: ' Selected Attributes will be applied to all batches of the product',
    },
    itemAndBatchDocuments: {
      noData: 'There are no documents available.',
      warningMessage: 'Selected Documents will be added to only the respective target item or batch of the product.',
    },
    images: {
      noData: 'There are no images available.',
      description: 'Images will transferred on a product level and will be added to all batches.',
      selectAllButton: 'Select All',
    },
    videos: {
      noData: 'There are no videos available.',
      description: 'Videos will transferred on a product level and will be added to all batches.',
      selectAllButton: 'Select All',
    },
    eventHistory: {
      noData: 'There are no tasks available with public content.',
      noItemBatch: 'There is no item or batch available to store events.',
      labels: {
        name: 'Name',
        classification: 'Classification',
      },
    },
    confirmationScreen: {
      noData: 'No Content Selected.',
      message: 'Updating from Clipboard...',
      successfulMessage: 'Update from Clipboard Successful',
    },
  },

  item: {
    filters: {
      doNotUse: 'Do Not Use',
      allClear: 'All Clear',
      normalPrecaution: 'Normal Precaution',
      reconsiderUse: 'Reconsider Use',
      increasedCaution: 'Increased Caution',
      unknownId: 'unknown ID',
      itemOk: 'Item Ok',
      ready: 'Ready',
      active: 'Active',
      resultPending: 'Result Pending',
      completedPositive: 'Completed Positive',
      completedNegative: 'Completed Negative',
      completedInconclusive: 'Completed Inconclusive',
      itemLocked: 'Item Locked',
      itemUnlocked: 'Item Unlocked',
      unused: 'Unused',
      returned: 'Returned',
      readyFilled: 'Ready Filled',
      readyPacked: 'Ready Packed',
      withCustomer: 'With Customer',
      issueRaised: 'Issue Raised',
      pickupRequested: 'Pickup Requested',
    },
    editItemAttribute: {
      title: 'Edit Attribute',
      fields: {
        name: 'Name',
        value: 'Value',
        gpsCoordinates: 'GPS Coordinates',
        address: 'Location',
      },
    },
  },
  thing: {
    menus: {
      about: 'About',
      discovery: 'Discovery',
      public: 'Public',
      tasks: 'Tasks',
      support: 'Support',
    },
    about: {
      general: 'General',
      category: 'Category',
      documentation: 'Documentation',
      attributes: 'Attributes',
      certifications: 'Certifications',
      miscellaneous: 'Miscellaneous',
      valueChains: 'Value Chains',
      gallery: 'Gallery ',
      advancedSettings: 'Advanced Settings',
      changeACategory: 'Change a Category',
      changeAValueChain: 'Change a Value Chain',
      contentBlocks: 'Content Blocks',
      certificationsLabel: {
        addCertification: 'Add Certification',
        editCertification: 'Edit Certification',
      },
      documentLabels: {
        addDocument: 'Add Document',
        editDocument: 'Edit Document',
      },
    },
    public: {
      generalSettings: 'General Settings',
      enablePublicContent: 'Enable public content',
      itemCheck: 'Item Check',
      usedAsDiscovery: 'Discovery Task',
      utilityLinks: 'Utility Links',
      rfiderAuthenticityCheck: 'Assure+ Authenticity Check',
      basicInformation: 'Basic Information',
      brandExperience: 'Brand Experience',
      links: 'Links',
      miscellaneous: 'Miscellaneous',
      contentBlocks: 'Content Blocks',
      attributes: 'Attributes',
      services: 'Services',
      documents: 'Documents',
      gallery: 'Gallery',
      certifications: 'Certifications',
      videos: 'Videos',
      longDescription: 'Long Description',
      supportProfile: 'Support Profile',
      itemHistory: 'Item History',
      none: 'None',
      heroImage: 'Hero Image',
      sectionImage: 'Section Image',
      heroOverlayImage: 'Hero Overlay Image',
      privacyPolicy: 'Privacy Policy',
      productInfo: 'Product Info',
      learnMore: 'Learn More',
      shopping: 'Shopping',
      section: 'Section',
      position: 'Position',
      enableAttributes: 'Enable Attributes',
      attributeVisibility: 'Attribute Visibility',
      enableAttachments: 'Enable attachments',
      enableCertifications: 'Enable Certifications',
      allowProfles: 'Allow Team Members’ Personal Profiles',
      surviceLabels: {
        enableServices: 'Enable Services',
        serviceVisibility: 'Service Visibility',
        basic: 'Basic',
        off: 'Off',
        peerToPeer: 'Peer To Peer',
      },
      itemHistoryLabels: {
        enableItemHistory: 'Enable item history',
        displayTemplate: 'Display Template',
        events: 'Events',
        data: 'Data',
        images: 'Images',
        eventGallery: 'Event Gallery',
        enableEventGallery: 'Enable Event Gallery',
      },
      switch: {
        confirmTextEnable: 'Are you sure you want to enable?',
        confirmTextDisable: 'Are you sure you want to disable?',
        confirmButtonEnable: 'Yes, enable it!',
        confirmButtonDisable: "Yes, disable it!'",
      },
      enablePublicLink: 'Enable Product Page URL',
      enablePublicItemUrl: 'Enable Marketing Item URL',
      itemUrlPlaceholder: 'Add Marketing Item URL here ...',
      attributeLabels: {
        editAttribute: 'Edit Attribute',
        addAttribute: 'Add Attribute',
        label: 'Label',
        value: 'Value',
        type: 'Type',
      },
    },
  },
  filter: {
    createdByMe: 'Created By Me',
  },
  swal: {
    duplicate: 'Duplicating',
    confirmTitle: 'Are you sure?',
    warningMessage: "You won't be able to revert this!",

    confirmButtonDelete: 'Yes, delete it!',
    confirmButtonRemove: 'Yes, remove it!',
    confirmButtonDuplicate: 'Yes, duplicate it!',
    confirmButtonReset: 'Yes, reset it!',
    confirmButtonResend: 'Yes, resend it!',
    confirmButtonDecline: 'Yes, decline it!',
    confirmButtonAccept: 'Yes, accept it!',
    keepIt: 'No, keep it',
    userText: 'You will need to invite them again.',
    associationError: 'Associations could not be created!',
    job: {
      deleteJob: {
        confirmTitle: 'Delete Job',
        warningMessage1: 'This action cannot be undone. ',
        warningMessage2: 'If you continue, you will delete this job and any recorded data associated with it.',
        confirmButtonDelete: 'Delete Job',
      },
    },
    template: {
      confirmButtonText: 'Yes, create a new product!',
      createProduct: 'Creating a Product',
      successMessage: 'New Product has been created from this Template',
      errorMessage: 'New Product cannot be created from this Template',
      successMessage1: 'Template has been duplicated',
      errorMessage1: 'Template cannot be duplicated',
    },
    products: {
      successMessage: 'Product has been duplicated',
      errorMessage: 'Product cannot be duplicated',
      createProductError: 'New Product cannot be created from this Template',
    },
    valueChain: {
      successMessage: 'Value chain has been duplicated',
      errorMessage: 'Could not duplicate the Value chain',
      successMessageThingAbout: 'Value chain for this product has been changed',
      errorMessageThingAbout: 'Could not change a Value chain for this product',
      thingValueChainText: 'Recommended that this is not changed.',
      lastChance: 'Last Chance',
      lastChanceText: 'Any data collected up to this point will not be displayed in the value chain view. This change cannot be undone.',
    },
    user: {
      successMessage: 'Resent the invitation to user',
      errorMessage: 'Could not resend the invitation to user',
      successMessage1: 'User updated',
      errorMessage1: 'Could not update user',
    },
    invitations: {
      titleDecline: 'Decline invitation?',
      titleAccept: 'Accept invitation?',
      text: 'You are invited to join',
    },
    category: {
      successMessageThingAbout: 'Category for this product has been changed',
      errorMessageThingAbout: 'Could not change a Category for this product',
      thingCategoryText: 'Recommended that this is not changed.',
      lastChance: 'Last Chance',
      lastChanceText: 'This change cannot be undone.',
    },
    document: {
      createDocument: 'Document has been created',
      createDocumentError: 'Could not create a Document',
      updateDocument: 'Document has been Updated',
      updateDocumentError: 'Could not update the Document',
      deleteDocument: 'Document has been deleted',
      deleteDocumentError: 'Could not delete the Document',
    },
    task: {
      text: 'You are about to exit task configuration without saving your changes. All unsaved changes will be lost. Do you wish to proceed?',
      deleteTask: 'Task has been deleted',
      updateTask: 'Task has been Updated',
      updateTaskError: 'Could not update the Task',
      createTask: 'Task has been created',
      createTaskTitle: 'Created Successfully!',
      createTaskText: 'Would you like to open its configuration?',
      labels: {
        configuration: 'Configuration',
      },
      warningMessage: 'Task will no longer be available for this product!',
    },
    genericTask: {
      cancel: {
        title: 'Are you sure you want to stop this task?',
      },
    },
    interaction: {
      createSuccess: 'Interaction created',
      createError: 'Could not create a Interaction.',
      mapLoadMessage: 'Loading error',
      mapLoadError: 'Could not load the interactions on the map',
      updateSuccess: 'Interaction has been updated',
      updateError: 'Interaction cannot be updated',
      deleteSuccess: 'Interaction has been deleted',
      deleteError: 'Interaction cannot be deleted.',
    },
    item: {
      itemsLimitError: 'The limit for input options is only {itemsLimit} items.',
      removeSupportProfile: {
        title: 'Are you sure?',
        success: 'The personal support profile removed',
        error: 'Could not remove personal support profile',
      },
    },
    miscellaneous: {
      noImageError: 'Please add an image',
      undefinedNotificationType: 'Please choose one of the notification types',
    },
    webhooks: {
      deleteAction: 'Action has been deleted',
      deleteActionError: 'Could not delete the action',
      updateActionStatus: 'Action status has been updated',
      updateActionStatusError: 'Could not update the action status',
      updateAction: 'Action has been updated',
      updateActionError: 'Could not update the action',
      createWebhook: 'Webhook created',
    },
    supportProfile: {
      warningMessage: 'Support Profile will no longer be available for this product!',
    },
    contentBlock: {
      changingCollectionProduct: 'You have modified the selection of products in the collection. If you exit now, your changes will be lost. Do you wish to proceed?',
      editContentBlock: {
        title: 'Would you like to continue?',
        warningMessage: 'Changes will display in all products this content block is assigned to',
        cancel: {
          title: 'Are you sure?',
          warningMessage: 'You are about to exit without saving your changes. All unsaved changes will be lost. Do you wish to proceed?',
        },
      },
      assignSuccess: 'Content Block Assigned',
    },
  },
  invitations: {
    title: 'Invitations',
    records: 'Records',
  },
  languages: {
    english: 'English',
    englishCertBeta: 'English (Farm Audits)',
    german: 'Deutsch',
    spanish: 'Español',
    japanese: '日本',
    korean: '한국인',
    dutch: 'Nederlands',
    portuguese: 'Português',
    chinese: '中国人',
    chineseTraditional: '中國人',
  },
  editMiscellaneous: {
    editMiscellaneous: 'Edit Miscellaneous',
    addMiscellaneous: 'Add Miscellaneous',
    editVideo: 'Edit Video',
    addVideo: 'Add Video',
    editOptions: 'Edit Options',
    addOptions: 'Add Options',
    labels: {
      embeddedLink: 'EMBEDDED LINK ONLY',
      videoEmbed: 'VIDEO EMBED CODE',
      codeInjection: 'Code Injection',
      imageLink: 'IMAGE LINK',
    },
  },
  discovery: {
    labels: {
      batchId: 'Batch Id',
      batchName: 'Batch Name',
      createdBy: 'Created By',
      itemCount: 'Item Count',
      used: '% Used',
    },
    buttons: {
      identificationMethod: 'Identification Method',
      createBatch: 'Create Batch',
      deactivate: 'Deactivate',
      activate: 'Activate',
      delete: 'Delete',
    },
    maximumItemsPerBatch: 'Maximum items per batch',
    batches: 'Batches',
    beta: 'Beta',
    item: 'Item',
    product: 'Product',
    selectIdentificationMethod: 'Select Identification Method',
    message: 'The Assure+ Unique ID (RFUID) is a system generated identifier of items managed on the Assure+ platform.',
  },
  dashboard: {
    dateRange: {
      today: 'Today',
      lastSevenDays: 'Last 7 days',
      lastWeek: 'Last week',
      lastThirtyDays: 'Last 30 days',
      lastMonth: 'Last month',
      lastTwelvemonths: 'Last 12 months',
      custom: 'Custom',
    },
    chart: {
      today: 'Today',
      allTime: 'All Time',
      totalInteractions: 'Total Interactions',
      totalTasks: 'Total Tasks',
      totalServices: 'Total Services',
      noData: 'No data',
      overallStatusBreakdown: 'Overall Status Breakdown',
    },
  },
  displayConditionsRules: {
    onlyAdmin: 'User Permissions',
    or: 'or',
    andAlso: 'And Also',
    addYourFirstCondition: 'Add your first condition',
    useSeparator: 'Use comma as separator',
    useDashSeparator: 'Use dash (-) as separator',
    rfuidItemId: 'RFUID Item Id',
    rfuidBatchId: 'RFUID Batch Id',
    rfuidSequenceNumber: 'RFUID Sequence Number',
    itemStatus: 'Item Status',
    userCountry: 'User Country',
    userLanguage: 'User Language',
    conditions: {
      stringEquals: 'String equals',
      stringNotEquals: 'String not equals',
      includedIn: 'Included in',
      notIncludedIn: 'Not included in',
      numberEquals: 'Number equals',
      numberNotEquals: 'Number not equals',
      numberGreaterThan: 'Number greater than',
      numberLessThan: 'Number less than',
      between: 'Between',
    },
    countries: {
      afghanistan: 'Afghanistan',
      alandIslands: 'Åland Islands',
      albania: 'Albania',
      algeria: 'Algeria',
      americanSamoa: 'American Samoa',
      andorra: 'Andorra',
      angola: 'Angola',
      anguilla: 'Anguilla',
      antarctica: 'Antarctica',
      antigua: 'Antigua and Barbuda',
      argentina: 'Argentina',
      armenia: 'Armenia',
      aruba: 'Aruba',
      australia: 'Australia',
      austria: 'Austria',
      azerbaijan: 'Azerbaijan',
      bahamas: 'Bahamas',
      bahrain: 'Bahrain',
      bangladesh: 'Bangladesh',
      barbados: 'Barbados',
      belarus: 'Belarus',
      belgium: 'Belgium',
      belize: 'Belize',
      benin: 'Benin',
      bermuda: 'Bermuda',
      bhutan: 'Bhutan',
      bolivia: 'Bolivia',
      bonaire: 'Bonaire',
      bosnia: 'Bosnia and Herzegovina',
      botswana: 'Botswana',
      bouvet: 'Bouvet Island',
      brazil: 'Brazil',
      britishIndian: 'British Indian Ocean Territory',
      brunei: 'Brunei Darussalam',
      bulgaria: 'Bulgaria',
      burkina: 'Burkina Faso',
      burundi: 'Burundi',
      cambodia: 'Cambodia',
      cameroon: 'Cameroon',
      canada: 'Canada',
      capeVerde: 'Cape Verde',
      caymanIslands: 'Cayman Islands',
      centralAfricanRepublic: 'Central African Republic',
      chad: 'Chad',
      chile: 'Chile',
      china: 'China',
      christmasIsland: 'Christmas Island',
      cocos: 'Cocos (Keeling) Islands',
      colombia: 'Colombia',
      comoros: 'Comoros',
      congo: 'Congo',
      congoDemocratic: 'Congo',
      cookIslands: 'Cook Islands',
      costaRica: 'Costa Rica',
      coteIvo: "Côte d'Ivoire",
      croatia: 'Croatia',
      cuba: 'Cuba',
      curacao: 'Curaçao',
      cyprus: 'Cyprus',
      czechRepublic: 'Czech Republic',
      denmark: 'Denmark',
      djibouti: 'Djibouti',
      dominica: 'Dominica',
      dominicanRepublic: 'Dominican Republic',
      ecuador: 'Ecuador',
      egypt: 'Egypt',
      elSalvador: 'El Salvador',
      equatorialGuinea: 'Equatorial Guinea',
      eritrea: 'Eritrea',
      estonia: 'Estonia',
      ethiopia: 'Ethiopia',
      falkland: 'Falkland Islands (Malvinas)',
      faroeIslands: 'Faroe Islands',
      fiji: 'Fiji',
      finland: 'Finland',
      france: 'France',
      frenchGuiana: 'French Guiana',
      frenchPolynesia: 'French Polynesia',
      frenchSouthern: 'French Southern Territories',
      gabon: 'Gabon',
      gambia: 'Gambia',
      georgia: 'Georgia',
      germany: 'Germany',
      ghana: 'Ghana',
      gibraltar: 'Gibraltar',
      greece: 'Greece',
      greenland: 'Greenland',
      grenada: 'Grenada',
      guadeloupe: 'Guadeloupe',
      guam: 'Guam',
      guatemala: 'Guatemala',
      guernsey: 'Guernsey',
      guinea: 'Guinea',
      guineaBissau: 'Guinea-Bissau',
      guyana: 'Guyana',
      haiti: 'Haiti',
      heardIsland: 'Heard Island and McDonald Islands',
      holySee: 'Holy See (Vatican City State)',
      honduras: 'Honduras',
      hongKong: 'Hong Kong',
      hungary: 'Hungary',
      iceland: 'Iceland',
      india: 'India',
      indonesia: 'Indonesia',
      iran: 'Iran',
      iraq: 'Iraq',
      ireland: 'Ireland',
      isleOfMan: 'Isle of Man',
      israel: 'Israel',
      italy: 'Italy',
      jamaica: 'Jamaica',
      japan: 'Japan',
      jersey: 'Jersey',
      jordan: 'Jordan',
      kazakhstan: 'Kazakhstan',
      kenya: 'Kenya',
      kiribati: 'Kiribati',
      korea: 'Korea',
      koreaRepublic: 'Korea',
      kuwait: 'Kuwait',
      kyrgyzstan: 'Kyrgyzstan',
      lao: 'Lao',
      latvia: 'Latvia',
      lebanon: 'Lebanon',
      lesotho: 'Lesotho',
      liberia: 'Liberia',
      libya: 'Libya',
      liechtenstein: 'Liechtenstein',
      lithuania: 'Lithuania',
      luxembourg: 'Luxembourg',
      macao: 'Macao',
      macedonia: 'Macedonia',
      madagascar: 'Madagascar',
      malawi: 'Malawi',
      malaysia: 'Malaysia',
      maldives: 'Maldives',
      mali: 'Mali',
      malta: 'Malta',
      marshall: 'Marshall Islands',
      martinique: 'Martinique',
      mauritania: 'Mauritania',
      mauritius: 'Mauritius',
      mayotte: 'Mayotte',
      mexico: 'Mexico',
      micronesia: 'Micronesia',
      moldova: 'Moldova',
      monaco: 'Monaco',
      mongolia: 'Mongolia',
      montenegro: 'Montenegro',
      montserrat: 'Montserrat',
      morocco: 'Morocco',
      mozambique: 'Mozambique',
      myanmar: 'Myanmar',
      namibia: 'Namibia',
      nauru: 'Nauru',
      nepal: 'Nepal',
      netherlands: 'Netherlands',
      newCaledonia: 'New Caledonia',
      newZealand: 'New Zealand',
      nicaragua: 'Nicaragua',
      niger: 'Niger',
      nigeria: 'Nigeria',
      niue: 'Niue',
      norfolk: 'Norfolk Island',
      northern: 'Northern Mariana Islands',
      norway: 'Norway',
      oman: 'Oman',
      pakistan: 'Pakistan',
      palau: 'Palau',
      palestinian: 'Palestinian Territory, Occupied',
      panama: 'Panama',
      papua: 'Papua New Guinea',
      paraguay: 'Paraguay',
      peru: 'Peru',
      philippines: 'Philippines',
      pitcairn: 'Pitcairn',
      poland: 'Poland',
      portugal: 'Portugal',
      puertoRico: 'Puerto Rico',
      qatar: 'Qatar',
      reunion: 'Réunion',
      romania: 'Romania',
      russian: 'Russian Federation',
      rwanda: 'Rwanda',
      saintBarth: 'Saint Barthélemy',
      saintHelena: 'Saint Helena',
      saintKitts: 'Saint Kitts and Nevis',
      saintLucia: 'Saint Lucia',
      saintMartin: 'Saint Martin (French part)',
      saintPierre: 'Saint Pierre and Miquelon',
      saintVincent: 'Saint Vincent and the Grenadines',
      samoa: 'Samoa',
      sanMarino: 'San Marino',
      saoTome: 'Sao Tome and Principe',
      saudiArabia: 'Saudi Arabia',
      senegal: 'Senegal',
      serbia: 'Serbia',
      seychelles: 'Seychelles',
      sierra: 'Sierra Leone',
      singapore: 'Singapore',
      sintMaarten: 'Sint Maarten (Dutch part)',
      slovakia: 'Slovakia',
      slovenia: 'Slovenia',
      solomon: 'Solomon Islands',
      somalia: 'Somalia',
      southAfrica: 'South Africa',
      southGeorgia: 'South Georgia and the South Sandwich Islands',
      southSudan: 'South Sudan',
      spain: 'Spain',
      sriLanka: 'Sri Lanka',
      sudan: 'Sudan',
      suriname: 'Suriname',
      svalbard: 'Svalbard and Jan Mayen',
      swaziland: 'Swaziland',
      sweden: 'Sweden',
      switzerland: 'Switzerland',
      syrian: 'Syrian Arab Republic',
      taiwan: 'Taiwan',
      tajikistan: 'Tajikistan',
      tanzania: 'Tanzania',
      thailand: 'Thailand',
      timorLeste: 'Timor-Leste',
      togo: 'Togo',
      tokelau: 'Tokelau',
      tonga: 'Tonga',
      trinidad: 'Trinidad and Tobago',
      tunisia: 'Tunisia',
      turkey: 'Turkey',
      turkmenistan: 'Turkmenistan',
      turksAndCaicosIslands: 'Turks and Caicos Islands',
      tuvalu: 'Tuvalu',
      uganda: 'Uganda',
      ukraine: 'Ukraine',
      uae: 'United Arab Emirates',
      uk: 'United Kingdom',
      usa: 'United States',
      usMinor: 'United States Minor Outlying Islands',
      uruguay: 'Uruguay',
      uzbekistan: 'Uzbekistan',
      vanuatu: 'Vanuatu',
      venezuelaBolivarian: 'Venezuela',
      vietnam: 'Vietnam',
      virginIslandsBritish: 'Virgin Islands',
      virginIslandsUS: 'Virgin Islands',
      wallisAndFutuna: 'Wallis and Futuna',
      westernSahara: 'Western Sahara',
      yemen: 'Yemen',
      zambia: 'Zambia',
      zimbabwe: 'Zimbabwe',
    },
    languages: {
      ab: 'Abkhazian',
      aa: 'Afar',
      af: 'Afrikaans',
      ak: 'Akan',
      sq: 'Albanian',
      am: 'Amharic',
      ar: 'Arabic',
      an: 'Aragonese',
      hy: 'Armenian',
      as: 'Assamese',
      av: 'Avaric',
      ae: 'Avestan',
      ay: 'Aymara',
      az: 'Azerbaijani',
      bm: 'Bambara',
      ba: 'Bashkir',
      eu: 'Basque',
      be: 'Belarusian',
      bn: 'Bengali',
      bh: 'Bihari languages',
      bi: 'Bislama',
      bs: 'Bosnian',
      br: 'Breton',
      bg: 'Bulgarian',
      my: 'Burmese',
      ca: 'Catalan; Valencian',
      km: 'Central Khmer',
      ch: 'Chamorro',
      ce: 'Chechen',
      ny: 'Chichewa; Chewa; Nyanja',
      zhCn: 'Chinese (Simplified)',
      zhTw: 'Chinese (Traditional)',
      cu: 'Church Slavic',
      cv: 'Chuvash',
      kw: 'Cornish',
      co: 'Corsican',
      cr: 'Cree',
      hr: 'Croatian',
      cs: 'Czech',
      da: 'Danish',
      dv: 'Divehi',
      nl: 'Dutch; Flemish',
      dz: 'Dzongkha',
      en: 'English',
      eo: 'Esperanto',
      et: 'Estonian',
      ee: 'Ewe',
      fo: 'Faroese',
      fj: 'Fijian',
      fi: 'Finnish',
      fr: 'French',
      ff: 'Fulah',
      gd: 'Gaelic',
      gl: 'Galician',
      lg: 'Ganda',
      ka: 'Georgian',
      de: 'German',
      el: 'Greek',
      gn: 'Guarani',
      gu: 'Gujarati',
      ht: 'Haitian',
      ha: 'Hausa',
      he: 'Hebrew',
      hz: 'Herero',
      hi: 'Hindi',
      ho: 'Hiri Motu',
      hu: 'Hungarian',
      is: 'Icelandic',
      io: 'Ido',
      ig: 'Igbo',
      id: 'Indonesian',
      iu: 'Inuktitut',
      ik: 'Inupiaq',
      ga: 'Irish',
      it: 'Italian',
      ja: 'Japanese',
      jv: 'Javanese',
      kl: 'Greenlandic',
      kn: 'Kannada',
      kr: 'Kanuri',
      ks: 'Kashmiri',
      kk: 'Kazakh',
      ki: 'Kikuyu; Gikuyu',
      rw: 'Kinyarwanda',
      ky: 'Kirghiz; Kyrgyz',
      kv: 'Komi',
      kg: 'Kongo',
      ko: 'Korean',
      kj: 'Kuanyama; Kwanyama',
      ku: 'Kurdish',
      lo: 'Lao',
      la: 'Latin',
      lv: 'Latvian',
      li: 'Limburger',
      ln: 'Lingala',
      lt: 'Lithuanian',
      lu: 'Luba-Katanga',
      lb: 'Luxembourgish',
      mk: 'Macedonian',
      mg: 'Malagasy',
      ms: 'Malay',
      ml: 'Malayalam',
      mt: 'Maltese',
      gv: 'Manx',
      mi: 'Maori',
      mr: 'Marathi',
      mh: 'Marshallese',
      mn: 'Mongolian',
      na: 'Nauru',
      nv: 'Navajo; Navaho',
      ng: 'Ndonga',
      ne: 'Nepali',
      se: 'Northern Sami',
      no: 'Norwegian',
      oj: 'Ojibwa',
      or: 'Oriya',
      om: 'Oromo',
      os: 'Ossetian; Ossetic',
      pi: 'Pali',
      pa: 'Panjabi; Punjabi',
      fa: 'Persian',
      pl: 'Polish',
      ptBr: 'Portuguese',
      ps: 'Pushto; Pashto',
      qu: 'Quechua',
      ro: 'Romanian',
      rm: 'Romansh',
      rn: 'Rundi',
      ru: 'Russian',
      sm: 'Samoan',
      sg: 'Sango',
      sa: 'Sanskrit',
      sc: 'Sardinian',
      sr: 'Serbian',
      sn: 'Shona',
      ii: 'Sichuan Yi; Nuosu',
      sd: 'Sindhi',
      si: 'Sinhala; Sinhalese',
      sk: 'Slovak',
      sl: 'Slovenian',
      so: 'Somali',
      st: 'Sotho, Southern',
      es: 'Spanish',
      su: 'Sundanese',
      sw: 'Swahili',
      ss: 'Swati',
      sv: 'Swedish',
      tl: 'Tagalog',
      ty: 'Tahitian',
      tg: 'Tajik',
      ta: 'Tamil',
      tt: 'Tatar',
      te: 'Telugu',
      th: 'Thai',
      bo: 'Tibetan',
      ti: 'Tigrinya',
      to: 'Tonga',
      ts: 'Tsonga',
      tn: 'Tswana',
      tr: 'Turkish',
      tk: 'Turkmen',
      tw: 'Twi',
      ug: 'Uighur; Uyghur',
      uk: 'Ukrainian',
      ur: 'Urdu',
      uz: 'Uzbek',
      ve: 'Venda',
      vi: 'Vietnamese',
      vo: 'Volapük',
      wa: 'Walloon',
      cy: 'Welsh',
      fy: 'Western Frisian',
      wo: 'Wolof',
      xh: 'Xhosa',
      yi: 'Yiddish',
      yo: 'Yoruba',
      za: 'Zhuang; Chuang',
      zu: 'Zulu',
    },
  },
  itemSummary: {
    title: 'Item Code Information',
    batchId: 'Batch Id',
    product: 'Product',
    sequence: 'Sequence',
    checksum: 'Checksum',
    warning:
      'Authenticity check is activated for this item. Accessing from the portal may trigger authenticity alert protocols, such as status update and warning upon subsequent scans.',
    hideUrl: 'Hide URL',
    showUrl: 'Show URL',
    hideCode: 'Hide Code',
    showCode: 'Show Code',
    copy: 'Copy URL',
    itemUrlCopied: 'Item URL copied.',
    thingUrlCopied: 'Product Page URL copied.',
    productName: 'Product Name',
    itemId: 'Item Id',
    itemStatus: 'Item Status',
    itemName: 'Item Name',
    batchName: 'Batch Name',
  },
  updateReportedData: {
    locationTitle: 'Location',
    dateTimeTitle: 'Date and Time',
    dateTimeInteraction: 'Interaction Date',
    dateTimeReported: 'Reported Date',
    reset: 'Reset',
    locationInteraction: 'Interaction Location',
    locationReported: 'Reported Location',
    reportedData: 'Reported Data',
  },
  collections: {
    title: 'Collections',
    addCollection: 'Add Collection',
    addProductsToCollection: 'Add Products to Collection',
    tableHeaders: {
      name: 'Name',
      createdAt: 'Created At',
      createdBy: 'Created By',
    },
  },
};
export default en;
