export const taskInfoFragment = `
id
name
description
image
icon
organizationId
createdById
createdAt
updatedAt
metadata
attributes
ruleConditions
`;

export const taskLibraryInfoFragment = `
id
name
description
icon
automatic
image
realtime
service
type
inAppId
`;

export const taskLibraryWithConfigInfoFragment = `
id
name
description
icon
automatic
image
realtime
service
type
config
inAppId
`;

export const taskThingInfoFragment = `
id
order
publicServiceEnabled
publicServiceSharingEnabled
publicTaskVisibility
publicTaskContent
expiration
stepsEngineConfig
messagesEngineConfig
itemStatuses
metadata
attributes
`;
