const es = {
  common: {
    labels: {
      selected: 'SELECCIONADO:',
      searchByName: 'Buscar por nombre',
      itemStatusMatches: 'Mostrar solo cuando el estado del artículo coincida',
      showTask: 'Dejar vacío para mostrar siempre esta tarea',
      emailAddress: 'Dirección de correo electrónico',
      name: 'Nombre',
      emailAddressWithComma: 'Direcciones de correo electrónico (separadas por comas)',
      emailSubject: 'Asunto del correo electrónico (EX: {tarea} - {nombre} para {cosa} con estado: {estado})',
      endpointURL: 'URL de punto final',
      securityHmacKey: 'Seguridad (clave HMAC)',
      apiKey: 'Clave API',
      organizationId: 'Identificación de la organización',
      xmlMessage: 'Mensaje XML',
      createYourFirst: 'Crea tu primera',
      valueChain: 'cadena de valor.',
      category: 'categoría.',
      supportProfile: 'Perfil de soporte.',
      task: 'tarea.',
      template: 'plantilla.',
      description: 'Descripción',
      learnMoreLink: 'Aprenda más enlace',
      image: 'Imagen',
      displayImage: 'Mostrar imagen',
      chainElementIcon: 'Icono de elemento de cadena',
      transportationMethod: 'Método de transporte',
      gln: 'GLN (Número de ubicación global)',
      status: 'Estado',
      thing: 'Cosa',
      selectDateRange: 'Seleccionar rango de fechas',
      searchBy: 'Búsqueda por',
      itemImage: 'Imagen del artículo',
      uploadAttachment: 'Subir archivo adjunto',
      addInteractionTo: 'Añadir interacción a',
      interactionName: 'Nombre de interacción',
      steps: 'Pasos',
      displayName: 'Nombre para mostrar',
      title: 'Título',
      attachments: 'Archivos adjuntos',
      currentStatus: 'Estado actual',
      country: 'País',
      language: 'Idioma',
      items: 'Elementos',
      interactions: 'Interacciones',
      tasks: 'Tareas',
      product: 'producto',
      item: 'ít',
      selectLanguage: 'Seleccione el idioma',
      addBatch: 'Agregar lote',
      editBatch: 'Editar lote',
      filters: 'filtros',
      unknown: 'Desconocido',
      from: 'desde',
      learnMore: 'Aprende más',
      search: 'Búsqueda',
      url: 'URL',
      addTask: 'Agregar tarea',
      at: 'en',
    },
    buttons: {
      save: 'Salvar',
      cancel: 'Cancelar',
      finish: 'Finalizar',
      submit: 'Enviar',
      delete: 'Borrar',
      remove: 'Eliminar',
      task: 'Tarea',
      ok: 'OK',
      logout: 'Cerrar sesión',
      decline: 'Disminución',
      accept: 'Aceptar',
      inviteUsers: 'Invitar usuarios',
      send: 'Enviar',
      addMore: 'Añadir más',
      createAnAction: 'Crear una acción',
      removeIntegration: 'Eliminar integración',
      continue: 'Continuar',
      update: 'Actualizar',
      back: 'Atrás',
      next: 'Próximo',
      close: 'Cerrar',
      upload: 'Subir',
      create: 'Crear',
      clear: 'Claro',
      apply: 'Aplicar',
      viewInDashboard: 'Ver en el tablero',
      confirm: 'Confirmar',
      yes: 'sí',
      no: 'No',
      configure: 'Configurar',
    },
    menus: {
      things: 'Cosas',
      items: 'Elementos',
      interactions: 'Interacciones',
      reportingAndAnalytics: 'Informes y análisis',
      templates: 'Plantillas',
      settings: 'Ajustes',
      tasks: 'Tareas',
      users: 'Usuarios',
      advancedSettings: 'Ajustes avanzados',
      valueChains: 'Cadenas de valor',
      organisationalSettings: 'Configuración organizacional',
      language: 'Idioma',
      catalogue: 'Catalogar',
    },
    menuItems: {
      edit: 'Editar',
      delete: 'Borrar',
      remove: 'Eliminar',
      manageConfiguration: 'Administrar configuración',
      setAsDefault: 'Establecer por defecto',
      setAsPublic: 'Establecer como público',
      setAsPrivate: 'Establecer como privado',
      manage: 'Administrar',
      duplicate: 'Duplicar',
      viewContent: 'Ver contenido',
      viewSettings: 'Ver configuraciones',
      displayToPublic: 'Mostrar al público',
      hideFromPublic: 'Ocultar del público',
      disable: 'Desactivar',
      enable: 'Habilitar',
      viewConditions: 'Ver Condiciones',
      manageConditions: 'Administrar Condiciones',
      viewDisplayConditions: 'Ver condiciones de visualización',
      manageDisplayConditions: 'Administrar condiciones de visualización',
      change: 'Cambiar',
      displayForBatch: 'Pantalla para lote',
      viewOrUpdateClassification: 'Ver/Actualizar Clasificación',
      displayInPublicPage: 'Mostrar en la página pública',
      suspended: 'Suspendido',
      unsuspend: 'Reactivar',
      suspend: 'Suspender',
      invitationDeclined: 'Invitación rechazada',
      invitationPending: 'Invitación pendiente',
      invitationExpired: 'Invitación caducada',
      invitationAccepted: 'Invitación aceptada',
      setAsMember: 'Establecer como miembro',
      setAsAdmin: 'Establecer como administrador',
      disablePortalAccess: 'Deshabilitar el acceso al portal',
      enablePortalAccess: 'Habilitar el acceso al portal',
      resendInvitation: 'Reenviar invitacíon',
      deleteUser: 'Borrar usuario',
      deactivate: 'Desactivar',
      activate: 'Activar',
      preview: 'Avance',
      customiseName: 'Personalizar nombre',
      customiseImage: 'Personalizar imagen',
      addAttachment: 'Añadir un adjunto',
      changeStatus: 'Cambiar Estado',
      removePersonalSupportProfile: 'Eliminar perfil de soporte personal',
      createBatch: 'Crear lote',
      editBatch: 'Editar lote',
      deleteBatch: 'Eliminar lote',
      copyToClipboard: 'Copiar al portapapeles',
      openPublicPage: 'Abrir página pública',
      createProduct: 'Crear producto',
      pagePosition: 'Posición de la página',
      uploadImage: 'Cargar imagen',
      deleteImage: 'Eliminar Imagen',
      updateFromClipboard: 'Actualizar desde el Portapapeles',
      viewCodeDetails: 'Ver detalles del código',
      updateDisplayData: 'Actualizar datos informados',
      hide: 'Esconder',
      unhide: 'Mostrar',
    },
    pagination: {
      text: 'Mostrando {desde} hasta {hasta} de {total} entradas',
    },
    tabs: {
      general: 'General',
      categories: 'Categorías',
      support: 'Apoyo',
      tasks: 'Tareas',
      users: 'Usuarios',
      integrationActions: 'Acciones de Integración',
      valueChain: 'Cadenas de valor',
      chart: 'Gráfico',
      map: 'Mapa',
      documents: 'Documentos',
    },
    messages: {
      invitationExpired: 'Invitación caducada',
      notFoundInvitation: 'Invitación no encontrada',
      userAlreadyExists: 'Los usuarios ya existen',
      nameAlreadyTaken: 'Nombre ya en uso',
      InvalidContent: 'Contenido inválido',
      PayloadTooLarge: 'Entidad de solicitud demasiado grande',
      noSearchResult: 'Lo sentimos, no pudimos encontrar ningún resultado que coincida',
      noneSelected: 'Nada seleccionado.',
      noneAvailable: 'Aún no se han creado tareas para esta organización.',
      noConfigurableAttributes: 'No hay atributos configurables disponibles para esta tarea.',
      noInvitation: 'No hay invitaciones pendientes',
      defaultValueChain: 'El elemento predeterminado existente solo se puede cambiar seleccionando un nuevo elemento como predeterminado desde su pantalla de edición.',
      fileSizeError: 'El tamaño máximo de archivo para cargar un archivo en Assure+ Portal es de 10 MB. Cargue un archivo de hasta 10 MB.',
      submitError: 'Por favor llene todos los campos requeridos',
      inviteSent: 'Invitaciones enviadas',
      inviteError: 'No se pudieron enviar invitaciones',
      saved: 'salvado',
      deleted: 'eliminado',
      duplicated: 'duplicado',
      createThing: 'Cosa creada',
      createThingError: 'No se pudo crear la Cosa',
      duplicateThingError: 'La cosa no se puede duplicar',
      updateThing: 'La cosa ha sido duplicada',
      updateThingError: 'No se pudo actualizar la Cosa',
      loadTypeError: 'No se pudo cargar {tipo}',
      deleteTypeError: 'No se pudo eliminar {tipo}',
      duplicateTypeError: 'no se pudo duplicar',
      createTask: 'Se ha añadido la tarea',
      createTaskError: 'No se pudo agregar la tarea',
      updateTask: 'La tarea ha sido actualizada',
      updateTaskError: 'No se pudo actualizar la tarea',
      taskError: 'No se pudo crear una tarea',
      createSupportProfile: 'Se ha agregado el perfil de soporte',
      createSupportProfileError: 'No se pudo agregar el perfil de soporte',
      updateSupportProfile: 'El perfil de soporte ha sido actualizado',
      updateSupportProfileError: 'No se pudo actualizar el perfil de soporte',
      deleteSupportProfile: 'El perfil de soporte ha sido eliminado',
      deleteSupportProfileError: 'No se pudo crear el perfil de soporte',
      supportProfile: 'Se ha creado el perfil de soporte',
      supportProfileError: 'No se pudo crear un perfil de soporte',
      createTeam: 'Equipo creado',
      createTeamError: 'No se pudo crear el equipo',
      saveTeam: 'Equipo salvado',
      saveTeamError: 'No se pudo guardar el equipo',
      renameTeam: 'Equipo renombrado',
      renameTeamError: 'No se pudo cambiar el nombre del equipo',
      teamStatus: 'El estado del equipo cambió',
      teamStatusError: 'No se pudo cambiar el estado del equipo',
      resendInvite: 'Invitar a reenviar',
      resendInviteError: 'No se pudo reenviar la invitación',
      revokeInvite: 'Invitar a revocar',
      revokeInviteError: 'No se pudo revocar la invitación',
      revokeAccess: 'Acceso revocado',
      revokeAccessError: 'No se pudo revocar el acceso',
      suspendAccess: 'Suspendido',
      suspendAccessError: 'No se pudo cambiar para suspender',
      accountChange: 'Cuenta cambiada',
      accountChangeAdminError: 'No se pudo cambiar a administrador',
      accountChangeOwnerError: 'No se pudo cambiar de propietario',
      accountChangeUserError: 'No se pudo cambiar a usuario',
      inviteUser: 'Usuario(s) invitado(s)',
      inviteUserError: 'No se pudo invitar a los usuarios',
      declineInvitation: 'Rechazó la invitación',
      declineInvitationError: 'No se pudo rechazar la invitación ({mensaje})',
      acceptInvitation: 'Aceptó la invitación',
      acceptInvitationError: 'No se pudo aceptar la invitación ({mensaje})',
      deleteUser: 'Usuario eliminado',
      deleteUserError: 'No se pudo eliminar el usuario',
      updateTemplate: 'La plantilla ha sido actualizada',
      updateTemplateError: 'No se pudo actualizar la plantilla',
      deleteTemplate: 'La plantilla ha sido eliminada',
      deleteTemplateError: 'No se pudo eliminar la plantilla',
      createTemplate: 'Se ha creado la plantilla',
      createTemplateError: 'No se pudo crear una plantilla',
      deleteBatch: 'Lote eliminado',
      deleteBatchError: 'Se ha utilizado el lote. No se pudo eliminar el lote.',
      updateBatch: 'Lote ha sido actualizado',
      createBatch: 'Lote creado',
      createBatchError: 'El ID de lote ya existe. No se pudo crear un lote.',
      identificationCode: 'Se ha creado el código de identificación',
      validateIdentifierError: 'No se pudo validar un identificador',
      addFileError: 'Por favor agregue un archivo',
      genericError: 'Error generico',
      createValueChain: 'Se ha creado una cadena de valor',
      createValueChainError: 'No se pudo crear una cadena de valor',
      updateValueChain: 'Se ha actualizado la cadena de valor',
      updateValueChainError: 'No se pudo actualizar la cadena de valor',
      deleteValueChain: 'Se ha eliminado la cadena de valor',
      deleteValueChainError: 'No se pudo eliminar la cadena de valor',
      updateCategory: 'La categoría ha sido actualizada',
      updateCategoryError: 'No se pudo actualizar la categoría',
      deleteCategory: 'Se ha eliminado la categoría',
      deleteCategoryError: 'No se pudo eliminar la categoría',
      CreateCategory: 'Se ha creado la categoría',
      createCategoryError: 'No se pudo crear la categoría',
    },
    validation: {
      minNumMaxNum: 'Mín. {0} Máx. {1} caracteres',
      maxNum: '{0} caracteres como máximo',
    },
    uploadFile: {
      maxSize: '(Tamaño máximo 10 MB)',
    },
  },
  organization: {
    myOrganizations: 'Mis organizaciones',
    viewAllOrganizations: 'Ver todas las organizaciones',
  },
  interaction: {
    batchRecord: 'registro de lote',
    unknownId: 'ID DESCONOCIDO',
    externalId: 'DNI EXTERNO',
    modal: {
      eventClassification: 'Clasificación de eventos',
      unclassified: 'Desclasificado',
      default: 'Por defecto',
    },
    filters: {
      notice: 'Aviso',
      alert: 'Alerta',
      warning: 'Advertencia',
    },
    hidden: 'Oculto',
  },

  interaction_action_0: 'Usuario notificado:',
  interaction_action_1: 'El estado del artículo cambió a:',
  interaction_action_2: 'Se ha asignado el perfil de soporte del artículo.',
  interaction_action_3: 'Se eliminó el perfil de soporte del artículo.',
  interaction_action_4: 'Notificación:',
  interaction_action_5: 'Advertencia:',
  interaction_action_6: 'Alerta:',
  interaction_administrative_title: 'Cambio Administrativo',
  task_steps: {
    status_check: 'El estado actual del elemento es: {status}',
  },
  item_status: '{estado}',
  interaction_collections_title: 'Colección - {texto}',
  interaction_collection_added_title: 'Añadido a una colección - {texto}',
  step_collection_fields: 'Campos:',
  publicPage: {
    positioning: {
      before: 'Antes',
      after: 'Después',
      top: 'Cima',
      bottom: 'Fondo',
    },
    components: {
      longDescription: 'descripción larga',
      attachments: 'Archivos adjuntos',
      attributes: 'Atributos',
      services: 'Servicios',
      gallery: 'Galería',
      certifications: 'Certificaciones',
      video: 'Video',
      history: 'Historia',
      supportProfile: 'Perfil de soporte',
      fs1TaskPanel: 'Panel de tareas FS1',
      fs1About: 'FS1 Acerca de',
      fs1FactsPanel: 'Panel de datos de FS1',
      fs1ResourcesPanel: 'Panel de recursos FS1',
      events: 'Eventos',
    },
  },
  OrganisationalSettings: {
    header: 'Configuración de la organización',
    components: {
      general: 'General',
      branding: 'Marca',
    },
    general: {
      name: 'Nombre',
      businessType: 'Tipo de negocio',
      OrganizationLogo: 'Logotipo de la organización',
      PublicPageLoadingImage: 'Imagen de carga de página pública',
    },
  },
  tasks: {
    tableHeader: {
      name: 'Nombre de la tarea',
      usedAsDiscovery: 'Tarea de descubrimiento',
      interactions: 'Recuento de uso',
      things: 'Asignaciones',
      createdAt: 'Creado en',
      createdBy: 'Creado por',
      displayConditions: 'Condiciones de visualización',
    },
    taskModal: {
      headerCreate: 'Crear una tarea',
      headerEdit: 'Editar tarea',
      components: {
        selectTask: 'Seleccionar tarea',
      },
    },
  },
  categories: {
    title: 'Categoría',
    noDataMessage1: 'Crear una categoría',
    tableHeaders: {
      name: 'Nombre',
      defaultTemplate: 'Plantilla predeterminada',
      createdAt: 'Creado en',
      createdBy: 'Creado por',
    },
    editCategoryModal: {
      createCategory: 'Crear una categoría',
      editCategory: 'Editar una categoría',

      labels: {
        name: 'nombre de la categoría',
        standard: 'Norma de identificación',
        identifier: 'Identificador de categoría',
        description: 'Descripción',
        image: 'Imagen de categoría',
        defaultTemplate: 'Cada producto creado para esta categoría se establecerá en esta plantilla de forma predeterminada.',
      },
    },
  },
  supportProfile: {
    title: 'Perfil de soporte',
    addSupportProfile: 'Agregar perfil de soporte',
    createMessage1: 'Crear un perfil de soporte',
    tableHeaders: {
      name: 'Nombre',
      content: 'Contenido',
      displayConditions: 'Condiciones de visualización',
      contactMethods: 'Métodos de contacto',
    },
    editSupportProfileModal: {
      createSupportProfile: 'Crear perfil de soporte',
      editSupportProfile: 'Editar perfil de soporte',
      labels: {
        name: 'NOMBRE',
        headerText: 'TEXTO DE CABECERA',
        mainText: 'TEXTO PRINCIPAL',
        mainContent: 'CONTENIDO PRINCIPAL',
        showExpanded: 'MOSTRAR AMPLIADO',
        phone: 'TELÉFONO',
        email: 'EMAIL',
        imagery: 'IMÁGENES',
        primary: 'PRIMARIO',
        secondary: 'SECUNDARIO',
        kmlMapType: 'Tipo de mapa KML',
        kmlLink: 'Enlace KML',
        kmlFile: 'Archivo KML',
      },
    },
  },
  templates: {
    title: 'Plantilla',
    tableHeaders: {
      name: 'Nombre',
      createdAt: 'Creado en',
      createdBy: 'Creado por',
      assignedCategories: 'Categorías asignadas',
    },
    editTemplateModal: {
      createTemplate: 'crear una plantilla',
      editTemplate: 'Editar una plantilla',
      labels: {
        templateName: 'Nombre de la plantilla',
        templateDescription: 'Descripción de la plantilla',
        templateImage: 'Imagen de plantilla',
      },
    },
    templateCreatedModal: {
      title: 'Plantilla creada',
      message: 'Plantilla creada con éxito, ¿desea administrar ahora y agregar atributos, tareas, imágenes y más contenido?',
      later: 'Hazlo después',
      now: 'Administrar ahora',
    },
    templateCreatedProductModal: {
      title: 'Producto creado',
      message: 'Producto creado con éxito, ¿desea administrar ahora y agregar atributos, tareas, imágenes y más contenido?',
    },
  },
  user: {
    viewInvitations: 'Ver invitaciones',
    inviteUsers: 'Invitar usuarios',
    to: 'para',
    userHeaders: {
      name: 'Nombre',
      email: 'Correo electrónico',
      role: 'Papel',
      access: 'Acceso',
      status: 'Estado',
    },
    userType: {
      admin: 'Administración',
      member: 'Miembro',
    },
  },
  webhooks: {
    webhookHeaders: {
      name: 'Nombre',
      createdAt: 'Creado en',
      createdBy: 'Creado por',
    },
    webhookModal: {
      headerCreate: 'acción para comenzar a compartir las interacciones de su organización con los miembros del equipo, otros sistemas y terceros de confianza.',
      components: {
        emailSettings: 'Ajustes del correo electrónico',
        webhookSettings: 'Configuración de webhook',
        integrations: 'integraciones',
        thingsAndTasks: 'cosas y tareas',
      },
    },
    actions: 'Comportamiento',
    editAction: 'Editar acción',
  },
  valueChain: {
    headerCreate: 'Cadena de valor',
    headerEdit: 'Editar una cadena de valor',
    editAChainElement: 'Editar un elemento de cadena',
    createAChainElement: 'Crear un elemento de cadena',
    headerDescription: 'Seleccione una plantilla para ayudarlo a comenzar.',
    organisationActivity: 'Actividad de la organización',
    components: {
      miscellaneousContent: 'Contenido misceláneo',
      logisticsAndEnvironment: 'Logística y Medio Ambiente',
      generalInformation: 'Información general',
    },
    tableHeaders: {
      creationDate: 'Fecha de creación',
      createdBy: 'Creado por',
    },
    templates: {
      justTheBasics: {
        name: 'solo lo basico',
        description: 'La cadena de valor predeterminada utilizada en Assure+.',
      },
      beverage: {
        name: 'Bebida',
        description:
          'La capacidad de rastrear y rastrear productos de bebidas a lo largo de toda la cadena de suministro ahora está siendo impulsada por el consumidor. Quieren saber el origen de todos los productos hasta el campo donde se cultivan los ingredientes y las instalaciones que producen los materiales de apoyo y los suministros de empaque.',
      },
      coffee: {
        name: 'Café',
        description: 'Antes de llegar a su taza matutina, los granos de café viajan a través de una compleja cadena de suministro global.',
      },
      honey: {
        name: 'Cariño',
        description: 'La miel viaja a través de una compleja cadena de suministro global desde la colmena hasta el consumidor.',
      },
      porterValueChain: {
        name: 'Cadena de valor de Porter',
        description:
          'En lugar de mirar los departamentos o los tipos de costos contables, la cadena de valor de Porter se enfoca en los sistemas y cómo las entradas se transforman en las salidas compradas por los consumidores.',
      },
      usaidCommodity: {
        name: 'Producto USAid',
        description:
          'El proceso comienza cuando se realizan solicitudes de alimentos a USAID, que solicita ofertas de contratos y adquiere productos alimenticios para las solicitudes aprobadas. Luego, los productos se envían desde los proveedores a puntos de transporte o instalaciones de almacenamiento en diferentes nodos logísticos de la cadena de suministro, incluidos puertos de EE. UU., puertos de descarga en el extranjero y almacenes de posicionamiento previo de USAID en EE. transferido a organizaciones asociadas, que luego son responsables de distribuir los productos alimenticios utilizando sus propias redes de socios de transporte y distribución, así como sus propios sistemas de seguimiento de información.',
      },
      wine: {
        name: 'Vino',
        description:
          'La cadena de valor del vino consta de cinco amplias etapas de producción: preparación del viñedo, producción de uva (viticultura), producción de vino (vinicultura), logística y distribución, comercialización y ventas.',
      },
    },
  },
  documents: {
    title: 'Documentos',
    labels: {
      documentName: 'nombre del documento',
      gdtiNumber: 'Número GDTI',
      valueChainSteps: 'Paso de la cadena de valor',
      publicRecord: 'Registro Público',
      dateModified: 'Fecha modificada',
    },
    valueChainSteps: {
      Unclassified: 'Desclasificado',
    },
  },
  products: {
    tableHeaders: {
      name: 'Nombre',
      category: 'Categoría',
      tasks: 'Tareas asignadas',
      interactions: 'Eventos grabados',
      items: 'Elementos',
    },
    menuOptions: {
      manage: 'Administrar',
      duplicate: 'Duplicar',
      updateFromClipboard: 'Actualizar desde el Portapapeles',
    },
    bulkActions: {
      addTasks: 'Agregar tareas',
      removeTasks: 'Eliminar tareas',
      addValueChain: 'Agregar cadena de valor',
      addSupportProfile: 'Agregar perfil de soporte',
      removeSupportProfile: 'Eliminar perfil de soporte',
    },
    productCreatedModal: {
      title: 'Producto creado',
      message: 'Producto creado con éxito, ¿desea administrar ahora y agregar atributos, tareas, imágenes y más contenido?',
      later: 'Hazlo después',
      now: 'Administrar ahora',
    },
    title: 'Productos',
  },
  collaborations: {
    header: {
      step: 'Paso',
      title: 'Actualizar desde el Portapapeles',
    },
    subTitles: {
      general: 'General',
      productInfo: 'Información del Producto',
      attributes: 'Atributos',
      productDocuments: 'Documentos',
      itemAndBatchDocuments: 'Documentos de artículos y lotes',
      images: 'Imágenes',
      videos: 'Vídeos',
      eventHistory: 'Historial de eventos',
      contentTransferSummary: 'Resumen de transferencia de contenido',
    },
    descriptiveContent: {
      noData: 'No hay información general disponible.',
      description: 'La información general se transferirá a nivel de producto y se agregará a todos los lotes.',
      target: 'Objetivo',
      source: 'Fuente',
      labels: {
        name: 'Nombre',
        shortDescription: 'Breve descripción',
        longDescription: 'descripción larga',
      },
      longDescriptionButtons: {
        append: 'Adjuntar',
        replace: 'Reemplazar',
      },
    },
    attributes: {
      noData: 'No hay atributos disponibles.',
      description: 'Los atributos se transferirán a nivel de producto y se agregarán a todos los lotes.',
      warningMessage: 'Los atributos seleccionados se aplicarán a todos los lotes del producto',
    },
    itemAndBatchDocuments: {
      noData: 'No hay documentos disponibles.',
      warningMessage: 'Los documentos seleccionados se agregarán solo al artículo o lote de destino respectivo del producto',
    },
    images: {
      noData: 'No hay imágenes disponibles.',
      description: 'Las imágenes se transferirán a nivel de producto y se agregarán a todos los lotes.',
      selectAllButton: 'Seleccionar todo',
    },
    videos: {
      noData: 'No hay videos disponibles.',
      description: 'Los videos se transferirán a nivel de producto y se agregarán a todos los lotes.',
      selectAllButton: 'Seleccionar todo',
    },
    eventHistory: {
      noData: 'No hay tareas disponibles con contenido público.',
      noItemBatch: 'No hay ningún artículo o lote disponible para almacenar eventos.',
      labels: {
        name: 'Nombre',
        classification: 'Clasificación',
      },
    },
    confirmationScreen: {
      noData: 'Ningún contenido seleccionado.',
      message: 'Actualizando desde el Portapapeles...',
      successfulMessage: 'Actualización desde portapapeles exitosa',
    },
  },
  item: {
    filters: {
      doNotUse: 'No utilice',
      allClear: 'Todo claro',
      normalPrecaution: 'Precauciones normales',
      reconsiderUse: 'Reconsiderar el uso',
      increasedCaution: 'Mayor precaución',
      unknownId: 'identificación desconocida',
      itemOk: 'artículo bien',
      ready: 'Listo',
      active: 'Activo',
      resultPending: 'Resultado pendiente',
      completedPositive: 'Positivo Completado',
      completedNegative: 'Negativo Completado',
      completedInconclusive: 'Completado No concluyente',
      itemLocked: 'Artículo bloqueado',
      itemUnlocked: 'Artículo desbloqueado',
      unused: 'No usado',
      returned: 'devuelto',
      readyFilled: 'Listo Lleno',
      readyPacked: 'Listo Embalado',
      withCustomer: 'con el cliente',
      issueRaised: 'Problema planteado',
      pickupRequested: 'Recogida solicitada',
    },
  },
  thing: {
    menus: {
      about: 'Sobre',
      discovery: 'Descubrimiento',
      public: 'Público',
      tasks: 'Tareas',
      support: 'Apoyo',
    },
    about: {
      general: 'General',
      category: 'Categoría',
      documentation: 'Documentación',
      attributes: 'Atributos',
      certifications: 'Certificaciones',
      miscellaneous: 'Diverso',
      valueChains: 'Cadenas de valor',
      gallery: 'Galería',
      advancedSettings: 'Ajustes avanzados',
      changeACategory: 'Cambiar una categoría',
      changeAValueChain: 'Cambiar una cadena de valor',

      certificationsLabel: {
        addCertification: 'Agregar Certificación',
        editCertification: 'Editar certificación',
      },
    },
    public: {
      generalSettings: 'Configuración general',
      enablePublicContent: 'Habilitar contenido público',
      itemCheck: 'Comprobación de artículos',
      usedAsDiscovery: 'Tarea de descubrimiento',
      advancedSettings: 'Ajustes avanzados',
      rfiderAuthenticityCheck: 'Comprobación de autenticidad de RFID',
      basicInformation: 'Información básica',
      brandExperience: 'Experiencia de marca',
      links: 'Enlaces',
      miscellaneous: 'Diverso',
      attributes: 'Atributos',
      services: 'Servicios',
      documents: 'Documentos',
      gallery: 'Galería',
      certifications: 'Certificaciones',
      videos: 'Vídeos',
      longDescription: 'descripción larga',
      supportProfile: 'Perfil de soporte',
      itemHistory: 'Historial de artículos',
      none: 'Ninguna',
      heroImage: 'Imagen de héroe',
      sectionImage: 'Imagen de la sección',
      heroOverlayImage: 'Imagen superpuesta de héroe',
      privacyPolicy: 'Política de privacidad',
      productInfo: 'Información del producto',
      learnMore: 'Aprende más',
      shopping: 'Compras',
      section: 'Sección',
      position: 'Posición',
      enableAttributes: 'Habilitar atributos',
      attributeVisibility: 'Visibilidad de atributos',
      enableAttachments: 'Habilitar archivos adjuntos',
      enableCertifications: 'Habilitar certificaciones',
      allowProfles: 'Permitir los perfiles personales de los miembros del equipo',
      surviceLabels: {
        enableServices: 'Habilitar servicios',
        serviceVisibility: 'Visibilidad del servicio',
        basic: 'Básico',
        off: 'Apagado',
        peerToPeer: 'De igual a igual',
      },
      itemHistoryLabels: {
        enableItemHistory: 'Habilitar historial de elementos',
        displayTemplate: 'Plantilla de visualización',
        events: 'Eventos',
        data: 'Datos',
        images: 'Imágenes',
        eventGallery: 'Galería de eventos',
        enableEventGallery: 'Habilitar galería de eventos',
      },
      switch: {
        confirmTextEnable: '¿Estás seguro de que quieres habilitarlo?',
        confirmTextDisable: '¿Está seguro de que desea desactivar?',
        confirmButtonEnable: '¡Sí, habilítalo!',
        confirmButtonDisable: '¡Sí, deshabilítalo!',
      },
      enablePublicLink: 'Habilitar enlace público',
      attributeLabels: {
        editAttribute: 'Editar atributo',
        addAttribute: 'Agregar atributo',
        label: 'Etiqueta',
        value: 'Valor',
        type: 'Tipo',
      },
    },
  },
  filter: {
    createdByMe: 'Creado por mí',
  },
  swal: {
    duplicate: 'duplicando',
    confirmTitle: '¿Está seguro?',
    warningMessage: '¡No podrás revertir esto!',
    confirmButtonDelete: '¡Sí, bórralo!',
    confirmButtonRemove: '¡Sí, quítalo!',
    confirmButtonDuplicate: '¡Sí, duplícalo!',
    confirmButtonReset: '¡Sí, reinícialo!',
    confirmButtonResend: '¡Sí, reenvíalo!',
    confirmButtonDecline: '¡Sí, recházalo!',
    confirmButtonAccept: '¡Sí, acéptalo!',
    keepIt: 'No, quédatelo',
    userText: 'Tendrás que invitarlos de nuevo.',
    associationError: '¡No se pueden crear asociaciones!',
    template: {
      confirmButtonText: '¡Sí, crea un nuevo producto!',
      createProduct: 'Crear un producto',
      successMessage: 'Se ha creado un nuevo producto a partir de esta plantilla',
      errorMessage: 'No se puede crear un nuevo producto a partir de esta plantilla',
      successMessage1: 'La plantilla ha sido duplicada',
    },
    products: {
      successMessage: 'El producto ha sido duplicado',
      errorMessage: 'El producto no se puede duplicar',
      createProductError: 'No se puede crear un nuevo producto a partir de esta plantilla',
    },
    valueChain: {
      successMessage: 'La cadena de valor se ha duplicado',
      errorMessage: 'No se pudo duplicar la cadena de valor',
      successMessageThingAbout: 'Se ha cambiado la cadena de valor de este producto.',
      errorMessageThingAbout: 'No se pudo cambiar una cadena de valor para este producto',
      thingValueChainText: 'Recomendado que esto no se cambie.',
      lastChance: 'Última oportunidad',
      lastChanceText: 'Los datos recopilados hasta este punto no se mostrarán en la vista de la cadena de valor. Este cambio no se puede deshacer.',
    },
    user: {
      successMessage: 'Reenviar la invitación al usuario',
      errorMessage: 'No se pudo reenviar la invitación al usuario',
      successMessage1: 'Usuario actualizado',
      errorMessage1: 'No se pudo actualizar el usuario',
    },
    invitations: {
      titleDecline: '¿Rechazar la invitación?',
      titleAccept: '¿Aceptar la invitacion?',
      text: 'Estás invitado a unirte',
    },
    category: {
      successMessageThingAbout: 'Se ha cambiado la categoría de este producto.',
      errorMessageThingAbout: 'No se pudo cambiar una categoría para este producto',
      thingCategoryText: 'Recomendado que esto no se cambie.',
      lastChance: 'Última oportunidad',
      lastChanceText: 'Este cambio no se puede deshacer.',
    },
    document: {
      createDocument: 'El documento ha sido creado',
      createDocumentError: 'No se pudo crear un documento',
      updateDocument: 'El documento ha sido actualizado',
      updateDocumentError: 'No se pudo actualizar el documento',
      deleteDocument: 'El documento ha sido eliminado',
      deleteDocumentError: 'No se pudo eliminar el documento',
    },
    task: {
      text: 'Está a punto de salir de la configuración de tareas sin guardar los cambios. Todos los cambios no guardados se perderán. ¿Desea continuar?',
      deleteTask: 'La tarea ha sido eliminada',
      updateTask: 'La tarea ha sido actualizada',
      updateTaskError: 'No se pudo actualizar la tarea',
      createTask: 'Se ha creado la tarea',
      createTaskTitle: '¡Creado con éxito!',
      createTaskText: '¿Quieres abrir su configuración?',
      labels: {
        configuration: 'Configuración',
      },
    },
    interaction: {
      createSuccess: 'Interacción creada',
      createError: 'No se pudo crear una interacción.',
      mapLoadMessage: 'error de carga',
      mapLoadError: 'No se pudieron cargar las interacciones en el mapa',
      updateSuccess: 'La interacción ha sido actualizada',
      updateError: 'La interacción no se puede actualizar',
      deleteSuccess: 'Se ha eliminado la interacción.',
      deleteError: 'La interacción no se puede eliminar.',
    },
    item: {
      itemsLimitError: 'El límite para las opciones de entrada es solo {itemsLimit} elementos.',
      removeSupportProfile: {
        title: '¿Está seguro?',
        success: 'El perfil de soporte personal eliminado',
        error: 'No se pudo eliminar el perfil de soporte personal',
      },
    },
    miscellaneous: {
      noImageError: 'Agrega una imagen',
      undefinedNotificationType: 'Elija uno de los tipos de notificación',
    },
    webhooks: {
      deleteAction: 'La acción ha sido eliminada',
      deleteActionError: 'No se pudo eliminar la acción.',
      updateActionStatus: 'El estado de la acción ha sido actualizado',
      updateActionStatusError: 'No se pudo actualizar el estado de la acción',
      updateAction: 'La acción ha sido actualizada',
      updateActionError: 'No se pudo actualizar la acción.',
      createWebhook: 'Webhook creado',
    },
  },
  invitations: {
    title: 'Invitaciones',
    records: 'Registros',
  },
  languages: {
    english: 'English',
    german: 'Deutsch',
    spanish: 'Español',
    japanese: '日本',
    korean: '한국인',
    dutch: 'Nederlands',
    portuguese: 'Português',
    chinese: '中国人',
    chineseTraditional: '中國人',
  },
  editMiscellaneous: {
    editMiscellaneous: 'Editar varios',
    addMiscellaneous: 'Agregar varios',
    editVideo: 'Editar video',
    addVideo: 'Añadir video',
    editOptions: 'Editar opciones',
    addOptions: 'Añadir opciones',
    labels: {
      embeddedLink: 'SOLO ENLACE INTEGRADO',
      videoEmbed: 'CÓDIGO DE INSERCIÓN DE VÍDEO',
      codeInjection: 'Inyección de código',
      imageLink: 'ENLACE DE IMÁGEN',
    },
  },
  discovery: {
    labels: {
      batchId: 'ID del lote',
      batchName: 'Nombre del lote',
      createdBy: 'Creado por',
      itemCount: 'Recuento de elementos',
      used: '% Usado',
    },
    buttons: {
      identificationMethod: 'Método de identificación',
      createBatch: 'Crear lote',
      deactivate: 'Desactivar',
      activate: 'Activar',
      delete: 'Borrar',
    },
    maximumItemsPerBatch: 'Máximo de artículos por lote',
    batches: 'lotes',
    beta: 'Beta',
    item: 'Articulo',
    product: 'Producto',
    selectIdentificationMethod: 'Seleccionar método de identificación',
    message: 'El Assure+ Unique ID (RFUID) es un identificador generado por el sistema de artículos administrados en la plataforma Assure+.',
  },
  dashboard: {
    dateRange: {
      today: 'Hoy dia',
      lastSevenDays: 'Los últimos 7 días',
      lastWeek: 'La semana pasada',
      lastThirtyDays: 'últimos 30 días',
      lastMonth: 'El mes pasado',
      lastTwelvemonths: 'últimos 12 meses',
      custom: 'Personalizado',
    },
    chart: {
      today: 'Hoy dia',
      allTime: 'Todo el tiempo',
      totalInteractions: 'Interacciones totales',
      totalTasks: 'Tareas totales',
      totalServices: 'Servicios Totales',
      noData: 'Sin datos',
      overallStatusBreakdown: 'Desglose general del estado',
    },
  },
  displayConditionsRules: {
    onlyAdmin: 'Permisos de usuario',
    or: 'o',
    andAlso: 'Y también',
    addYourFirstCondition: 'Añade tu primera condición',
    useSeparator: 'Usar coma como separador',
    useDashSeparator: 'Usa el guión (-) como separador',
    rfuidItemId: 'Id. de artículo RFUID',
    rfuidBatchId: 'ID de lote RFUID',
    rfuidSequenceNumber: 'Número de secuencia RFUID',
    itemStatus: 'Estado del artículo',
    userCountry: 'País del usuario',
    userLanguage: 'Idioma del usuario',
    conditions: {
      stringEquals: 'cadena es igual',
      stringNotEquals: 'Cadena no es igual',
      includedIn: 'Incluido en',
      notIncludedIn: 'no incluido en',
      numberEquals: 'número es igual',
      numberNotEquals: 'número no es igual',
      numberGreaterThan: 'Número mayor que',
      numberLessThan: 'Número menor que',
      between: 'entre',
    },
    countries: {
      afghanistan: 'Afganistán',
      alandIslands: 'Islas Aland',
      albania: 'Albania',
      algeria: 'Argelia',
      americanSamoa: 'Samoa Americana',
      andorra: 'Andorra',
      angola: 'angola',
      anguilla: 'anguila',
      antarctica: 'Antártida',
      antigua: 'Antigua y Barbuda',
      argentina: 'Argentina',
      armenia: 'Armenia',
      aruba: 'Aruba',
      australia: 'Australia',
      austria: 'Austria',
      azerbaijan: 'Azerbaiyán',
      bahamas: 'bahamas',
      bahrain: 'Baréin',
      bangladesh: 'bangladesh',
      barbados: 'barbados',
      belarus: 'Bielorrusia',
      belgium: 'Bélgica',
      belize: 'Belice',
      benin: 'Benín',
      bermuda: 'islas Bermudas',
      bhutan: 'Bután',
      bolivia: 'Bolivia',
      bonaire: 'bonaire',
      bosnia: 'Bosnia y Herzegovina',
      botswana: 'Botsuana',
      bouvet: 'Isla Bouvet',
      brazil: 'Brasil',
      britishIndian: 'Territorio Británico del Océano Índico',
      brunei: 'Brunei Darussalam',
      bulgaria: 'Bulgaria',
      burkina: 'Burkina Faso',
      burundi: 'Burundi',
      cambodia: 'Camboya',
      cameroon: 'Camerún',
      canada: 'Canadá',
      capeVerde: 'Cabo Verde',
      caymanIslands: 'Islas Caimán',
      centralAfricanRepublic: 'República Centroafricana',
      chad: 'Chad',
      chile: 'Chile',
      china: 'China',
      christmasIsland: 'Isla de Navidad',
      cocos: 'Islas Cocos (Keeling)',
      colombia: 'Colombia',
      comoros: 'Comoras',
      congo: 'Congo',
      congoDemocratic: 'Congo',
      cookIslands: 'Islas Cook',
      costaRica: 'Costa Rica',
      coteIvo: 'Costa de Marfil',
      croatia: 'Croacia',
      cuba: 'Cuba',
      curacao: 'curazao',
      cyprus: 'Chipre',
      czechRepublic: 'Republica checa',
      denmark: 'Dinamarca',
      djibouti: 'Yibuti',
      dominica: 'Dominica',
      dominicanRepublic: 'República Dominicana',
      ecuador: 'Ecuador',
      egypt: 'Egipto',
      elSalvador: 'El Salvador',
      equatorialGuinea: 'Guinea Ecuatorial',
      eritrea: 'Eritrea',
      estonia: 'Estonia',
      ethiopia: 'Etiopía',
      falkland: 'Islas Malvinas (Malvinas)',
      faroeIslands: 'Islas Faroe',
      fiji: 'Fiyi',
      finland: 'Finlandia',
      france: 'Francia',
      frenchGuiana: 'Guayana Francesa',
      frenchPolynesia: 'Polinesia francés',
      frenchSouthern: 'Territorios Franceses del Sur',
      gabon: 'Gabón',
      gambia: 'Gambia',
      georgia: 'Georgia',
      germany: 'Alemania',
      ghana: 'Ghana',
      gibraltar: 'Gibraltar',
      greece: 'Grecia',
      greenland: 'Groenlandia',
      grenada: 'Granada',
      guadeloupe: 'Guadalupe',
      guam: 'Guam',
      guatemala: 'Guatemala',
      guernsey: 'Guernesey',
      guinea: 'Guinea',
      guineaBissau: 'Guinea-Bisáu',
      guyana: 'Guayana',
      haiti: 'Haití',
      heardIsland: 'Islas Heard y McDonald',
      holySee: 'Santa Sede (Estado de la Ciudad del Vaticano)',
      honduras: 'Honduras',
      hongKong: 'Hong Kong',
      hungary: 'Hungría',
      iceland: 'Islandia',
      india: 'India',
      indonesia: 'Indonesia',
      iran: 'Irán',
      iraq: 'Irak',
      ireland: 'Irlanda',
      isleOfMan: 'Isla del hombre',
      israel: 'Israel',
      italy: 'Italia',
      jamaica: 'Jamaica',
      japan: 'Japón',
      jersey: 'Jersey',
      jordan: 'Jordán',
      kazakhstan: 'Kazajistán',
      kenya: 'Kenia',
      kiribati: 'Kiribati',
      korea: 'Corea',
      koreaRepublic: 'Corea',
      kuwait: 'Kuwait',
      kyrgyzstan: 'Kirguistán',
      lao: 'Labor',
      latvia: 'letonia',
      lebanon: 'Líbano',
      lesotho: 'Lesoto',
      liberia: 'Liberia',
      libya: 'Libia',
      liechtenstein: 'Liechtenstein',
      lithuania: 'Lituania',
      luxembourg: 'luxemburgo',
      macao: 'Macao',
      macedonia: 'macedonia',
      madagascar: 'Madagascar',
      malawi: 'Malaui',
      malaysia: 'Malasia',
      maldives: 'Maldivas',
      mali: 'Malí',
      malta: 'Malta',
      marshall: 'Islas Marshall',
      martinique: 'Martinica',
      mauritania: 'Mauritania',
      mauritius: 'Mauricio',
      mayotte: 'Mayotte',
      mexico: 'México',
      micronesia: 'Micronesia',
      moldova: 'Moldavia',
      monaco: 'Mónaco',
      mongolia: 'Mongolia',
      montenegro: 'montenegro',
      montserrat: 'Montserrat',
      morocco: 'Marruecos',
      mozambique: 'Mozambique',
      myanmar: 'Birmania',
      namibia: 'Namibia',
      nauru: 'Nauru',
      nepal: 'Nepal',
      netherlands: 'Países Bajos',
      newCaledonia: 'Nueva Caledonia',
      newZealand: 'Nueva Zelanda',
      nicaragua: 'Nicaragua',
      niger: 'Níger',
      nigeria: 'Nigeria',
      niue: 'Niue',
      norfolk: 'Isla Norfolk',
      northern: 'Islas Marianas del Norte',
      norway: 'Noruega',
      oman: 'Omán',
      pakistan: 'Pakistán',
      palau: 'palaos',
      palestinian: 'Territorio Palestino, Ocupado',
      panama: 'Panamá',
      papua: 'Papúa Nueva Guinea',
      paraguay: 'Paraguay',
      peru: 'Perú',
      philippines: 'Filipinas',
      pitcairn: 'pitcairn',
      poland: 'Polonia',
      portugal: 'Portugal',
      puertoRico: 'Puerto Rico',
      qatar: 'Katar',
      reunion: 'Reunión',
      romania: 'Rumania',
      russian: 'Federación Rusa',
      rwanda: 'Ruanda',
      saintBarth: 'San Bartolomé',
      saintHelena: 'Santa Elena',
      saintKitts: 'San Cristóbal y Nieves',
      saintLucia: 'Santa Lucía',
      saintMartin: 'San Martín (parte francesa)',
      saintPierre: 'San Pedro y Miquelón',
      saintVincent: 'San Vicente y las Granadinas',
      samoa: 'samoa',
      sanMarino: 'San Marino',
      saoTome: 'Santo Tomé y Príncipe',
      saudiArabia: 'Arabia Saudita',
      senegal: 'Senegal',
      serbia: 'Serbia',
      seychelles: 'Seychelles',
      sierra: 'Sierra Leone',
      singapore: 'Singapur',
      sintMaarten: 'Sint Maarten (parte holandesa)',
      slovakia: 'Eslovaquia',
      slovenia: 'Eslovenia',
      solomon: 'Islas Salomón',
      somalia: 'Somalia',
      southAfrica: 'Sudáfrica',
      southGeorgia: 'Georgia del sur y las islas Sandwich del sur',
      southSudan: 'Sudán del Sur',
      spain: 'España',
      sriLanka: 'Sri Lanka',
      sudan: 'Sudán',
      suriname: 'Surinam',
      svalbard: 'Svalbard y Jan Mayen',
      swaziland: 'Suazilandia',
      sweden: 'Suecia',
      switzerland: 'Suiza',
      syrian: 'República Árabe Siria',
      taiwan: 'Taiwán',
      tajikistan: 'Tayikistán',
      tanzania: 'Tanzania',
      thailand: 'Tailandia',
      timorLeste: 'Timor Oriental',
      togo: 'Ir',
      tokelau: 'Tokelau',
      tonga: 'tonga',
      trinidad: 'Trinidad y Tobago',
      tunisia: 'Túnez',
      turkey: 'pavo',
      turkmenistan: 'turkmenistán',
      turksAndCaicosIslands: 'Islas Turcas y Caicos',
      tuvalu: 'Tuvalu',
      uganda: 'Uganda',
      ukraine: 'Ucrania',
      uae: 'Emiratos Árabes Unidos',
      uk: 'Reino Unido',
      usa: 'Estados Unidos',
      usMinor: 'Islas ultramarinas menores de los Estados Unidos',
      uruguay: 'Uruguay',
      uzbekistan: 'Uzbekistán',
      vanuatu: 'Vanuatu',
      venezuelaBolivarian: 'Venezuela',
      vietnam: 'Vietnam',
      virginIslandsBritish: 'Islas Virgenes',
      virginIslandsUS: 'Islas Virgenes',
      wallisAndFutuna: 'Wallis y Futuna',
      westernSahara: 'Sahara Occidental',
      yemen: 'Yemen',
      zambia: 'Zambia',
      zimbabwe: 'Zimbabue',
    },
    languages: {
      ab: 'abjasio',
      aa: 'Lejos',
      af: 'africaans',
      ak: 'Voluntad',
      sq: 'albanés',
      am: 'amárico',
      ar: 'Arábica',
      an: 'aragonés',
      hy: 'armenio',
      as: 'asamés',
      av: 'avárico',
      ae: 'avéstico',
      ay: 'Aymara',
      az: 'azerbaiyano',
      bm: 'Bámbara',
      ba: 'Bashkir',
      eu: 'vasco',
      be: 'bielorruso',
      bn: 'bengalí',
      bh: 'Idiomas bihari',
      bi: 'Bislama',
      bs: 'bosnio',
      br: 'bretón',
      bg: 'búlgaro',
      my: 'birmano',
      ca: 'Catalán; valenciano',
      km: 'jemer central',
      ch: 'Chamorro',
      ce: 'checheno',
      ny: 'chichewa; masticar; El mar',
      zhCn: 'Chino simplificado)',
      zhTw: 'Chino tradicional)',
      cu: 'Iglesia eslava',
      cv: 'chuvasio',
      kw: 'de Cornualles',
      co: 'corso',
      cr: 'Cree',
      hr: 'croata',
      cs: 'checo',
      da: 'danés',
      dv: 'buceohi',
      nl: 'Holandés; flamenco',
      dz: 'Dzongkha',
      en: 'inglés',
      eo: 'esperanto',
      et: 'estonio',
      ee: 'Oveja',
      fo: 'feroés',
      fj: 'fiyiano',
      fi: 'finlandés',
      fr: 'francés',
      ff: 'Fula',
      gd: 'gaélico',
      gl: 'gallego',
      lg: 'Doble',
      ka: 'georgiano',
      de: 'alemán',
      el: 'griego',
      gn: 'guaraní',
      gu: 'guyaratí',
      ht: 'haitiano',
      ha: 'Hausa',
      he: 'hebreo',
      hz: 'Herero',
      hi: 'No.',
      ho: 'Hiri motu',
      hu: 'húngaro',
      is: 'islandés',
      io: 'Ido',
      ig: 'igbo',
      id: 'indonesio',
      iu: 'Inuktitut',
      ik: 'Inupiaq',
      ga: 'irlandesa',
      it: 'italiano',
      ja: 'japonés',
      jv: 'javanés',
      kl: 'groenlandés',
      kn: 'Canadá',
      kr: 'kanuri',
      ks: 'Cachemira',
      kk: 'kazajo',
      ki: 'kikuyú; Gikuyu',
      rw: 'Kinyarwanda',
      ky: 'kirguís; kirguís',
      kv: 'Vamos',
      kg: 'Congo',
      ko: 'coreano',
      kj: 'animales; En la carne',
      ku: 'kurdo',
      lo: 'Labor',
      la: 'latín',
      lv: 'letón',
      li: 'Limburgo',
      ln: 'lingala',
      lt: 'lituano',
      lu: 'Luba-Katanga',
      lb: 'luxemburgués',
      mk: 'macedónio',
      mg: 'madagascarí',
      ms: 'malayo',
      ml: 'Malayalam',
      mt: 'maltés',
      gv: 'de la isla de Man',
      mi: 'maorí',
      mr: 'marathi',
      mh: 'marshalés',
      mn: 'mongol',
      na: 'Nauru',
      nv: 'navajo; navajo',
      ng: 'Ndonga',
      ne: 'nepalí',
      se: 'Sami del norte',
      no: 'noruego',
      oj: 'Ojibwa',
      or: 'Oriya',
      om: 'Oromo',
      os: 'osetio; osético',
      pi: 'Pali',
      pa: 'Punjabi; punjabi',
      fa: 'persa',
      pl: 'polaco',
      ptBr: 'portugués',
      ps: 'Pushto; pastún',
      qu: 'quechua',
      ro: 'rumano',
      rm: 'romanche',
      rn: 'Rondas',
      ru: 'ruso',
      sm: 'samoano',
      sg: 'sango',
      sa: 'sánscrito',
      sc: 'sardo',
      sr: 'serbio',
      sn: 'Shona',
      ii: 'Sichuan Yi, Nuosu',
      sd: 'Sindhi',
      si: 'cingalés; cingalés',
      sk: 'eslovaco',
      sl: 'esloveno',
      so: 'somalí',
      st: 'Sotho, Sur',
      es: 'español',
      su: 'sundanés',
      sw: 'swahili',
      ss: 'swati',
      sv: 'sueco',
      tl: 'tagalo',
      ty: 'tahitiano',
      tg: 'tayiko',
      ta: 'tamil',
      tt: 'tártaro',
      te: 'telugu',
      th: 'tailandés',
      bo: 'tibetano',
      ti: 'tigrinya',
      to: 'tonga',
      ts: 'Tsonga',
      tn: 'Tswana',
      tr: 'turco',
      tk: 'turkmeno',
      tw: 'Dos',
      ug: 'uigur; uigur',
      uk: 'ucranio',
      ur: 'urdu',
      uz: 'uzbeco',
      ve: 'Rebaja',
      vi: 'vietnamita',
      vo: 'Volapük',
      wa: 'valón',
      cy: 'galés',
      fy: 'frisón occidental',
      wo: 'Wolof',
      xh: 'Xhosa',
      yi: 'yídish',
      yo: 'yoruba',
      za: 'Zhuang; Chuang',
      zu: 'zulú',
    },
  },
  itemSummary: {
    title: 'Información de código de artículo',
    batchId: 'ID del lote',
    product: 'Producto',
    sequence: 'Secuencia',
    checksum: 'Suma de comprobación',
    warning:
      'El control de autenticidad está activado para este artículo. Acceder desde el portal puede desencadenar protocolos de alerta de autenticidad, como actualización de estado y advertencia en escaneos posteriores.',
    hideUrl: 'ocultar URL',
    showUrl: 'Mostrar URL',
    copy: 'Dupdo',
    itemUrlCopied: 'Se copió la URL del artículo.',
    productName: 'Nombre del producto',
    itemId: 'Identificación del artículo',
    itemStatus: 'Estado del artículo',
    itemName: 'Nombre del árticulo',
    batchName: 'Nombre del lote',
  },
  updateReportedData: {
    locationTitle: 'Ubicación',
    dateTimeTitle: 'Fecha y hora',
    dateTimeInteraction: 'Fecha de interacción',
    dateTimeReported: 'Fecha informada',
    reset: 'Reiniciar',
    locationInteraction: 'Ubicación de interacción',
    locationReported: 'Ubicación reportada',
    reportedData: 'Datos informados',
  },
};
export default es;
