const state = {
  openThingHeader: false,
  openNewThingPanel: false,
  thingStatuses: [
    {
      value: 0,
      name: 'Live',
    },
    {
      value: 1,
      name: 'Archived',
    },
  ],
};

const mutations = {
  SET_THING_HEADER_OPEN(state: any) {
    state.openThingHeader = true;
  },
  SET_NEW_THING_PANEL(state: any) {
    state.openNewThingPanel = true;
  },
  SET_THING_HEADER_CLOSE(state: any) {
    state.openThingHeader = false;
  },
};

const actions = {
  openThingHeader(context: any) {
    context.commit('SET_THING_HEADER_OPEN');
  },
  openNewThingPanel(context: any) {
    context.commit('SET_NEW_THING_PANEL');
  },
  closeThingHeader(context: any) {
    context.commit('SET_THING_HEADER_CLOSE');
  },
};

const getters = {};

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
