import { AxiosRequestConfig } from 'axios';
import settings from '@/settings';
import store from '@/store';
import { getJwtClaims } from '../../utils/jwt';

const middleware = (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  if (config.headers.Authorization) {
    return Promise.resolve(config);
  }

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let token = store.getters['auth/jwt'];
    let orgJwt: string = (store.state as any).auth.orgJwt;

    if (orgJwt) {
      const claims = getJwtClaims(orgJwt);
      if (claims.exp - Math.floor(new Date().getTime() / 1000) < 30) {
        orgJwt = await store.dispatch('auth/setOrgJWT', {
          orgId: claims.orgId,
        });
      }
      token = orgJwt;
    }

    if (token) config.headers.Authorization = `Bearer ${token}`;
    resolve(config);
  });
};

export default middleware;
