<template>
  <div>
    <task-run v-if="token" :token="token" />
  </div>
</template>

<script>
import TaskRun from '@/components/Organization/Task/RunTasks';

export default {
  metaInfo: {
    meta: [
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1, maximum-scale=1',
      },
    ],
  },
  components: {
    TaskRun,
  },
  props: ['token'],
};
</script>
