export const componentInfoFragment = `
id
enabled
type
subType
name
ruleConditions
order
displayType
component
thingId
valueChainElementId
collectionsInfo
createdAt
`;
