import Vue from 'vue';

const state = {
  hasNewVersion: false,
};

const mutations = {
  SET_VERSION(state: any, payload: any) {
    Vue.set(state, 'hasNewVersion', payload);
  },
};

const actions = {
  setNewVersion(context: any, payload: any) {
    context.commit('SET_VERSION', payload);
  },
};

const getters = {};

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
