export const thingInfoFragment = `
id
organizationId
organizationName
organizationImage
attributes
name
description
image
createdAt
inheritedId
inheritedMode
categoryId
`;
