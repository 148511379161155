import { VueConstructor } from 'vue';
import { directive as vClickOutside } from 'vue-clickaway';
import lazyReload from '@/directives/lazyReload';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue: VueConstructor) {
    Vue.directive('click-outside', vClickOutside);
    Vue.directive('lazy-reload', lazyReload);
  },
};

export default GlobalDirectives;
