<template>
  <div class="text-align--center invite">
    <!-- <qr-code v-if="inviteUrl" :text="inviteUrl" :size="codeSize" errorLevel="M" class="qr-code"> </qr-code> -->
  </div>
</template>

<script>
import { http } from '@/api/http/index';
import { executionApi } from '@/api/http/api';
// import qrCode from 'vue-qrcode-component';

export default {
  // components: {
  //   qrCode,
  // },
  props: {
    executionId: {
      type: String,
      required: true,
    },
    executionToken: {
      type: String,
      required: true,
    },
    issuerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      refreshInterval: null,
      inviteUrl: null,
      codeSize: 200,
    };
  },
  mounted() {
    this.codeSize = Math.min(this.$el.clientWidth, this.$el.clientHeight) - 40;
    this.create();
  },
  beforeDestroy: function () {
    clearInterval(this.refreshInterval);
  },
  methods: {
    async create() {
      await http
        .post(
          '/integrations/evernym/invite',
          { issuerId: this.issuerId },
          {
            headers: {
              Authorization: `Bearer ${this.executionToken}`,
            },
          },
        )
        .then(res => {
          this.inviteUrl = res.data.inviteUrl;

          this.refreshInterval = setInterval(() => {
            this.checkState();
          }, 3000);
        });
    },
    async checkState() {
      const taskData = (await executionApi.get(this.executionToken, this.executionId)).data;
      if (taskData.executionData.widget_1_relationshipID) {
        this.$emit('on-submit');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.invite {
  width: 100%;
  height: 100%;
}

.qr-code {
  display: inline-block;
}
</style>
