import Vue from 'vue';
import { GraphqlFastQuery } from '@/api/graphql';
import { query as getNotificationsQuery } from '@/graphql/getNotifications';
import { IDtoCollection, IDtoNotification } from '@/store/dto';

type stateDTO = IDtoCollection<IDtoNotification> | null;

const state: { notifications: stateDTO } = {
  notifications: null,
};

const mutations = {
  SET_NOTIFICATIONS: function (state: { notifications: stateDTO }, payload: any) {
    Vue.set(state, 'notifications', payload);
  },
};

const actions = {
  getNotifications: async function (context: any, payload: any) {
    try {
      const response = await GraphqlFastQuery(getNotificationsQuery, {
        variables: {
          organizationId: String(payload.organizationId),
          page: payload.page || null,
          perPage: payload.perPage || null,
        },
      });
      context.commit('SET_NOTIFICATIONS', response.data.notifications);
    } catch (e) {
      console.log(e);
    }
  },
};

const getters = {
  notificationsInfo: function (state: { notifications: stateDTO }, getters: any) {
    return state.notifications;
  },
};

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
