import i18n from '@/i18n';

interface IState {
  properties: any;
  conditions: any;
}

const state: IState = {
  properties: [
    {
      value: 'onlyAdmin',
      name: 'displayConditionsRules.onlyAdmin',
      allowedConditions: [0],
      option: [
        {
          value: 'YES',
          name: 'user.userType.admin',
        },
      ],
    },
    {
      value: 'itemId',
      name: 'displayConditionsRules.rfuidItemId',
      allowedConditions: [0, 1, 2, 3],
    },
    {
      value: 'batchId',
      name: 'displayConditionsRules.rfuidBatchId',
      allowedConditions: [2, 3],
    },
    {
      value: 'rfiderSequenceNumber',
      name: 'displayConditionsRules.rfuidSequenceNumber',
      allowedConditions: [4, 5, 6, 7, 8],
    },
    {
      value: 'itemStatus',
      name: 'displayConditionsRules.itemStatus',
      allowedConditions: [2, 3],
      option: [],
    },
    Object.freeze({
      value: 'country',
      name: 'displayConditionsRules.userCountry',
      allowedConditions: [2, 3],
      option: [
        {
          value: 'AF',
          name: 'displayConditionsRules.countries.afghanistan',
        },
        {
          value: 'AX',
          name: 'displayConditionsRules.countries.alandIslands',
        },
        {
          value: 'AL',
          name: 'displayConditionsRules.countries.albania',
        },
        {
          value: 'DZ',
          name: 'displayConditionsRules.countries.algeria',
        },
        {
          value: 'AS',
          name: 'displayConditionsRules.countries.americanSamoa',
        },
        {
          value: 'AD',
          name: 'displayConditionsRules.countries.andorra',
        },
        {
          value: 'AO',
          name: 'displayConditionsRules.countries.angola',
        },
        {
          value: 'AI',
          name: 'displayConditionsRules.countries.anguilla',
        },
        {
          value: 'AQ',
          name: 'displayConditionsRules.countries.antarctica',
        },
        {
          value: 'AG',
          name: 'displayConditionsRules.countries.antigua',
        },
        {
          value: 'AR',
          name: 'displayConditionsRules.countries.argentina',
        },
        {
          value: 'AM',
          name: 'displayConditionsRules.countries.armenia',
        },
        { value: 'AW', name: 'displayConditionsRules.countries.aruba' },
        {
          value: 'AU',
          name: 'displayConditionsRules.countries.australia',
        },
        {
          value: 'AT',
          name: 'displayConditionsRules.countries.austria',
        },
        {
          value: 'AZ',
          name: 'displayConditionsRules.countries.azerbaijan',
        },
        {
          value: 'BS',
          name: 'displayConditionsRules.countries.bahamas',
        },
        {
          value: 'BH',
          name: 'displayConditionsRules.countries.bahrain',
        },
        {
          value: 'BD',
          name: 'displayConditionsRules.countries.bangladesh',
        },
        {
          value: 'BB',
          name: 'displayConditionsRules.countries.barbados',
        },
        {
          value: 'BY',
          name: 'displayConditionsRules.countries.belarus',
        },
        {
          value: 'BE',
          name: 'displayConditionsRules.countries.belgium',
        },
        {
          value: 'BZ',
          name: 'displayConditionsRules.countries.belize',
        },
        { value: 'BJ', name: 'displayConditionsRules.countries.benin' },
        {
          value: 'BM',
          name: 'displayConditionsRules.countries.bermuda',
        },
        {
          value: 'BT',
          name: 'displayConditionsRules.countries.bhutan',
        },
        {
          value: 'BO',
          name: 'displayConditionsRules.countries.bolivia',
        },
        {
          value: 'BQ',
          name: 'displayConditionsRules.countries.bonaire',
        },
        {
          value: 'BA',
          name: 'displayConditionsRules.countries.bosnia',
        },
        {
          value: 'BW',
          name: 'displayConditionsRules.countries.botswana',
        },
        {
          value: 'BV',
          name: 'displayConditionsRules.countries.bouvet',
        },
        {
          value: 'BR',
          name: 'displayConditionsRules.countries.brazil',
        },
        {
          value: 'IO',
          name: 'displayConditionsRules.countries.britishIndian',
        },
        {
          value: 'BN',
          name: 'displayConditionsRules.countries.brunei',
        },
        {
          value: 'BG',
          name: 'displayConditionsRules.countries.bulgaria',
        },
        {
          value: 'BF',
          name: 'displayConditionsRules.countries.burkina',
        },
        {
          value: 'BI',
          name: 'displayConditionsRules.countries.burundi',
        },
        {
          value: 'KH',
          name: 'displayConditionsRules.countries.cambodia',
        },
        {
          value: 'CM',
          name: 'displayConditionsRules.countries.cameroon',
        },
        {
          value: 'CA',
          name: 'displayConditionsRules.countries.canada',
        },
        {
          value: 'CV',
          name: 'displayConditionsRules.countries.capeVerde',
        },
        {
          value: 'KY',
          name: 'displayConditionsRules.countries.caymanIslands',
        },
        {
          value: 'CF',
          name: 'displayConditionsRules.countries.centralAfricanRepublic',
        },
        { value: 'TD', name: 'displayConditionsRules.countries.chad' },
        { value: 'CL', name: 'displayConditionsRules.countries.chile' },
        { value: 'CN', name: 'displayConditionsRules.countries.china' },
        {
          value: 'CX',
          name: 'displayConditionsRules.countries.christmasIsland',
        },
        { value: 'CC', name: 'displayConditionsRules.countries.cocos' },
        {
          value: 'CO',
          name: 'displayConditionsRules.countries.colombia',
        },
        {
          value: 'KM',
          name: 'displayConditionsRules.countries.comoros',
        },
        { value: 'CG', name: 'displayConditionsRules.countries.congo' },
        {
          value: 'CD',
          name: 'displayConditionsRules.countries.congoDemocratic',
        },
        {
          value: 'CK',
          name: 'displayConditionsRules.countries.cookIslands',
        },
        {
          value: 'CR',
          name: 'displayConditionsRules.countries.costaRica',
        },
        {
          value: 'CI',
          name: 'displayConditionsRules.countries.coteIvo',
        },
        {
          value: 'HR',
          name: 'displayConditionsRules.countries.croatia',
        },
        { value: 'CU', name: 'displayConditionsRules.countries.cuba' },
        {
          value: 'CW',
          name: 'displayConditionsRules.countries.curacao',
        },
        {
          value: 'CY',
          name: 'displayConditionsRules.countries.cyprus',
        },
        {
          value: 'CZ',
          name: 'displayConditionsRules.countries.czechRepublic',
        },
        {
          value: 'DK',
          name: 'displayConditionsRules.countries.denmark',
        },
        {
          value: 'DJ',
          name: 'displayConditionsRules.countries.djibouti',
        },
        {
          value: 'DM',
          name: 'displayConditionsRules.countries.dominica',
        },
        {
          value: 'DO',
          name: 'displayConditionsRules.countries.dominicanRepublic',
        },
        {
          value: 'EC',
          name: 'displayConditionsRules.countries.ecuador',
        },
        { value: 'EG', name: 'displayConditionsRules.countries.egypt' },
        {
          value: 'SV',
          name: 'displayConditionsRules.countries.elSalvador',
        },
        {
          value: 'GQ',
          name: 'displayConditionsRules.countries.equatorialGuinea',
        },
        {
          value: 'ER',
          name: 'displayConditionsRules.countries.eritrea',
        },
        {
          value: 'EE',
          name: 'displayConditionsRules.countries.estonia',
        },
        {
          value: 'ET',
          name: 'displayConditionsRules.countries.ethiopia',
        },
        {
          value: 'FK',
          name: 'displayConditionsRules.countries.falkland',
        },
        {
          value: 'FO',
          name: 'displayConditionsRules.countries.faroeIslands',
        },
        { value: 'FJ', name: 'displayConditionsRules.countries.fiji' },
        {
          value: 'FI',
          name: 'displayConditionsRules.countries.finland',
        },
        {
          value: 'FR',
          name: 'displayConditionsRules.countries.france',
        },
        {
          value: 'GF',
          name: 'displayConditionsRules.countries.frenchGuiana',
        },
        {
          value: 'PF',
          name: 'displayConditionsRules.countries.frenchPolynesia',
        },
        {
          value: 'TF',
          name: 'displayConditionsRules.countries.frenchSouthern',
        },
        { value: 'GA', name: 'displayConditionsRules.countries.gabon' },
        {
          value: 'GM',
          name: 'displayConditionsRules.countries.gambia',
        },
        {
          value: 'GE',
          name: 'displayConditionsRules.countries.georgia',
        },
        {
          value: 'DE',
          name: 'displayConditionsRules.countries.germany',
        },
        { value: 'GH', name: 'displayConditionsRules.countries.ghana' },
        {
          value: 'GI',
          name: 'displayConditionsRules.countries.gibraltar',
        },
        {
          value: 'GR',
          name: 'displayConditionsRules.countries.greece',
        },
        {
          value: 'GL',
          name: 'displayConditionsRules.countries.greenland',
        },
        {
          value: 'GD',
          name: 'displayConditionsRules.countries.grenada',
        },
        {
          value: 'GP',
          name: 'displayConditionsRules.countries.guadeloupe',
        },
        { value: 'GU', name: 'displayConditionsRules.countries.guam' },
        {
          value: 'GT',
          name: 'displayConditionsRules.countries.guatemala',
        },
        {
          value: 'GG',
          name: 'displayConditionsRules.countries.guernsey',
        },
        {
          value: 'GN',
          name: 'displayConditionsRules.countries.guinea',
        },
        {
          value: 'GW',
          name: 'displayConditionsRules.countries.guineaBissau',
        },
        {
          value: 'GY',
          name: 'displayConditionsRules.countries.guyana',
        },
        { value: 'HT', name: 'displayConditionsRules.countries.haiti' },
        {
          value: 'HM',
          name: 'displayConditionsRules.countries.heardIsland',
        },
        {
          value: 'VA',
          name: 'displayConditionsRules.countries.holySee',
        },
        {
          value: 'HN',
          name: 'displayConditionsRules.countries.honduras',
        },
        {
          value: 'HK',
          name: 'displayConditionsRules.countries.hongKong',
        },
        {
          value: 'HU',
          name: 'displayConditionsRules.countries.hungary',
        },
        {
          value: 'IS',
          name: 'displayConditionsRules.countries.iceland',
        },
        { value: 'IN', name: 'displayConditionsRules.countries.india' },
        {
          value: 'ID',
          name: 'displayConditionsRules.countries.indonesia',
        },
        { value: 'IR', name: 'displayConditionsRules.countries.iran' },
        { value: 'IQ', name: 'displayConditionsRules.countries.iraq' },
        {
          value: 'IE',
          name: 'displayConditionsRules.countries.ireland',
        },
        {
          value: 'IM',
          name: 'displayConditionsRules.countries.isleOfMan',
        },
        {
          value: 'IL',
          name: 'displayConditionsRules.countries.israel',
        },
        { value: 'IT', name: 'displayConditionsRules.countries.italy' },
        {
          value: 'JM',
          name: 'displayConditionsRules.countries.jamaica',
        },
        { value: 'JP', name: 'displayConditionsRules.countries.japan' },
        {
          value: 'JE',
          name: 'displayConditionsRules.countries.jersey',
        },
        {
          value: 'JO',
          name: 'displayConditionsRules.countries.jordan',
        },
        {
          value: 'KZ',
          name: 'displayConditionsRules.countries.kazakhstan',
        },
        { value: 'KE', name: 'displayConditionsRules.countries.kenya' },
        {
          value: 'KI',
          name: 'displayConditionsRules.countries.kiribati',
        },
        { value: 'KP', name: 'displayConditionsRules.countries.korea' },
        {
          value: 'KR',
          name: 'displayConditionsRules.countries.koreaRepublic',
        },
        {
          value: 'KW',
          name: 'displayConditionsRules.countries.kuwait',
        },
        {
          value: 'KG',
          name: 'displayConditionsRules.countries.kyrgyzstan',
        },
        { value: 'LA', name: 'displayConditionsRules.countries.lao' },
        {
          value: 'LV',
          name: 'displayConditionsRules.countries.latvia',
        },
        {
          value: 'LB',
          name: 'displayConditionsRules.countries.lebanon',
        },
        {
          value: 'LS',
          name: 'displayConditionsRules.countries.lesotho',
        },
        {
          value: 'LR',
          name: 'displayConditionsRules.countries.liberia',
        },
        { value: 'LY', name: 'displayConditionsRules.countries.libya' },
        {
          value: 'LI',
          name: 'displayConditionsRules.countries.liechtenstein',
        },
        {
          value: 'LT',
          name: 'displayConditionsRules.countries.lithuania',
        },
        {
          value: 'LU',
          name: 'displayConditionsRules.countries.luxembourg',
        },
        { value: 'MO', name: 'displayConditionsRules.countries.macao' },
        {
          value: 'MK',
          name: 'displayConditionsRules.countries.macedonia',
        },
        {
          value: 'MG',
          name: 'displayConditionsRules.countries.madagascar',
        },
        {
          value: 'MW',
          name: 'displayConditionsRules.countries.malawi',
        },
        {
          value: 'MY',
          name: 'displayConditionsRules.countries.malaysia',
        },
        {
          value: 'MV',
          name: 'displayConditionsRules.countries.maldives',
        },
        { value: 'ML', name: 'displayConditionsRules.countries.mali' },
        { value: 'MT', name: 'displayConditionsRules.countries.malta' },
        {
          value: 'MH',
          name: 'displayConditionsRules.countries.marshall',
        },
        {
          value: 'MQ',
          name: 'displayConditionsRules.countries.martinique',
        },
        {
          value: 'MR',
          name: 'displayConditionsRules.countries.mauritania',
        },
        {
          value: 'MU',
          name: 'displayConditionsRules.countries.mauritius',
        },
        {
          value: 'YT',
          name: 'displayConditionsRules.countries.mayotte',
        },
        {
          value: 'MX',
          name: 'displayConditionsRules.countries.mexico',
        },
        {
          value: 'FM',
          name: 'displayConditionsRules.countries.micronesia',
        },
        {
          value: 'MD',
          name: 'displayConditionsRules.countries.moldova',
        },
        {
          value: 'MC',
          name: 'displayConditionsRules.countries.monaco',
        },
        {
          value: 'MN',
          name: 'displayConditionsRules.countries.mongolia',
        },
        {
          value: 'ME',
          name: 'displayConditionsRules.countries.montenegro',
        },
        {
          value: 'MS',
          name: 'displayConditionsRules.countries.montserrat',
        },
        {
          value: 'MA',
          name: 'displayConditionsRules.countries.morocco',
        },
        {
          value: 'MZ',
          name: 'displayConditionsRules.countries.mozambique',
        },
        {
          value: 'MM',
          name: 'displayConditionsRules.countries.myanmar',
        },
        {
          value: 'NA',
          name: 'displayConditionsRules.countries.namibia',
        },
        { value: 'NR', name: 'displayConditionsRules.countries.nauru' },
        { value: 'NP', name: 'displayConditionsRules.countries.nepal' },
        {
          value: 'NL',
          name: 'displayConditionsRules.countries.netherlands',
        },
        {
          value: 'NC',
          name: 'displayConditionsRules.countries.newCaledonia',
        },
        {
          value: 'NZ',
          name: 'displayConditionsRules.countries.newZealand',
        },
        {
          value: 'NI',
          name: 'displayConditionsRules.countries.nicaragua',
        },
        { value: 'NE', name: 'displayConditionsRules.countries.niger' },
        {
          value: 'NG',
          name: 'displayConditionsRules.countries.nigeria',
        },
        { value: 'NU', name: 'displayConditionsRules.countries.niue' },
        {
          value: 'NF',
          name: 'displayConditionsRules.countries.norfolk',
        },
        {
          value: 'MP',
          name: 'displayConditionsRules.countries.northern',
        },
        {
          value: 'NO',
          name: 'displayConditionsRules.countries.norway',
        },
        { value: 'OM', name: 'displayConditionsRules.countries.oman' },
        {
          value: 'PK',
          name: 'displayConditionsRules.countries.pakistan',
        },
        { value: 'PW', name: 'displayConditionsRules.countries.palau' },
        {
          value: 'PS',
          name: 'displayConditionsRules.countries.palestinian',
        },
        {
          value: 'PA',
          name: 'displayConditionsRules.countries.panama',
        },
        { value: 'PG', name: 'displayConditionsRules.countries.papua' },
        {
          value: 'PY',
          name: 'displayConditionsRules.countries.paraguay',
        },
        { value: 'PE', name: 'displayConditionsRules.countries.peru' },
        {
          value: 'PH',
          name: 'displayConditionsRules.countries.philippines',
        },
        {
          value: 'PN',
          name: 'displayConditionsRules.countries.pitcairn',
        },
        {
          value: 'PL',
          name: 'displayConditionsRules.countries.poland',
        },
        {
          value: 'PT',
          name: 'displayConditionsRules.countries.portugal',
        },
        {
          value: 'PR',
          name: 'displayConditionsRules.countries.puertoRico',
        },
        { value: 'QA', name: 'displayConditionsRules.countries.qatar' },
        {
          value: 'RE',
          name: 'displayConditionsRules.countries.reunion',
        },
        {
          value: 'RO',
          name: 'displayConditionsRules.countries.romania',
        },
        {
          value: 'RU',
          name: 'displayConditionsRules.countries.russian',
        },
        {
          value: 'RW',
          name: 'displayConditionsRules.countries.rwanda',
        },
        {
          value: 'BL',
          name: 'displayConditionsRules.countries.saintBarth',
        },
        {
          value: 'SH',
          name: 'displayConditionsRules.countries.saintHelena',
        },
        {
          value: 'KN',
          name: 'displayConditionsRules.countries.saintKitts',
        },
        {
          value: 'LC',
          name: 'displayConditionsRules.countries.saintLucia',
        },
        {
          value: 'MF',
          name: 'displayConditionsRules.countries.saintMartin',
        },
        {
          value: 'PM',
          name: 'displayConditionsRules.countries.saintPierre',
        },
        {
          value: 'VC',
          name: 'displayConditionsRules.countries.saintVincent',
        },
        { value: 'WS', name: 'displayConditionsRules.countries.samoa' },
        {
          value: 'SM',
          name: 'displayConditionsRules.countries.sanMarino',
        },
        {
          value: 'ST',
          name: 'displayConditionsRules.countries.saoTome',
        },
        {
          value: 'SA',
          name: 'displayConditionsRules.countries.saudiArabia',
        },
        {
          value: 'SN',
          name: 'displayConditionsRules.countries.senegal',
        },
        {
          value: 'RS',
          name: 'displayConditionsRules.countries.serbia',
        },
        {
          value: 'SC',
          name: 'displayConditionsRules.countries.seychelles',
        },
        {
          value: 'SL',
          name: 'displayConditionsRules.countries.sierra',
        },
        {
          value: 'SG',
          name: 'displayConditionsRules.countries.singapore',
        },
        {
          value: 'SX',
          name: 'displayConditionsRules.countries.sintMaarten',
        },
        {
          value: 'SK',
          name: 'displayConditionsRules.countries.slovakia',
        },
        {
          value: 'SI',
          name: 'displayConditionsRules.countries.slovenia',
        },
        {
          value: 'SB',
          name: 'displayConditionsRules.countries.solomon',
        },
        {
          value: 'SO',
          name: 'displayConditionsRules.countries.somalia',
        },
        {
          value: 'ZA',
          name: 'displayConditionsRules.countries.southAfrica',
        },
        {
          value: 'GS',
          name: 'displayConditionsRules.countries.southGeorgia',
        },
        {
          value: 'SS',
          name: 'displayConditionsRules.countries.southSudan',
        },
        { value: 'ES', name: 'displayConditionsRules.countries.spain' },
        {
          value: 'LK',
          name: 'displayConditionsRules.countries.sriLanka',
        },
        { value: 'SD', name: 'displayConditionsRules.countries.sudan' },
        {
          value: 'SR',
          name: 'displayConditionsRules.countries.suriname',
        },
        {
          value: 'SJ',
          name: 'displayConditionsRules.countries.svalbard',
        },
        {
          value: 'SZ',
          name: 'displayConditionsRules.countries.swaziland',
        },
        {
          value: 'SE',
          name: 'displayConditionsRules.countries.sweden',
        },
        {
          value: 'CH',
          name: 'displayConditionsRules.countries.switzerland',
        },
        {
          value: 'SY',
          name: 'displayConditionsRules.countries.syrian',
        },
        {
          value: 'TW',
          name: 'displayConditionsRules.countries.taiwan',
        },
        {
          value: 'TJ',
          name: 'displayConditionsRules.countries.tajikistan',
        },
        {
          value: 'TZ',
          name: 'displayConditionsRules.countries.tanzania',
        },
        {
          value: 'TH',
          name: 'displayConditionsRules.countries.thailand',
        },
        {
          value: 'TL',
          name: 'displayConditionsRules.countries.timorLeste',
        },
        { value: 'TG', name: 'displayConditionsRules.countries.togo' },
        {
          value: 'TK',
          name: 'displayConditionsRules.countries.tokelau',
        },
        { value: 'TO', name: 'displayConditionsRules.countries.tonga' },
        {
          value: 'TT',
          name: 'displayConditionsRules.countries.trinidad',
        },
        {
          value: 'TN',
          name: 'displayConditionsRules.countries.tunisia',
        },
        {
          value: 'TR',
          name: 'displayConditionsRules.countries.turkey',
        },
        {
          value: 'TM',
          name: 'displayConditionsRules.countries.turkmenistan',
        },
        {
          value: 'TC',
          name: 'displayConditionsRules.countries.turksAndCaicosIslands',
        },
        {
          value: 'TV',
          name: 'displayConditionsRules.countries.tuvalu',
        },
        {
          value: 'UG',
          name: 'displayConditionsRules.countries.uganda',
        },
        {
          value: 'UA',
          name: 'displayConditionsRules.countries.ukraine',
        },
        { value: 'AE', name: 'displayConditionsRules.countries.uae' },
        { value: 'GB', name: 'displayConditionsRules.countries.uk' },
        { value: 'US', name: 'displayConditionsRules.countries.usa' },
        {
          value: 'UM',
          name: 'displayConditionsRules.countries.usMinor',
        },
        {
          value: 'UY',
          name: 'displayConditionsRules.countries.uruguay',
        },
        {
          value: 'UZ',
          name: 'displayConditionsRules.countries.uzbekistan',
        },
        {
          value: 'VU',
          name: 'displayConditionsRules.countries.vanuatu',
        },
        {
          value: 'VE',
          name: 'displayConditionsRules.countries.venezuelaBolivarian',
        },
        {
          value: 'VN',
          name: 'displayConditionsRules.countries.vietnam',
        },
        {
          value: 'VG',
          name: 'displayConditionsRules.countries.virginIslandsBritish',
        },
        {
          value: 'VI',
          name: 'displayConditionsRules.countries.virginIslandsUS',
        },
        {
          value: 'WF',
          name: 'displayConditionsRules.countries.wallisAndFutuna',
        },
        {
          value: 'EH',
          name: 'displayConditionsRules.countries.westernSahara',
        },
        { value: 'YE', name: 'displayConditionsRules.countries.yemen' },
        {
          value: 'ZM',
          name: 'displayConditionsRules.countries.zambia',
        },
        {
          value: 'ZW',
          name: 'displayConditionsRules.countries.zimbabwe',
        },
      ],
    }),
    Object.freeze({
      value: 'language',
      name: 'displayConditionsRules.userLanguage',
      allowedConditions: [2, 3],
      option: [
        { value: 'ab', name: 'displayConditionsRules.languages.ab' },
        { value: 'aa', name: 'displayConditionsRules.languages.aa' },
        { value: 'af', name: 'displayConditionsRules.languages.af' },
        { value: 'ak', name: 'displayConditionsRules.languages.ak' },
        { value: 'sq', name: 'displayConditionsRules.languages.sq' },
        { value: 'am', name: 'displayConditionsRules.languages.am' },
        {
          value: 'ar,ar-DZ,ar-BH,ar-EG,ar-IQ,ar-JO,ar-KW,ar-LB,ar-LY,ar-MA,ar-OM,ar-QA,ar-SA,ar-SY,ar-TN,ar-AE,ar-YE',
          name: 'displayConditionsRules.languages.ar',
        },
        { value: 'an', name: 'displayConditionsRules.languages.an' },
        { value: 'hy', name: 'displayConditionsRules.languages.hy' },
        { value: 'as', name: 'displayConditionsRules.languages.as' },
        { value: 'av', name: 'displayConditionsRules.languages.av' },
        { value: 'ae', name: 'displayConditionsRules.languages.ae' },
        { value: 'ay', name: 'displayConditionsRules.languages.ay' },
        { value: 'az', name: 'displayConditionsRules.languages.az' },
        { value: 'bm', name: 'displayConditionsRules.languages.bm' },
        { value: 'ba', name: 'displayConditionsRules.languages.ba' },
        { value: 'eu', name: 'displayConditionsRules.languages.eu' },
        { value: 'be', name: 'displayConditionsRules.languages.be' },
        { value: 'bn', name: 'displayConditionsRules.languages.bn' },
        { value: 'bh', name: 'displayConditionsRules.languages.bh' },
        { value: 'bi', name: 'displayConditionsRules.languages.bi' },
        { value: 'bs', name: 'displayConditionsRules.languages.bs' },
        { value: 'br', name: 'displayConditionsRules.languages.br' },
        { value: 'bg', name: 'displayConditionsRules.languages.bg' },
        { value: 'my', name: 'displayConditionsRules.languages.my' },
        { value: 'ca', name: 'displayConditionsRules.languages.ca' },
        { value: 'km', name: 'displayConditionsRules.languages.km' },
        { value: 'ch', name: 'displayConditionsRules.languages.ch' },
        { value: 'ce', name: 'displayConditionsRules.languages.ce' },
        { value: 'ny', name: 'displayConditionsRules.languages.ny' },
        {
          value: 'zh,zh-CN,zh-SG',
          name: 'displayConditionsRules.languages.zhCn',
        },
        {
          value: 'zh-TW,zh-HK',
          name: 'displayConditionsRules.languages.zhTw',
        },
        {
          value: 'cu',
          name: 'displayConditionsRules.languages.cu',
        },
        { value: 'cv', name: 'displayConditionsRules.languages.cv' },
        { value: 'kw', name: 'displayConditionsRules.languages.kw' },
        { value: 'co', name: 'displayConditionsRules.languages.co' },
        { value: 'cr', name: 'displayConditionsRules.languages.cr' },
        { value: 'hr', name: 'displayConditionsRules.languages.hr' },
        { value: 'cs', name: 'displayConditionsRules.languages.cs' },
        { value: 'da', name: 'displayConditionsRules.languages.da' },
        { value: 'dv', name: 'displayConditionsRules.languages.dv' },
        { value: 'nl,nl-BE', name: 'displayConditionsRules.languages.nl' },
        { value: 'dz', name: 'displayConditionsRules.languages.dz' },
        {
          value: 'en,en-AU,en-BZ,en-CA,en-IE,en-JM,en-NZ,en-ZA,en-TT,en-GB,en-US,en-IN,en-GB-oxendict',
          name: 'displayConditionsRules.languages.en',
        },
        { value: 'eo', name: 'displayConditionsRules.languages.eo' },
        { value: 'et', name: 'displayConditionsRules.languages.et' },
        { value: 'ee', name: 'displayConditionsRules.languages.ee' },
        { value: 'fo', name: 'displayConditionsRules.languages.fo' },
        { value: 'fj', name: 'displayConditionsRules.languages.fj' },
        { value: 'fi', name: 'displayConditionsRules.languages.fi' },
        {
          value: 'fr,fr-BE,fr-CA,fr-LU,fr-CH',
          name: 'displayConditionsRules.languages.fr',
        },
        { value: 'ff', name: 'displayConditionsRules.languages.ff' },
        { value: 'gd', name: 'displayConditionsRules.languages.gd' },
        { value: 'gl', name: 'displayConditionsRules.languages.gl' },
        { value: 'lg', name: 'displayConditionsRules.languages.lg' },
        { value: 'ka', name: 'displayConditionsRules.languages.ka' },
        {
          value: 'de,de-AT,de-LI,de-LU,de-CH',
          name: 'displayConditionsRules.languages.de',
        },
        { value: 'el', name: 'displayConditionsRules.languages.el' },
        { value: 'gn', name: 'displayConditionsRules.languages.gn' },
        { value: 'gu', name: 'displayConditionsRules.languages.gu' },
        { value: 'ht', name: 'displayConditionsRules.languages.ht' },
        { value: 'ha', name: 'displayConditionsRules.languages.ha' },
        { value: 'he', name: 'displayConditionsRules.languages.he' },
        { value: 'hz', name: 'displayConditionsRules.languages.hz' },
        { value: 'hi', name: 'displayConditionsRules.languages.hi' },
        { value: 'ho', name: 'displayConditionsRules.languages.ho' },
        { value: 'hu', name: 'displayConditionsRules.languages.hu' },
        { value: 'is', name: 'displayConditionsRules.languages.is' },
        { value: 'io', name: 'displayConditionsRules.languages.io' },
        { value: 'ig', name: 'displayConditionsRules.languages.ig' },
        { value: 'id', name: 'displayConditionsRules.languages.id' },
        { value: 'iu', name: 'displayConditionsRules.languages.iu' },
        { value: 'ik', name: 'displayConditionsRules.languages.ik' },
        { value: 'ga', name: 'displayConditionsRules.languages.ga' },
        { value: 'it,it-CH', name: 'displayConditionsRules.languages.it' },
        { value: 'ja', name: 'displayConditionsRules.languages.ja' },
        { value: 'jv', name: 'displayConditionsRules.languages.jv' },
        { value: 'kl', name: 'displayConditionsRules.languages.kl' },
        { value: 'kn', name: 'displayConditionsRules.languages.kn' },
        { value: 'kr', name: 'displayConditionsRules.languages.kr' },
        { value: 'ks', name: 'displayConditionsRules.languages.ks' },
        { value: 'kk', name: 'displayConditionsRules.languages.kk' },
        { value: 'ki', name: 'displayConditionsRules.languages.ki' },
        { value: 'rw', name: 'displayConditionsRules.languages.rw' },
        { value: 'ky', name: 'displayConditionsRules.languages.ky' },
        { value: 'kv', name: 'displayConditionsRules.languages.kv' },
        { value: 'kg', name: 'displayConditionsRules.languages.kg' },
        { value: 'ko', name: 'displayConditionsRules.languages.ko' },
        { value: 'kj', name: 'displayConditionsRules.languages.kj' },
        { value: 'ku', name: 'displayConditionsRules.languages.ku' },
        { value: 'lo', name: 'displayConditionsRules.languages.lo' },
        { value: 'la', name: 'displayConditionsRules.languages.la' },
        { value: 'lv', name: 'displayConditionsRules.languages.lv' },
        { value: 'li', name: 'displayConditionsRules.languages.li' },
        { value: 'ln', name: 'displayConditionsRules.languages.ln' },
        { value: 'lt', name: 'displayConditionsRules.languages.lt' },
        { value: 'lu', name: 'displayConditionsRules.languages.lu' },
        { value: 'lb', name: 'displayConditionsRules.languages.lb' },
        { value: 'mk', name: 'displayConditionsRules.languages.mk' },
        { value: 'mg', name: 'displayConditionsRules.languages.mg' },
        { value: 'ms', name: 'displayConditionsRules.languages.ms' },
        { value: 'ml', name: 'displayConditionsRules.languages.ml' },
        { value: 'mt', name: 'displayConditionsRules.languages.mt' },
        { value: 'gv', name: 'displayConditionsRules.languages.gv' },
        { value: 'mi', name: 'displayConditionsRules.languages.mi' },
        { value: 'mr', name: 'displayConditionsRules.languages.mr' },
        { value: 'mh', name: 'displayConditionsRules.languages.mh' },
        { value: 'mn', name: 'displayConditionsRules.languages.mn' },
        { value: 'na', name: 'displayConditionsRules.languages.na' },
        { value: 'nv', name: 'displayConditionsRules.languages.nv' },
        { value: 'ng', name: 'displayConditionsRules.languages.ng' },
        { value: 'ne', name: 'displayConditionsRules.languages.ne' },
        { value: 'se', name: 'displayConditionsRules.languages.se' },
        { value: 'no', name: 'displayConditionsRules.languages.no' },
        { value: 'oj', name: 'displayConditionsRules.languages.oj' },
        { value: 'or', name: 'displayConditionsRules.languages.or' },
        { value: 'om', name: 'displayConditionsRules.languages.om' },
        { value: 'os', name: 'displayConditionsRules.languages.os' },
        { value: 'pi', name: 'displayConditionsRules.languages.pi' },
        { value: 'pa', name: 'displayConditionsRules.languages.pa' },
        { value: 'fa', name: 'displayConditionsRules.languages.fa' },
        { value: 'pl', name: 'displayConditionsRules.languages.pl' },
        {
          value: 'pt-BR,pt,pt-PT',
          name: 'displayConditionsRules.languages.ptBr',
        },
        { value: 'ps', name: 'displayConditionsRules.languages.ps' },
        { value: 'qu', name: 'displayConditionsRules.languages.qu' },
        { value: 'ro,ro-MD', name: 'displayConditionsRules.languages.ro' },
        { value: 'rm', name: 'displayConditionsRules.languages.rm' },
        { value: 'rn', name: 'displayConditionsRules.languages.rn' },
        { value: 'ru,ru-MD', name: 'displayConditionsRules.languages.ru' },
        { value: 'sm', name: 'displayConditionsRules.languages.sm' },
        { value: 'sg', name: 'displayConditionsRules.languages.sg' },
        { value: 'sa', name: 'displayConditionsRules.languages.sa' },
        { value: 'sc', name: 'displayConditionsRules.languages.sc' },
        { value: 'sr', name: 'displayConditionsRules.languages.sr' },
        { value: 'sn', name: 'displayConditionsRules.languages.sn' },
        { value: 'ii', name: 'displayConditionsRules.languages.ii' },
        { value: 'sd', name: 'displayConditionsRules.languages.sd' },
        { value: 'si', name: 'displayConditionsRules.languages.si' },
        { value: 'sk', name: 'displayConditionsRules.languages.sk' },
        { value: 'sl', name: 'displayConditionsRules.languages.sl' },
        { value: 'so', name: 'displayConditionsRules.languages.so' },
        { value: 'st', name: 'displayConditionsRules.languages.st' },
        {
          value: 'es,es-AR,es-BO,es-CL,es-CO,es-CR,es-DO,es-EC,es-SV,es-GT,es-HN,es-MX,es-NI,es-PA,es-PY,es-PE,es-PR,es-UY,es-VE,es-ES,es-US,es-419',
          name: 'displayConditionsRules.languages.es',
        },
        { value: 'su', name: 'displayConditionsRules.languages.su' },
        { value: 'sw', name: 'displayConditionsRules.languages.sw' },
        { value: 'ss', name: 'displayConditionsRules.languages.ss' },
        { value: 'sv,sv-FI', name: 'displayConditionsRules.languages.sv' },
        { value: 'tl', name: 'displayConditionsRules.languages.tl' },
        { value: 'ty', name: 'displayConditionsRules.languages.ty' },
        { value: 'tg', name: 'displayConditionsRules.languages.tg' },
        { value: 'ta', name: 'displayConditionsRules.languages.ta' },
        { value: 'tt', name: 'displayConditionsRules.languages.tt' },
        { value: 'te', name: 'displayConditionsRules.languages.te' },
        { value: 'th', name: 'displayConditionsRules.languages.th' },
        { value: 'bo', name: 'displayConditionsRules.languages.bo' },
        { value: 'ti', name: 'displayConditionsRules.languages.ti' },
        { value: 'to', name: 'displayConditionsRules.languages.to' },
        { value: 'ts', name: 'displayConditionsRules.languages.ts' },
        { value: 'tn', name: 'displayConditionsRules.languages.tn' },
        { value: 'tr', name: 'displayConditionsRules.languages.tr' },
        { value: 'tk', name: 'displayConditionsRules.languages.tk' },
        { value: 'tw', name: 'displayConditionsRules.languages.tw' },
        { value: 'ug', name: 'displayConditionsRules.languages.ug' },
        { value: 'uk', name: 'displayConditionsRules.languages.uk' },
        { value: 'ur', name: 'displayConditionsRules.languages.ur' },
        { value: 'uz', name: 'displayConditionsRules.languages.uz' },
        { value: 've', name: 'displayConditionsRules.languages.ve' },
        { value: 'vi', name: 'displayConditionsRules.languages.vi' },
        { value: 'vo', name: 'displayConditionsRules.languages.vo' },
        { value: 'wa', name: 'displayConditionsRules.languages.wa' },
        { value: 'cy', name: 'displayConditionsRules.languages.cy' },
        { value: 'fy', name: 'displayConditionsRules.languages.fy' },
        { value: 'wo', name: 'displayConditionsRules.languages.wo' },
        { value: 'xh', name: 'displayConditionsRules.languages.xh' },
        { value: 'yi', name: 'displayConditionsRules.languages.yi' },
        { value: 'yo', name: 'displayConditionsRules.languages.yo' },
        { value: 'za', name: 'displayConditionsRules.languages.za' },
        { value: 'zu', name: 'displayConditionsRules.languages.zu' },
      ],
    }),
  ],
  conditions: [
    // value is using in the rules engine
    {
      value: 0,
      name: 'displayConditionsRules.conditions.stringEquals',
    },
    {
      value: 1,
      name: 'displayConditionsRules.conditions.stringNotEquals',
    },
    {
      value: 2,
      name: 'displayConditionsRules.conditions.includedIn',
    },
    {
      value: 3,
      name: 'displayConditionsRules.conditions.notIncludedIn',
    },
    {
      value: 4,
      name: 'displayConditionsRules.conditions.numberEquals',
    },
    {
      value: 5,
      name: 'displayConditionsRules.conditions.numberNotEquals',
    },
    {
      value: 6,
      name: 'displayConditionsRules.conditions.numberGreaterThan',
    },
    {
      value: 7,
      name: 'displayConditionsRules.conditions.numberLessThan',
    },
    {
      value: 8,
      name: 'displayConditionsRules.conditions.between',
    },
  ],
};

const mutations = {};

const actions = {};

const getters = {
  properties: function (state: IState, getters: any, rootState: any, rootGetters: any) {
    const src = state.properties.map((prop: any) => {
      let options = null;
      if (prop.option) {
        options = prop.option.map((opt: any) => {
          return {
            ...opt,
            name: capitalize(i18n.t(opt.name).toString()),
          };
        });
      }
      return {
        ...prop,
        name: capitalize(i18n.t(prop.name).toString()),
        ...(options && { option: options }),
      };
    });
    try {
      const statuses = rootGetters['core/currentOrganizationInfo'].itemStatuses.items;
      src.find((x: any) => x.value === 'itemStatus').option = statuses
        .map((s: any) => ({
          name: getItemStatusTranslated(s.name),
          value: s.value,
        }))
        .slice(0, -1);
    } catch (err) {
      console.log(err);
    }
    return src;
  },
  conditions: function (state: IState, getters: any) {
    const src = state.conditions.map((prop: any) => {
      return {
        ...prop,
        name: capitalize(i18n.t(prop.name).toString()),
      };
    });
    return src;
  },
};

function capitalize(s: string) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function getItemStatusTranslated(filter: string) {
  const filterVariable = filter.toLowerCase().replace(/\s+(.)/g, function (match, group) {
    return group.toUpperCase();
  });

  return i18n.te(`item.filters.${filterVariable}`) ? i18n.t(`item.filters.${filterVariable}`) : filter;
}

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
