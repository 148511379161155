const DashboardLayout = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Layout/DashboardLayout.vue');
const Dashboard = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/DashboardComponent.vue');

const User = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Pages/PricingComponent.vue');
const TimeLine = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Pages/TimeLinePage.vue');
const RtlSupport = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Pages/RtlSupport.vue');
const Login = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Pages/LoginComponent.vue');
const Register = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Pages/RegisterComponent.vue');
const Lock = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Pages/LockComponent.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Components/ButtonsComponent.vue');
const GridSystem = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Components/GridSystem.vue');
const Panels = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Components/PanelsComponent.vue');
const SweetAlert = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Components/SweetAlert.vue');
const Notifications = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Components/NotificationsComponent.vue');
const Icons = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Components/IconsComponent.vue');
const Typography = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Components/TypographyComponent.vue');

// Forms pages
const RegularForms = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Forms/RegularForms.vue');
const ExtendedForms = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Forms/ExtendedFormsComponent.vue');
const ValidationForms = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Forms/ValidationForms.vue');
const Wizard = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Forms/WizardComponent.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Tables/RegularTables.vue');
const ExtendedTables = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Tables/ExtendedTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Tables/PaginatedTables.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Maps/GoogleMaps.vue');
const FullScreenMap = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Maps/FullScreenMap.vue');
const VectorMaps = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/Maps/VectorMaps.vue');

// Calendar
const Calendar = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/CalendarComponent.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/ChartsComponent.vue');
const Widgets = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide/WidgetsComponent.vue');

const componentsMenu = {
  path: '/styleguide/components',
  component: DashboardLayout,
  meta: { bodyClass: 'sidebar-mini' },
  redirect: '/styleguide/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      components: { default: Buttons },
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      components: { default: GridSystem },
    },
    {
      path: 'panels',
      name: 'Panels',
      components: { default: Panels },
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      components: { default: SweetAlert },
    },
    {
      path: 'notifications',
      name: 'Notifications',
      components: { default: Notifications },
    },
    {
      path: 'icons',
      name: 'Icons',
      components: { default: Icons },
    },
    {
      path: 'typography',
      name: 'Typography',
      components: { default: Typography },
    },
  ],
};
const formsMenu = {
  path: '/styleguide/forms',
  component: DashboardLayout,
  meta: { bodyClass: 'sidebar-mini' },
  redirect: '/styleguide/forms/regular',
  name: 'Forms',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      components: { default: RegularForms },
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      components: { default: ExtendedForms },
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      components: { default: ValidationForms },
    },
    {
      path: 'wizard',
      name: 'Wizard',
      components: { default: Wizard },
    },
  ],
};

const tablesMenu = {
  path: '/styleguide/table-list',
  component: DashboardLayout,
  meta: { bodyClass: 'sidebar-mini' },
  redirect: '/styleguide/table-list/regular',
  name: 'Tables',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      components: { default: RegularTables },
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      components: { default: ExtendedTables },
    },
    {
      path: 'paginated',
      name: 'PaginatedTables Tables',
      components: { default: PaginatedTables },
    },
  ],
};

const mapsMenu = {
  path: '/styleguide/maps',
  component: DashboardLayout,
  meta: { bodyClass: 'sidebar-mini' },
  name: 'Maps',
  redirect: '/styleguide/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      components: { default: GoogleMaps },
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      components: { default: VectorMaps },
    },
  ],
};

const pagesMenu = {
  path: '/styleguide/pages',
  component: DashboardLayout,
  meta: { bodyClass: 'sidebar-mini' },
  name: 'Pages',
  redirect: '/styleguide/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      components: { default: User },
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      components: { default: TimeLine },
    },
    {
      path: 'rtl',
      name: 'وحة القيادة',
      meta: {
        rtlActive: true,
      },
      components: { default: RtlSupport },
    },
  ],
};

const routes = [
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  {
    path: '/styleguide',
    component: DashboardLayout,
    meta: { bodyClass: 'sidebar-mini' },
    redirect: '/styleguide/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard },
      },
      {
        path: 'calendar',
        name: 'Calendar',
        components: { default: Calendar },
      },
      {
        path: 'charts',
        name: 'Charts',
        components: { default: Charts },
      },
      {
        path: 'widgets',
        name: 'Widgets',
        components: { default: Widgets },
      },
    ],
  },
];

export default routes;
