<template>
  <div>
    <notifications-component></notifications-component>
    <router-view></router-view>
    <md-snackbar :md-position="'left'" :md-duration="Infinity" :md-active.sync="hasNewVersion" md-persistent>
      <small>A new version has been released!!</small>
      <md-button class="md-primary md-button-legacy" @click="reload()">Reload</md-button>
    </md-snackbar>
    <md-snackbar :md-position="'left'" :md-duration="Infinity" :md-active.sync="orgStatusChanged" md-persistent>
      <small>{{ orgStatusChangedMessage }}</small>
      <md-button class="md-primary md-button-legacy" @click="orgStatusChanged = false">Ok</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import settings from '@/settings';
import Intercom from '@/utils/Intercom';
import { EventBus, Events } from '@/utils/EventBus';

const json = require('../public/manifest.json');

export default {
  data() {
    return {
      currentVersion: json.description,
      checkVersionTime: 120,
      orgStatusChanged: false,
      orgStatusChangedMessage: '',
    };
  },
  computed: {
    ...mapState('version', ['hasNewVersion']),
  },
  created() {
    EventBus.$on('notify::error', this.showError);
    EventBus.$on('notify::success', this.showSuccess);
    EventBus.$on(Events.authenticationLogin, this.intercomHandleLogin);
    EventBus.$on(Events.routerChangePage, this.intercomHandleChangePage);
    EventBus.$on(Events.authenticationLogout, this.intercomHandleLogout);
    EventBus.$on(Events.reloadOrganization, this.setOrgStatusChanged);

    setInterval(() => {
      this.getNewVersion();
    }, this.checkVersionTime * 1000);
  },
  beforeDestroy() {
    EventBus.$off('notify::error', this.showError);
    EventBus.$off('notify::success', this.showSuccess);
    EventBus.$off(Events.authenticationLogin, this.intercomHandleLogin);
    EventBus.$off(Events.routerChangePage, this.intercomHandleChangePage);
    EventBus.$off(Events.authenticationLogout, this.intercomHandleLogout);
    EventBus.$off(Events.reloadOrganization, this.setOrgStatusChanged);
  },
  methods: {
    getNewVersion() {
      if (!this.hasNewVersion) {
        axios
          .get('/manifest.json?' + Date.now())
          .then(response => {
            if (this.currentVersion !== response.data.description) {
              this.$store.dispatch('version/setNewVersion', true);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    reload() {
      if (location) location.reload(true);
    },
    showSuccess(message) {
      this.showAlert('success', message);
    },
    showError(message) {
      this.showAlert('danger', message);
    },
    showAlert(type, message) {
      this.$notify({
        message: message,
        icon: 'add_alert',
        horizontalAlign: 'right',
        verticalAlign: 'bottom',
        type: type,
      });
    },
    intercomHandleLogin() {
      Intercom('boot', {
        app_id: settings.intercomAppId,
        email: this.$store.state.core.account.email,
        user_id: String(this.$store.state.core.account.id),
        created_at: parseInt(new Date().getTime() / 1000),
      });
    },
    intercomHandleChangePage() {
      Intercom('update', {
        last_request_at: parseInt(new Date().getTime() / 1000),
      });
    },
    intercomHandleLogout() {
      Intercom('shutdown');
    },
    setOrgStatusChanged(action) {
      switch (action) {
        case 'suspended':
          this.orgStatusChangedMessage = 'Your account has been suspended for this organization!!';
          break;
        case 'suspendedPortal':
          this.orgStatusChangedMessage = 'You no longer have portal access for this organization!!';
          break;
        case 'suspendedAdmin':
          this.orgStatusChangedMessage = 'You no longer have admin rights for this organization!!';
          break;
        case 'deleted':
          this.orgStatusChangedMessage = 'Your account has been deleted for this organization!!';
          break;
        default:
          this.orgStatusChangedMessage = '';
      }
      this.orgStatusChanged = true;
    },
  },
};
</script>
