import Vue from 'vue';
import store from '@/store';
import { GraphqlFastQuery } from '@/api/graphql';
import { query as organizationTasksQuery } from '@/graphql/organizationTasks';

interface IState {
  thingStatuses: object[] | null;
  interactionStatuses: object[] | null;
}

const state: IState = {
  thingStatuses: [
    {
      value: 0,
      name: 'Live',
    },
    {
      value: 1,
      name: 'Archived',
    },
  ],
  interactionStatuses: [],
};

const mutations = {
  SET_INTERACTION_STATUSES: function (state: IState, payload: object[]) {
    Vue.set(state, 'interactionStatuses', payload);
  },
};

const actions = {
  setInteractionStatuses: async function (context: any, payload: any) {
    try {
      let discoveryTasksFiltered = [];
      const currentOrganization = store.getters['core/currentOrganizationInfo'];
      const variables = {
        page: 1,
        perPage: 50,
        sort: `usedForDiscovery_asc`,
        organizationId: String(currentOrganization.id),
      };
      const firstPage = 1;
      const firstResponse = await GraphqlFastQuery(organizationTasksQuery, {
        fetchPolicy: 'no-cache',
        variables: variables,
      });
      discoveryTasksFiltered = firstResponse.data.organizationTasks.items;

      for (let i = firstPage + 1; i <= firstResponse.data.organizationTasks.totalPages; i++) {
        const response = await GraphqlFastQuery(organizationTasksQuery, {
          variables: {
            ...variables,
            page: i,
          },
        });
        discoveryTasksFiltered = discoveryTasksFiltered.concat(response.data.organizationTasks.items);
      }

      discoveryTasksFiltered = (Array.isArray(discoveryTasksFiltered) ? discoveryTasksFiltered : [])
        .filter(t => t.taskLibrary.automatic === true && t.taskLibrary.type === 1)
        .map(task => task.id);

      context.commit('SET_INTERACTION_STATUSES', [
        {
          value: 1,
          name: 'Notice',
        },
        {
          value: 0,
          name: 'Alert',
        },
        {
          value: 2,
          name: 'Warning',
        },
        {
          value: 'tag:collection',
          name: 'Collections Only',
        },
        {
          value: `tasksToHide:${discoveryTasksFiltered}`,
          name: 'Team Tasks',
        },
        {
          value: 'isAdmin:true',
          name: 'Administration Tasks',
        },
        /*{
          value: 'show-expired',
          name: 'Show expired',
        },*/
      ]);
    } catch (e) {
      console.log(e);
    }
  },
};

const getters = {
  interactionStatusesInfo: function (state: IState, getters: any) {
    return state.interactionStatuses;
  },
};

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
