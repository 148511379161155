<template>
  <router-view></router-view>
</template>

<script>
export default {
  mounted() {
    let $auth = this.$router.app.$auth;
    let loggedin = $auth.isAuthenticated();
    if (loggedin) {
      try {
        const isMobile = this.$router.currentRoute.query.src === 'mobile';
        this.$router.replace(isMobile ? { name: 'SignupConfirmation' } : { name: 'Home' });
      } catch (e) {
        this.$router.replace({ name: 'Home' });
      }
    } else {
      $auth.signup(this.$router.currentRoute.fullPath);
    }
  },
};
</script>
