<template>
  <form @submit.prevent="save" class="text-align--center">
    <div class="h3 margin-0">{{ label }}</div>

    <div v-if="input" class="input">
      <md-field>
        <label>{{ input }}</label>
        <md-input v-model="inputValue"></md-input>
      </md-field>
    </div>

    <md-button type="submit" class="md-primary">Submit</md-button>
  </form>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    input: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      saving: false,
      inputValue: '',
    };
  },
  methods: {
    async save() {
      if (this.saving) return;
      this.saving = true;
      this.$emit('on-submit', this.id, this.label, this.input, this.inputValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 500px;
  margin: 30px auto;
  margin-bottom: 0;
}

.md-button {
  margin-top: 20px !important;
}
</style>
