import Axios from 'axios';
import { http } from './index';
import settings from '@/settings';
import store from '@/store';

const uploadApi = {
  getUploadUri: (data: any) => http.post(`/functions/rfider-file-upload`, data),
  uploadFile: function (url: string, blobOrFile: any) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.onload = function (e) {
        resolve(true);
      };
      xhr.upload.onprogress = function (e) {
        if (e.lengthComputable) {
          //console.log((e.loaded / e.total) * 100)
        }
      };
      xhr.send(blobOrFile);
    });
  },
  //
  delete: (data: any) => http.delete(`/functions/rfider-file-upload`, { data: data }),
};

const jwtApi = {
  exchange: (data: any) => {
    const headers: any = {
      Authorization: `Bearer ${store.getters['auth/idpJwt']}`, // we send the token from here to bypass the middleware
    };

    return http.post(`/core/access-tokens/exchange`, data || {}, {
      headers: headers,
      withCredentials: true, // this endpoint sets a cookie
    });
  },
};
const accessTokenApi = {
  index: () => http.get(`/core/access-tokens`),
  get: (id: string) => http.get(`/core/access-tokens/${id}`),
  create: (data: any) => http.post(`/core/access-tokens`, data),
  update: (id: string, data: any) => http.patch(`/core/access-tokens/${id}`, data),
  delete: (id: string) => http.delete(`/core/access-tokens/${id}`),
};
const organizationApi = {
  getThingsSummaries: (orgId: String, ids: string) => http.get(`/core/organizations/${orgId}/things/summaries/${ids}`),
  get: (orgId: String) => http.get(`/core/organizations/${orgId}`),
  createAccount: (orgId: String, data: any) => http.post(`/core/organizations/${orgId}/accounts`, data),
  updateAccount: (orgId: String, accountOrganizationId: String, data: any) => http.patch(`/core/organizations/${orgId}/accounts/${accountOrganizationId}`, data),
  deleteAccount: (orgId: String, accountOrganizationId: String) => http.delete(`/core/organizations/${orgId}/accounts/${accountOrganizationId}`),
  updateOrganization: (orgId: string, data: any) => http.patch(`/core/organizations/${orgId}`, data),
  createOrganization: (data: any) => http.post(`/core/organizations`, data),
  findAllAccounts: (orgId: String, data: any) => http.get(`/core/organizations/${orgId}/accounts`),
};

const teamApi = {
  create: (data: any) => http.post(`/core/teams`, data),
  update: (id: string, data: any) => http.patch(`/core/teams/${id}`, data),
  delete: (id: string) => http.delete(`/core/teams/${id}`),
  sendInvites: (teamId: string, data: any) => http.post(`/core/teams/${teamId}/invite`, data),
  setMemberAdmin: (teamId: string, accountId: string) => {
    return new Promise(resolve => setTimeout(resolve, 500));
  },
  setMemberOwner: (teamId: string, accountId: string) => {
    return new Promise(resolve => setTimeout(resolve, 500));
  },
  setMemberUser: (teamId: string, accountId: string) => {
    return new Promise(resolve => setTimeout(resolve, 500));
  },
  setMemberSuspend: (teamId: string, accountId: string) => {
    return new Promise(resolve => setTimeout(resolve, 500));
  },
  setRevokeAccess: (teamId: string, accountId: string) => {
    return new Promise(resolve => setTimeout(resolve, 500));
  },
  setResendInvite: (teamId: string, inviteId: string) => {
    return new Promise(resolve => setTimeout(resolve, 500));
  },
  setRevokeInvite: (teamId: string, inviteId: string) => {
    return new Promise(resolve => setTimeout(resolve, 500));
  },
};

const interactionApi = {
  index: (orgId: number, data: any) =>
    http.get(`/functions/rfider-interactions/interactions/${orgId}`, {
      params: data,
    }),
  get: (orgId: number, id: number) => http.get(`/functions/rfider-interactions/interactions/${orgId}/${id}`),
  create: (orgId: number, data: any) => http.post(`/functions/rfider-interactions/interactions/${orgId}`, data),
  update: (orgId: number, id: number, data: any) => http.put(`/functions/rfider-interactions/interactions/${orgId}/${id}`, data),
  expire: (orgId: number, id: number) => http.put(`/functions/rfider-interactions/interactions/${orgId}/${id}/expire`, {}),
  delete: (orgId: number, id: number) => http.delete(`/functions/rfider-interactions/interactions/${orgId}/${id}`),
  downloadReport: (orgId: number, data: any) => {
    const headers: any = { Accept: 'application/csv' };
    return http.post(`/functions/rfider-interactions/exports/${orgId}/interactions/`, data, { headers, responseType: 'blob' });
  },
  getThingsSummaries: (orgId: number, thingId: string, ids: string) => http.get(`/functions/rfider-interactions/thing-summaries/${orgId}/${thingId}/tasks/${ids}`),
  downloadPdf: (orgId: number, data: any) => {
    const headers: any = { Accept: 'application/pdf , application/zip' };
    return http.post(`/functions/rfider-interactions/exports/${orgId}/interactions/`, data, { headers, responseType: 'blob' });
  },
};

const itemApi = {
  index: (orgId: number, data: any) =>
    http.get(`/functions/rfider-interactions/items/${orgId}`, {
      params: data,
    }),
  get: (orgId: string, id: string) => http.get(`/functions/rfider-interactions/items/${orgId}/${id}`),
  update: (orgId: number, id: number, data: any) => http.put(`/functions/rfider-interactions/items/${orgId}/${id}`, data),
};

const thingApi = {
  index: (orgId: number, data: any) =>
    http.get(`/core/organizations/${orgId}/things`, {
      params: data,
    }),
  get: (id: number) => http.get(`/core/things/${id}`), //get data for thingAbout Tab and thingHeader
  update: (id: number, data: any) => http.patch(`/core/things/${id}`, data), //update data for thingAbout Tab
  getTasks: (id: number, params: any, allowInherited?: boolean) => {
    return http.get(`/core/things/${id}/tasks`, {
      params: {
        ...params,
        allowInherited: allowInherited ? '1' : '0',
      },
    });
  },
  getPublic: (id: number) => http.get(`/core/things/${id}/public-info`), //get data for thingPublic Tab
  updatePublic: (id: string, data: any) => http.patch(`/core/things/${id}/public-info`, data), //update data for thingPublic Tab
  createThing: (data: any) => http.post(`/core/things`, data), //create a thing
  updateTaskThing: (id: string, data: any) => http.patch(`/core/task-thing/${id}`, data), //update data for Taskthing in thingPublic Tab
  duplicateThing: (id: string) => http.post(`/core/things/${id}/duplicate`), //duplicate a thing
  enablePublicLink: (id: string, reset: boolean = false) =>
    thingApi.updatePublic(id, {
      isEnabledPublicLink: true,
      ...(reset && {
        publicLink: '1',
      }),
    }),
  disablePublicLink: (id: string) => thingApi.updatePublic(id, { isEnabledPublicLink: false }),
  getSupportProfiles: (id: number, data: any) =>
    http.get(`/core/things/${id}/support-profiles`, {
      params: data,
    }),
  bulkUpdate: (data: any) => http.post(`/core/things/bulk-update`, data),
  getPublicInfo: (organizationId: string, interactionId: string, qs?: any) =>
    http.get(`/public/things/${organizationId}/${interactionId}`, {
      withCredentials: true,
      params: qs,
    }),
};

const taskThingApi = {
  index: (orgId: number, data: any) =>
    http.get(`/core/organizations/${orgId}/taskThings`, {
      params: data,
    }),
  create: (data: any) => http.post(`/core/task-thing`, data),
  update: (id: string, data: any) => http.patch(`/core/task-thing/${id}`, data),
  reorder: (data: any) => http.post(`/core/task-thing/reorder`, data),
  delete: (id: string) => http.delete(`/core/task-thing/${id}`),
  start: (id: string, data: any) => http.post(`/core/task-thing/${id}/executionToken`, data),
  bulkAssign: (data: any) => http.post(`/core/task-thing/bulk-assign`, data),
  bulkDelete: (data: any) => http.post(`/core/task-thing/bulk-delete`, data),
};

const taskApi = {
  get: (id: string) => http.get(`/core/tasks/${id}`),
  create: (data: any) => http.post(`/core/tasks`, data),
  update: (id: string, data: any) => http.patch(`/core/tasks/${id}`, data),
  delete: (id: string) => http.delete(`/core/tasks/${id}`),
};

const jobApi = {
  index: () => http.get(`/core/jobs`),
  get: (id: any) => http.get(`/core/jobs/${id}`),
  create: (data: any) => http.post(`/core/jobs`, data),
  update: (id: any, data: any) => http.patch(`/core/jobs/${id}`, data),
  delete: (id: any) => http.delete(`/core/jobs/${id}`),
  generate: (id: any) => http.post(`/core/jobs/${id}/generate`),
  isExists: (id: any, data: any) => {
    return http.get(`/core/jobs/is-exists`, {
      params: data,
    });
  },
};

const identificationCodeApi = {
  updateIdentificationCode: (id: number, data: any) => http.patch(`/core/identification-codes/${id}`, data), //update data for identificationCodes
  deleteIdentificationCode: (id: number) => http.delete(`/core/identification-codes/${id}`), //delete identificationCode
  createIdentificationCode: (data: any) => http.post(`/core/identification-codes`, data), //create data for identificationCodes
  getIdentificationCodeValidate: (data: any) => http.post(`/core/identification-codes/validate`, data),
};

const batchApi = {
  getBatch: (id: number) => http.get(`/core/batches/${id}`), //get a batch
  createBatch: (data: any) => http.post(`/core/batches`, data), //create a batch
  updateBatch: (id: string, data: any) => http.patch(`/core/batches/${id}`, data), //update a batch
  deleteBatch: (id: number) => http.delete(`/core/batches/${id}`), //delete a batch
  downloadBatch: (uuid: number, group: number, amount: number, fields: string[], instantiateItems?: string, isDownload: boolean = false) => {
    if (!isDownload) {
      const data = {
        size: amount,
        fields: fields,
        instantiateItems: instantiateItems,
      };
      return http.get(`/core/virtual-code/${uuid}/download/${group}`, {
        params: data,
      });
    } else {
      return (
        `${settings.apiUrlRest}/core/virtual-code/${uuid}/download/${group}?size=${amount}` +
        (fields && fields.length ? '&fields=' + fields.join(',') : '') +
        (instantiateItems ? '&instantiateItems=' + instantiateItems : '')
      );
    }
  },
};

const webhookApi = {
  get: (id: number) => http.get(`/core/webhooks/${id}`),
  create: (data: any) => http.post(`/core/webhooks`, data),
  update: (webhookId: number, data: any) => http.patch(`/core/webhooks/${webhookId}`, data),
  delete: (webhookId: number) => http.delete(`/core/webhooks/${webhookId}`),
};

const executionApi = {
  start: (token: string) => {
    return http.post(
      `/core/executions`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  create: (token: string, ...data: any[]) =>
    http.post('/core/executions', ...data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  get: (token: string, id: string) =>
    http.get(`/core/executions/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  update: (token: string, id: string, data: any) =>
    http.patch(`/core/executions/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  process: (token: string, id: string) =>
    http.post(
      `/core/executions/${id}/process`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
};

const locationApi = {
  get: () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve({
            latitude: Number(position.coords.latitude),
            longitude: Number(position.coords.longitude),
          });
        },
        err => {
          reject(err);
        },
      );
    }).catch(err => {
      return Axios.post(
        'https://www.googleapis.com/geolocation/v1/geolocate',
        { considerIp: true },
        {
          timeout: 10000,
          params: { key: settings.googleGeoKey },
        },
      ).then(locationData => {
        return {
          accuracy: locationData.data.accuracy,
          latitude: Number(locationData.data.location.lat),
          longitude: Number(locationData.data.location.lng),
        };
      });
    });
  },
};

const accountApi = {
  resendInvitation: (data: any) => http.post(`/core/accounts/invitations/organization/resend`, data),
  acceptInvitation: (data: any) => http.post(`/core/accounts/invitations/organization/accept`, data),
  declineInvitation: (data: any) => http.post(`/core/accounts/invitations/organization/decline`, data),
  getInvitation: (inviteToken: string) => http.get(`/core/accounts/invitations/${inviteToken}`),
};

const authApi = {
  getPermissions: () => http.get(`/auth/permissions`),
};

const componentApi = {
  index: (type: string, data: any) =>
    http.get(`/core/components/${type}`, {
      params: data,
    }), //get data for component by type on thingPublic tab or value chain element overlay
  get: (type: string, componentId: string) => http.get(`/core/components/${type}/${componentId}`), //get data for component by type and id
  create: (type: string, data: any) => http.post(`/core/components/${type}`, data), //create a component with type and thingId or valueChainElementId
  update: (type: string, componentId: string, data: any) => http.patch(`/core/components/${type}/${componentId}`, data), //update data for component by type and id
  delete: (type: string, componentId: string) => http.delete(`/core/components/${type}/${componentId}`), //delete a component with type and id
  duplicate: (type: string, componentId: string, data?: any) => http.post(`/core/components/${type}/${componentId}/duplicate`, data), //duplicate a component with type and id
  reorder: (type: string, data: any) => http.post(`/core/components/${type}/reorder`, data),
  bulkDelete: (data: any) => http.post(`/core/components/bulk-delete`, data),
};

const valueChainApi = {
  index: () => http.get(`/core/valueChain`), //get the value chain list
  get: (id: number) => http.get(`/core/valueChain/${id}`), //get a value chain
  create: (data: any) => http.post(`/core/valueChain`, data), //create a value chain
  update: (id: number, data: any) => http.patch(`/core/valueChain/${id}`, data), //update a value chain
  delete: (id: number) => http.delete(`/core/valueChain/${id}`), //delete a value chain
  duplicate: (id: number) => http.post(`/core/valueChain/${id}/duplicate`), //duplicate a value chain
};

const templateApi = {
  get: (id: number) => http.get(`/core/templates/${id}`),
  create: (data: any) => http.post(`/core/templates`, data),
  update: (id: number, data: any) => http.patch(`/core/templates/${id}`, data),
  delete: (id: number) => http.delete(`/core/templates/${id}`),
  duplicate: (id: number) => http.post(`/core/templates/${id}/duplicate`),
};

const categoryApi = {
  get: (id: number) => http.get(`/core/categories/${id}`),
  create: (data: any) => http.post(`/core/categories`, data),
  update: (id: number, data: any) => http.patch(`/core/categories/${id}`, data),
  delete: (id: number) => http.delete(`/core/categories/${id}`),
};

const productComponentApi = {
  create: (data: any) => http.post(`/core/support-profile`, data),
  update: (id: number, data: any) => http.patch(`/core/support-profile/${id}`, data),
  delete: (id: number) => http.delete(`/core/support-profile/${id}`),
  bulkAssign: (data: any) => http.post(`/core/support-profile/bulk-assign`, data),
  bulkDelete: (data: any) => http.post(`/core/support-profile/bulk-delete`, data),
  reorder: (data: any) => http.post(`/core/support-profile/reorder`, data),
};

const downloadApi = {
  downloadFile(url: any, label: any) {
    Axios.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  },
};

const collectionApi = {
  index: (orgId: number, data: any) =>
    http.get(`/functions/rfider-interactions/collections/${orgId}`, {
      params: data,
    }),
  get: (orgId: string, id: string) => http.get(`/functions/rfider-interactions/collections/${orgId}/${id}`),
  create: (orgId: number, data: any) => http.post(`/functions/rfider-interactions/collections/${orgId}`, data),
  update: (orgId: number, id: number, data: any) => http.put(`/functions/rfider-interactions/collections/${orgId}/${id}`, data),
  delete: (orgId: number, id: number) => http.delete(`/functions/rfider-interactions/collections/${orgId}/${id}`),
};

export {
  jwtApi,
  organizationApi,
  teamApi,
  interactionApi,
  itemApi,
  thingApi,
  uploadApi,
  taskThingApi,
  identificationCodeApi,
  batchApi,
  webhookApi,
  taskApi,
  executionApi,
  locationApi,
  accountApi,
  authApi,
  componentApi,
  valueChainApi,
  templateApi,
  categoryApi,
  productComponentApi,
  downloadApi,
  collectionApi,
  accessTokenApi,
  jobApi,
};
