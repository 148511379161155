import Vue from 'vue';
import VueApollo from 'vue-apollo';
import VueRouter from 'vue-router';
import Chartist from 'chartist';
import router from './router';
import DashboardPlugin from './material-dashboard';
import { apolloProvider } from './api/graphql';
import store from '@/store';
import auth from '@/auth/auth';
import './registerServiceWorker';
import App from './App.vue';
import vbclass from 'vue-body-class';
import VueVirtualScroller from 'vue-virtual-scroller';
import PortalVue from 'portal-vue';
import Vue2TouchEvents from 'vue2-touch-events';
import AsyncComputed from 'vue-async-computed';
import i18n from '@/i18n';
import Lightbox from 'vue-easy-lightbox';
import VueLazyLoad from 'vue-lazyload';
import '@/mixins';
import '@/directives';
import '@/filters';
import VueCookies from 'vue-cookies';
import VueGtag from 'vue-gtag';
import settings from '@/settings';
import Vue2Filters from 'vue2-filters';
import VCalendar from 'v-calendar';
import VueClipboard from 'vue-clipboard2';
import * as VueGoogleMaps from 'vue2-google-maps';
import * as VueOffline from 'v-offline';
import VueMeta from 'vue-meta';
import UUID from 'vue-uuid';

Vue.config.productionTip = false;

// plugin setup
Vue.use(auth);
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(VueApollo);
Vue.use(vbclass, router);
Vue.use(PortalVue);
Vue.use(VueLazyLoad);
Vue.use(VueVirtualScroller);
Vue.use(Vue2TouchEvents, {
  touchClass: '', //Add an extra CSS class when touch start, and remove it when touch end.
  tapTolerance: 10,
  swipeTolerance: 30,
  longTapTimeInterval: 400,
});
Vue.use(AsyncComputed);
Vue.use(Lightbox);
Vue.use(VueCookies);
Vue.use(Vue2Filters);
Vue.use(VueClipboard);
Vue.use(VueOffline);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});
Vue.use(UUID);

Vue.use(
  VueGtag,
  {
    config: { id: settings.GtagID }, //google Analytics Gtag ID
    enabled: settings.GtagID && settings.GtagID.length,
  },
  router,
);

// global library setup
Object.defineProperty(Vue.prototype, '$Chartist', {
  get() {
    return this.$root.Chartist;
  },
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h: any) => h(App),
  router,
  store,
  i18n,
  apolloProvider,
  data: {
    Chartist: Chartist,
  },
});

(function () {
  if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
    document.documentElement.classList.add('istouchdevice');
  }
})();

Vue.use(VueGoogleMaps, {
  load: {
    key: settings.googleAutoCompleteKey,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  installComponents: true,
});

Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
});
