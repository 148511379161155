import settings from '@/settings';

let hasBooted: boolean = false;
let bootParams: any = null;

const Intercom = async function (...args: any[]) {
  const win = window as any;
  if (win && win.Intercom && settings.intercomAppId) {
    // we need to delay boot to where we have an org
    await new Promise(resolve => setTimeout(resolve, 1000));

    // here we save booting state so we only boot once a user access an organizaiton
    if (!hasBooted && args[0] === 'boot') {
      bootParams = args;
    }

    if (bootParams && !hasBooted && args[0] === 'update' && args[1]['portal_org']) {
      hasBooted = true;
      win.Intercom(...bootParams);
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    if (hasBooted) {
      win.Intercom(...args);
    }
  }
};

export default Intercom;
