import { searchOrganizationFragment } from './fragments/searchOrganizationFragment';

export const query = `query searchOrganizations($organizationId: Float! $search: String $page: Int $perPage: Int){
    searchWithinOrganization(organizationId:$organizationId search:$search page:$page perPage:$perPage){
        totalItems
        items{
            ${searchOrganizationFragment}
        }
    }
}`;
