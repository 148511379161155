import router from '@/router';
import settings from '@/settings';
import { jwtApi } from '@/api/http/api';
import { getJwtClaims } from '@/api/utils/jwt';
import { EventBus, Events } from '@/utils/EventBus';
import store from '@/store';

const state = {
  idpJwt: null,
  jwt: null,
  orgJwt: null,
  orgJwtPromise: null,
  orgJwtRenewInterval: null,
};

const mutations = {
  SET_IDP_JWT: function (state, payload) {
    state.idpJwt = payload;
  },
  SET_JWT: function (state, payload) {
    state.jwt = payload;
  },
  SET_ORG_JWT: function (state, payload) {
    if (state.orgJwtRenewInterval) {
      clearInterval(state.orgJwtRenewInterval);
      state.orgJwtRenewInterval = null;
    }

    state.orgJwt = payload;
  },
  SET_ORG_JWT_PROMISE: function (state, payload) {
    state.orgJwtPromise = payload;
  },
};

const actions = {
  async setJWT(context, payload) {
    const idpJwt = payload.jwt;
    if (state.idpJwt !== idpJwt) {
      context.commit('SET_IDP_JWT', idpJwt);

      if (!state.orgJwt) {
        await context.dispatch('renewJwt');
      }
    }
    return payload.jwt;
  },

  async renewJwt(context) {
    const response = await jwtApi.exchange();
    context.commit('SET_JWT', response.data.jwt);
    await store.dispatch('core/setPermissions');
  },

  async setOrgJWT(context, payload) {
    if (payload && payload.jwt) {
      context.commit('SET_ORG_JWT', payload.jwt);

      if (!context.state.orgJwtRenewInterval) {
        context.state.orgJwtRenewInterval = setInterval(
          jwt => {
            const claims = getJwtClaims(jwt);
            if (Number(claims.exp) * 1000 - Date.now() < 60000) {
              context.dispatch('setOrgJWT', { orgId: String(claims.orgId) });
            }
          },
          10000,
          payload.jwt,
        );
      }

      return Promise.resolve(payload.jwt);
    }

    if (payload && payload.orgId) {
      if (context.state.orgJwtPromise) {
        return context.state.orgJwtPromise;
      }

      const promise = jwtApi
        .exchange({
          organizationId: String(payload.orgId),
        })
        .then(async result => {
          const token = result.data.jwt;
          await context.dispatch('setOrgJWT', { jwt: token });
          await store.dispatch('core/setPermissions');
          return token;
        })
        .finally(() => {
          context.commit('SET_ORG_JWT_PROMISE', null);
        });

      context.commit('SET_ORG_JWT_PROMISE', promise);
      return promise;
    }

    context.commit('SET_ORG_JWT', null);
    await context.dispatch('renewJwt');
    return Promise.resolve(null);
  },

  logout() {
    router.app.$auth.logout();
    EventBus.$emit(Events.authenticationLogout);
    return true;
  },
};

const getters = {
  idpJwt: state => (settings.test_jwt ? settings.test_jwt : state.idpJwt),
  jwt: state => state.jwt,
};

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
