const de = {
  common: {
    labels: {
      selected: 'AUSGEWÄHLT:',
      searchByName: 'Suche mit Name',
      itemStatusMatches: 'Nur anzeigen, wenn der Artikelstatus übereinstimmt',
      showTask: 'Leer lassen, um diese Aufgabe immer anzuzeigen',
      emailAddress: 'E-Mail-Addresse',
      name: 'Name',
      emailAddressWithComma: 'E-Mail-Adressen (durch Komma getrennt)',
      emailSubject: 'E-Mail-Betreff (EX:. {task} - {name} für {thing} mit Status: {status})',
      endpointURL: 'Endpunkt-URL',
      securityHmacKey: 'Sicherheit (HMAC-Schlüssel)',
      apiKey: 'API-Schlüssel',
      organizationId: 'Organisations-ID',
      xmlMessage: 'XML-Nachricht',
      createYourFirst: 'Erstellen Sie Ihre erste',
      valueChain: 'Wertschöpfungskette.',
      category: 'Kategorie.',
      supportProfile: 'Support-Profil.',
      task: 'Aufgabe.',
      template: 'Vorlage.',
      description: 'Beschreibung',
      learnMoreLink: 'Weitere Informationen',
      image: 'Bild',
      displayImage: 'Bild anzeigen',
      chainElementIcon: 'Kettenelement-Symbol',
      transportationMethod: 'Transportmethode',
      gln: 'GLN (Globale Standortnummer)',
      status: 'Status',
      thing: 'Ding',
      selectDateRange: 'Datumsbereich auswählen',
      searchBy: 'Suchen nach',
      itemImage: 'Artikelbild',
      uploadAttachment: 'Anhang hochladen',
      addInteractionTo: 'Interaktion hinzufügen zu',
      interactionName: 'Interaktionsname',
      steps: 'Schritte',
      displayName: 'Anzeigename',
      title: 'Titel',
      attachments: 'Anhänge',
      currentStatus: 'Aktueller Status',
      country: 'Land',
      language: 'Sprache',
      items: 'Artikel',
      interactions: 'Interaktionen',
      tasks: 'Aufgaben',
      product: 'Produkt',
      item: 'Artikel',
      selectLanguage: 'Sprache auswählen',
      addBatch: 'Stapel hinzufügen',
      editBatch: 'Stapel bearbeiten',
      filters: 'Filter',
      unknown: 'Unbekannt',
      from: 'von',
      learnMore: 'Mehr erfahren',
      search: 'Suche',
      url: 'URL',
      addTask: 'Aufgabe hinzufügen',
      at: 'bei',
    },
    buttons: {
      save: 'Speichern',
      cancel: 'Stornieren',
      finish: 'Ziel',
      submit: 'einreichen',
      delete: 'Löschen',
      remove: 'Entfernen',
      task: 'Aufgabe',
      ok: 'OK',
      logout: 'Ausloggen',
      decline: 'Ablehnen',
      accept: 'Annehmen',
      inviteUsers: 'Benutzer einladen',
      send: 'Schicken',
      addMore: 'Fügen Sie mehr hinzu',
      createAnAction: 'Erstellen Sie eine Aktion',
      removeIntegration: 'Integration entfernen',
      continue: 'Fortsetzen',
      update: 'Aktualisieren',
      back: 'Zurück',
      next: 'Nächste',
      close: 'Nah dran',
      upload: 'Hochladen',
      create: 'Schaffen',
      clear: 'Klar',
      apply: 'Anwenden',
      viewInDashboard: 'Im Dashboard anzeigen',
      confirm: 'Bestätigen Sie',
      yes: 'Jawohl',
      no: 'Nein',
      configure: 'Konfigurieren',
    },
    menus: {
      things: 'Dinge',
      items: 'Artikel',
      interactions: 'Interaktionen',
      reportingAndAnalytics: 'Berichterstattung und Analysen',
      templates: 'Vorlagen',
      settings: 'Einstellungen',
      tasks: 'Aufgaben',
      users: 'Benutzer',
      advancedSettings: 'Erweiterte Einstellungen',
      valueChains: 'Wertschöpfungsketten',
      organisationalSettings: 'Organisatorische Einstellungen',
      language: 'Sprache',
      catalogue: 'Katalog',
    },
    menuItems: {
      edit: 'Bearbeiten',
      delete: 'Löschen',
      remove: 'Entfernen',
      manageConfiguration: 'Konfiguration verwalten',
      setAsDefault: 'Als Standard festlegen',
      setAsPublic: 'Als öffentlich festlegen',
      setAsPrivate: 'Als privat festlegen',
      manage: 'Verwalten',
      duplicate: 'Duplikat',
      viewContent: 'Inhalt anzeigen',
      viewSettings: 'Einstellungen anzeigen',
      displayToPublic: 'Öffentlich anzeigen',
      hideFromPublic: 'Vor der Öffentlichkeit verstecken',
      disable: 'Deaktivieren',
      enable: 'Aktivieren',
      viewConditions: 'Bedingungen anzeigen',
      manageConditions: 'Bedingungen verwalten',
      viewDisplayConditions: 'Anzeigebedingungen anzeigen',
      manageDisplayConditions: 'Anzeigebedingungen verwalten',
      change: 'Ändern',
      displayForBatch: 'Anzeige für Batch',
      viewOrUpdateClassification: 'Klassifizierung anzeigen/aktualisieren',
      displayInPublicPage: 'Anzeige auf öffentlicher Seite',
      suspended: 'Ausgesetzt',
      unsuspend: 'Suspendierung aufheben',
      suspend: 'Aussetzen',
      invitationDeclined: 'Einladung abgelehnt',
      invitationPending: 'Einladung ausstehend',
      invitationExpired: 'Einladung abgelaufen',
      invitationAccepted: 'Einladung angenommen',
      setAsMember: 'Als Mitglied festlegen',
      setAsAdmin: 'Als Admin festlegen',
      disablePortalAccess: 'Deaktivieren Sie den Portalzugriff',
      enablePortalAccess: 'Portalzugriff aktivieren',
      resendInvitation: 'Einladung erneut versenden',
      deleteUser: 'Benutzer löschen',
      deactivate: 'Deaktivieren',
      activate: 'aktivieren Sie',
      preview: 'Vorschau',
      customiseName: 'Namen anpassen',
      customiseImage: 'Bild anpassen',
      addAttachment: 'Anhang hinzufügen',
      changeStatus: 'Status ändern',
      removePersonalSupportProfile: 'Persönliches Support-Profil entfernen',
      createBatch: 'Stapel erstellen',
      editBatch: 'Stapel bearbeiten',
      deleteBatch: 'Stapel löschen',
      copyToClipboard: 'In die Zwischenablage kopieren',
      openPublicPage: 'Öffentliche Seite öffnen',
      createProduct: 'Produkt erstellen',
      pagePosition: 'Seitenposition',
      uploadImage: 'Bild hochladen',
      deleteImage: 'Lösche Bild',
      updateFromClipboard: 'Update aus der Zwischenablage',
      viewCodeDetails: 'Codedetails anzeigen',
      updateDisplayData: 'Gemeldete Daten aktualisieren',
      hide: 'Ausblenden',
      unhide: 'Einblenden',
    },
    pagination: {
      text: '{from} bis {to} von {total} Einträgen werden angezeigt',
    },
    tabs: {
      general: 'Allgemein',
      categories: 'Kategorien',
      support: 'Unterstützung',
      tasks: 'Aufgaben',
      users: 'Benutzer',
      integrationActions: 'Integrationsaktionen',
      valueChain: 'Wertschöpfungsketten',
      chart: 'Diagramm',
      map: 'Karte',
      documents: 'Unterlagen',
    },
    messages: {
      invitationExpired: 'Einladung abgelaufen',
      notFoundInvitation: 'Einladung nicht gefunden',
      userAlreadyExists: 'Benutzer existieren bereits',
      nameAlreadyTaken: 'Name bereits vergeben',
      InvalidContent: 'Ungültiger Inhalt',
      PayloadTooLarge: 'Anforderungsentität zu groß',
      noSearchResult: 'Leider konnten wir keine passenden Ergebnisse finden',
      noneSelected: 'Nichts ausgewählt.',
      noneAvailable: 'Für diese Organisation wurden noch keine Aufgaben erstellt.',
      noConfigurableAttributes: 'Für diese Aufgabe sind keine konfigurierbaren Attribute verfügbar.',
      noInvitation: 'Es sind keine ausstehenden Einladungen vorhanden',
      defaultValueChain: 'Das vorhandene Standardelement kann nur geändert werden, indem auf seinem Bearbeitungsbildschirm ein neues Element als Standard ausgewählt wird.',
      fileSizeError: 'Die maximale Dateigröße für das Hochladen von Dateien auf das Assure+-Portal beträgt 10 MB. Bitte laden Sie eine Datei mit bis zu 10 MB hoch.',
      submitError: 'Bitte füllen Sie alle Pflichtfelder aus',
      inviteSent: 'Einladungen verschickt',
      inviteError: 'Einladungen konnten nicht gesendet werden',
      saved: 'Gerettet',
      deleted: 'gelöscht',
      duplicated: 'dupliziert',
      createThing: 'Ding erstellt',
      createThingError: 'Ding konnte nicht erstellt werden',
      duplicateThingError: 'Das Ding kann nicht dupliziert werden',
      updateThing: 'Das Ding wurde dupliziert',
      updateThingError: 'Das Ding konnte nicht aktualisiert werden',
      loadTypeError: '{type} konnte nicht geladen werden',
      deleteTypeError: '{type} konnte nicht gelöscht werden',
      duplicateTypeError: 'Konnte nicht dupliziert werden',
      createTask: 'Aufgabe wurde hinzugefügt',
      createTaskError: 'Aufgabe konnte nicht hinzugefügt werden',
      updateTask: 'Aufgabe wurde aktualisiert',
      updateTaskError: 'Die Aufgabe konnte nicht aktualisiert werden',
      taskError: 'Aufgabe konnte nicht erstellt werden',
      createSupportProfile: 'Support-Profil wurde hinzugefügt',
      createSupportProfileError: 'Support-Profil konnte nicht hinzugefügt werden',
      updateSupportProfile: 'Das Support-Profil wurde aktualisiert',
      updateSupportProfileError: 'Das Support-Profil konnte nicht aktualisiert werden',
      deleteSupportProfile: 'Support-Profil wurde gelöscht',
      deleteSupportProfileError: 'Support-Profil konnte nicht erstellt werden',
      supportProfile: 'Support-Profil wurde erstellt',
      supportProfileError: 'Es konnte kein Support-Profil erstellt werden',
      createTeam: 'Team erstellt',
      createTeamError: 'Team konnte nicht erstellt werden',
      saveTeam: 'Mannschaft gespeichert',
      saveTeamError: 'Team konnte nicht gespeichert werden',
      renameTeam: 'Mannschaft umbenannt',
      renameTeamError: 'Team konnte nicht umbenannt werden',
      teamStatus: 'Teamstatus geändert',
      teamStatusError: 'Der Teamstatus konnte nicht geändert werden',
      resendInvite: 'Einladung erneut senden',
      resendInviteError: 'Einladung konnte nicht erneut gesendet werden',
      revokeInvite: 'Widerruf einladen',
      revokeInviteError: 'Einladung konnte nicht widerrufen werden',
      revokeAccess: 'Zugriff widerrufen',
      revokeAccessError: 'Zugriff konnte nicht widerrufen werden',
      suspendAccess: 'Ausgesetzt',
      suspendAccessError: 'Konnte nicht in Suspend geändert werden',
      accountChange: 'Konto geändert',
      accountChangeAdminError: 'Konnte nicht zu Admin wechseln',
      accountChangeOwnerError: 'Besitzer konnte nicht geändert werden',
      accountChangeUserError: 'Benutzer konnte nicht geändert werden',
      inviteUser: 'Benutzer eingeladen',
      inviteUserError: 'Benutzer konnten nicht eingeladen werden',
      declineInvitation: 'Habe die Einladung abgelehnt',
      declineInvitationError: 'Die Einladung konnte nicht abgelehnt werden ({message})',
      acceptInvitation: 'Einladung angenommen',
      acceptInvitationError: 'Die Einladung konnte nicht angenommen werden ({message})',
      deleteUser: 'Benutzer gelöscht',
      deleteUserError: 'Benutzer konnte nicht gelöscht werden',
      updateTemplate: 'Vorlage wurde aktualisiert',
      updateTemplateError: 'Die Vorlage konnte nicht aktualisiert werden',
      deleteTemplate: 'Vorlage wurde gelöscht',
      deleteTemplateError: 'Die Vorlage konnte nicht gelöscht werden',
      createTemplate: 'Vorlage wurde erstellt',
      createTemplateError: 'Eine Vorlage konnte nicht erstellt werden',
      deleteBatch: 'Stapel gelöscht',
      deleteBatchError: 'Charge wurde verwendet. Der Stapel konnte nicht gelöscht werden.',
      updateBatch: 'Charge wurde aktualisiert',
      createBatch: 'Stapel erstellt',
      createBatchError: 'Chargen-ID ist bereits vorhanden. Es konnte kein Stapel erstellt werden.',
      identificationCode: 'Identifikationscode wurde erstellt',
      validateIdentifierError: 'Eine Kennung konnte nicht validiert werden',
      addFileError: 'Bitte fügen Sie eine Datei hinzu',
      genericError: 'Allgemeiner Fehler',
      createValueChain: 'Wertschöpfungskette ist entstanden',
      createValueChainError: 'Es konnte keine Wertschöpfungskette erstellt werden',
      updateValueChain: 'Die Wertschöpfungskette wurde aktualisiert',
      updateValueChainError: 'Die Wertschöpfungskette konnte nicht aktualisiert werden',
      deleteValueChain: 'Wertschöpfungskette wurde gelöscht',
      deleteValueChainError: 'Die Wertschöpfungskette konnte nicht gelöscht werden',
      updateCategory: 'Kategorie wurde aktualisiert',
      updateCategoryError: 'Die Kategorie konnte nicht aktualisiert werden',
      deleteCategory: 'Kategorie wurde gelöscht',
      deleteCategoryError: 'Die Kategorie konnte nicht gelöscht werden',
      CreateCategory: 'Kategorie wurde erstellt',
      createCategoryError: 'Die Kategorie konnte nicht erstellt werden',
    },
    validation: {
      minNumMaxNum: 'Min. {0} Max. {1} Zeichen',
      maxNum: 'Maximal {0} Zeichen',
    },
    uploadFile: {
      maxSize: '(Maximale Größe 10 MB)',
    },
  },
  organization: {
    myOrganizations: 'Meine Organisationen',
    viewAllOrganizations: 'Alle Organisationen anzeigen',
  },
  interaction: {
    batchRecord: 'Ladungsliste',
    unknownId: 'UNBEKANNTE ID',
    externalId: 'EXTERNE ID',
    modal: {
      eventClassification: 'Ereignisklassifizierung',
      unclassified: 'Nicht klassifiziert',
      default: 'Standard',
    },
    filters: {
      notice: 'Notiz',
      alert: 'Alarm',
      warning: 'Warnung',
    },
    hidden: 'Versteckt',
  },

  interaction_action_0: 'Benutzer benachrichtigt:',
  interaction_action_1: 'Artikelstatus geändert zu:',
  interaction_action_2: 'Artikelunterstützungsprofil wurde zugewiesen.',
  interaction_action_3: 'Gegenstandsunterstützungsprofil wurde entfernt.',
  interaction_action_4: 'Benachrichtigung:',
  interaction_action_5: 'Warnung:',
  interaction_action_6: 'Alarm:',
  interaction_administrative_title: 'Verwaltungsänderung',
  task_steps: {
    status_check: 'Aktueller Artikelstatus ist: {status}',
  },
  item_status: '{Status}',
  interaction_collections_title: 'Sammlung - {text}',
  interaction_collection_added_title: 'Einer Sammlung hinzugefügt - {text}',
  step_collection_fields: 'Felder:',
  publicPage: {
    positioning: {
      before: 'Vor',
      after: 'Nach dem',
      top: 'oben',
      bottom: 'Unterseite',
    },
    components: {
      longDescription: 'Lange Beschreibung',
      attachments: 'Anhänge',
      attributes: 'Attribute',
      services: 'Dienstleistungen',
      gallery: 'Galerie',
      certifications: 'Zertifizierungen',
      video: 'Video',
      history: 'Geschichte',
      supportProfile: 'Support-Profil',
      fs1TaskPanel: 'FS1-Aufgabenbereich',
      fs1About: 'FS1 Über',
      fs1FactsPanel: 'FS1-Faktenpanel',
      fs1ResourcesPanel: 'FS1-Ressourcenfenster',
      events: 'Veranstaltungen',
    },
  },
  OrganisationalSettings: {
    header: 'Organisationseinstellungen',
    components: {
      general: 'Allgemein',
      branding: 'Branding',
    },
    general: {
      name: 'Name',
      businessType: 'Unternehmensart',
      OrganizationLogo: 'Organisationslogo',
      PublicPageLoadingImage: 'Bild zum Laden öffentlicher Seiten',
    },
  },
  tasks: {
    tableHeader: {
      name: 'Aufgabennname',
      usedAsDiscovery: 'Entdeckungsaufgabe',
      interactions: 'Nutzungszähler',
      things: 'Zuweisungen',
      createdAt: 'Hergestellt in',
      createdBy: 'Erstellt von',
      displayConditions: 'Bedingungen anzeigen',
    },
    taskModal: {
      headerCreate: 'Erstellen Sie eine Aufgabe',
      headerEdit: 'Aufgabe bearbeiten',
      components: {
        selectTask: 'Wählen Sie Aufgabe aus',
      },
    },
  },
  categories: {
    title: 'Kategorie',
    noDataMessage1: 'Erstellen Sie eine Kategorie',
    tableHeaders: {
      name: 'Name',
      defaultTemplate: 'Standardvorlage',
      createdAt: 'Hergestellt in',
      createdBy: 'Erstellt von',
    },
    editCategoryModal: {
      createCategory: 'Erstellen Sie eine Kategorie',
      editCategory: 'Bearbeiten Sie eine Kategorie',

      labels: {
        name: 'Kategoriename',
        standard: 'Identifikationsstandard',
        identifier: 'Kategoriekennung',
        description: 'Beschreibung',
        image: 'Kategorie Bild',
        defaultTemplate: 'Jedes für diese Kategorie erstellte Produkt wird standardmäßig auf diese Vorlage eingestellt.',
      },
    },
  },
  supportProfile: {
    title: 'Support-Profil',
    addSupportProfile: 'Support-Profil hinzufügen',
    createMessage1: 'Erstellen Sie ein Support-Profil',
    tableHeaders: {
      name: 'Name',
      content: 'Inhalt',
      displayConditions: 'Bedingungen anzeigen',
      contactMethods: 'Kontaktmethoden',
    },
    editSupportProfileModal: {
      createSupportProfile: 'Support-Profil erstellen',
      editSupportProfile: 'Support-Profil bearbeiten',
      labels: {
        name: 'NAME',
        headerText: 'ÜBERSCHRIFT',
        mainText: 'HAUPT TEXT',
        mainContent: 'HAUPTINHALT',
        showExpanded: 'ERWEITERT ANZEIGEN',
        phone: 'TELEFON',
        email: 'EMAIL',
        imagery: 'BILDER',
        primary: 'PRIMÄR',
        secondary: 'SEKUNDÄRE',
        kmlMapType: 'KML-Kartentyp',
        kmlLink: 'KML-Link',
        kmlFile: 'KML-Datei',
      },
    },
  },
  templates: {
    title: 'Vorlage',
    tableHeaders: {
      name: 'Name',
      createdAt: 'Hergestellt in',
      createdBy: 'Erstellt von',
      assignedCategories: 'Zugewiesene Kategorien',
    },
    editTemplateModal: {
      createTemplate: 'Erstellen Sie eine Vorlage',
      editTemplate: 'Bearbeiten Sie eine Vorlage',
      labels: {
        templateName: 'Vorlagenname',
        templateDescription: 'Vorlagenbeschreibung',
        templateImage: 'Vorlagenbild',
      },
    },
    templateCreatedModal: {
      title: 'Vorlage erstellt',
      message: 'Vorlage erfolgreich erstellt, möchten Sie Attribute, Aufgaben, Bilder und weitere Inhalte jetzt verwalten und hinzufügen?',
      later: 'Mach es später',
      now: 'Jetzt verwalten',
    },
    templateCreatedProductModal: {
      title: 'Produkt erstellt',
      message: 'Produkt erfolgreich erstellt, möchten Sie Attribute, Aufgaben, Bilder und weitere Inhalte jetzt verwalten und hinzufügen?',
    },
  },
  user: {
    viewInvitations: 'Einladungen anzeigen',
    inviteUsers: 'Benutzer einladen',
    to: 'zu',
    userHeaders: {
      name: 'Name',
      email: 'Email',
      role: 'Rolle',
      access: 'Betreten',
      status: 'Status',
    },
    userType: {
      admin: 'Administrator',
      member: 'Mitglied',
    },
  },
  webhooks: {
    webhookHeaders: {
      name: 'Name',
      createdAt: 'Hergestellt in',
      createdBy: 'Erstellt von',
    },
    webhookModal: {
      headerCreate: 'Aktion, um die Interaktionen Ihrer Organisation mit Teammitgliedern, anderen Systemen und vertrauenswürdigen Dritten zu teilen.',
      components: {
        emailSettings: 'Email Einstellungen',
        webhookSettings: 'Webhook-Einstellungen',
        integrations: 'Integrationen',
        thingsAndTasks: 'Dinge und Aufgaben',
      },
    },
    actions: 'Aktionen',
    editAction: 'Aktion bearbeiten',
  },
  valueChain: {
    headerCreate: 'Wertschöpfungskette',
    headerEdit: 'Bearbeiten Sie eine Wertschöpfungskette',
    editAChainElement: 'Bearbeiten Sie ein Kettenelement',
    createAChainElement: 'Erstellen Sie ein Kettenelement',
    headerDescription: 'Wählen Sie eine Vorlage aus, die Ihnen den Einstieg erleichtert.',
    organisationActivity: 'Organisationsaktivität',
    components: {
      miscellaneousContent: 'Verschiedene Inhalte',
      logisticsAndEnvironment: 'Logistik &amp; Umwelt',
      generalInformation: 'Allgemeine Informationen',
    },
    tableHeaders: {
      creationDate: 'Erstellungsdatum',
      createdBy: 'Erstellt von',
    },
    templates: {
      justTheBasics: {
        name: 'Nur die Grundlagen',
        description: 'Die in Assure+ verwendete Standardwertkette.',
      },
      beverage: {
        name: 'Getränk',
        description:
          'Die Möglichkeit, Getränkeprodukte über die gesamte Lieferkette zu verfolgen und zu verfolgen, wird jetzt vom Verbraucher vorangetrieben. Sie möchten die Herkunft aller Produkte bis hin zu den Feldern, auf denen die Zutaten angebaut werden, und den Einrichtungen, die die Trägermaterialien und Verpackungsmaterialien herstellen, kennen.',
      },
      coffee: {
        name: 'Kaffee',
        description: 'Bevor Kaffeebohnen in Ihre morgendliche Tasse gelangen, durchlaufen sie eine komplexe globale Lieferkette.',
      },
      honey: {
        name: 'Schatz',
        description: 'Honig durchläuft eine komplexe globale Lieferkette vom Bienenstock bis zum Verbraucher.',
      },
      porterValueChain: {
        name: 'Porter-Wertschöpfungskette',
        description:
          'Anstatt Abteilungen oder Buchhaltungskostenarten zu betrachten, konzentriert sich die Wertschöpfungskette von Porter auf Systeme und darauf, wie Eingaben in von Verbrauchern gekaufte Ausgaben umgewandelt werden.',
      },
      usaidCommodity: {
        name: 'USAid-Ware',
        description:
          'Der Prozess beginnt, wenn Lebensmittelanfragen an USAID gestellt werden, die Vertragsangebote einholt und Lebensmittel für genehmigte Anfragen beschafft. Die Waren werden dann von den Lieferanten zu Transportpunkten oder Lagereinrichtungen an verschiedenen logistischen Knotenpunkten der Lieferkette, einschließlich US-Häfen, ausländischen Entladehäfen und US-amerikanischen und internationalen USAID-Lagern zur Vorpositionierung, gesendet an Partnerorganisationen übertragen, die dann für die Verteilung der Lebensmittel mit ihren eigenen Transport- und Vertriebspartnernetzwerken sowie ihren eigenen Informationsverfolgungssystemen verantwortlich sind.',
      },
      wine: {
        name: 'Wein',
        description:
          'Die Wein-Wertschöpfungskette besteht aus fünf großen Produktionsstufen: Weinbereitung, Traubenproduktion (Weinbau), Weinherstellung (Weinbau), Logistik sowie Vertrieb, Marketing und Verkauf.',
      },
    },
  },
  documents: {
    title: 'Unterlagen',
    labels: {
      documentName: 'Dokumentname',
      gdtiNumber: 'GDTI-Nummer',
      valueChainSteps: 'Schritt der Wertschöpfungskette',
      publicRecord: 'Öffentliche Aufnahme',
      dateModified: 'Datum geändert',
    },
    valueChainSteps: {
      Unclassified: 'Nicht klassifiziert',
    },
  },
  products: {
    tableHeaders: {
      name: 'Name',
      category: 'Kategorie',
      tasks: 'Aufgaben',
      interactions: 'Aufgezeichnete Ereignisse',
      items: 'Artikel',
    },
    menuOptions: {
      manage: 'Verwalten',
      duplicate: 'Duplikat',
      updateFromClipboard: 'Update aus der Zwischenablage',
    },
    bulkActions: {
      addTasks: 'Aufgaben hinzufügen',
      removeTasks: 'Aufgaben entfernen',
      addValueChain: 'Wertschöpfungskette hinzufügen',
      addSupportProfile: 'Support-Profil hinzufügen',
      removeSupportProfile: 'Support-Profil entfernen',
    },
    productCreatedModal: {
      title: 'Produkt erstellt',
      message: 'Produkt erfolgreich erstellt, möchten Sie Attribute, Aufgaben, Bilder und weitere Inhalte jetzt verwalten und hinzufügen?',
      later: 'Mach es später',
      now: 'Jetzt verwalten',
    },
    title: 'Produkte',
  },
  collaborations: {
    header: {
      step: 'Schritt',
      title: 'Update aus der Zwischenablage',
    },
    subTitles: {
      general: 'Allgemein',
      productInfo: 'Produktinformation',
      attributes: 'Attribute',
      productDocuments: 'Unterlagen',
      itemAndBatchDocuments: 'Artikel- und Chargendokumente',
      images: 'Bilder',
      videos: 'Videos',
      eventHistory: 'Ereignisverlauf',
      contentTransferSummary: 'Zusammenfassung der Inhaltsübertragung',
    },
    descriptiveContent: {
      noData: 'Es sind keine allgemeinen Informationen verfügbar.',
      description: 'Allgemeine Informationen werden auf Produktebene übertragen und allen Chargen hinzugefügt.',
      target: 'Ziel',
      source: 'Quelle',
      labels: {
        name: 'Name',
        shortDescription: 'kurze Beschreibung',
        longDescription: 'Lange Beschreibung',
      },
      longDescriptionButtons: {
        append: 'Anhängen',
        replace: 'Ersetzen',
      },
    },
    attributes: {
      noData: 'Es sind keine Attribute verfügbar.',
      description: 'Attribute werden auf Produktebene übertragen und allen Chargen hinzugefügt.',
      warningMessage: 'Ausgewählte Attribute werden auf alle Chargen des Produkts angewendet',
    },
    itemAndBatchDocuments: {
      noData: 'Es sind keine Dokumente vorhanden.',
      warningMessage: 'Ausgewählte Dokumente werden nur dem jeweiligen Zielartikel oder der Charge des Produkts hinzugefügt',
    },
    images: {
      noData: 'Es sind keine Bilder verfügbar.',
      description: 'Bilder werden auf Produktebene übertragen und allen Stapeln hinzugefügt.',
      selectAllButton: 'Wählen Sie Alle',
    },
    videos: {
      noData: 'Es sind keine Videos verfügbar.',
      description: 'Videos werden auf Produktebene übertragen und allen Stapeln hinzugefügt.',
      selectAllButton: 'Wählen Sie Alle',
    },
    eventHistory: {
      noData: 'Es sind keine Aufgaben mit öffentlichen Inhalten verfügbar.',
      noItemBatch: 'Es ist kein Artikel oder Stapel zum Speichern von Ereignissen verfügbar.',
      labels: {
        name: 'Name',
        classification: 'Einstufung',
      },
    },
    confirmationScreen: {
      noData: 'Kein Inhalt ausgewählt.',
      message: 'Aktualisierung aus der Zwischenablage...',
      successfulMessage: 'Aktualisierung aus der Zwischenablage erfolgreich',
    },
  },
  item: {
    filters: {
      doNotUse: 'Verwende nicht',
      allClear: 'Alles klar',
      normalPrecaution: 'Normale Vorsichtsmaßnahme',
      reconsiderUse: 'Nutzung überdenken',
      increasedCaution: 'Erhöhte Vorsicht',
      unknownId: 'unbekannte Kennung',
      itemOk: 'Artikel Ok',
      ready: 'Bereit',
      active: 'Aktiv',
      resultPending: 'Ergebnis ausstehend',
      completedPositive: 'Positiv abgeschlossen',
      completedNegative: 'Abgeschlossenes Negativ',
      completedInconclusive: 'Nicht schlüssig abgeschlossen',
      itemLocked: 'Artikel gesperrt',
      itemUnlocked: 'Artikel entsperrt',
      unused: 'Ungebraucht',
      returned: 'Ist zurückgekommen',
      readyFilled: 'Fertig gefüllt',
      readyPacked: 'Fertig verpackt',
      withCustomer: 'Mit Kunde',
      issueRaised: 'Thema angesprochen',
      pickupRequested: 'Abholung erbeten',
    },
  },
  thing: {
    menus: {
      about: 'Über',
      discovery: 'Entdeckung',
      public: 'Öffentlich zugänglich',
      tasks: 'Aufgaben',
      support: 'Unterstützung',
    },
    about: {
      general: 'Allgemein',
      category: 'Kategorie',
      documentation: 'Dokumentation',
      attributes: 'Attribute',
      certifications: 'Zertifizierungen',
      miscellaneous: 'Sonstig',
      valueChains: 'Wertschöpfungsketten',
      gallery: 'Galerie',
      advancedSettings: 'Erweiterte Einstellungen',
      changeACategory: 'Ändern Sie eine Kategorie',
      changeAValueChain: 'Ändern Sie eine Wertschöpfungskette',

      certificationsLabel: {
        addCertification: 'Zertifizierung hinzufügen',
        editCertification: 'Zertifizierung bearbeiten',
      },
    },
    public: {
      generalSettings: 'Allgemeine Einstellungen',
      enablePublicContent: 'Aktivieren Sie öffentliche Inhalte',
      itemCheck: 'Artikelprüfung',
      usedAsDiscovery: 'Entdeckungsaufgabe',
      advancedSettings: 'Erweiterte Einstellungen',
      rfiderAuthenticityCheck: 'AssurePlus-Authentizitätsprüfung',
      basicInformation: 'Grundinformation',
      brandExperience: 'Markenerfahrung',
      links: 'Verknüpfungen',
      miscellaneous: 'Sonstig',
      attributes: 'Attribute',
      services: 'Dienstleistungen',
      documents: 'Unterlagen',
      gallery: 'Galerie',
      certifications: 'Zertifizierungen',
      videos: 'Videos',
      longDescription: 'Lange Beschreibung',
      supportProfile: 'Support-Profil',
      itemHistory: 'Artikelhistorie',
      none: 'Keiner',
      heroImage: 'Heldenbild',
      sectionImage: 'Schnittbild',
      heroOverlayImage: 'Hero Overlay-Bild',
      privacyPolicy: 'Datenschutz-Bestimmungen',
      productInfo: 'Produktinformation',
      learnMore: 'Erfahren Sie mehr',
      shopping: 'Das Einkaufen',
      section: 'Abschnitt',
      position: 'Position',
      enableAttributes: 'Attribute aktivieren',
      attributeVisibility: 'Attributsichtbarkeit',
      enableAttachments: 'Anhänge aktivieren',
      enableCertifications: 'Zertifizierungen aktivieren',
      allowProfles: 'Persönliche Profile der Teammitglieder zulassen',
      surviceLabels: {
        enableServices: 'Dienste aktivieren',
        serviceVisibility: 'Service-Sichtbarkeit',
        basic: 'Basic',
        off: 'Aus',
        peerToPeer: 'Peer-To-Peer',
      },
      itemHistoryLabels: {
        enableItemHistory: 'Artikelverlauf aktivieren',
        displayTemplate: 'Vorlage anzeigen',
        events: 'Veranstaltungen',
        data: 'Daten',
        images: 'Bilder',
        eventGallery: 'Event-Galerie',
        enableEventGallery: 'Ereignisgalerie aktivieren',
      },
      switch: {
        confirmTextEnable: 'Möchten Sie wirklich aktivieren?',
        confirmTextDisable: 'Möchten Sie wirklich deaktivieren?',
        confirmButtonEnable: 'Ja, aktiviere es!',
        confirmButtonDisable: 'Ja, deaktivieren!&#39;',
      },
      enablePublicLink: 'Öffentlichen Link aktivieren',
      attributeLabels: {
        editAttribute: 'Attribut bearbeiten',
        addAttribute: 'Attribute hinzufügen',
        label: 'Etikette',
        value: 'Wert',
        type: 'Typ',
      },
    },
  },
  filter: {
    createdByMe: 'Von mir erstellt',
  },
  swal: {
    duplicate: 'Duplizieren',
    confirmTitle: 'Bist du sicher?',
    warningMessage: 'Sie können dies nicht rückgängig machen!',
    confirmButtonDelete: 'Ja, löschen!',
    confirmButtonRemove: 'Ja, entfernen!',
    confirmButtonDuplicate: 'Ja, duplizieren!',
    confirmButtonReset: 'Ja, zurücksetzen!',
    confirmButtonResend: 'Ja, erneut senden!',
    confirmButtonDecline: 'Ja, lehne es ab!',
    confirmButtonAccept: 'Ja, akzeptiere es!',
    keepIt: 'Nein, behalte es',
    userText: 'Sie müssen sie erneut einladen.',
    associationError: 'Assoziationen konnten nicht erstellt werden!',
    template: {
      confirmButtonText: 'Ja, erstellen Sie ein neues Produkt!',
      createProduct: 'Erstellen eines Produkts',
      successMessage: 'Aus dieser Vorlage wurde ein neues Produkt erstellt',
      errorMessage: 'Aus dieser Vorlage kann kein neues Produkt erstellt werden',
      successMessage1: 'Vorlage wurde dupliziert',
    },
    products: {
      successMessage: 'Produkt wurde dupliziert',
      errorMessage: 'Produkt kann nicht dupliziert werden',
      createProductError: 'Aus dieser Vorlage kann kein neues Produkt erstellt werden',
    },
    valueChain: {
      successMessage: 'Die Wertschöpfungskette wurde dupliziert',
      errorMessage: 'Die Wertschöpfungskette konnte nicht dupliziert werden',
      successMessageThingAbout: 'Die Wertschöpfungskette für dieses Produkt wurde geändert',
      errorMessageThingAbout: 'Eine Wertschöpfungskette für dieses Produkt konnte nicht geändert werden',
      thingValueChainText: 'Es wird empfohlen, dies nicht zu ändern.',
      lastChance: 'Letzte Möglichkeit',
      lastChanceText:
        'Alle bis zu diesem Zeitpunkt erhobenen Daten werden in der Wertschöpfungskettenansicht nicht angezeigt. Diese Änderung kann nicht rückgängig gemacht werden.',
    },
    user: {
      successMessage: 'Senden Sie die Einladung erneut an den Benutzer',
      errorMessage: 'Die Einladung konnte nicht erneut an den Benutzer gesendet werden',
      successMessage1: 'Benutzer aktualisiert',
      errorMessage1: 'Benutzer konnte nicht aktualisiert werden',
    },
    invitations: {
      titleDecline: 'Einladung ablehnen?',
      titleAccept: 'Die Einladung annehmen?',
      text: 'Sie sind eingeladen, mitzumachen',
    },
    category: {
      successMessageThingAbout: 'Die Kategorie für dieses Produkt wurde geändert',
      errorMessageThingAbout: 'Eine Kategorie für dieses Produkt konnte nicht geändert werden',
      thingCategoryText: 'Es wird empfohlen, dies nicht zu ändern.',
      lastChance: 'Letzte Möglichkeit',
      lastChanceText: 'Diese Änderung kann nicht rückgängig gemacht werden.',
    },
    document: {
      createDocument: 'Dokument wurde erstellt',
      createDocumentError: 'Ein Dokument konnte nicht erstellt werden',
      updateDocument: 'Dokument wurde aktualisiert',
      updateDocumentError: 'Das Dokument konnte nicht aktualisiert werden',
      deleteDocument: 'Dokument wurde gelöscht',
      deleteDocumentError: 'Das Dokument konnte nicht gelöscht werden',
    },
    task: {
      text: 'Sie sind dabei, die Aufgabenkonfiguration zu beenden, ohne Ihre Änderungen zu speichern. Alle nicht gespeicherten Änderungen gehen verloren. Möchten Sie fortfahren?',
      deleteTask: 'Aufgabe wurde gelöscht',
      updateTask: 'Aufgabe wurde aktualisiert',
      updateTaskError: 'Die Aufgabe konnte nicht aktualisiert werden',
      createTask: 'Aufgabe wurde erstellt',
      createTaskTitle: 'Erfolgreich erstellt!',
      createTaskText: 'Möchten Sie seine Konfiguration öffnen?',
      labels: {
        configuration: 'Aufbau',
      },
    },
    interaction: {
      createSuccess: 'Interaktion erstellt',
      createError: 'Eine Interaktion konnte nicht erstellt werden.',
      mapLoadMessage: 'Ladefehler',
      mapLoadError: 'Die Interaktionen auf der Karte konnten nicht geladen werden',
      updateSuccess: 'Interaktion wurde aktualisiert',
      updateError: 'Interaktion kann nicht aktualisiert werden',
      deleteSuccess: 'Interaktion wurde gelöscht',
      deleteError: 'Interaktion kann nicht gelöscht werden.',
    },
    item: {
      itemsLimitError: 'Das Limit für Eingabeoptionen beträgt nur {itemsLimit} Artikel.',
      removeSupportProfile: {
        title: 'Bist du sicher?',
        success: 'Das persönliche Support-Profil wurde entfernt',
        error: 'Persönliches Support-Profil konnte nicht entfernt werden',
      },
    },
    miscellaneous: {
      noImageError: 'Bitte fügen Sie ein Bild hinzu',
      undefinedNotificationType: 'Bitte wählen Sie eine der Benachrichtigungsarten aus',
    },
    webhooks: {
      deleteAction: 'Aktion wurde gelöscht',
      deleteActionError: 'Die Aktion konnte nicht gelöscht werden',
      updateActionStatus: 'Aktionsstatus wurde aktualisiert',
      updateActionStatusError: 'Der Aktionsstatus konnte nicht aktualisiert werden',
      updateAction: 'Aktion wurde aktualisiert',
      updateActionError: 'Die Aktion konnte nicht aktualisiert werden',
      createWebhook: 'Webhook erstellt',
    },
  },
  invitations: {
    title: 'Einladungen',
    records: 'Aufzeichnungen',
  },
  languages: {
    english: 'English',
    german: 'Deutsch',
    spanish: 'Español',
    japanese: '日本',
    korean: '한국인',
    dutch: 'Nederlands',
    portuguese: 'Português',
    chinese: '中国人',
    chineseTraditional: '中國人',
  },
  editMiscellaneous: {
    editMiscellaneous: 'Sonstiges bearbeiten',
    addMiscellaneous: 'Sonstiges hinzufügen',
    editVideo: 'Video bearbeiten',
    addVideo: 'Video hinzufügen',
    editOptions: 'Optionen bearbeiten',
    addOptions: 'Optionen hinzufügen',
    labels: {
      embeddedLink: 'NUR EINGEBETTETER LINK',
      videoEmbed: 'VIDEO-EMBED-CODE',
      codeInjection: 'Code-Injektion',
      imageLink: 'BILDLINK',
    },
  },
  discovery: {
    labels: {
      batchId: 'Batch-ID',
      batchName: 'Chargenname',
      createdBy: 'Erstellt von',
      itemCount: 'Stückzahl',
      used: '% Benutzt',
    },
    buttons: {
      identificationMethod: 'Identifikationsmethode',
      createBatch: 'Stapel erstellen',
      deactivate: 'Deaktivieren',
      activate: 'aktivieren Sie',
      delete: 'Löschen',
    },
    maximumItemsPerBatch: 'Maximale Artikel pro Charge',
    batches: 'Chargen',
    beta: 'Beta',
    item: 'Artikel',
    product: 'Produkt',
    selectIdentificationMethod: 'Wählen Sie Identifikationsmethode',
    message: 'Die Assure+ Unique ID (RFUID) ist eine vom System generierte Kennung von Artikeln, die auf der Assure+-Plattform verwaltet werden.',
  },
  dashboard: {
    dateRange: {
      today: 'Heute',
      lastSevenDays: 'Letzten 7 Tage',
      lastWeek: 'Letzte Woche',
      lastThirtyDays: 'Letzte 30 Tage',
      lastMonth: 'Im vergangenen Monat',
      lastTwelvemonths: 'Letzte 12 Monate',
      custom: 'Brauch',
    },
    chart: {
      today: 'Heute',
      allTime: 'Alle Zeit',
      totalInteractions: 'Gesamte Interaktionen',
      totalTasks: 'Gesamtaufgaben',
      totalServices: 'Gesamtleistungen',
      noData: 'Keine Daten',
      overallStatusBreakdown: 'Gesamtstatusaufschlüsselung',
    },
  },
  displayConditionsRules: {
    onlyAdmin: 'Benutzerberechtigungen',
    or: 'oder',
    andAlso: 'Und auch',
    addYourFirstCondition: 'Fügen Sie Ihre erste Bedingung hinzu',
    useSeparator: 'Verwenden Sie Komma als Trennzeichen',
    useDashSeparator: 'Χρησιμοποιήστε την παύλα (-) ως διαχωριστικό',
    rfuidItemId: 'RFUID-Element-ID',
    rfuidBatchId: 'RFUID-Batch-ID',
    rfuidSequenceNumber: 'RFUID-Sequenznummer',
    itemStatus: 'Artikelstatus',
    userCountry: 'Benutzerland',
    userLanguage: 'Benutzersprache',
    conditions: {
      stringEquals: 'Zeichenfolge gleich',
      stringNotEquals: 'Zeichenfolge nicht gleich',
      includedIn: 'Eingeschlossen in',
      notIncludedIn: 'Nicht enthalten',
      numberEquals: 'Zahl gleich',
      numberNotEquals: 'Zahl nicht gleich',
      numberGreaterThan: 'Zahl größer als',
      numberLessThan: 'Zahl kleiner als',
      between: 'zwischen',
    },
    countries: {
      afghanistan: 'Afghanistan',
      alandIslands: 'Åland-Inseln',
      albania: 'Albanien',
      algeria: 'Algerien',
      americanSamoa: 'Amerikanischen Samoa-Inseln',
      andorra: 'Andorra',
      angola: 'Angola',
      anguilla: 'Anguilla',
      antarctica: 'Antarktis',
      antigua: 'Antigua und Barbuda',
      argentina: 'Argentinien',
      armenia: 'Armenien',
      aruba: 'Aruba',
      australia: 'Australien',
      austria: 'Österreich',
      azerbaijan: 'Aserbaidschan',
      bahamas: 'Bahamas',
      bahrain: 'Bahrein',
      bangladesh: 'Bangladesch',
      barbados: 'Barbados',
      belarus: 'Weißrussland',
      belgium: 'Belgien',
      belize: 'Belize',
      benin: 'Benin',
      bermuda: 'Bermudas',
      bhutan: 'Bhutan',
      bolivia: 'Bolivien',
      bonaire: 'Bonaire',
      bosnia: 'Bosnien und Herzegowina',
      botswana: 'Botswana',
      bouvet: 'Bouvet-Insel',
      brazil: 'Brasilien',
      britishIndian: 'Britisches Territorium des Indischen Ozeans',
      brunei: 'Brunei Darussalam',
      bulgaria: 'Bulgarien',
      burkina: 'Burkina Faso',
      burundi: 'Burundi',
      cambodia: 'Kambodscha',
      cameroon: 'Kamerun',
      canada: 'Kanada',
      capeVerde: 'Kap Verde',
      caymanIslands: 'Cayman Inseln',
      centralAfricanRepublic: 'Zentralafrikanische Republik',
      chad: 'Tschad',
      chile: 'Chile',
      china: 'China',
      christmasIsland: 'Weihnachtsinsel',
      cocos: 'Kokosinseln (Keelinginseln).',
      colombia: 'Kolumbien',
      comoros: 'Komoren',
      congo: 'Kongo',
      congoDemocratic: 'Kongo',
      cookIslands: 'Cookinseln',
      costaRica: 'Costa Rica',
      coteIvo: 'Elfenbeinküste',
      croatia: 'Kroatien',
      cuba: 'Kuba',
      curacao: 'Curacao',
      cyprus: 'Zypern',
      czechRepublic: 'Tschechien',
      denmark: 'Dänemark',
      djibouti: 'Dschibuti',
      dominica: 'Dominika',
      dominicanRepublic: 'Dominikanische Republik',
      ecuador: 'Ecuador',
      egypt: 'Ägypten',
      elSalvador: 'Der Retter',
      equatorialGuinea: 'Äquatorialguinea',
      eritrea: 'Eritrea',
      estonia: 'Estland',
      ethiopia: 'Äthiopien',
      falkland: 'Falklandinseln (Malvinas)',
      faroeIslands: 'Färöer Inseln',
      fiji: 'Fidschi',
      finland: 'Finnland',
      france: 'Frankreich',
      frenchGuiana: 'Französisch-Guayana',
      frenchPolynesia: 'Französisch Polynesien',
      frenchSouthern: 'Südfranzösische Territorien',
      gabon: 'Gabun',
      gambia: 'Gambia',
      georgia: 'Georgia',
      germany: 'Deutschland',
      ghana: 'Ghana',
      gibraltar: 'Gibraltar',
      greece: 'Griechenland',
      greenland: 'Grönland',
      grenada: 'Grenada',
      guadeloupe: 'Guadeloupe',
      guam: 'Guam',
      guatemala: 'Guatemala',
      guernsey: 'Guernsey',
      guinea: 'Guinea',
      guineaBissau: 'Guinea-Bissau',
      guyana: 'Guyana',
      haiti: 'Haiti',
      heardIsland: 'Heard-Insel und McDonald-Inseln',
      holySee: 'Heiliger Stuhl (Staat Vatikanstadt)',
      honduras: 'Honduras',
      hongKong: 'Hongkong',
      hungary: 'Ungarn',
      iceland: 'Island',
      india: 'Indien',
      indonesia: 'Indonesien',
      iran: 'Iran',
      iraq: 'Irak',
      ireland: 'Irland',
      isleOfMan: 'Isle of Man',
      israel: 'Israel',
      italy: 'Italien',
      jamaica: 'Jamaika',
      japan: 'Japan',
      jersey: 'Jersey',
      jordan: 'Jordanien',
      kazakhstan: 'Kasachstan',
      kenya: 'Kenia',
      kiribati: 'Kiribati',
      korea: 'Korea',
      koreaRepublic: 'Korea',
      kuwait: 'Kuwait',
      kyrgyzstan: 'Kirgistan',
      lao: 'Arbeit',
      latvia: 'Lettland',
      lebanon: 'Libanon',
      lesotho: 'Lesotho',
      liberia: 'Liberia',
      libya: 'Libyen',
      liechtenstein: 'Liechtenstein',
      lithuania: 'Litauen',
      luxembourg: 'Luxemburg',
      macao: 'Macau',
      macedonia: 'Mazedonien',
      madagascar: 'Madagaskar',
      malawi: 'Malawi',
      malaysia: 'Malaysia',
      maldives: 'Malediven',
      mali: 'Mali',
      malta: 'Malta',
      marshall: 'Marshallinseln',
      martinique: 'Martinique',
      mauritania: 'Mauretanien',
      mauritius: 'Mauritius',
      mayotte: 'Mayotte',
      mexico: 'Mexiko',
      micronesia: 'Mikronesien',
      moldova: 'Moldau',
      monaco: 'Monaco',
      mongolia: 'Mongolei',
      montenegro: 'Montenegro',
      montserrat: 'Montserrat',
      morocco: 'Marokko',
      mozambique: 'Mosambik',
      myanmar: 'Burma',
      namibia: 'Namibia',
      nauru: 'Nauru',
      nepal: 'Nepal',
      netherlands: 'Niederlande',
      newCaledonia: 'Neu-Kaledonien',
      newZealand: 'Neuseeland',
      nicaragua: 'Nicaragua',
      niger: 'Niger',
      nigeria: 'Nigeria',
      niue: 'Niue',
      norfolk: 'Norfolkinsel',
      northern: 'Nördliche Marianneninseln',
      norway: 'Norwegen',
      oman: 'Oman',
      pakistan: 'Pakistan',
      palau: 'Palau',
      palestinian: 'Besetzte palästinensische Gebiete',
      panama: 'Panama',
      papua: 'Papua Neu-Guinea',
      paraguay: 'Paraguay',
      peru: 'Peru',
      philippines: 'Philippinen',
      pitcairn: 'Pitcairn',
      poland: 'Polen',
      portugal: 'Portugal',
      puertoRico: 'Puerto Rico',
      qatar: 'Katar',
      reunion: 'Meeting',
      romania: 'Rumänien',
      russian: 'Russische Föderation',
      rwanda: 'Ruanda',
      saintBarth: 'Heiliger Barthelemäus',
      saintHelena: 'Heilige Helena',
      saintKitts: 'St. Kitts und Nevis',
      saintLucia: 'St. Lucia',
      saintMartin: 'Saint Martin (französischer Teil)',
      saintPierre: 'St. Pierre und Miquelon',
      saintVincent: 'St. Vincent und die Grenadinen',
      samoa: 'Samoa',
      sanMarino: 'San Marino',
      saoTome: 'Sao Tome und Principe',
      saudiArabia: 'Saudi-Arabien',
      senegal: 'Senegal',
      serbia: 'Serbien',
      seychelles: 'Seychellen',
      sierra: 'Sierra Leone',
      singapore: 'Singapur',
      sintMaarten: 'Sint Maarten (niederländischer Teil)',
      slovakia: 'Slowakei',
      slovenia: 'Slowenien',
      solomon: 'Salomon-Inseln',
      somalia: 'Somalia',
      southAfrica: 'Südafrika',
      southGeorgia: 'Süd-Georgien und die südlichen Sandwich-Inseln',
      southSudan: 'Südsudan',
      spain: 'Spanien',
      sriLanka: 'Sri Lanka',
      sudan: 'Sudan',
      suriname: 'Surinam',
      svalbard: 'Spitzbergen und Jan Mayen',
      swaziland: 'Swasiland',
      sweden: 'Schweden',
      switzerland: 'Schweiz',
      syrian: 'Syrische Arabische Republik',
      taiwan: 'Taiwan',
      tajikistan: 'Tadschikistan',
      tanzania: 'Tansania',
      thailand: 'Thailand',
      timorLeste: 'Osttimor',
      togo: 'Gehen',
      tokelau: 'Tokelau',
      tonga: 'Tonga',
      trinidad: 'Trinidad und Tobago',
      tunisia: 'Tunesien',
      turkey: 'Truthahn',
      turkmenistan: 'Turkmenistan',
      turksAndCaicosIslands: 'Turks- und Caicosinseln',
      tuvalu: 'Tuvalu',
      uganda: 'Uganda',
      ukraine: 'Ukraine',
      uae: 'Vereinigte Arabische Emirate',
      uk: 'Vereinigtes Königreich',
      usa: 'Vereinigte Staaten',
      usMinor: 'Kleinere abgelegene Inseln der Vereinigten Staaten',
      uruguay: 'Uruguay',
      uzbekistan: 'Usbekistan',
      vanuatu: 'Vanuatu',
      venezuelaBolivarian: 'Venezuela',
      vietnam: 'Vietnam',
      virginIslandsBritish: 'Jungferninseln',
      virginIslandsUS: 'Jungferninseln',
      wallisAndFutuna: 'Wallis und Futuna',
      westernSahara: 'Westsahara',
      yemen: 'Jemen',
      zambia: 'Sambia',
      zimbabwe: 'Zimbabwe',
    },
    languages: {
      ab: 'Abchasisch',
      aa: 'Fern',
      af: 'Afrikaans',
      ak: 'Wille',
      sq: 'albanisch',
      am: 'Amharisch',
      ar: 'Arabisch',
      an: 'Aragonesisch',
      hy: 'Armenisch',
      as: 'Assamesisch',
      av: 'Avarisch',
      ae: 'Avestan',
      ay: 'Aymara',
      az: 'Aserbaidschanisch',
      bm: 'Bambara',
      ba: 'Baschkirisch',
      eu: 'baskisch',
      be: 'Belarussisch',
      bn: 'Bengali',
      bh: 'Bihari-Sprachen',
      bi: 'Bislama',
      bs: 'bosnisch',
      br: 'Bretonisch',
      bg: 'bulgarisch',
      my: 'birmanisch',
      ca: 'Katalanisch; Valencianisch',
      km: 'Zentrale Khmer',
      ch: 'Chamorro',
      ce: 'Tschetschenisch',
      ny: 'Chichewa; Chewa; Das Meer',
      zhCn: 'Vereinfachtes Chinesisch)',
      zhTw: 'Chinesische Tradition)',
      cu: 'Kirchenslawisch',
      cv: 'Tschuwaschen',
      kw: 'kornisch',
      co: 'Korsisch',
      cr: 'glauben',
      hr: 'kroatisch',
      cs: 'Tschechisch',
      da: 'dänisch',
      dv: 'Divehi',
      nl: 'Niederländisch; flämisch',
      dz: 'Dzongkha',
      en: 'Englisch',
      eo: 'Esperanto',
      et: 'estnisch',
      ee: 'Mutterschaf',
      fo: 'Färöisch',
      fj: 'Fidschi',
      fi: 'finnisch',
      fr: 'Französisch',
      ff: 'Fulah',
      gd: 'gälisch',
      gl: 'galizisch',
      lg: 'Doppelt',
      ka: 'georgisch',
      de: 'Deutsch',
      el: 'griechisch',
      gn: 'Guarani',
      gu: 'Gujarati',
      ht: 'haitianisch',
      ha: 'Hausa',
      he: 'hebräisch',
      hz: 'Herero',
      hi: 'Nein.',
      ho: 'Hiri Motu',
      hu: 'ungarisch',
      is: 'isländisch',
      io: 'Gegangen',
      ig: 'Igbo',
      id: 'Indonesisch',
      iu: 'Inuktitut',
      ik: 'Inupiaq',
      ga: 'irisch',
      it: 'Italienisch',
      ja: 'japanisch',
      jv: 'Javanisch',
      kl: 'Grönländisch',
      kn: 'Kannada',
      kr: 'Kanuri',
      ks: 'Kaschmir',
      kk: 'Kasachisch',
      ki: 'Kikuyu; Gikuyu',
      rw: 'Kinyarwanda',
      ky: 'Kirgisen; Kirgisisch',
      kv: 'Komm schon',
      kg: 'Kongo',
      ko: 'Koreanisch',
      kj: 'Tiere; Im Fleisch',
      ku: 'kurdisch',
      lo: 'Arbeit',
      la: 'Latein',
      lv: 'lettisch',
      li: 'Limburger',
      ln: 'Lingala',
      lt: 'litauisch',
      lu: 'Luba-Katanga',
      lb: 'Luxemburgisch',
      mk: 'mazedonisch',
      mg: 'Madagassisch',
      ms: 'malaiisch',
      ml: 'Malayalam',
      mt: 'maltesisch',
      gv: 'Manx',
      mi: 'Maori',
      mr: 'Marathi',
      mh: 'Marshallisch',
      mn: 'mongolisch',
      na: 'Nauru',
      nv: 'Navajo; Navaho',
      ng: 'Ndonga',
      ne: 'Nepali',
      se: 'Nordsamisch',
      no: 'norwegisch',
      oj: 'Ojibwa',
      or: 'Orija',
      om: 'Oromo',
      os: 'Ossetisch; Ossetisch',
      pi: 'Pali',
      pa: 'Punjabi; Punjabi',
      fa: 'persisch',
      pl: 'Polieren',
      ptBr: 'Portugiesisch',
      ps: 'Puschto; Paschtu',
      qu: 'Quechua',
      ro: 'rumänisch',
      rm: 'Rätoromanisch',
      rn: 'Runden',
      ru: 'Russisch',
      sm: 'Samoanisch',
      sg: 'Sango',
      sa: 'Sanskrit',
      sc: 'Sardisch',
      sr: 'serbisch',
      sn: 'Shona',
      ii: 'Sichuan-Yi; Nuosu',
      sd: 'Sindhi',
      si: 'Singhalesisch; Singhalesisch',
      sk: 'slowakisch',
      sl: 'Slowenisch',
      so: 'somali',
      st: 'Sotho, Süd',
      es: 'Spanisch',
      su: 'Sundanesisch',
      sw: 'Suaheli',
      ss: 'Schwati',
      sv: 'Schwedisch',
      tl: 'Tagalog',
      ty: 'Tahitianisch',
      tg: 'Tadschikisch',
      ta: 'Tamil',
      tt: 'Tatar',
      te: 'Telugu',
      th: 'Thailändisch',
      bo: 'Tibetisch',
      ti: 'Tigrinja',
      to: 'Tonga',
      ts: 'Tsonga',
      tn: 'Tswana',
      tr: 'Türkisch',
      tk: 'Turkmenisch',
      tw: 'Zwei',
      ug: 'Uiguren; Uiguren',
      uk: 'ukrainisch',
      ur: 'Urdu',
      uz: 'Usbekisch',
      ve: 'Verkauf',
      vi: 'Vietnamesisch',
      vo: 'Volapük',
      wa: 'wallonisch',
      cy: 'Walisisch',
      fy: 'Westfriesisch',
      wo: 'Wolof',
      xh: 'Xhosa',
      yi: 'Jiddisch',
      yo: 'Yoruba',
      za: 'Zhuang; Chuang',
      zu: 'Zulu-',
    },
  },
  itemSummary: {
    title: 'Informationen zum Artikelcode',
    batchId: 'Batch-ID',
    product: 'Produkt',
    sequence: 'Reihenfolge',
    checksum: 'Prüfsumme',
    warning:
      'Für diesen Artikel ist die Echtheitsprüfung aktiviert. Der Zugriff über das Portal kann Authentizitätswarnprotokolle auslösen, wie z. B. Statusaktualisierungen und Warnungen bei nachfolgenden Scans.',
    hideUrl: 'URL verstecken',
    showUrl: 'URL anzeigen',
    copy: 'Kopieren',
    itemUrlCopied: 'Element-URL kopiert.',
    productName: 'Produktname',
    itemId: 'Artikel Identifikationsnummer',
    itemStatus: 'Artikelstatus',
    itemName: 'Artikelname',
    batchName: 'Chargenname',
  },
  updateReportedData: {
    locationTitle: 'Standort',
    dateTimeTitle: 'Datum (und Uhrzeit',
    dateTimeInteraction: 'Interaktionsdatum',
    dateTimeReported: 'Gemeldetes Datum',
    reset: 'Zurücksetzen',
    locationInteraction: 'Interaktionsort',
    locationReported: 'Gemeldeter Ort',
    reportedData: 'Gemeldete Daten',
  },
};
export default de;
