import { notificationInfoFragment } from './fragments/notificationInfoFragment';

export const query = `query getNotifications($organizationId: String $page: Int $perPage: Int){
    notifications(organizationId:$organizationId page:$page perPage:$perPage){
        totalItems
        items{
            ${notificationInfoFragment}
        }
    }
}`;
