export const valueChainInfoFragment = `
id
organizationId
name
description
link
image
isDefault
defaultElementId
createdById
createdAt
`;
