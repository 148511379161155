export const organizationItemStatusFragment = `
id
name
color
value
enabled
organizationId
createdAt
updatedAt
createdById
`;
