const ja = {
  common: {
    labels: {
      selected: '選択：',
      searchByName: '名前で検索',
      itemStatusMatches: 'アイテムのステータスが一致する場合にのみ表示されます',
      showTask: 'このタスクを常に表示するには、空のままにします',
      emailAddress: '電子メールアドレス',
      name: '名前',
      emailAddressWithComma: 'メールアドレス（カンマ区切り）',
      emailSubject: 'メールの件名（例：。{task}-{name} for {thing} with status：{status}）',
      endpointURL: 'エンドポイントURL',
      securityHmacKey: 'セキュリティ（HMACキー）',
      apiKey: 'ApiKey',
      organizationId: '組織ID',
      xmlMessage: 'XMLメッセージ',
      createYourFirst: '最初の作成',
      valueChain: '価値連鎖。',
      category: 'カテゴリー。',
      supportProfile: 'サポートプロファイル。',
      task: 'タスク。',
      template: 'テンプレート。',
      description: '説明',
      learnMoreLink: '詳細リンク',
      image: '画像',
      displayImage: '画像を表示',
      chainElementIcon: 'チェーン要素アイコン',
      transportationMethod: '輸送方法',
      gln: 'GLN（グローバルロケーション番号）',
      status: 'スターテス',
      thing: 'もの',
      selectDateRange: '日付範囲を選択',
      searchBy: 'で検索',
      itemImage: 'アイテム画像',
      uploadAttachment: '添付ファイルをアップロード',
      addInteractionTo: 'インタラクションを追加する',
      interactionName: 'インタラクション名',
      steps: 'ステップ',
      displayName: '表示名',
      title: 'タイトル',
      attachments: '添付ファイル',
      currentStatus: '現在のステータス',
      country: '国',
      language: '言語',
      items: 'アイテム',
      interactions: '相互作用',
      tasks: 'タスク',
      product: '製品',
      item: 'アイテム',
      selectLanguage: '言語を選択する',
      addBatch: 'バッチを追加',
      editBatch: 'バッチの編集',
      filters: 'フィルタ',
      unknown: '未知の',
      from: 'から',
      learnMore: 'もっと詳しく知る',
      search: '検索',
      url: 'URL',
      addTask: 'タスクの追加',
      at: 'で',
    },
    buttons: {
      save: '保存',
      cancel: 'キャンセル',
      finish: '終了',
      submit: '送信',
      delete: '消去',
      remove: '削除',
      task: 'タスク',
      ok: 'わかった',
      logout: 'ログアウト',
      decline: '却下',
      accept: '承認',
      inviteUsers: 'ユーザーを招待する',
      send: '送信',
      addMore: 'さらに追加',
      createAnAction: 'アクションを作成する',
      removeIntegration: '統合を削除する',
      continue: '継続する',
      update: 'アップデート',
      back: '戻る',
      next: '次',
      close: '選ぶ',
      upload: 'アップロード',
      create: '作成',
      clear: 'クリア',
      apply: '申し込み',
      viewInDashboard: 'ダッシュボードで表示',
      confirm: '確認',
      yes: 'はい',
      no: '番号',
      configure: '構成、設定',
    },
    menus: {
      things: 'もの',
      items: 'アイテム',
      interactions: '相互作用',
      reportingAndAnalytics: 'レポートと分析',
      templates: 'テンプレート',
      settings: '設定',
      tasks: 'タスク',
      users: 'ユーザー',
      advancedSettings: '高度な設定',
      valueChains: 'バリューチェーン',
      organisationalSettings: '組織の設定',
      language: '言語',
      catalogue: 'カタログ',
    },
    menuItems: {
      edit: '編集',
      delete: '消去',
      remove: '削除',
      manageConfiguration: '構成の管理',
      setAsDefault: 'デフォルトとして設定',
      setAsPublic: '公開に設定',
      setAsPrivate: 'プライベートとして設定',
      manage: '管理',
      duplicate: '複製',
      viewContent: 'コンテンツを見る',
      viewSettings: '設定を表示',
      displayToPublic: '一般公開',
      hideFromPublic: '公開から隠す',
      disable: '無効にする',
      enable: '有効',
      viewConditions: '表示条件',
      manageConditions: '条件の管理',
      viewDisplayConditions: '表示条件の表示',
      manageDisplayConditions: '表示条件の管理',
      change: '変化する',
      displayForBatch: 'バッチの表示',
      viewOrUpdateClassification: '分類の表示/更新',
      displayInPublicPage: '公開ページに表示',
      suspended: '一時停止',
      unsuspend: '一時停止を解除',
      suspend: 'つるす',
      invitationDeclined: '招待は辞退しました',
      invitationPending: '招待保留中',
      invitationExpired: '招待状の有効期限が切れました',
      invitationAccepted: '招待を受け付けました',
      setAsMember: 'メンバーとして設定',
      setAsAdmin: '管理者として設定',
      disablePortalAccess: 'ポータルアクセスを無効にする',
      enablePortalAccess: 'ポータルアクセスを有効にする',
      resendInvitation: '招待状を再送する',
      deleteUser: 'ユーザーを削除する',
      deactivate: '非アクティブ化',
      activate: '活性化',
      preview: 'プレビュー',
      customiseName: '名前をカスタマイズ',
      customiseImage: '画像をカスタマイズする',
      addAttachment: '添付ファイルを追加する',
      changeStatus: 'ステータスを変更する',
      removePersonalSupportProfile: 'パーソナルサポートプロファイルを削除する',
      createBatch: 'バッチの作成',
      editBatch: 'バッチの編集',
      deleteBatch: 'バッチを削除',
      copyToClipboard: 'クリップボードにコピー',
      openPublicPage: '公開ページを開く',
      createProduct: '製品を作成する',
      pagePosition: 'ページ位置',
      uploadImage: '画像をアップロード',
      deleteImage: '画像を削除',
      updateFromClipboard: 'クリップボードからの更新',
      viewCodeDetails: 'コードの詳細を表示',
      updateDisplayData: '報告されたデータを更新する',
      hide: '隠れる',
      unhide: '再表示',
    },
    pagination: {
      text: '{合計}エントリの{from}から{to}までを表示',
    },
    tabs: {
      general: '全般的',
      categories: 'カテゴリ',
      support: 'サポート',
      tasks: 'タスク',
      users: 'ユーザー',
      integrationActions: '統合アクション',
      valueChain: 'バリューチェーン',
      chart: 'チャート',
      map: '地図',
      documents: 'ドキュメント',
    },
    messages: {
      invitationExpired: '招待状の有効期限が切れました',
      notFoundInvitation: '招待状が見つかりません',
      userAlreadyExists: 'ユーザーはすでに存在します',
      nameAlreadyTaken: '名前は使用中',
      InvalidContent: '無効なコンテンツ',
      PayloadTooLarge: 'エンティティが大きすぎるリクエスト',
      noSearchResult: '申し訳ありませんが、一致する結果が見つかりませんでした',
      noneSelected: '何も選択されていません。',
      noneAvailable: 'この組織用に作成されたタスクはまだありません。',
      noConfigurableAttributes: 'このタスクに使用できる構成可能な属性はありません。',
      noInvitation: '保留中の招待はありません',
      defaultValueChain: '既存のデフォルト要素は、編集画面からデフォルトとして新しい要素を選択することによってのみ変更できます。',
      fileSizeError: 'Rfiderポータルにファイルをアップロードするための最大ファイルサイズは10MBです。 10MBまでのファイルをアップロードしてください。',
      submitError: 'すべての必須フィールドに入力してください',
      inviteSent: '送信された招待状',
      inviteError: '招待状を送信できませんでした',
      saved: '保存しました',
      deleted: '削除',
      duplicated: '複製',
      createThing: '作成されたもの',
      createThingError: 'モノを作成できませんでした',
      duplicateThingError: '複製することはできません',
      updateThing: '物が複製されました',
      updateThingError: 'Thingを更新できませんでした',
      loadTypeError: '{type}を読み込めませんでした',
      deleteTypeError: '{type}を削除できませんでした',
      duplicateTypeError: '複製できませんでした',
      createTask: 'タスクが追加されました',
      createTaskError: 'タスクを追加できませんでした',
      updateTask: 'タスクが更新されました',
      updateTaskError: 'タスクを更新できませんでした',
      taskError: 'タスクを作成できませんでした',
      createSupportProfile: 'サポートプロファイルが追加されました',
      createSupportProfileError: 'サポートプロファイルを追加できませんでした',
      updateSupportProfile: 'サポートプロファイルが更新されました',
      updateSupportProfileError: 'サポートプロファイルを更新できませんでした',
      deleteSupportProfile: 'サポートプロファイルが削除されました',
      deleteSupportProfileError: 'サポートプロファイルを作成できませんでした',
      supportProfile: 'サポートプロファイルが作成されました',
      supportProfileError: 'サポートプロファイルを作成できませんでした',
      createTeam: 'チームが作成されました',
      createTeamError: 'チームを作成できませんでした',
      saveTeam: 'チームが保存されました',
      saveTeamError: 'チームを保存できませんでした',
      renameTeam: 'チームの名前が変更されました',
      renameTeamError: 'チームの名前を変更できませんでした',
      teamStatus: 'チームステータスが変更されました',
      teamStatusError: 'チームステータスを変更できませんでした',
      resendInvite: '再送を招待',
      resendInviteError: '招待状を再送できませんでした',
      revokeInvite: '取り消しを招待する',
      revokeInviteError: '招待を取り消せませんでした',
      revokeAccess: 'アクセスが取り消されました',
      revokeAccessError: 'アクセスを取り消せませんでした',
      suspendAccess: '一時停止',
      suspendAccessError: '一時停止に変更できませんでした',
      accountChange: 'アカウントが変更されました',
      accountChangeAdminError: '管理者に変更できませんでした',
      accountChangeOwnerError: '所有者に変更できませんでした',
      accountChangeUserError: 'ユーザーに変更できませんでした',
      inviteUser: '招待されたユーザー',
      inviteUserError: 'ユーザーを招待できませんでした',
      declineInvitation: '招待を辞退しました',
      declineInvitationError: '招待を辞退できませんでした（{メッセージ}）',
      acceptInvitation: '招待を受け入れました',
      acceptInvitationError: '招待を受け入れることができませんでした（{メッセージ}）',
      deleteUser: 'ユーザーが削除されました',
      deleteUserError: 'ユーザーを削除できませんでした',
      updateTemplate: 'テンプレートが更新されました',
      updateTemplateError: 'テンプレートを更新できませんでした',
      deleteTemplate: 'テンプレートが削除されました',
      deleteTemplateError: 'テンプレートを削除できませんでした',
      createTemplate: 'テンプレートが作成されました',
      createTemplateError: 'テンプレートを作成できませんでした',
      deleteBatch: 'バッチ削除',
      deleteBatchError: 'バッチが使用されました。バッチを削除できませんでした。',
      updateBatch: 'バッチが更新されました',
      createBatch: 'バッチ作成',
      createBatchError: 'バッチIDはすでに存在します。バッチを作成できませんでした。',
      identificationCode: '識別コードが作成されました',
      validateIdentifierError: '識別子を検証できませんでした',
      addFileError: 'ファイルを追加してください',
      genericError: '一般的なエラー',
      createValueChain: 'バリューチェーンが構築されました',
      createValueChainError: 'バリューチェーンを作成できませんでした',
      updateValueChain: 'バリューチェーンが更新されました',
      updateValueChainError: 'バリューチェーンを更新できませんでした',
      deleteValueChain: 'バリューチェーンが削除されました',
      deleteValueChainError: 'バリューチェーンを削除できませんでした',
      updateCategory: 'カテゴリが更新されました',
      updateCategoryError: 'カテゴリを更新できませんでした',
      deleteCategory: 'カテゴリが削除されました',
      deleteCategoryError: 'カテゴリを削除できませんでした',
      CreateCategory: 'カテゴリが作成されました',
      createCategoryError: 'カテゴリを作成できませんでした',
    },
    validation: {
      minNumMaxNum: '最小{0}最大{1}文字',
      maxNum: '最大{0}文字',
    },
    uploadFile: {
      maxSize: '（最大サイズ10MB）',
    },
  },
  organization: {
    myOrganizations: '私の組織',
    viewAllOrganizations: 'すべての組織を表示',
  },
  interaction: {
    batchRecord: 'バッチレコード',
    unknownId: '不明なID',
    externalId: '外部ID',
    modal: {
      eventClassification: 'イベント分類',
      unclassified: '未分類',
      default: 'ディフォルト',
    },
    filters: {
      notice: '知らせ',
      alert: 'アラート',
      warning: '警告',
    },
    hidden: '隠れた',
  },

  interaction_action_0: 'ユーザー通知：',
  interaction_action_1: 'アイテムのステータスが次のように変更されました：',
  interaction_action_2: 'アイテムサポートプロファイルが割り当てられました。',
  interaction_action_3: 'アイテムサポートプロファイルは削除されました。',
  interaction_action_4: '通知：',
  interaction_action_5: '警告：',
  interaction_action_6: '警告：',
  interaction_administrative_title: '管理上の変更',
  task_steps: {
    status_check: '現在のアイテムのステータスは次のとおりです：{ステータス}',
  },
  item_status: '{スターテス}',
  interaction_collections_title: 'コレクション-{テキスト}',
  interaction_collection_added_title: 'コレクションに追加-{テキスト}',
  step_collection_fields: '田畑：',
  publicPage: {
    positioning: {
      before: '前',
      after: '後',
      top: '上',
      bottom: '下',
    },
    components: {
      longDescription: '長い説明',
      attachments: '添付ファイル',
      attributes: '属性',
      services: 'サービス',
      gallery: 'ギャラリー',
      certifications: '認定',
      video: 'ビデオ',
      history: '歴史',
      supportProfile: 'サポートプロファイル',
      fs1TaskPanel: 'FS1タスクパネル',
      fs1About: 'FS1について',
      fs1FactsPanel: 'FS1ファクトパネル',
      fs1ResourcesPanel: 'FS1リソースパネル',
      events: 'イベント',
    },
  },
  OrganisationalSettings: {
    header: '組織の設定',
    components: {
      general: '全般的',
      branding: 'ブランディング',
    },
    general: {
      name: '名前',
      businessType: '事業の種類',
      OrganizationLogo: '組織のロゴ',
      PublicPageLoadingImage: 'パブリックページの読み込み画像',
    },
  },
  tasks: {
    tableHeader: {
      name: 'タスク名',
      usedAsDiscovery: '発見タスク',
      interactions: '使用回数',
      things: '割り当て',
      createdAt: 'で作成',
      createdBy: 'によって作成された',
      displayConditions: '表示条件',
    },
    taskModal: {
      headerCreate: 'タスクを作成する',
      headerEdit: 'タスクの編集',
      components: {
        selectTask: 'タスクを選択',
      },
    },
  },
  categories: {
    title: 'カテゴリー',
    noDataMessage1: 'カテゴリを作成する',
    tableHeaders: {
      name: '名前',
      defaultTemplate: 'デフォルトのテンプレート',
      createdAt: 'で作成',
      createdBy: 'によって作成された',
    },
    editCategoryModal: {
      createCategory: 'カテゴリを作成する',
      editCategory: 'カテゴリを編集する',

      labels: {
        name: '種別名',
        standard: '識別基準',
        identifier: 'カテゴリ識別子',
        description: '説明',
        image: 'カテゴリ画像',
        defaultTemplate: 'このカテゴリ用に作成された各製品は、デフォルトでこのテンプレートに設定されます。',
      },
    },
  },
  supportProfile: {
    title: 'サポートプロファイル',
    addSupportProfile: 'サポートプロファイルを追加',
    createMessage1: 'サポートプロファイルを作成する',
    tableHeaders: {
      name: '名前',
      content: 'コンテンツ',
      displayConditions: '表示条件',
      contactMethods: '連絡方法',
    },
    editSupportProfileModal: {
      createSupportProfile: 'サポートプロファイルを作成する',
      editSupportProfile: 'サポートプロファイルの編集',
      labels: {
        name: '名前',
        headerText: 'ヘッダーテキスト',
        mainText: '本文',
        mainContent: 'メインコンテンツ',
        showExpanded: '拡大表示',
        phone: '電話',
        email: 'Eメール',
        imagery: '画像',
        primary: '主要な',
        secondary: '二次',
        kmlMapType: 'KML マップ タイプ',
        kmlLink: 'KML リンク',
        kmlFile: 'KML ファイル',
      },
    },
  },
  templates: {
    title: 'テンプレート',
    tableHeaders: {
      name: '名前',
      createdAt: 'で作成',
      createdBy: 'によって作成された',
      assignedCategories: '割り当てられたカテゴリ',
    },
    editTemplateModal: {
      createTemplate: 'テンプレートを作成する',
      editTemplate: 'テンプレートを編集する',
      labels: {
        templateName: 'テンプレート名',
        templateDescription: 'テンプレートの説明',
        templateImage: 'テンプレート画像',
      },
    },
    templateCreatedModal: {
      title: 'テンプレートが作成されました',
      message: 'テンプレートが正常に作成されました。今すぐ管理して、属性、タスク、画像、その他のコンテンツを追加しますか？',
      later: '後でやる',
      now: '今すぐ管理',
    },
    templateCreatedProductModal: {
      title: '作成された製品',
      message: '製品が正常に作成されました。今すぐ管理して、属性、タスク、画像、その他のコンテンツを追加しますか？',
    },
  },
  user: {
    viewInvitations: '招待状を表示',
    inviteUsers: 'ユーザーを招待する',
    to: 'に',
    userHeaders: {
      name: '名前',
      email: 'Eメール',
      role: '役割',
      access: 'アクセス',
      status: 'スターテス',
    },
    userType: {
      admin: '管理者',
      member: 'メンバー',
    },
  },
  webhooks: {
    webhookHeaders: {
      name: '名前',
      createdAt: 'で作成',
      createdBy: 'によって作成された',
    },
    webhookModal: {
      headerCreate: 'チームメンバー、他のシステム、および信頼できるサードパーティとの組織の相互作用の共有を開始するためのアクション。',
      components: {
        emailSettings: 'メール設定',
        webhookSettings: 'Webhook設定',
        integrations: '統合',
        thingsAndTasks: '物事とタスク',
      },
    },
    actions: '行動',
    editAction: 'アクションの編集',
  },
  valueChain: {
    headerCreate: '価値連鎖',
    headerEdit: 'バリューチェーンを編集する',
    editAChainElement: 'チェーン要素を編集する',
    createAChainElement: 'チェーン要素を作成する',
    headerDescription: '始めるのに役立つテンプレートを選択してください。',
    organisationActivity: '組織活動',
    components: {
      miscellaneousContent: 'その他のコンテンツ',
      logisticsAndEnvironment: 'ロジスティクスと環境',
      generalInformation: '一般情報',
    },
    tableHeaders: {
      creationDate: '作成日',
      createdBy: 'によって作成された',
    },
    templates: {
      justTheBasics: {
        name: '基本だけ',
        description: 'Rfiderで使用されるデフォルトのバリューチェーン。',
      },
      beverage: {
        name: '飲料',
        description:
          'サプライチェーン全体を通じて飲料製品を追跡および追跡する機能は、現在、消費者によって推進されています。彼らは、すべての製品の起源を、原料が栽培されている分野や、補助材料や包装用品を生産する施設にまでさかのぼって知りたいと考えています。',
      },
      coffee: {
        name: 'コーヒー',
        description: '朝のカップに入る前に、コーヒー豆は複雑なグローバルサプライチェーンを通過します。',
      },
      honey: {
        name: 'はちみつ',
        description: 'ハニーは、蜂の巣から消費者まで、複雑なグローバルサプライチェーンを移動します。',
      },
      porterValueChain: {
        name: 'ポーターバリューチェーン',
        description:
          'ポーターのバリューチェーンは、部門や経理のコストタイプではなく、システムと、消費者が購入したアウトプットにインプットをどのように変更するかに焦点を当てています。',
      },
      usaidCommodity: {
        name: 'USAid商品',
        description:
          'このプロセスは、USAIDに食品の要求が行われたときに開始されます。USAIDは、契約入札を求め、承認された要求のために食品を調達します。次に、商品はサプライヤーから、米国の港、外国の排出港、米国および国際的なUSAID事前配置倉庫など、サプライチェーンのさまざまなロジスティクスノードにある輸送ポイントまたは倉庫施設に送られます。プロセスのある時点で、食品は次のようになります。パートナー組織に転送され、パートナー組織は、独自の輸送および流通パートナーネットワークと独自の情報追跡システムを使用して食料品を流通させる責任があります。',
      },
      wine: {
        name: 'ワイン',
        description:
          'ワインのバリューチェーンは、ブドウ園の準備、ブドウの生産（ブドウ栽培）、ワインの生産（ブドウ栽培）、ロジスティクス、流通、マーケティング、販売の5つの幅広い生産段階で構成されています。',
      },
    },
  },
  documents: {
    title: 'ドキュメント',
    labels: {
      documentName: 'ドキュメント名',
      gdtiNumber: 'GDTI番号',
      valueChainSteps: 'バリューチェーンのステップ',
      publicRecord: '公共記録',
      dateModified: '日付が変更されました',
    },
    valueChainSteps: {
      Unclassified: '未分類',
    },
  },
  products: {
    tableHeaders: {
      name: '名前',
      category: 'カテゴリー',
      tasks: '割り当てられたタスク',
      interactions: '記録されたイベント',
      items: 'アイテム',
    },
    menuOptions: {
      manage: '管理',
      duplicate: '複製',
      updateFromClipboard: 'クリップボードからの更新',
    },
    bulkActions: {
      addTasks: 'タスクの追加',
      removeTasks: 'タスクを削除する',
      addValueChain: 'バリューチェーンの追加',
      addSupportProfile: 'サポートプロファイルを追加',
      removeSupportProfile: 'サポートプロファイルを削除する',
    },
    productCreatedModal: {
      title: '作成された製品',
      message: '製品が正常に作成されました。今すぐ管理して、属性、タスク、画像、その他のコンテンツを追加しますか？',
      later: '後でやる',
      now: '今すぐ管理',
    },
    title: '製品',
  },
  collaborations: {
    header: {
      step: 'ステップ',
      title: 'クリップボードからの更新',
    },
    subTitles: {
      general: '全般的',
      productInfo: '商品情報',
      attributes: '属性',
      productDocuments: 'ドキュメント',
      itemAndBatchDocuments: 'アイテムおよびバッチドキュメント',
      images: '画像',
      videos: 'ビデオ',
      eventHistory: 'イベント履歴',
      contentTransferSummary: 'コンテンツ転送の概要',
    },
    descriptiveContent: {
      noData: '利用可能な一般的な情報はありません。',
      description: '一般情報は製品レベルで転送され、すべてのバッチに追加されます。',
      target: '目標',
      source: 'ソース',
      labels: {
        name: '名前',
        shortDescription: '簡単な説明',
        longDescription: '長い説明',
      },
      longDescriptionButtons: {
        append: '追加する',
        replace: '交換',
      },
    },
    attributes: {
      noData: '使用可能な属性はありません。',
      description: '属性は製品レベルで転送され、すべてのバッチに追加されます。',
      warningMessage: '選択した属性は、製品のすべてのバッチに適用されます',
    },
    itemAndBatchDocuments: {
      noData: '利用可能なドキュメントはありません。',
      warningMessage: '選択したドキュメントは、製品のそれぞれのターゲットアイテムまたはバッチにのみ追加されます',
    },
    images: {
      noData: '利用可能な画像はありません。',
      description: '画像は製品レベルで転送され、すべてのバッチに追加されます。',
      selectAllButton: 'すべて選択',
    },
    videos: {
      noData: '利用可能なビデオはありません。',
      description: 'ビデオは製品レベルで転送され、すべてのバッチに追加されます。',
      selectAllButton: 'すべて選択',
    },
    eventHistory: {
      noData: '公開コンテンツで利用できるタスクはありません。',
      noItemBatch: 'イベントを保存するために使用できるアイテムまたはバッチはありません。',
      labels: {
        name: '名前',
        classification: '分類',
      },
    },
    confirmationScreen: {
      noData: 'コンテンツが選択されていません。',
      message: 'クリップボードから更新しています...',
      successfulMessage: 'クリップボードからの更新に成功',
    },
  },
  item: {
    filters: {
      doNotUse: '使ってはいけません',
      allClear: 'オールクリア',
      normalPrecaution: '通常の注意事項',
      reconsiderUse: '使用を再検討する',
      increasedCaution: '注意の強化',
      unknownId: '不明なID',
      itemOk: 'アイテムOK',
      ready: '準備',
      active: 'アクティブ',
      resultPending: '結果は保留中',
      completedPositive: '完了したポジティブ',
      completedNegative: 'ネガティブ完了',
      completedInconclusive: '未定完了',
      itemLocked: 'アイテムがロックされています',
      itemUnlocked: 'アイテムのロックが解除されました',
      unused: '未使用',
      returned: '戻ってきた',
      readyFilled: '準備完了',
      readyPacked: 'レディパック',
      withCustomer: 'お客様と',
      issueRaised: '提起された問題',
      pickupRequested: 'ピックアップが要求されました',
    },
  },
  thing: {
    menus: {
      about: 'だいたい',
      discovery: '発見',
      public: '公衆',
      tasks: 'タスク',
      support: 'サポート',
    },
    about: {
      general: '全般的',
      category: 'カテゴリー',
      documentation: 'ドキュメンテーション',
      attributes: '属性',
      certifications: '認定',
      miscellaneous: 'その他',
      valueChains: 'バリューチェーン',
      gallery: 'ギャラリー',
      advancedSettings: '高度な設定',
      changeACategory: 'カテゴリを変更する',
      changeAValueChain: 'バリューチェーンを変える',

      certificationsLabel: {
        addCertification: '認定を追加',
        editCertification: '認定の編集',
      },
    },
    public: {
      generalSettings: '一般設定',
      enablePublicContent: '公開コンテンツを有効にする',
      itemCheck: 'アイテムチェック',
      usedAsDiscovery: '発見タスク',
      advancedSettings: '高度な設定',
      rfiderAuthenticityCheck: 'Rfiderの信頼性チェック',
      basicInformation: '基本情報',
      brandExperience: 'ブランド体験',
      links: 'リンク',
      miscellaneous: 'その他',
      attributes: '属性',
      services: 'サービス',
      documents: 'ドキュメント',
      gallery: 'ギャラリー',
      certifications: '認定',
      videos: 'ビデオ',
      longDescription: '長い説明',
      supportProfile: 'サポートプロファイル',
      itemHistory: 'アイテム履歴',
      none: 'なし',
      heroImage: 'ヒーロー画像',
      sectionImage: '断面画像',
      heroOverlayImage: 'ヒーローオーバーレイ画像',
      privacyPolicy: 'プライバシーポリシー',
      productInfo: '製品情報',
      learnMore: 'もっと詳しく知る',
      shopping: '買い物',
      section: 'セクション',
      position: '位置',
      enableAttributes: '属性を有効にする',
      attributeVisibility: '属性の可視性',
      enableAttachments: '添付ファイルを有効にする',
      enableCertifications: '認定を有効にする',
      allowProfles: 'チームメンバーの個人プロファイルを許可する',
      surviceLabels: {
        enableServices: 'サービスを有効にする',
        serviceVisibility: 'サービスの可視性',
        basic: '基本',
        off: 'オフ',
        peerToPeer: 'ピアツーピア',
      },
      itemHistoryLabels: {
        enableItemHistory: 'アイテム履歴を有効にする',
        displayTemplate: 'テンプレートを表示',
        events: 'イベント',
        data: 'データ',
        images: '画像',
        eventGallery: 'イベントギャラリー',
        enableEventGallery: 'イベントギャラリーを有効にする',
      },
      switch: {
        confirmTextEnable: '有効にしてよろしいですか？',
        confirmTextDisable: '無効にしてもよろしいですか？',
        confirmButtonEnable: 'はい、有効にしてください！',
        confirmButtonDisable: 'はい、無効にしてください！」',
      },
      enablePublicLink: 'パブリックリンクを有効にする',
      attributeLabels: {
        editAttribute: '属性の編集',
        addAttribute: '属性を追加',
        label: 'ラベル',
        value: '価値',
        type: 'タイプ',
      },
    },
  },
  filter: {
    createdByMe: '私が作成',
  },
  swal: {
    duplicate: '複製',
    confirmTitle: '本気ですか？',
    warningMessage: 'これを元に戻すことはできません！',
    confirmButtonDelete: 'はい、削除してください！',
    confirmButtonRemove: 'はい、削除してください！',
    confirmButtonDuplicate: 'はい、複製してください！',
    confirmButtonReset: 'はい、リセットしてください！',
    confirmButtonResend: 'はい、再送してください！',
    confirmButtonDecline: 'はい、断ります！',
    confirmButtonAccept: 'はい、受け入れてください！',
    keepIt: 'いいえ、保管してください',
    userText: 'もう一度招待する必要があります。',
    associationError: 'アソシエーションは作成されません！',
    template: {
      confirmButtonText: 'はい、新製品を作成してください！',
      createProduct: '製品の作成',
      successMessage: 'このテンプレートから新製品が作成されました',
      errorMessage: 'このテンプレートから新製品を作成することはできません',
      successMessage1: 'テンプレートが複製されました',
    },
    products: {
      successMessage: '製品が複製されました',
      errorMessage: '製品を複製することはできません',
      createProductError: 'このテンプレートから新製品を作成することはできません',
    },
    valueChain: {
      successMessage: 'バリューチェーンが重複しています',
      errorMessage: 'バリューチェーンを複製できませんでした',
      successMessageThingAbout: 'この製品のバリューチェーンが変更されました',
      errorMessageThingAbout: 'この製品のバリューチェーンを変更できませんでした',
      thingValueChainText: 'これは変更しないことをお勧めします。',
      lastChance: '最後のチャンス',
      lastChanceText: 'これまでに収集されたデータは、バリューチェーンビューに表示されません。この変更は元に戻せません。',
    },
    user: {
      successMessage: 'ユーザーへの招待状を再送する',
      errorMessage: 'ユーザーに招待状を再送信できませんでした',
      successMessage1: 'ユーザーが更新されました',
      errorMessage1: 'ユーザーを更新できませんでした',
    },
    invitations: {
      titleDecline: '招待を辞退しますか？',
      titleAccept: '招待を受ける？',
      text: 'あなたは参加するように招待されています',
    },
    category: {
      successMessageThingAbout: 'この商品のカテゴリが変更されました',
      errorMessageThingAbout: 'この製品のカテゴリを変更できませんでした',
      thingCategoryText: 'これは変更しないことをお勧めします。',
      lastChance: '最後のチャンス',
      lastChanceText: 'この変更は元に戻せません。',
    },
    document: {
      createDocument: 'ドキュメントが作成されました',
      createDocumentError: 'ドキュメントを作成できませんでした',
      updateDocument: 'ドキュメントが更新されました',
      updateDocumentError: 'ドキュメントを更新できませんでした',
      deleteDocument: 'ドキュメントが削除されました',
      deleteDocumentError: 'ドキュメントを削除できませんでした',
    },
    task: {
      text: '変更を保存せずにタスク構成を終了しようとしています。保存されていない変更はすべて失われます。続行しますか？',
      deleteTask: 'タスクが削除されました',
      updateTask: 'タスクが更新されました',
      updateTaskError: 'タスクを更新できませんでした',
      createTask: 'タスクが作成されました',
      createTaskTitle: '正常に作成されました！',
      createTaskText: 'その構成を開きますか？',
      labels: {
        configuration: '構成',
      },
    },
    interaction: {
      createSuccess: '作成されたインタラクション',
      createError: 'インタラクションを作成できませんでした。',
      mapLoadMessage: '読み込みエラー',
      mapLoadError: 'マップにインタラクションを読み込めませんでした',
      updateSuccess: 'インタラクションが更新されました',
      updateError: 'インタラクションは更新できません',
      deleteSuccess: 'インタラクションは削除されました',
      deleteError: 'インタラクションは削除できません。',
    },
    item: {
      itemsLimitError: '入力オプションの制限は、{itemsLimit}アイテムのみです。',
      removeSupportProfile: {
        title: '本気ですか？',
        success: 'パーソナルサポートプロファイルが削除されました',
        error: 'パーソナルサポートプロファイルを削除できませんでした',
      },
    },
    miscellaneous: {
      noImageError: '画像を追加してください',
      undefinedNotificationType: '通知タイプのいずれかを選択してください',
    },
    webhooks: {
      deleteAction: 'アクションは削除されました',
      deleteActionError: 'アクションを削除できませんでした',
      updateActionStatus: 'アクションステータスが更新されました',
      updateActionStatusError: 'アクションステータスを更新できませんでした',
      updateAction: 'アクションが更新されました',
      updateActionError: 'アクションを更新できませんでした',
      createWebhook: 'Webhookが作成されました',
    },
  },
  invitations: {
    title: '招待状',
    records: '記録',
  },
  languages: {
    english: 'English',
    german: 'Deutsch',
    spanish: 'Español',
    japanese: '日本',
    korean: '한국인',
    dutch: 'Nederlands',
    portuguese: 'Português',
    chinese: '中国人',
    chineseTraditional: '中國人',
  },
  editMiscellaneous: {
    editMiscellaneous: 'その他の編集',
    addMiscellaneous: 'その他を追加',
    editVideo: 'ビデオの編集',
    addVideo: 'ビデオを追加',
    editOptions: 'オプションの編集',
    addOptions: 'オプションの追加',
    labels: {
      embeddedLink: '埋め込みリンクのみ',
      videoEmbed: 'ビデオ埋め込みコード',
      codeInjection: 'コードインジェクション',
      imageLink: '画像リンク',
    },
  },
  discovery: {
    labels: {
      batchId: 'バッチID',
      batchName: 'バッチ名',
      createdBy: 'によって作成された',
      itemCount: 'アイテム数',
      used: '％ 中古',
    },
    buttons: {
      identificationMethod: '識別方法',
      createBatch: 'バッチの作成',
      deactivate: '非アクティブ化',
      activate: '活性化',
      delete: '消去',
    },
    maximumItemsPerBatch: 'バッチあたりの最大アイテム',
    batches: 'バッチ',
    beta: 'ベータ',
    item: 'アイテム',
    product: '製品',
    selectIdentificationMethod: '識別方法を選択します',
    message: 'Rfiderの一意のID（RFUID）は、Rfiderプラットフォームで管理されるアイテムのシステム生成識別子です。',
  },
  dashboard: {
    dateRange: {
      today: '今日',
      lastSevenDays: '過去7日間',
      lastWeek: '先週',
      lastThirtyDays: '過去30日間',
      lastMonth: '先月',
      lastTwelvemonths: '過去12か月',
      custom: 'カスタム',
    },
    chart: {
      today: '今日',
      allTime: 'いつも',
      totalInteractions: 'トータルインタラクション',
      totalTasks: '総タスク',
      totalServices: 'トータルサービス',
      noData: 'データなし',
      overallStatusBreakdown: '全体的なステータスの内訳',
    },
  },
  displayConditionsRules: {
    onlyAdmin: 'ユーザー権限',
    or: 'また',
    andAlso: 'そしてまた',
    addYourFirstCondition: '最初の条件を追加します',
    useSeparator: '区切り文字としてコンマを使用する',
    rfuidItemId: 'RFUIDアイテムID',
    rfuidBatchId: 'RFUIDバッチID',
    rfuidSequenceNumber: 'RFUIDシーケンス番号',
    useDashSeparator: '区切り記号としてダッシュ (-) を使用する',
    itemStatus: 'アイテムステータス',
    userCountry: 'ユーザーの国',
    userLanguage: 'ユーザー言語',
    conditions: {
      stringEquals: '文字列は等しい',
      stringNotEquals: '文字列が等しくない',
      includedIn: 'に含まれる',
      notIncludedIn: '含まれていません',
      numberEquals: '数は等しい',
      numberNotEquals: '数が等しくない',
      numberGreaterThan: 'より大きい数',
      numberLessThan: '未満の数',
      between: '間',
    },
    countries: {
      afghanistan: 'アフガニスタン',
      alandIslands: 'オーランド諸島',
      albania: 'アルバニア',
      algeria: 'アルジェリア',
      americanSamoa: 'アメリカ領サモア',
      andorra: 'アンドラ',
      angola: 'アンゴラ',
      anguilla: 'アンギラ',
      antarctica: '南極大陸',
      antigua: 'アンティグアバーブーダ',
      argentina: 'アルゼンチン',
      armenia: 'アルメニア',
      aruba: 'アルバ',
      australia: 'オーストラリア',
      austria: 'オーストリア',
      azerbaijan: 'アゼルバイジャン',
      bahamas: 'バハマ',
      bahrain: 'バーレーン',
      bangladesh: 'バングラデシュ',
      barbados: 'バルバドス',
      belarus: 'ベラルーシ',
      belgium: 'ベルギー',
      belize: 'ベリーズ',
      benin: 'ベナン',
      bermuda: 'バミューダ',
      bhutan: 'ブータン',
      bolivia: 'ボリビア',
      bonaire: 'ボネール',
      bosnia: 'ボスニア・ヘルツェゴビナ',
      botswana: 'ボツワナ',
      bouvet: 'ブーベ島',
      brazil: 'ブラジル',
      britishIndian: 'イギリス領インド洋地域',
      brunei: 'ブルネイダルサラーム',
      bulgaria: 'ブルガリア',
      burkina: 'ブルキナファソ',
      burundi: 'ブルンジ',
      cambodia: 'カンボジア',
      cameroon: 'カメルーン',
      canada: 'カナダ',
      capeVerde: 'カーボベルデ',
      caymanIslands: 'ケイマン諸島',
      centralAfricanRepublic: '中央アフリカ共和国',
      chad: 'チャド',
      chile: 'チリ',
      china: '中国',
      christmasIsland: 'クリスマス島',
      cocos: 'ココス（キーリング）諸島',
      colombia: 'コロンビア',
      comoros: 'コモロ',
      congo: 'コンゴ',
      congoDemocratic: 'コンゴ',
      cookIslands: 'クック諸島',
      costaRica: 'コスタリカ',
      coteIvo: 'コートジボワール',
      croatia: 'クロアチア',
      cuba: 'キューバ',
      curacao: 'キュラソー',
      cyprus: 'キプロス',
      czechRepublic: 'チェコ共和国',
      denmark: 'デンマーク',
      djibouti: 'ジブチ',
      dominica: 'ドミニカ',
      dominicanRepublic: 'ドミニカ共和国',
      ecuador: 'エクアドル',
      egypt: 'エジプト',
      elSalvador: '救世主',
      equatorialGuinea: '赤道ギニア',
      eritrea: 'エリトリア',
      estonia: 'エストニア',
      ethiopia: 'エチオピア',
      falkland: 'フォークランド諸島（マルビナス）',
      faroeIslands: 'フェロー諸島',
      fiji: 'フィジー',
      finland: 'フィンランド',
      france: 'フランス',
      frenchGuiana: 'フランス領ギアナ',
      frenchPolynesia: 'フランス領ポリネシア',
      frenchSouthern: 'フランス領南方南方領土',
      gabon: 'ガボン',
      gambia: 'ガンビア',
      georgia: 'ジョージア',
      germany: 'ドイツ',
      ghana: 'ガーナ',
      gibraltar: 'ジブラルタル',
      greece: 'ギリシャ',
      greenland: 'グリーンランド',
      grenada: 'グレナダ',
      guadeloupe: 'グアドループ',
      guam: 'グアム',
      guatemala: 'グアテマラ',
      guernsey: 'ガーンジー',
      guinea: 'ギニア',
      guineaBissau: 'ギニアビサウ',
      guyana: 'ガイアナ',
      haiti: 'ハイチ',
      heardIsland: 'ハード島とマクドナルド諸島',
      holySee: '聖座（バチカン市国）',
      honduras: 'ホンジュラス',
      hongKong: '香港',
      hungary: 'ハンガリー',
      iceland: 'アイスランド',
      india: 'インド',
      indonesia: 'インドネシア',
      iran: 'イラン',
      iraq: 'イラク',
      ireland: 'アイルランド',
      isleOfMan: 'マン島',
      israel: 'イスラエル',
      italy: 'イタリア',
      jamaica: 'ジャマイカ',
      japan: '日本',
      jersey: 'ジャージー',
      jordan: 'ヨルダン',
      kazakhstan: 'カザフスタン',
      kenya: 'ケニア',
      kiribati: 'キリバス',
      korea: '韓国',
      koreaRepublic: '韓国',
      kuwait: 'クウェート',
      kyrgyzstan: 'キルギスタン',
      lao: '労働',
      latvia: 'ラトビア',
      lebanon: 'レバノン',
      lesotho: 'レソト',
      liberia: 'リベリア',
      libya: 'リビア',
      liechtenstein: 'リヒテンシュタイン',
      lithuania: 'リトアニア',
      luxembourg: 'ルクセンブルク',
      macao: 'マカオ',
      macedonia: 'マケドニア',
      madagascar: 'マダガスカル',
      malawi: 'マラウイ',
      malaysia: 'マレーシア',
      maldives: 'モルディブ',
      mali: 'マリ',
      malta: 'マルタ',
      marshall: 'マーシャル諸島',
      martinique: 'マルティニーク',
      mauritania: 'モーリタニア',
      mauritius: 'モーリシャス',
      mayotte: 'Mayotte',
      mexico: 'メキシコ',
      micronesia: 'ミクロネシア',
      moldova: 'モルダビア',
      monaco: 'モナコ',
      mongolia: 'モンゴル',
      montenegro: 'モンテネグロ',
      montserrat: 'モントセラト',
      morocco: 'モロッコ',
      mozambique: 'モザンビーク',
      myanmar: 'ミャンマー',
      namibia: 'ナミビア',
      nauru: 'ナウル',
      nepal: 'ネパール',
      netherlands: 'オランダ',
      newCaledonia: 'ニューカレドニア',
      newZealand: 'ニュージーランド',
      nicaragua: 'ニカラグア',
      niger: 'ニジェール',
      nigeria: 'ナイジェリア',
      niue: 'ニウエ',
      norfolk: 'ノーフォーク島',
      northern: '北マリアナ諸島',
      norway: 'ノルウェー',
      oman: 'オマーン',
      pakistan: 'パキスタン',
      palau: 'パラオ',
      palestinian: 'パレスチナ自治区、占領下',
      panama: 'パナマ',
      papua: 'パプアニューギニア',
      paraguay: 'パラグアイ',
      peru: 'ペルー',
      philippines: 'フィリピン',
      pitcairn: 'ピトケアン',
      poland: 'ポーランド',
      portugal: 'ポルトガル',
      puertoRico: 'プエルトリコ',
      qatar: 'カタール',
      reunion: 'ミーティング',
      romania: 'ルーマニア',
      russian: 'ロシア連邦',
      rwanda: 'ルワンダ',
      saintBarth: 'サンバルテルミー',
      saintHelena: 'セントヘレナ',
      saintKitts: 'セントクリストファーネイビス',
      saintLucia: 'セントルシア',
      saintMartin: 'サンマルタン（フランス語部）',
      saintPierre: 'サンピエール島とミクロン島',
      saintVincent: 'セントビンセントおよびグレナディーン諸島',
      samoa: 'サモア',
      sanMarino: 'サンマリノ',
      saoTome: 'サントメ・プリンシペ',
      saudiArabia: 'サウジアラビア',
      senegal: 'セネガル',
      serbia: 'セルビア',
      seychelles: 'セイシェル',
      sierra: 'シエラレオネ',
      singapore: 'シンガポール',
      sintMaarten: 'シント・マールテン（オランダ語部）',
      slovakia: 'スロバキア',
      slovenia: 'スロベニア',
      solomon: 'ソロモン諸島',
      somalia: 'ソマリア',
      southAfrica: '南アフリカ',
      southGeorgia: 'サウスジョージアおよびサウスサンドイッチ諸島',
      southSudan: '南スーダン',
      spain: 'スペイン',
      sriLanka: 'スリランカ',
      sudan: 'スーダン',
      suriname: 'スリナム',
      svalbard: 'スバールバル諸島およびヤンマイエン',
      swaziland: 'スワジランド',
      sweden: 'スウェーデン',
      switzerland: 'スイス',
      syrian: 'シリアアラブ共和国',
      taiwan: '台湾',
      tajikistan: 'タジキスタン',
      tanzania: 'タンザニア',
      thailand: 'タイ',
      timorLeste: '東ティモール',
      togo: 'トーゴ',
      tokelau: 'トケラウ',
      tonga: 'トンガ',
      trinidad: 'トリニダード・トバゴ',
      tunisia: 'チュニジア',
      turkey: '七面鳥',
      turkmenistan: 'トルクメニスタン',
      turksAndCaicosIslands: 'タークス・カイコス諸島',
      tuvalu: 'ツバル',
      uganda: 'ウガンダ',
      ukraine: 'ウクライナ',
      uae: 'アラブ首長国連邦',
      uk: 'イギリス',
      usa: 'アメリカ',
      usMinor: '合衆国領有小島',
      uruguay: 'ウルグアイ',
      uzbekistan: 'ウズベキスタン',
      vanuatu: 'バヌアツ',
      venezuelaBolivarian: 'ベネズエラ',
      vietnam: 'ベトナム',
      virginIslandsBritish: 'ヴァージン諸島',
      virginIslandsUS: 'ヴァージン諸島',
      wallisAndFutuna: 'ウォリス・フツナ',
      westernSahara: '西サハラ',
      yemen: 'イエメン',
      zambia: 'ザンビア',
      zimbabwe: 'ジンバブエ',
    },
    languages: {
      ab: 'アブハジア人',
      aa: '遠く',
      af: 'アフリカーンス語',
      ak: '意思',
      sq: 'アルバニア語',
      am: 'アムハラ語',
      ar: 'アラビア語',
      an: 'アラゴン語',
      hy: 'アルメニア語',
      as: 'アッサム語',
      av: 'アヴァル語',
      ae: 'アヴェスター語',
      ay: 'アイマラ',
      az: 'アゼルバイジャン',
      bm: 'バンバラ',
      ba: 'バシキール人',
      eu: 'バスク',
      be: 'ベラルーシ語',
      bn: 'ベンガル語',
      bh: 'ビハール語',
      bi: 'ビスラマ語',
      bs: 'ボスニア',
      br: 'ブルトン語',
      bg: 'ブルガリア語',
      my: 'ビルマ語',
      ca: 'カタロニア語;バレンシア語',
      km: '中央クメール',
      ch: 'チャモロ',
      ce: 'チェチェン',
      ny: 'チェワ語;チェワ;海',
      zhCn: '中国語（簡体字）',
      zhTw: '中国の伝統的な）',
      cu: '教会スラヴ語',
      cv: 'チュヴァシ人',
      kw: 'コーニッシュ',
      co: 'コルシカ語',
      cr: '信じる',
      hr: 'クロアチア語',
      cs: 'チェコ語',
      da: 'デンマーク語',
      dv: 'ディベヒ語',
      nl: 'オランダの;フラマン語',
      dz: 'ゾンカ語',
      en: '英語',
      eo: 'エスペラント',
      et: 'エストニア語',
      ee: '雌羊',
      fo: 'フェロー語',
      fj: 'フィジー',
      fi: 'フィンランド語',
      fr: 'フランス語',
      ff: 'フラニ語',
      gd: 'ゲール語',
      gl: 'ガリシア語',
      lg: 'ダブル',
      ka: 'ジョージア語',
      de: 'ドイツ人',
      el: 'ギリシャ語',
      gn: 'グアラニー語',
      gu: 'グジャラート語',
      ht: 'ハイチ',
      ha: 'ハウサ語',
      he: 'ヘブライ語',
      hz: 'ヘレロ',
      hi: 'いいえ。',
      ho: 'ヒリモツ語',
      hu: 'ハンガリー語',
      is: 'アイスランド語',
      io: 'なくなった',
      ig: 'イボ',
      id: 'インドネシア語',
      iu: 'イヌクティトゥット語',
      ik: 'イヌピアット',
      ga: 'アイルランド人',
      it: 'イタリアの',
      ja: '日本',
      jv: 'ジャワ語',
      kl: 'グリーンランド語',
      kn: 'カンナダ語',
      kr: 'カヌリ語',
      ks: 'カシミール人',
      kk: 'カザフ',
      ki: 'キクユ;キクユ',
      rw: 'ルワンダ語',
      ky: 'キルギス;キルギス',
      kv: '来て',
      kg: 'コンゴ',
      ko: '韓国語',
      kj: '動物;生きている',
      ku: 'クルド',
      lo: '労働',
      la: 'ラテン',
      lv: 'ラトビア語',
      li: 'リンバーガー',
      ln: 'リンガラ語',
      lt: 'リトアニア語',
      lu: 'ルバ・カタンガ',
      lb: 'ルクセンブルク語',
      mk: 'マケドニアの',
      mg: 'マダガスカル',
      ms: 'マレー語',
      ml: 'マラヤーラム語',
      mt: 'マルタ語',
      gv: 'マンクス',
      mi: 'マオリ',
      mr: 'マラーティー語',
      mh: 'マーシャル語',
      mn: 'モンゴル',
      na: 'ナウル',
      nv: 'ナバホ;ナバホ',
      ng: 'ンドンガ語',
      ne: 'ネパール',
      se: '北部サーミ語',
      no: 'ノルウェー語',
      oj: 'オジブワ',
      or: 'オリヤー語',
      om: 'オロモ',
      os: 'オセット人;オセティック',
      pi: 'パーリ語',
      pa: 'パンジャブ語;パンジャブ語',
      fa: 'ペルシア語',
      pl: '研磨',
      ptBr: 'ポルトガル語',
      ps: 'パシュトゥー語;パシュトゥー語',
      qu: 'ケチュア',
      ro: 'ルーマニア語',
      rm: 'ロマンシュ語',
      rn: 'ラウンド',
      ru: 'ロシア',
      sm: 'サモア人',
      sg: 'Sango',
      sa: 'サンスクリット',
      sc: 'サルデーニャ語',
      sr: 'セルビア語',
      sn: 'ショナ語',
      ii: '四川イ;四川彝',
      sd: 'シンド人',
      si: 'シンハラ語;シンハラ語',
      sk: 'スロバキア',
      sl: 'スロベニア語',
      so: 'ソマリア',
      st: 'ソト、南部',
      es: 'スペイン語',
      su: 'スンダ語',
      sw: 'スワヒリ語',
      ss: 'スワティ',
      sv: 'スウェーデンの',
      tl: 'タガログ語',
      ty: 'タヒチアン',
      tg: 'タジク語',
      ta: 'タミル語',
      tt: 'タタール',
      te: 'テルグ語',
      th: 'タイ語',
      bo: 'チベット人',
      ti: 'ティグリニャ語',
      to: 'トンガ',
      ts: 'ツォンガ',
      tn: 'ツワナ',
      tr: 'トルコ語',
      tk: 'トルクメン語',
      tw: '二',
      ug: 'ウイグル;ウイグル',
      uk: 'ウクライナ語',
      ur: 'ウルドゥー語',
      uz: 'ウズベク語',
      ve: 'セール',
      vi: 'ベトナム語',
      vo: 'ヴォラピュク',
      wa: 'ワロン人',
      cy: 'ウェールズ',
      fy: '西フリジア語',
      wo: 'ウォロフ語',
      xh: 'コサ語',
      yi: 'イディッシュ語',
      yo: 'ヨルバ人',
      za: '荘;荘',
      zu: 'ズールー',
    },
  },
  itemSummary: {
    title: 'アイテムコード情報',
    batchId: 'バッチID',
    product: '製品',
    sequence: '順序',
    checksum: 'チェックサム',
    warning:
      'このアイテムの信頼性チェックがアクティブになります。ポータルからアクセスすると、ステータスの更新や後続のスキャン時の警告など、信頼性アラートプロトコルがトリガーされる場合があります。',
    hideUrl: 'URLを非表示',
    showUrl: 'URLを表示',
    copy: 'コピー',
    itemUrlCopied: 'アイテムのURLがコピーされました。',
    productName: '商品名',
    itemId: 'アイテムID',
    itemStatus: 'アイテムステータス',
    itemName: '項目名',
    batchName: 'バッチ名',
  },
  updateReportedData: {
    locationTitle: '位置',
    dateTimeTitle: '日時',
    dateTimeInteraction: 'インタラクション日',
    dateTimeReported: '報告日',
    reset: 'リセット',
    locationInteraction: 'インタラクションの場所',
    locationReported: '報告された場所',
    reportedData: '報告されたデータ',
  },
};
export default ja;
