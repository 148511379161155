<template>
  <div class="interaction-event-classification">
    <div v-if="valueChainItem" class="text-center">
      {{ valueChainItem.name }}
    </div>
    <div v-if="!isMatchedElement" class="md-layout list-item active">
      <div class="md-layout-item md-size-20"></div>
      <div class="md-layout-item md-size-60 title">
        {{ $t('interaction.modal.unclassified') }}
      </div>
      <div class="md-layout-item md-size-20 activity"></div>
    </div>
    <div v-if="elementItems.length">
      <div class="md-layout list-item" :class="{ active: element.id === interactionElementId }" @click="update(element)" v-for="(element, index) in elementItems" :key="index">
        <div class="md-layout-item md-size-20">
          <div
            class="avatar"
            :style="{
              'background-image': !element.image ? 'none' : `url(${mixinGetFilePath(element.image, 'O')})`,
              'background-color': !element.image ? '#0B7EE4' : 'none',
            }"
          >
            <div
              class="avatar-icon"
              :style="{
                'background-image': `url(${mixinGetFilePath(element.icon, 'O')})`,
              }"
            ></div>
          </div>
        </div>
        <div class="md-layout-item md-size-60 title">{{ element.name }}</div>
        <div class="md-layout-item md-size-20 activity">
          <div v-if="element.isDefault">
            <small>{{ $t('interaction.modal.default') }}</small>
          </div>
          <!-- <div v-if="element.isOrganizationActivity">
            <small>Organization Activity</small>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/EventBus';
import gql from 'graphql-tag';
import { query as thingQuery } from '@/graphql/thing';
import { interactionApi } from '@/api/http/api';

export default {
  apollo: {
    tableData: {
      query: gql(thingQuery),
      variables() {
        return {
          thingId: String(this.thingId),
        };
      },
      update(data) {
        return data.thing;
      },
    },
  },
  props: {
    thingId: {
      type: String,
      required: true,
    },
    interactionId: {
      type: String,
      required: true,
    },
    orgId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.getInteraction();
  },
  computed: {
    thingItem() {
      try {
        return this.tableData;
      } catch (e) {
        return {};
      }
    },
    valueChainItem() {
      return this.thingItem && this.thingItem.valueChain ? this.thingItem.valueChain : {};
    },
    elementItems() {
      if (this.thingItem && this.thingItem.valueChain && this.thingItem.valueChain.elements && this.thingItem.valueChain.elements.length) {
        return this.thingItem.valueChain.elements.filter(item => !!item.enabled);
      }
      return [];
    },
    isMatchedElement() {
      if (!this.interaction || !this.interaction.valueChainElement) return false;

      return !!this.elementItems.find(item => item.id === this.interactionElementId);
    },
  },
  data() {
    return {
      tableData: null,
      interaction: {},
      interactionElementId: null,
    };
  },
  methods: {
    async getInteraction() {
      const response = await interactionApi.get(this.orgId, this.interactionId);
      this.interaction = response.data;
      this.interactionElementId = response.data.valueChainElement ? response.data.valueChainElement.id : null;
    },
    update(element) {
      element.valueChainName = this.valueChainItem ? this.valueChainItem.name : '';
      interactionApi
        .update(this.orgId, this.interactionId, {
          valueChainElement: element,
        })
        .then(async () => {
          this.$emit('update-interaction', element);
          this.interaction.valueChainElement = element;
          this.interactionElementId = element.id;
          this.$forceUpdate();
          EventBus.$emit('notify::success', this.$t('swal.interaction.updateSuccess'));
        })
        .catch(() => {
          EventBus.$emit('notify::error', this.$t('swal.interaction.updateError'));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.interaction-event-classification {
  .list-item {
    cursor: pointer;
    text-align: left;
    min-height: 40px;
    margin: 10px 0;

    .title {
      font-weight: 500;
      line-height: 50px;
    }

    .activity {
      font-size: 12px;
      display: table;
      line-height: 50px;

      div {
        display: table-row;
      }
    }
  }
  .avatar {
    text-align: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    margin: 5px auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &-icon {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .active {
    background-color: #eeeeee;
  }
}
</style>
