export const accountOrganizationFragment = `
id
accountId
organizationId
isAdmin
accessPortal
inviteEmail
inviteToken
inviteExpiry
inviteDecline
isSuspended
createdById
createdAt
portalLanguage
`;
