import de from './de';
import en from './en';
import es from './es';
import ja from './ja';
import ko from './ko';
import nl from './nl';
import zh from './zh';
import pt from './pt';
import zhTw from './zhTw';
import enCertBeta from './enCertBeta';

export default {
  de,
  en,
  es,
  ja,
  ko,
  nl,
  pt,
  zh,
  zhTw,
  enCertBeta,
};
