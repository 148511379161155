import { clientInstance as optimizely, getUserId, getAttributes } from '@/utils/optimizely';
import DropDown from './components/Dropdown.vue';
// @ts-ignore
import Debounce from 'lodash.debounce';

const optimizelySDK = {
  loaded: false,
};

if (optimizely) {
  optimizely.onReady().then(() => {
    optimizelySDK.loaded = true;
  });
}

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */
const GlobalComponents = {
  install(Vue: any) {
    Vue.component('drop-down', DropDown);
    Vue.component('wrapper-component', {
      name: 'wrapper-component',
      render(createElement: any) {
        return createElement(
          this.tag,
          {
            on: this.$listeners,
          },
          this.$slots.default,
        );
      },

      props: {
        tag: {
          type: String,
          required: true,
        },
      },
    });
    Vue.component('feature-enabled', {
      data() {
        return {
          initDebounced: Debounce(this.init, 500),
          sdk: optimizelySDK,
          enabled: false,
          config: null,
        };
      },
      render() {
        return this.$scopedSlots.default({
          enabled: this.enabled,
          config: this.config,
        });
      },
      props: {
        id: String,
      },
      methods: {
        init() {
          // @ts-ignore
          const id = this.id;
          // @ts-ignore
          const loaded = this.sdk.loaded;

          let enabled = false;
          let config = null;
          const userId = getUserId();
          if (loaded && id && userId && optimizely) {
            try {
              enabled = optimizely.isFeatureEnabled(id, userId, getAttributes());
              if (enabled) {
                config = optimizely.getAllFeatureVariables(id, userId, getAttributes());
              }
            } catch (err) {
              enabled = false;
              config = null;
            }
          }
          // @ts-ignore
          this.enabled = enabled;
          // @ts-ignore
          this.config = config;
        },
      },
      watch: {
        'sdk.loaded': {
          immediate: true,
          handler(newVal: any) {
            // @ts-ignore
            this.initDebounced();
          },
        },
        id: {
          immediate: true,
          handler(newVal: any) {
            // @ts-ignore
            this.initDebounced();
          },
        },
      },
    });
  },
};

export default GlobalComponents;
