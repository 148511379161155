import Vue from 'vue';
import auth0 from 'auth0-js';
import store from '@/store';
import settings from '@/settings';
import { getJwtClaims } from '@/api/utils/jwt';

const authDomain = settings.AUTH0_domain;
const authclientID = settings.AUTH0_clientID;
const authAudience = settings.AUTH0_audience;
const authRedirectUri = `${window.location.origin}/loggedin`;
const authAfterLogoutUri = `${window.location.origin}/loggedout`;

const webAuth = new auth0.WebAuth({
  domain: authDomain,
  redirectUri: authRedirectUri,
  clientID: authclientID,
  audience: authAudience,
  responseType: 'token id_token',
  scope: 'openid profile email',
});

let auth = new Vue({
  data: () => ({
    refreshInterval: null,
    dataAccessToken: null,
  }),
  methods: {
    login(path, email) {
      webAuth.authorize({
        ...(email && {
          login_hint: email,
        }),
        redirectUri: `${authRedirectUri}?path=` + encodeURIComponent(path),
      });
    },
    signup(path, email) {
      webAuth.authorize({
        screen_hint: 'signup',
        ...(email && {
          login_hint: email,
        }),
        redirectUri: `${authRedirectUri}?path=` + encodeURIComponent(path),
      });
    },
    logout() {
      return new Promise(() => {
        if (this.refreshInterval) {
          clearInterval(this.refreshInterval);
          this.refreshInterval = null;
        }
        webAuth.logout({
          returnTo: authAfterLogoutUri,
          clientID: authclientID,
        });
      });
    },

    isAuthenticated() {
      let exp = null;
      try {
        exp = Number(getJwtClaims(this.accessToken).exp) * 1000;
      } catch (err) {
        return false;
      }
      let ret = new Date().getTime() < exp;
      if (ret && !this.refreshInterval) {
        this.refreshInterval = setInterval(() => this.renewJwt(), 10000);
      }
      return ret;
    },

    handleAuthentication() {
      return new Promise((resolve, reject) => {
        webAuth.parseHash((err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            resolve(this.setAuthValues(authResult.accessToken));
          } else if (err) {
            this.logout();
            reject(err);
          }
        });
      });
    },

    setAuthValues(accessToken) {
      this.accessToken = accessToken;
      return store.dispatch('auth/setJWT', { jwt: accessToken });
    },

    renewJwt() {
      // IF JWT expiring in less than one minute
      let exp = Number(getJwtClaims(this.accessToken).exp) * 1000;
      if (exp - new Date().getTime() < 60000) {
        webAuth.checkSession({}, (err, authResult) => {
          if (authResult && authResult.accessToken) {
            this.setAuthValues(authResult.accessToken);
          } else if (err) {
            this.logout();
          }
        });
      }
    },
  },
  computed: {
    accessToken: {
      cache: false, // really important
      get: function () {
        return this.dataAccessToken;
      },
      set: function (accessToken) {
        this.dataAccessToken = accessToken;
      },
    },
  },
});

export default {
  install: function (Vue) {
    Vue.prototype.$auth = auth;
  },
};
