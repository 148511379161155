<template>
  <div
    class="taskRun text-left"
    :class="{
      absolute: !taskResultMessage,
      'in-modal': !token,
      'in-app': !!token,
    }"
  >
    <div v-if="hasError">Try again</div>
    <template v-else>
      <template v-if="loading">
        <md-progress-spinner :md-diameter="50" :md-stroke="7" md-mode="indeterminate"></md-progress-spinner>
      </template>
      <template v-else-if="taskResultMessage">
        <div class="taskResultMessage text-align--center">
          <div v-if="taskResultMessage.image">
            <img :src="mixinGetFilePath(taskResultMessage.image, 'O')" alt="Result image" />
          </div>
          <div v-if="taskResultMessage.title" class="h3">
            {{ taskResultMessage.title }}
          </div>
        </div>
        <div v-if="taskResult && taskResult.thingId">
          <div class="text-align--center h4 margin-top-lg taskRun-title">Event Classification</div>
          <interaction-event-classification :thingId="taskResult.thingId" :interactionId="taskResult._id" :orgId="taskResult.orgId"></interaction-event-classification>
        </div>
      </template>
      <template v-else>
        <v-offline @detected-condition="isOnline">
          <template #offline>
            <md-snackbar :md-position="'center'" :md-duration="Infinity" :md-active="true">
              <span>Offline</span>
            </md-snackbar>
          </template>
        </v-offline>
        <div v-if="formstack" class="form-stack">
          <iframe :src="formstack" frameborder="0"></iframe>
        </div>
        <quick-task
          v-else-if="quicktask"
          class="quick-task"
          :id="currentWidget.id"
          :label="currentWidget.parameters.label || 'Label not set'"
          :input="currentWidget.parameters.input"
          @on-submit="onQuicktaskFinished"
        />
        <evernym-invitation
          v-else-if="evernymInvitation"
          :execution-id="executionId"
          :execution-token="executionToken"
          :issuer-id="currentWidget.parameters.evernymIssuerId"
          @on-submit="triggerProcess"
        />
      </template>
    </template>
  </div>
</template>

<script>
import Debounce from 'lodash.debounce';
import settings from '@/settings';
import { executionApi, locationApi } from '@/api/http/api';
import { taskThingApi } from '@/api/http/api';
import QuickTask from './QuickTask';
import EvernymInvitation from './EvernymInvitation';
import VOffline from 'v-offline';
import InteractionEventClassification from '@/components/Organization/Interaction/Interactions/InteractionEventClassification.vue';
import { GraphqlFastQuery } from '@/api/graphql';
import { query as thingQuery } from '@/graphql/thing';

export default {
  components: {
    VOffline,
    QuickTask,
    EvernymInvitation,
    InteractionEventClassification,
  },
  created: function () {
    window.addEventListener('message', this.onMessage);
  },
  destroyed: function () {
    window.removeEventListener('message', this.onMessage);
  },
  props: {
    closeFn: {
      type: Function,
      required: false,
    },
    taskThingId: {
      type: String,
      required: false,
    },
    itemId: {
      type: String,
      required: false,
    },
    token: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      initThrottled: Debounce(this.init, 500),
      loading: true,
      executionToken: null,
      executionId: null,
      taskData: null,
      hasError: false,
      attachmentSteps: {},
      taskResultMessage: null,
      taskResult: null,
      taskClassifications: [],
    };
  },
  watch: {
    taskThingId: {
      immediate: true,
      handler() {
        this.initThrottled();
      },
    },
    itemId: {
      immediate: true,
      handler() {
        this.initThrottled();
      },
    },
    token: {
      immediate: true,
      handler() {
        this.initThrottled();
      },
    },
  },
  computed: {
    currentWidget() {
      try {
        {
          {
            this.taskData.config.widgets[0];
          }
        }
        return this.taskData.config.widgets[0];
      } catch (err) {
        return false;
      }
    },
    formstack() {
      try {
        return this.currentWidget.formstackUri;
      } catch (err) {
        return false;
      }
    },
    quicktask() {
      try {
        return this.currentWidget.type === 'quicktask';
      } catch (err) {
        return false;
      }
    },
    evernymInvitation() {
      try {
        return this.currentWidget.type === 'evernymInvitation';
      } catch (err) {
        return false;
      }
    },
  },
  methods: {
    async init() {
      this.loading = true;

      // need to create token using ID's to start execution
      try {
        if (!this.token && (!this.taskThingId || !this.itemId)) {
          throw new Error();
        }

        const token =
          this.token ||
          (
            await taskThingApi.start(this.taskThingId, {
              itemId: this.itemId,
              channel: 'Portal',
            })
          ).data.jwt;

        const postData = {};

        if (!this.isRunningInApp()) {
          const locationData = await locationApi.get();

          postData.metadata = {
            metadata: {
              location: {
                ...(locationData.accuracy && {
                  pointAccuracy: locationData.accuracy,
                }),
                point: {
                  coordinates: [locationData.longitude, locationData.latitude],
                },
              },
            },
          };
        }

        const response = (await executionApi.create(token, postData)).data;
        this.executionId = response.id;
        this.executionToken = response.token;
        this.taskData = response.execution || (await executionApi.get(this.executionToken, this.executionId)).data;

        this.loading = false;

        if (this.isRunningInApp()) {
          if (this.taskData.thingId) {
            this.taskClassifications = await this.getTaskClassifications(this.taskData.thingId, this.executionToken);
          }

          const that = this;

          window.receiveMessageFromApp = function (event) {
            if (settings.debugNativeAppIntegration) {
              that.$notify({
                message: JSON.stringify(event),
                icon: 'add_alert',
                horizontalAlign: 'right',
                verticalAlign: 'bottom',
              });
            }

            if (event.type === 'event' && event.name === 'geoLocation' && event.value) {
              that.sendMessageFS1App({
                type: 'log',
                name: `'geoLocation' called`,
              });
            }

            if (event.type === 'event' && event.name === 'process' && event.value) {
              that.sendMessageFS1App({ type: 'log', name: `'process' called` });

              const postData = {};
              const attachments = event.value.attachments;
              const coordinates = event.value.coordinates;

              if (coordinates && coordinates.longitude && coordinates.latitude) {
                postData.metadata = {
                  metadata: {
                    location: {
                      point: {
                        coordinates: [coordinates.longitude, coordinates.latitude],
                      },
                    },
                  },
                };
              }

              if (attachments && Array.isArray(attachments) && attachments.length) {
                postData.attachments = that.setupAttachments(attachments);
              }

              let pr;
              if (postData.metadata || postData.attachments) {
                pr = executionApi.update(that.executionToken, that.executionId, postData);
              } else {
                pr = Promise.resolve();
              }

              pr.then(() => that.process())
                .then(ret => {
                  let endTaskConfig = that.parseResultMessage(ret.taskResultMessage);

                  endTaskConfig = {
                    interactionId: ret._id,
                    taskMessage: endTaskConfig,
                    miscellaneous: null,
                    taskClassifications: that.taskClassifications,
                  };

                  that.sendMessageFS1App({
                    type: 'event',
                    name: 'rfider-task-completed',
                    value: endTaskConfig,
                  });
                })
                .catch(() => {
                  that.hasError = true;
                });
            }
          };
        }

        this.sendMessageFS1App({ type: 'event', name: 'rfider-task-started' });
        this.setAttachmentStep('General', null);

        ///debug locally
        if (settings.debugNativeAppIntegration) {
          /*setTimeout(() => {
            this.sendMessageFS1App({
              type: 'event',
              name: 'rfider-task-pre-process',
            });
          }, 10000);*/
        }
      } catch (err) {
        this.loading = false;
        this.hasError = true;
      }
    },

    /**
     * Currently this is being called when formstack has finished
     */
    onMessage(evt) {
      if (evt.data === 'rfider-task-complete') {
        this.triggerProcess();
      }
    },

    /**
     * Currently this is being called when quicktask has finished
     */
    async onQuicktaskFinished(widgetId, label, input, value) {
      this.triggerProcess({
        widgets: {
          [widgetId]: {
            label: label,
            ...(input && {
              input: input,
            }),
            ...(value && {
              value: value,
            }),
          },
        },
      });
    },

    async triggerProcess(updateData) {
      try {
        this.taskData = null;

        if (updateData) {
          await executionApi.update(this.executionToken, this.executionId, updateData);
        }

        if (this.isRunningInApp()) {
          // get the attachemts from the app
          this.sendMessageFS1App({
            type: 'event',
            name: 'rfider-task-pre-process',
          });
        } else {
          const response = await this.process();
          this.taskResultMessage = this.parseResultMessage(response.taskResultMessage);
          this.taskResult = response;
          this.$emit('isTaskCompleted', true);
        }
      } catch (err) {
        this.hasError = true;
      }
    },

    async process() {
      this.loading = true;

      const response = (await executionApi.process(this.executionToken, this.executionId)).data;

      this.loading = false;

      return response;
    },

    isOnline(evt) {
      this.sendMessageFS1App({
        type: 'event',
        name: 'rfider-task-online',
        value: evt,
      });
    },

    isRunningInApp() {
      return typeof fs1App !== 'undefined';
    },

    sendMessageFS1App() {
      if (this.isRunningInApp()) {
        // fs1App.postMessage(JSON.stringify(message));
      }
    },

    parseResultMessage(msg) {
      let message = undefined;

      if (msg) {
        let obj = {
          image: null,
          title: null,
          description: null,
          backgroundImage: null,
          autoCloseSeconds: null,
        };

        try {
          const parsed = JSON.parse(String(msg).trim());
          if (typeof parsed === 'string') {
            obj.description = parsed;
          } else if (typeof parsed === 'object') {
            obj = Object.assign(obj, parsed);
          }
        } catch (err) {
          obj.description = String(msg).trim();
        }

        if (obj.image || obj.title || obj.description || obj.backgroundImage || obj.autoCloseSeconds) {
          if (obj.image) {
            obj.image = this.mixinGetFilePath(obj.image, 'O');
          }

          if (obj.backgroundImage) {
            obj.backgroundImage = this.mixinGetFilePath(obj.backgroundImage, 'L');
          }

          message = obj;
        }
      }

      return message
        ? message
        : {
            title: 'Task Complete',
            description: '',
          };
    },

    setAttachmentStep(stepName, stepId) {
      let counter = 1;
      let finalStepId = String(stepId || '');
      let finalStepName = String(stepName);

      while (typeof this.attachmentSteps[finalStepName.toLowerCase()] !== 'undefined' && this.attachmentSteps[finalStepName.toLowerCase()] !== finalStepId) {
        finalStepName = `${stepName}(${counter++})`;
      }

      this.attachmentSteps[finalStepName.toLowerCase()] = finalStepId;
      this.sendMessageFS1App({
        type: 'event',
        name: 'rfider-task-attachment-group',
        value: finalStepName,
      });
    },

    setupAttachments(attachments) {
      let ret = [];

      if (attachments && Array.isArray(attachments) && Array.length) {
        ret = attachments
          .map(att => {
            let ret = null;
            try {
              if (att.stepName && att.uploadPath && typeof this.attachmentSteps[String(att.stepName).toLowerCase()] !== 'undefined') {
                ret = {
                  stepId: this.attachmentSteps[String(att.stepName).toLowerCase()] || null,
                  name: att.name || null,
                  file: att.uploadPath,
                  camera: att.type === 'camera',
                };
                if (!ret.stepId) delete ret.stepId;
                if (!ret.name) delete ret.name;
                if (!ret.camera) delete ret.camera;
              }
            } catch (err) {
              ret = null;
            }
            return ret;
          })
          .filter(att => !!att);
      }
      return ret;
    },

    async getTaskClassifications(thingId, token) {
      let taskClassifications = [];

      // Updates the token to be able to execute the apollo query below.
      let $auth = this.$router.app.$auth;
      await $auth.setAuthValues(token);

      try {
        let response = await GraphqlFastQuery(thingQuery, {
          variables: {
            thingId: String(thingId),
          },
        });

        if (response && response.data) {
          const { thing } = response.data;
          if (thing) {
            await Promise.all(
              thing.valueChain.elements.map(async element => {
                if (element.enabled && element.isOrganizationActivity) {
                  taskClassifications.push({
                    ...element,
                    image: this.mixinGetFilePath(element.image, 'L'),
                    icon: this.mixinGetFilePath(element.icon, 'L'),
                    valueChainId: thing.valueChain.id,
                    valueChainName: thing.valueChain.name,
                  });
                }
              }),
            );
          }
        }
      } catch (e) {
        console.log(e);
      }

      return taskClassifications;
    },
  },
};
</script>

<style lang="scss" scoped>
.taskRun {
  .taskResultMessage {
    margin-top: 30px;
  }

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    &.in-modal {
      top: 100px;
      bottom: 0;
    }
    &.in-app {
      height: 100%;
    }
    .form-stack,
    .quick-task {
      height: 100%;
    }
    .quick-task {
      padding: 0 20px;
    }
    .form-stack iframe {
      width: 100% !important;
      height: 100% !important;
    }
    .md-progress-spinner {
      top: 50px;
      left: calc(50% - 25px);
    }
  }

  &-title {
    font-weight: 500;
  }
}
</style>
