let reloadInterval: any = null;

export default {
  inserted: async (el: any, binding: any) => {
    const timer = binding.value.timer;
    const loading = binding.value.loading;
    const source = el.src;

    async function isValidImg(url: string) {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        img.onload = function () {
          resolve(url);
        };
        img.onerror = function () {
          reject(url);
        };
        img.src = url;
      });
    }

    async function loadImage() {
      const isImg = await isValidImg(source);

      if (isImg) {
        clearInterval(reloadInterval);
        el.src = isImg;
      }
    }

    el.onerror = () => {
      el.src = loading || require('../../public/img/spinner.gif');
      if (timer) {
        reloadInterval = setInterval(async () => {
          await loadImage();
        }, timer);
      }
    };
  },
  unbind: function (el: any) {
    clearInterval(reloadInterval);
  },
};
