import Vue from 'vue';
import { GraphqlFastQuery } from '@/api/graphql';
import { query as searchWithinOrganizationsQuery } from '@/graphql/searchOrganization';
import { query as searchMyOrganizationsQuery } from '@/graphql/myOrganizations';
import { IDtoCollection, IDtoOrganization, IDtoSearchResult } from '@/store/dto';

type stateDTO = IDtoCollection<IDtoOrganization> | null;

interface IState {
  organizations: IDtoCollection<IDtoOrganization> | null;
  organizationsSearch: IDtoCollection<IDtoSearchResult> | null;
}

const state: IState = {
  organizations: null,
  organizationsSearch: null,
};

const mutations = {
  SET_ORGANIZATIONS: function (state: IState, payload: IDtoCollection<IDtoOrganization>) {
    Vue.set(state, 'organizations', payload);
  },
  SET_ORGANIZATIONS_SEARCH: function (state: IState, payload: IDtoCollection<IDtoSearchResult>) {
    Vue.set(state, 'organizationsSearch', payload);
  },
};

const actions = {
  searchWithinOrganizations: async function (context: any, payload: any) {
    try {
      const response = await GraphqlFastQuery(searchWithinOrganizationsQuery, {
        variables: {
          organizationId: String(payload.organizationId),
          search: String(payload.search),
          page: payload.page || null,
          perPage: payload.perPage || null,
        },
      });
      context.commit('SET_ORGANIZATIONS_SEARCH', response.data.searchWithinOrganization);
    } catch (e) {
      console.log(e);
    }
  },

  searchMyOrganizations: async function (context: any, payload: any) {
    try {
      const response = await GraphqlFastQuery(searchMyOrganizationsQuery, {
        variables: {
          page: payload.page || null,
          perPage: payload.perPage || null,
          ...(payload.sort && {
            sort: String(payload.sort),
          }),
          search: String(payload.search),
        },
      });
      context.commit('SET_ORGANIZATIONS', response.data.organizations);
    } catch (e) {
      console.log(e);
    }
  },

  refreshMyOrganizations: async function (context: any, payload: any) {
    context.dispatch('searchMyOrganizations', {
      search: '',
    });
  },
};

const getters = {
  organizationsInfo: function (state: IState, getters: any) {
    return state.organizations;
  },
  organizationsSearchInfo: function (state: IState, getters: any) {
    return state.organizationsSearch;
  },
};

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
