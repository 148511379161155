const ko = {
  common: {
    labels: {
      selected: '선택된:',
      searchByName: '이름으로 검색',
      itemStatusMatches: '항목 상태가 일치할 때만 표시',
      showTask: '이 작업을 항상 표시하려면 비워 둡니다.',
      emailAddress: '이메일 주소',
      name: '이름',
      emailAddressWithComma: '이메일 주소(쉼표로 구분)',
      emailSubject: '이메일 제목(예: {task} - 상태:{status}의 {thing}에 대한 {name})',
      endpointURL: '엔드포인트 URL',
      securityHmacKey: '보안(HMAC 키)',
      apiKey: 'API 키',
      organizationId: '조직 ID',
      xmlMessage: 'XML 메시지',
      createYourFirst: '첫 번째 만들기',
      valueChain: '가치 사슬.',
      category: '범주.',
      supportProfile: '지원 프로필.',
      task: '직무.',
      template: '주형.',
      description: '설명',
      learnMoreLink: '자세히 알아보기 링크',
      image: '영상',
      displayImage: '디스플레이 이미지',
      chainElementIcon: '체인 요소 아이콘',
      transportationMethod: '운송 방법',
      gln: 'GLN(글로벌 위치 번호)',
      status: '상태',
      thing: '물건',
      selectDateRange: '기간 선택',
      searchBy: '검색 기준',
      itemImage: '아이템 이미지',
      uploadAttachment: '첨부파일 업로드',
      addInteractionTo: '상호 작용 추가',
      interactionName: '상호작용 이름',
      steps: '단계',
      displayName: '이름 표시하기',
      title: '제목',
      attachments: '첨부 파일',
      currentStatus: '현재 상태',
      country: '국가',
      language: '언어',
      items: '아이템',
      interactions: '상호작용',
      tasks: '작업',
      product: '제품',
      item: '안건',
      selectLanguage: '언어 선택',
      addBatch: '배치 추가',
      editBatch: '일괄 수정',
      filters: '필터',
      unknown: '알려지지 않은',
      from: '~에서',
      learnMore: '더 알아보기',
      search: '검색',
      url: 'URL',
      addTask: '작업 추가',
      at: '~에',
    },
    buttons: {
      save: '구하다',
      cancel: '취소',
      finish: '마치다',
      submit: '제출하다',
      delete: '삭제',
      remove: '제거하다',
      task: '일',
      ok: '좋아요',
      logout: '로그 아웃',
      decline: '감소',
      accept: '수용하다',
      inviteUsers: '사용자 초대',
      send: '보내다',
      addMore: '더 추가',
      createAnAction: '작업 만들기',
      removeIntegration: '통합 제거',
      continue: '계속하다',
      update: '업데이트',
      back: '뒤',
      next: '다음',
      close: '닫기',
      upload: '업로드',
      create: '만들다',
      clear: '분명한',
      apply: '적용하다',
      viewInDashboard: '대시보드에서 보기',
      confirm: '확인하다',
      yes: '네',
      no: '아니요',
      configure: '구성',
    },
    menus: {
      things: '것들',
      items: '아이템',
      interactions: '상호작용',
      reportingAndAnalytics: '보고 및 분석',
      templates: '템플릿',
      settings: '설정',
      tasks: '작업',
      users: '사용자',
      advancedSettings: '고급 설정',
      valueChains: '가치 사슬',
      organisationalSettings: '조직 설정',
      language: '언어',
      catalogue: '목록',
    },
    menuItems: {
      edit: '편집하다',
      delete: '삭제',
      remove: '제거하다',
      manageConfiguration: '구성 관리',
      setAsDefault: '기본값으로 설정',
      setAsPublic: '공개로 설정',
      setAsPrivate: '비공개로 설정',
      manage: '관리하다',
      duplicate: '복제하다',
      viewContent: '콘텐츠 보기',
      viewSettings: '설정 보기',
      displayToPublic: '공개 표시',
      hideFromPublic: '공개에서 숨기기',
      disable: '장애를 입히다',
      enable: '할 수있게하다',
      viewConditions: '조건 보기',
      manageConditions: '조건 관리',
      viewDisplayConditions: '표시 조건 보기',
      manageDisplayConditions: '표시 조건 관리',
      change: '변화',
      displayForBatch: '일괄 표시',
      viewOrUpdateClassification: '분류 보기/업데이트',
      displayInPublicPage: '공개 페이지에 표시',
      suspended: '정지 된',
      unsuspend: '일시 중단 해제',
      suspend: '유예하다',
      invitationDeclined: '초대 거부됨',
      invitationPending: '초대 대기 중',
      invitationExpired: '초대장 만료됨',
      invitationAccepted: '초대 수락됨',
      setAsMember: '회원으로 설정',
      setAsAdmin: '관리자로 설정',
      disablePortalAccess: '포털 액세스 비활성화',
      enablePortalAccess: '포털 액세스 활성화',
      resendInvitation: '초대장 재전송',
      deleteUser: '사용자 삭제',
      deactivate: '비활성화',
      activate: '활성화',
      preview: '시사',
      customiseName: '이름 사용자 정의',
      customiseImage: '이미지 사용자 정의',
      addAttachment: '첨부 파일 추가',
      changeStatus: '상태 변경',
      removePersonalSupportProfile: '개인 지원 프로필 제거',
      createBatch: '배치 생성',
      editBatch: '일괄 수정',
      deleteBatch: '일괄 삭제',
      copyToClipboard: '클립 보드에 복사',
      openPublicPage: '공개 페이지 열기',
      createProduct: '제품 만들기',
      pagePosition: '페이지 위치',
      uploadImage: '이미지 업로드',
      deleteImage: '이미지 삭제',
      updateFromClipboard: '클립보드에서 업데이트',
      viewCodeDetails: '코드 세부 정보 보기',
      updateDisplayData: '보고된 데이터 업데이트',
      hide: '숨다',
      unhide: '숨기기 해제',
    },
    pagination: {
      text: '{to} 항목 중 {from}에서 {to} 표시',
    },
    tabs: {
      general: '일반적인',
      categories: '카테고리',
      support: '지원하다',
      tasks: '작업',
      users: '사용자',
      integrationActions: '통합 작업',
      valueChain: '가치 사슬',
      chart: '차트',
      map: '지도',
      documents: '서류',
    },
    messages: {
      invitationExpired: '초대장이 만료되었습니다.',
      notFoundInvitation: '초대장을 찾을 수 없음',
      userAlreadyExists: '사용자가 이미 존재합니다.',
      nameAlreadyTaken: '이미 사용 중인 이름',
      InvalidContent: '잘못된 콘텐츠',
      PayloadTooLarge: '엔터티가 너무 큼 요청',
      noSearchResult: '죄송합니다. 일치하는 결과를 찾을 수 없습니다',
      noneSelected: '선택된 항목이 없습니다.',
      noneAvailable: '아직 이 조직에 대해 생성된 작업이 없습니다.',
      noConfigurableAttributes: '이 작업에 사용할 수 있는 구성 가능한 속성이 없습니다.',
      noInvitation: '대기 중인 초대가 없습니다',
      defaultValueChain: '기존 기본 요소는 편집 화면에서 새 요소를 기본값으로 선택해야만 변경할 수 있습니다.',
      fileSizeError: 'Assure+ Portal에 파일을 업로드할 수 있는 최대 파일 크기는 10MB입니다. 최대 10MB의 파일을 업로드하세요.',
      submitError: '모든 필수 필드를 작성하십시오',
      inviteSent: '보낸 초대장',
      inviteError: '초대장을 보낼 수 없습니다',
      saved: '저장',
      deleted: '삭제됨',
      duplicated: '복제',
      createThing: '생성된 것',
      createThingError: '사물을 생성할 수 없습니다.',
      duplicateThingError: '복제할 수 없는 것',
      updateThing: '항목이 복제되었습니다.',
      updateThingError: '사물을 업데이트할 수 없습니다.',
      loadTypeError: '{유형}을(를) 로드할 수 없습니다.',
      deleteTypeError: '{type}을(를) 삭제할 수 없습니다.',
      duplicateTypeError: '복제할 수 없습니다.',
      createTask: '작업이 추가되었습니다',
      createTaskError: '작업을 추가할 수 없습니다.',
      updateTask: '작업이 업데이트되었습니다.',
      updateTaskError: '작업을 업데이트할 수 없습니다.',
      taskError: '작업을 생성할 수 없습니다.',
      createSupportProfile: '지원 프로필이 추가되었습니다',
      createSupportProfileError: '지원 프로필을 추가할 수 없습니다.',
      updateSupportProfile: '지원 프로필이 업데이트되었습니다.',
      updateSupportProfileError: '지원 프로필을 업데이트할 수 없습니다.',
      deleteSupportProfile: '지원 프로필이 삭제되었습니다.',
      deleteSupportProfileError: '지원 프로필을 만들 수 없습니다.',
      supportProfile: '지원 프로필이 생성되었습니다',
      supportProfileError: '지원 프로필을 만들 수 없습니다.',
      createTeam: '팀이 생성됨',
      createTeamError: '팀을 만들 수 없습니다.',
      saveTeam: '팀이 저장되었습니다.',
      saveTeamError: '팀을 저장할 수 없습니다',
      renameTeam: '팀 이름이 변경됨',
      renameTeamError: '팀 이름을 바꿀 수 없습니다.',
      teamStatus: '팀 상태가 변경됨',
      teamStatusError: '팀 상태를 변경할 수 없습니다.',
      resendInvite: '초대 다시 보내기',
      resendInviteError: '초대를 다시 보낼 수 없습니다.',
      revokeInvite: '초대 취소',
      revokeInviteError: '초대를 취소할 수 없습니다.',
      revokeAccess: '액세스 취소됨',
      revokeAccessError: '액세스 권한을 취소할 수 없습니다.',
      suspendAccess: '정지 된',
      suspendAccessError: '일시 중지로 변경할 수 없습니다.',
      accountChange: '계정 변경됨',
      accountChangeAdminError: '관리자로 변경할 수 없습니다.',
      accountChangeOwnerError: '소유자로 변경할 수 없습니다.',
      accountChangeUserError: '사용자로 변경할 수 없습니다.',
      inviteUser: '초대된 사용자',
      inviteUserError: '사용자를 초대할 수 없습니다.',
      declineInvitation: '초대를 거부함',
      declineInvitationError: '초대를 거부할 수 없습니다({message}).',
      acceptInvitation: '초대 수락',
      acceptInvitationError: '초대를 수락할 수 없습니다({message}).',
      deleteUser: '사용자가 삭제됨',
      deleteUserError: '사용자를 삭제할 수 없습니다.',
      updateTemplate: '템플릿이 업데이트되었습니다.',
      updateTemplateError: '템플릿을 업데이트할 수 없습니다.',
      deleteTemplate: '템플릿이 삭제되었습니다.',
      deleteTemplateError: '템플릿을 삭제할 수 없습니다.',
      createTemplate: '템플릿이 생성되었습니다',
      createTemplateError: '템플릿을 만들 수 없습니다.',
      deleteBatch: '일괄 삭제됨',
      deleteBatchError: '일괄 사용되었습니다. 배치를 삭제할 수 없습니다.',
      updateBatch: '배치가 업데이트되었습니다',
      createBatch: '배치 생성됨',
      createBatchError: '배치 ID가 이미 있습니다. 배치를 생성할 수 없습니다.',
      identificationCode: '식별 코드가 생성되었습니다.',
      validateIdentifierError: '식별자를 확인할 수 없습니다.',
      addFileError: '파일을 추가해주세요',
      genericError: '일반 오류',
      createValueChain: '가치 사슬이 생성되었습니다.',
      createValueChainError: '가치 사슬을 만들 수 없습니다',
      updateValueChain: '가치 사슬이 업데이트되었습니다.',
      updateValueChainError: '가치 사슬을 업데이트할 수 없습니다',
      deleteValueChain: '가치 사슬이 삭제되었습니다.',
      deleteValueChainError: '가치 사슬을 삭제할 수 없습니다',
      updateCategory: '카테고리가 업데이트되었습니다',
      updateCategoryError: '카테고리를 업데이트할 수 없습니다.',
      deleteCategory: '카테고리가 삭제되었습니다.',
      deleteCategoryError: '카테고리를 삭제할 수 없습니다.',
      CreateCategory: '카테고리가 생성되었습니다.',
      createCategoryError: '카테고리를 생성할 수 없습니다.',
    },
    validation: {
      minNumMaxNum: '최소 {0}자 최대 {1}자',
      maxNum: '최대 {0}자',
    },
    uploadFile: {
      maxSize: '(최대 크기 10MB)',
    },
  },
  organization: {
    myOrganizations: '내 조직',
    viewAllOrganizations: '모든 조직 보기',
  },
  interaction: {
    batchRecord: '일괄 기록',
    unknownId: '알 수 없는 ID',
    externalId: '외부 ID',
    modal: {
      eventClassification: '이벤트 분류',
      unclassified: '미분류',
      default: '기본',
    },
    filters: {
      notice: '알아 채다',
      alert: '알리다',
      warning: '경고',
    },
    hidden: '숨겨진',
  },

  interaction_action_0: '사용자에게 알림:',
  interaction_action_1: '항목 상태가 다음으로 변경됨:',
  interaction_action_2: '항목 지원 프로필이 할당되었습니다.',
  interaction_action_3: '아이템 지원 프로필이 삭제되었습니다.',
  interaction_action_4: '공고:',
  interaction_action_5: '경고:',
  interaction_action_6: '알리다:',
  interaction_administrative_title: '행정 변경',
  task_steps: {
    status_check: '현재 항목 상태: {status}',
  },
  item_status: '{상태}',
  interaction_collections_title: '컬렉션 - {text}',
  interaction_collection_added_title: '컬렉션에 추가됨 - {text}',
  step_collection_fields: '필드:',
  publicPage: {
    positioning: {
      before: '전에',
      after: '후에',
      top: '맨 위',
      bottom: '맨 아래',
    },
    components: {
      longDescription: '장황한 설명',
      attachments: '첨부 파일',
      attributes: '속성',
      services: '서비스',
      gallery: '갤러리',
      certifications: '인증',
      video: '동영상',
      history: '역사',
      supportProfile: '지원 프로필',
      fs1TaskPanel: 'FS1 작업 패널',
      fs1About: 'FS1 소개',
      fs1FactsPanel: 'FS1 사실 패널',
      fs1ResourcesPanel: 'FS1 리소스 패널',
      events: '이벤트',
    },
  },
  OrganisationalSettings: {
    header: '조직 설정',
    components: {
      general: '일반적인',
      branding: '브랜딩',
    },
    general: {
      name: '이름',
      businessType: '사업 유형',
      OrganizationLogo: '조직 로고',
      PublicPageLoadingImage: '공개 페이지 로딩 이미지',
    },
  },
  tasks: {
    tableHeader: {
      name: '작업 이름',
      usedAsDiscovery: '검색 작업',
      interactions: '사용 횟수',
      things: '할당',
      createdAt: '만든 시간',
      createdBy: '만든 사람',
      displayConditions: '표시 조건',
    },
    taskModal: {
      headerCreate: '작업 만들기',
      headerEdit: '작업 수정',
      components: {
        selectTask: '작업 선택',
      },
    },
  },
  categories: {
    title: '범주',
    noDataMessage1: '카테고리 생성',
    tableHeaders: {
      name: '이름',
      defaultTemplate: '기본 템플릿',
      createdAt: '만든 시간',
      createdBy: '만든 사람',
    },
    editCategoryModal: {
      createCategory: '카테고리 만들기',
      editCategory: '카테고리 편집',

      labels: {
        name: '카테고리 이름',
        standard: '식별 기준',
        identifier: '카테고리 식별자',
        description: '설명',
        image: '카테고리 이미지',
        defaultTemplate: '이 카테고리에 대해 생성된 각 제품은 기본적으로 이 템플릿으로 설정됩니다.',
      },
    },
  },
  supportProfile: {
    title: '지원 프로필',
    addSupportProfile: '지원 프로필 추가',
    createMessage1: '지원 프로필 만들기',
    tableHeaders: {
      name: '이름',
      content: '콘텐츠',
      displayConditions: '표시 조건',
      contactMethods: '연락 방법',
    },
    editSupportProfileModal: {
      createSupportProfile: '지원 프로필 만들기',
      editSupportProfile: '지원 프로필 수정',
      labels: {
        name: '이름',
        headerText: '헤더 텍스트',
        mainText: '본문',
        mainContent: '주요 내용',
        showExpanded: '확장 표시',
        phone: '핸드폰',
        email: '이메일',
        imagery: '형상',
        primary: '일 순위',
        secondary: '중고등 학년',
        kmlMapType: 'KML 지도 유형',
        kmlLink: 'KML 링크',
        kmlFile: 'KML 파일',
      },
    },
  },
  templates: {
    title: '주형',
    tableHeaders: {
      name: '이름',
      createdAt: '만든 시간',
      createdBy: '만든 사람',
      assignedCategories: '할당된 카테고리',
    },
    editTemplateModal: {
      createTemplate: '템플릿 만들기',
      editTemplate: '템플릿 편집',
      labels: {
        templateName: '템플릿 이름',
        templateDescription: '템플릿 설명',
        templateImage: '템플릿 이미지',
      },
    },
    templateCreatedModal: {
      title: '템플릿 생성',
      message: '템플릿이 생성되었습니다. 지금 관리하고 속성, 작업, 이미지 및 추가 콘텐츠를 추가하시겠습니까?',
      later: '나중에 해',
      now: '지금 관리',
    },
    templateCreatedProductModal: {
      title: '생성된 제품',
      message: '제품이 성공적으로 생성되었습니다. 지금 관리하고 속성, 작업, 이미지 및 추가 콘텐츠를 추가하시겠습니까?',
    },
  },
  user: {
    viewInvitations: '초대장 보기',
    inviteUsers: '사용자 초대',
    to: '에게',
    userHeaders: {
      name: '이름',
      email: '이메일',
      role: '역할',
      access: '입장',
      status: '상태',
    },
    userType: {
      admin: '관리자',
      member: '회원',
    },
  },
  webhooks: {
    webhookHeaders: {
      name: '이름',
      createdAt: '만든 시간',
      createdBy: '만든 사람',
    },
    webhookModal: {
      headerCreate: '조직의 상호 작용을 팀 구성원, 다른 시스템 및 신뢰할 수 있는 제3자와 공유하기 시작하는 작업입니다.',
      components: {
        emailSettings: '이메일 설정',
        webhookSettings: '웹훅 설정',
        integrations: '통합',
        thingsAndTasks: '사물 및 작업',
      },
    },
    actions: '행위',
    editAction: '작업 편집',
  },
  valueChain: {
    headerCreate: '가치 사슬',
    headerEdit: '가치 사슬 편집',
    editAChainElement: '체인 요소 편집',
    createAChainElement: '체인 요소 작성',
    headerDescription: '시작하는 데 도움이 되는 템플릿을 선택하세요.',
    organisationActivity: '조직 활동',
    components: {
      miscellaneousContent: '기타 콘텐츠',
      logisticsAndEnvironment: '물류 및 환경',
      generalInformation: '일반 정보',
    },
    tableHeaders: {
      creationDate: '제작 일',
      createdBy: '만든 사람',
    },
    templates: {
      justTheBasics: {
        name: '그냥 기본',
        description: 'Assure+에서 사용하는 기본 값 사슬입니다.',
      },
      beverage: {
        name: '음료',
        description:
          '전체 공급망을 통해 음료 제품을 추적하고 추적하는 기능은 이제 소비자가 주도하고 있습니다. 그들은 재료가 재배되는 현장과 지원 재료 및 포장 용품을 생산하는 시설로 돌아가서 모든 제품의 원산지를 알고 싶어합니다.',
      },
      coffee: {
        name: '커피',
        description: '모닝 컵에 도착하기 전에 커피 원두는 복잡한 글로벌 공급망을 통과합니다.',
      },
      honey: {
        name: '꿀',
        description: '꿀은 벌집에서 소비자까지 복잡한 글로벌 공급망을 통해 이동합니다.',
      },
      porterValueChain: {
        name: '포터 가치 사슬',
        description: 'Porter&#39;s Value Chain은 부서나 회계 비용 유형을 보는 대신 시스템에 초점을 맞추고 입력이 소비자가 구매한 출력으로 변경되는 방식에 중점을 둡니다.',
      },
      usaidCommodity: {
        name: 'USAid 상품',
        description:
          '프로세스는 계약 입찰을 요청하고 승인된 요청에 대한 식품 상품을 조달하는 USAID에 식품 요청이 있을 때 시작됩니다. 그런 다음 상품은 공급자로부터 미국 항구, 외국 하역 항구, 미국 및 국제 USAID 사전 배치 창고를 포함한 공급망의 다른 물류 노드에 있는 운송 지점 또는 창고 시설로 보내집니다. 프로세스의 어느 시점에서 식품 상품은 파트너 조직으로 이전된 후 자체 운송 및 유통 파트너 네트워크와 자체 정보 추적 시스템을 사용하여 식품 유통을 담당합니다.',
      },
      wine: {
        name: '와인',
        description: '와인 가치 사슬은 포도밭 준비, 포도 생산(포도 재배), 와인 생산(포도 재배), 물류 및 유통, 마케팅 및 판매의 5가지 광범위한 생산 단계로 구성됩니다.',
      },
    },
  },
  documents: {
    title: '서류',
    labels: {
      documentName: '문서 이름',
      gdtiNumber: 'GDTI 번호',
      valueChainSteps: '가치 사슬 단계',
      publicRecord: '공공 기록',
      dateModified: '수정 날짜',
    },
    valueChainSteps: {
      Unclassified: '미분류',
    },
  },
  products: {
    tableHeaders: {
      name: '이름',
      category: '범주',
      tasks: '할당된 작업',
      interactions: '녹화된 이벤트',
      items: '아이템',
    },
    menuOptions: {
      manage: '관리하다',
      duplicate: '복제하다',
      updateFromClipboard: '클립보드에서 업데이트',
    },
    bulkActions: {
      addTasks: '작업 추가',
      removeTasks: '작업 제거',
      addValueChain: '가치 사슬 추가',
      addSupportProfile: '지원 프로필 추가',
      removeSupportProfile: '지원 프로필 제거',
    },
    productCreatedModal: {
      title: '생성된 제품',
      message: '제품이 성공적으로 생성되었습니다. 지금 관리하고 속성, 작업, 이미지 및 추가 콘텐츠를 추가하시겠습니까?',
      later: '나중에 해',
      now: '지금 관리',
    },
    title: '제품',
  },
  collaborations: {
    header: {
      step: '단계',
      title: '클립보드에서 업데이트',
    },
    subTitles: {
      general: '일반적인',
      productInfo: '제품 정보',
      attributes: '속성',
      productDocuments: '서류',
      itemAndBatchDocuments: '품목 및 배치 문서',
      images: '이미지',
      videos: '비디오',
      eventHistory: '이벤트 내역',
      contentTransferSummary: '콘텐츠 전송 요약',
    },
    descriptiveContent: {
      noData: '이용 가능한 일반 정보가 없습니다.',
      description: '일반 정보는 제품 수준에서 전송되고 모든 배치에 추가됩니다.',
      target: '표적',
      source: '원천',
      labels: {
        name: '이름',
        shortDescription: '간단한 설명',
        longDescription: '장황한 설명',
      },
      longDescriptionButtons: {
        append: '추가',
        replace: '바꾸다',
      },
    },
    attributes: {
      noData: '사용 가능한 속성이 없습니다.',
      description: '속성은 제품 수준에서 전송되고 모든 배치에 추가됩니다.',
      warningMessage: '선택한 속성은 제품의 모든 배치에 적용됩니다.',
    },
    itemAndBatchDocuments: {
      noData: '사용 가능한 문서가 없습니다.',
      warningMessage: '선택한 문서는 해당 대상 품목 또는 제품 배치에만 추가됩니다.',
    },
    images: {
      noData: '사용 가능한 이미지가 없습니다.',
      description: '이미지는 제품 수준에서 전송되고 모든 배치에 추가됩니다.',
      selectAllButton: '모두 선택',
    },
    videos: {
      noData: '사용 가능한 동영상이 없습니다.',
      description: '비디오는 제품 수준에서 전송되고 모든 배치에 추가됩니다.',
      selectAllButton: '모두 선택',
    },
    eventHistory: {
      noData: '공개 콘텐츠에 사용할 수 있는 작업이 없습니다.',
      noItemBatch: '이벤트를 저장할 수 있는 항목이나 배치가 없습니다.',
      labels: {
        name: '이름',
        classification: '분류',
      },
    },
    confirmationScreen: {
      noData: '선택한 콘텐츠가 없습니다.',
      message: '클립보드에서 업데이트 중...',
      successfulMessage: '클립보드에서 업데이트 성공',
    },
  },
  item: {
    filters: {
      doNotUse: '사용하지 마세요',
      allClear: '공습 경보 해제',
      normalPrecaution: '일반적인 주의사항',
      reconsiderUse: '사용을 재고하다',
      increasedCaution: '주의 증가',
      unknownId: '알 수 없는 아이디',
      itemOk: '항목 확인',
      ready: '준비가 된',
      active: '활동적인',
      resultPending: '결과 보류 중',
      completedPositive: '완료 긍정적',
      completedNegative: '완료된 네거티브',
      completedInconclusive: '완료되지 않음',
      itemLocked: '잠긴 항목',
      itemUnlocked: '아이템 잠금 해제',
      unused: '미사용',
      returned: '반환',
      readyFilled: '준비 완료',
      readyPacked: '준비 포장',
      withCustomer: '고객과 함께',
      issueRaised: '제기된 문제',
      pickupRequested: '픽업 요청',
    },
  },
  thing: {
    menus: {
      about: '에 대한',
      discovery: '발견',
      public: '공공의',
      tasks: '작업',
      support: '지원하다',
    },
    about: {
      general: '일반적인',
      category: '범주',
      documentation: '선적 서류 비치',
      attributes: '속성',
      certifications: '인증',
      miscellaneous: '여러 가지 잡다한',
      valueChains: '가치 사슬',
      gallery: '갤러리',
      advancedSettings: '고급 설정',
      changeACategory: '카테고리 변경',
      changeAValueChain: '가치 사슬 변경',

      certificationsLabel: {
        addCertification: '인증 추가',
        editCertification: '인증 편집',
      },
    },
    public: {
      generalSettings: '일반 설정',
      enablePublicContent: '공개 콘텐츠 활성화',
      itemCheck: '항목 확인',
      usedAsDiscovery: '검색 작업',
      advancedSettings: '고급 설정',
      rfiderAuthenticityCheck: 'Assure+ 정품 확인',
      basicInformation: '기본 정보',
      brandExperience: '브랜드 경험',
      links: '연결',
      miscellaneous: '여러 가지 잡다한',
      attributes: '속성',
      services: '서비스',
      documents: '서류',
      gallery: '갤러리',
      certifications: '인증',
      videos: '비디오',
      longDescription: '장황한 설명',
      supportProfile: '지원 프로필',
      itemHistory: '아이템 내역',
      none: '없음',
      heroImage: '영웅 이미지',
      sectionImage: '단면 이미지',
      heroOverlayImage: '영웅 오버레이 이미지',
      privacyPolicy: '개인 정보 정책',
      productInfo: '제품 정보',
      learnMore: '더 알아보기',
      shopping: '쇼핑',
      section: '부분',
      position: '위치',
      enableAttributes: '속성 활성화',
      attributeVisibility: '속성 가시성',
      enableAttachments: '첨부 파일 사용',
      enableCertifications: '인증 활성화',
      allowProfles: '팀원의 개인 프로필 허용',
      surviceLabels: {
        enableServices: '서비스 활성화',
        serviceVisibility: '서비스 가시성',
        basic: '기초적인',
        off: '끄다',
        peerToPeer: '피어 투 피어',
      },
      itemHistoryLabels: {
        enableItemHistory: '항목 기록 사용',
        displayTemplate: '디스플레이 템플릿',
        events: '이벤트',
        data: '데이터',
        images: '이미지',
        eventGallery: '이벤트 갤러리',
        enableEventGallery: '이벤트 갤러리 활성화',
      },
      switch: {
        confirmTextEnable: '활성화하시겠습니까?',
        confirmTextDisable: '사용 중지하시겠습니까?',
        confirmButtonEnable: '예, 활성화합니다!',
        confirmButtonDisable: '예, 비활성화!&#39;',
      },
      enablePublicLink: '공개 링크 사용',
      attributeLabels: {
        editAttribute: '속성 편집',
        addAttribute: '속성 추가',
        label: '상표',
        value: '값',
        type: '유형',
      },
    },
  },
  filter: {
    createdByMe: '내가 만든',
  },
  swal: {
    duplicate: '복제 중',
    confirmTitle: '확실해?',
    warningMessage: '당신은 이것을 되돌릴 수 없습니다!',
    confirmButtonDelete: '예, 삭제하십시오!',
    confirmButtonRemove: '예, 제거하십시오!',
    confirmButtonDuplicate: '예, 복제하십시오!',
    confirmButtonReset: '예, 재설정하십시오!',
    confirmButtonResend: '예, 다시 보내십시오!',
    confirmButtonDecline: '응, 거절해!',
    confirmButtonAccept: '네, 받아주세요!',
    keepIt: '아니, 유지',
    userText: '다시 초대해야 합니다.',
    associationError: '연결을 만들 수 없습니다!',
    template: {
      confirmButtonText: '예, 새 제품을 만드십시오!',
      createProduct: '제품 만들기',
      successMessage: '이 템플릿에서 새 제품이 생성되었습니다.',
      errorMessage: '이 템플릿에서 새 제품을 만들 수 없습니다.',
      successMessage1: '템플릿이 복제되었습니다.',
    },
    products: {
      successMessage: '제품이 복제되었습니다.',
      errorMessage: '제품을 복제할 수 없습니다',
      createProductError: '이 템플릿에서 새 제품을 만들 수 없습니다.',
    },
    valueChain: {
      successMessage: '가치 사슬이 복제되었습니다',
      errorMessage: '가치 사슬을 복제할 수 없습니다',
      successMessageThingAbout: '이 제품의 가치 사슬이 변경되었습니다.',
      errorMessageThingAbout: '이 제품의 가치 사슬을 변경할 수 없습니다.',
      thingValueChainText: '변경하지 않는 것이 좋습니다.',
      lastChance: '마지막 기회',
      lastChanceText: '이 시점까지 수집된 모든 데이터는 가치 사슬 보기에 표시되지 않습니다. 이 변경은 취소할 수 없습니다.',
    },
    user: {
      successMessage: '사용자에 대한 초대를 거부',
      errorMessage: '사용자에게 초대장을 다시 보낼 수 없습니다.',
      successMessage1: '사용자 업데이트됨',
      errorMessage1: '사용자를 업데이트할 수 없습니다.',
    },
    invitations: {
      titleDecline: '초대를 거부하시겠습니까?',
      titleAccept: '초대를 수락?',
      text: '당신은 가입에 초대되었습니다',
    },
    category: {
      successMessageThingAbout: '이 제품의 카테고리가 변경되었습니다.',
      errorMessageThingAbout: '이 제품의 카테고리를 변경할 수 없습니다.',
      thingCategoryText: '변경하지 않는 것이 좋습니다.',
      lastChance: '마지막 기회',
      lastChanceText: '이 변경은 취소할 수 없습니다.',
    },
    document: {
      createDocument: '문서가 생성되었습니다',
      createDocumentError: '문서를 만들 수 없습니다',
      updateDocument: '문서가 업데이트되었습니다',
      updateDocumentError: '문서를 업데이트할 수 없습니다.',
      deleteDocument: '문서가 삭제되었습니다.',
      deleteDocumentError: '문서를 삭제할 수 없습니다.',
    },
    task: {
      text: '변경 사항을 저장하지 않고 작업 구성을 종료하려고 합니다. 저장하지 않은 모든 변경 사항이 손실됩니다. 계속하시겠습니까?',
      deleteTask: '작업이 삭제되었습니다.',
      updateTask: '작업이 업데이트되었습니다.',
      updateTaskError: '작업을 업데이트할 수 없습니다.',
      createTask: '작업이 생성되었습니다',
      createTaskTitle: '성공적으로 생성되었습니다!',
      createTaskText: '구성을 여시겠습니까?',
      labels: {
        configuration: '구성',
      },
    },
    interaction: {
      createSuccess: '상호작용 생성됨',
      createError: '상호 작용을 만들 수 없습니다.',
      mapLoadMessage: '로딩 오류',
      mapLoadError: '지도에서 상호작용을 로드할 수 없습니다.',
      updateSuccess: '상호 작용이 업데이트되었습니다.',
      updateError: '상호작용을 업데이트할 수 없습니다.',
      deleteSuccess: '상호작용이 삭제되었습니다.',
      deleteError: '상호작용을 삭제할 수 없습니다.',
    },
    item: {
      itemsLimitError: '입력 옵션에 대한 제한은 {itemsLimit} 항목뿐입니다.',
      removeSupportProfile: {
        title: '확실해?',
        success: '개인 지원 프로필이 제거됨',
        error: '개인 지원 프로필을 제거할 수 없습니다.',
      },
    },
    miscellaneous: {
      noImageError: '이미지를 추가해주세요',
      undefinedNotificationType: '알림 유형 중 하나를 선택하세요.',
    },
    webhooks: {
      deleteAction: '작업이 삭제되었습니다.',
      deleteActionError: '작업을 삭제할 수 없습니다.',
      updateActionStatus: '작업 상태가 업데이트되었습니다.',
      updateActionStatusError: '작업 상태를 업데이트할 수 없습니다.',
      updateAction: '작업이 업데이트되었습니다.',
      updateActionError: '작업을 업데이트할 수 없습니다.',
      createWebhook: '웹훅 생성됨',
    },
  },
  invitations: {
    title: '초대장',
    records: '기록',
  },
  languages: {
    english: 'English',
    german: 'Deutsch',
    spanish: 'Español',
    japanese: '日本',
    korean: '한국인',
    dutch: 'Nederlands',
    portuguese: 'Português',
    chinese: '中国人',
    chineseTraditional: '中國人',
  },
  editMiscellaneous: {
    editMiscellaneous: '기타 편집',
    addMiscellaneous: '기타 추가',
    editVideo: '영상을 편집하다',
    addVideo: '비디오 추가',
    editOptions: '편집 옵션',
    addOptions: '옵션 추가',
    labels: {
      embeddedLink: '포함된 링크만',
      videoEmbed: '비디오 삽입 코드',
      codeInjection: '코드 주입',
      imageLink: '이미지 링크',
    },
  },
  discovery: {
    labels: {
      batchId: '배치 ID',
      batchName: '배치 이름',
      createdBy: '만든 사람',
      itemCount: '항목 수',
      used: '% 사용 된',
    },
    buttons: {
      identificationMethod: '식별 방법',
      createBatch: '배치 생성',
      deactivate: '비활성화',
      activate: '활성화',
      delete: '삭제',
    },
    maximumItemsPerBatch: '배치당 최대 품목',
    batches: '배치',
    beta: '베타',
    item: '안건',
    product: '제품',
    selectIdentificationMethod: '식별 방법 선택',
    message: 'Assure+ 고유 ID(RFUID)는 Assure+ 플랫폼에서 관리되는 항목의 시스템 생성 식별자입니다.',
  },
  dashboard: {
    dateRange: {
      today: '오늘',
      lastSevenDays: '지난 7일',
      lastWeek: '지난주',
      lastThirtyDays: '지난 30일',
      lastMonth: '지난 달',
      lastTwelvemonths: '지난 12개월',
      custom: '관습',
    },
    chart: {
      today: '오늘',
      allTime: '올타임',
      totalInteractions: '총 상호작용',
      totalTasks: '총 작업',
      totalServices: '토탈 서비스',
      noData: '데이터 없음',
      overallStatusBreakdown: '전체 상태 분석',
    },
  },
  displayConditionsRules: {
    onlyAdmin: '사용자 권한',
    or: '또는',
    andAlso: '그리고 또한',
    addYourFirstCondition: '첫 번째 조건 추가',
    useSeparator: '쉼표를 구분 기호로 사용',
    useDashSeparator: '대시(-)를 구분 기호로 사용',
    rfuidItemId: 'RFID 품목 ID',
    rfuidBatchId: 'RFID 배치 ID',
    rfuidSequenceNumber: 'RFID 시퀀스 번호',
    itemStatus: '품목 상태',
    userCountry: '사용자 국가',
    userLanguage: '사용자 언어',
    conditions: {
      stringEquals: '문자열 같음',
      stringNotEquals: '문자열이 같지 않음',
      includedIn: '포함 된',
      notIncludedIn: '에 포함되지 않음',
      numberEquals: '숫자 같음',
      numberNotEquals: '숫자가 같지 않음',
      numberGreaterThan: '다음보다 큰 숫자',
      numberLessThan: '보다 작은 수',
      between: '~ 사이',
    },
    countries: {
      afghanistan: '아프가니스탄',
      alandIslands: '올란드 제도',
      albania: '알바니아',
      algeria: '알제리',
      americanSamoa: '아메리칸 사모아',
      andorra: '안도라',
      angola: '앙골라',
      anguilla: '앵귈라',
      antarctica: '남극',
      antigua: '앤티가 바부다',
      argentina: '아르헨티나',
      armenia: '아르메니아',
      aruba: '아루바',
      australia: '호주',
      austria: '오스트리아',
      azerbaijan: '아제르바이잔',
      bahamas: '바하마',
      bahrain: '바레인',
      bangladesh: '방글라데시',
      barbados: '바베이도스',
      belarus: '벨라루스',
      belgium: '벨기에',
      belize: '벨리즈',
      benin: '베냉',
      bermuda: '버뮤다',
      bhutan: '부탄',
      bolivia: '볼리비아',
      bonaire: '보네르',
      bosnia: '보스니아 헤르체고비나',
      botswana: '보츠와나',
      bouvet: '부베 아일랜드',
      brazil: '브라질',
      britishIndian: '영국령 인도양 영토',
      brunei: '브루나이 다루살람',
      bulgaria: '불가리아',
      burkina: '부키 나 파소',
      burundi: '부룬디',
      cambodia: '캄보디아',
      cameroon: '카메룬',
      canada: '캐나다',
      capeVerde: '카보베르데',
      caymanIslands: '케이맨 제도',
      centralAfricanRepublic: '중앙 아프리카 공화국',
      chad: '차드',
      chile: '칠레',
      china: '중국',
      christmasIsland: '크리스마스 섬',
      cocos: '코코스(킬링) 제도',
      colombia: '콜롬비아',
      comoros: '코모로',
      congo: '콩고',
      congoDemocratic: '콩고',
      cookIslands: '쿡 제도',
      costaRica: '코스타리카',
      coteIvo: '아이보리 해안',
      croatia: '크로아티아',
      cuba: '쿠바',
      curacao: '퀴라소',
      cyprus: '키프로스',
      czechRepublic: '체코 공화국',
      denmark: '덴마크',
      djibouti: '지부티',
      dominica: '도미니카',
      dominicanRepublic: '도미니카 공화국',
      ecuador: '에콰도르',
      egypt: '이집트',
      elSalvador: '구세주',
      equatorialGuinea: '적도 기니',
      eritrea: '에리트레아',
      estonia: '에스토니아',
      ethiopia: '에티오피아',
      falkland: '포클랜드 제도(말비나스)',
      faroeIslands: '페로 제도',
      fiji: '피지',
      finland: '핀란드',
      france: '프랑스',
      frenchGuiana: '프랑스령 기아나',
      frenchPolynesia: '프랑스 령 폴리네시아의',
      frenchSouthern: '프랑스 남부 지역',
      gabon: '가봉',
      gambia: '감비아',
      georgia: '그루지야',
      germany: '독일',
      ghana: '가나',
      gibraltar: '지브롤터',
      greece: '그리스',
      greenland: '그린란드',
      grenada: '그레나다',
      guadeloupe: '과들루프',
      guam: '괌',
      guatemala: '과테말라',
      guernsey: '건지',
      guinea: '기니',
      guineaBissau: '기니비사우',
      guyana: '가이아나',
      haiti: '아이티',
      heardIsland: '허드 아일랜드와 맥도날드 아일랜드',
      holySee: '교황청(바티칸 시국)',
      honduras: '온두라스',
      hongKong: '홍콩',
      hungary: '헝가리',
      iceland: '아이슬란드',
      india: '인도',
      indonesia: '인도네시아',
      iran: '이란',
      iraq: '이라크',
      ireland: '아일랜드',
      isleOfMan: '맨 섬',
      israel: '이스라엘',
      italy: '이탈리아',
      jamaica: '자메이카',
      japan: '일본',
      jersey: '저지',
      jordan: '요르단',
      kazakhstan: '카자흐스탄',
      kenya: '케냐',
      kiribati: '키리바시',
      korea: '대한민국',
      koreaRepublic: '대한민국',
      kuwait: '쿠웨이트',
      kyrgyzstan: '키르기스스탄',
      lao: '노동',
      latvia: '라트비아',
      lebanon: '레바논',
      lesotho: '레소토',
      liberia: '라이베리아',
      libya: '리비아',
      liechtenstein: '리히텐슈타인',
      lithuania: '리투아니아',
      luxembourg: '룩셈부르크',
      macao: '마카오',
      macedonia: '마케도니아',
      madagascar: '마다가스카르',
      malawi: '말라위',
      malaysia: '말레이시아',
      maldives: '몰디브',
      mali: '말리',
      malta: '몰타',
      marshall: '마셜 제도',
      martinique: '마르티니크',
      mauritania: '모리타니',
      mauritius: '모리셔스',
      mayotte: 'Mayotte',
      mexico: '멕시코',
      micronesia: '미크로네시아',
      moldova: '몰다비아',
      monaco: '모나코',
      mongolia: '몽골리아',
      montenegro: '몬테네그로',
      montserrat: '몬세라트',
      morocco: '모로코',
      mozambique: '모잠비크',
      myanmar: '미얀마',
      namibia: '나미비아',
      nauru: '나우루',
      nepal: '네팔',
      netherlands: '네덜란드',
      newCaledonia: '뉴 칼레도니아',
      newZealand: '뉴질랜드',
      nicaragua: '니카라과',
      niger: '니제르',
      nigeria: '나이지리아',
      niue: '니우에',
      norfolk: '노퍽 섬',
      northern: '북마리아나 제도',
      norway: '노르웨이',
      oman: '오만',
      pakistan: '파키스탄',
      palau: '팔라우',
      palestinian: '팔레스타인 영토, 점령',
      panama: '파나마',
      papua: '파푸아 뉴기니',
      paraguay: '파라과이',
      peru: '페루',
      philippines: '필리핀 제도',
      pitcairn: '핏케언',
      poland: '폴란드',
      portugal: '포르투갈',
      puertoRico: '푸에르토 리코',
      qatar: '카타르',
      reunion: '회의',
      romania: '루마니아',
      russian: '러시아 연방',
      rwanda: '르완다',
      saintBarth: '생 바르텔레미',
      saintHelena: '세인트 헬레나',
      saintKitts: '세인트키츠네비스',
      saintLucia: '세인트루시아',
      saintMartin: 'Saint Martin(프랑스어 부분)',
      saintPierre: '생 피에르와 미클롱',
      saintVincent: '세인트 빈센트 그레나딘',
      samoa: '사모아',
      sanMarino: '산 마리노',
      saoTome: '상투메프린시페',
      saudiArabia: '사우디 아라비아',
      senegal: '세네갈',
      serbia: '세르비아',
      seychelles: '세이셸',
      sierra: '시에라리온',
      singapore: '싱가포르',
      sintMaarten: '신트 마르텐(네덜란드 부분)',
      slovakia: '슬로바키아',
      slovenia: '슬로베니아',
      solomon: '솔로몬 제도',
      somalia: '소말리아',
      southAfrica: '남아프리카',
      southGeorgia: '사우스 조지아 및 사우스 샌드위치 제도',
      southSudan: '남 수단',
      spain: '스페인',
      sriLanka: '스리랑카',
      sudan: '수단',
      suriname: '수리남',
      svalbard: '스발바르와 얀 마옌',
      swaziland: '스와질랜드',
      sweden: '스웨덴',
      switzerland: '스위스',
      syrian: '시리아 아랍 공화국',
      taiwan: '대만',
      tajikistan: '타지키스탄',
      tanzania: '탄자니아',
      thailand: '태국',
      timorLeste: '동 티모르',
      togo: '토고',
      tokelau: '토켈라우',
      tonga: '통가',
      trinidad: '트리니다드토바고',
      tunisia: '튀니지',
      turkey: '칠면조',
      turkmenistan: '투르크메니스탄',
      turksAndCaicosIslands: '터크스케이커스 제도',
      tuvalu: '투발루',
      uganda: '우간다',
      ukraine: '우크라이나',
      uae: '아랍 에미리트',
      uk: '영국',
      usa: '미국',
      usMinor: '미국 작은 외딴 섬',
      uruguay: '우루과이',
      uzbekistan: '우즈베키스탄',
      vanuatu: '바누아투',
      venezuelaBolivarian: '베네수엘라',
      vietnam: '베트남',
      virginIslandsBritish: '버진 아일랜드',
      virginIslandsUS: '버진 아일랜드',
      wallisAndFutuna: '월리스와 푸투나',
      westernSahara: '서부 사하라',
      yemen: '예멘',
      zambia: '잠비아',
      zimbabwe: '짐바브웨',
    },
    languages: {
      ab: '압하지야',
      aa: '멀리',
      af: '아프리카 어',
      ak: '할 것이다',
      sq: '알바니아',
      am: '암하라어',
      ar: '아라비아 말',
      an: '아라곤',
      hy: '아르메니아 사람',
      as: '아쌈',
      av: '아바릭',
      ae: '아베스탄',
      ay: '아이마라',
      az: '아제르바이잔',
      bm: '밤바라',
      ba: '바쉬키르',
      eu: '바스크 사람',
      be: '벨라루스어',
      bn: '벵골 사람',
      bh: '비하리어',
      bi: '비슬라마',
      bs: '보스니아어',
      br: '브르타뉴어',
      bg: '불가리아 사람',
      my: '버마 사람',
      ca: '카탈로니아 사람; 발렌시아인',
      km: '중부 크메르어',
      ch: '차모로',
      ce: '체첸',
      ny: '치체와; 츄와; 바다',
      zhCn: '중국어 (간체)',
      zhTw: '중국 전통)',
      cu: '교회 슬라브어',
      cv: '추바시',
      kw: '콘월 말',
      co: '코르시카인',
      cr: '믿다',
      hr: '크로아티아어',
      cs: '체코 사람',
      da: '덴마크 말',
      dv: '디베히',
      nl: '네덜란드 사람; 플랑드르 사람',
      dz: '종카',
      en: '영어',
      eo: '에스페란토 말',
      et: '에스토니아 사람',
      ee: '암양',
      fo: '페로어',
      fj: '피지인',
      fi: '핀란드어',
      fr: '프랑스 국민',
      ff: '풀라',
      gd: '게일 말',
      gl: '갈리시아어',
      lg: '더블',
      ka: '조르지아 주 사람',
      de: '독일 사람',
      el: '그리스 어',
      gn: '과라니',
      gu: '구자라트어',
      ht: '아이티 섬 사람',
      ha: '하우사',
      he: '헤브라이 사람',
      hz: '헤레로',
      hi: '아니요.',
      ho: '히리 모투',
      hu: '헝가리 인',
      is: '아이슬란드어',
      io: '다 쓴',
      ig: '이보',
      id: '인도네시아 인',
      iu: '이누크티투트어',
      ik: '이누피아크',
      ga: '아일랜드의',
      it: '이탈리아 사람',
      ja: '일본어',
      jv: '자바어',
      kl: '그린란드어',
      kn: '칸나다어',
      kr: '카누리',
      ks: '카시미르 말',
      kk: '카자흐어',
      ki: '키쿠유; 기쿠유',
      rw: '키냐르완다',
      ky: '키르기즈 사람; 키르기스스탄',
      kv: '어서 해봐요',
      kg: '콩고',
      ko: '한국어',
      kj: '동물; 실물로',
      ku: '쿠르드어',
      lo: '노동',
      la: '라틴어',
      lv: '라트비아 사람',
      li: '림버거',
      ln: '링갈라',
      lt: '리투아니아 사람',
      lu: '루바카탕가',
      lb: '룩셈부르크어',
      mk: '마케도니아 어',
      mg: '마다가스카르 사람',
      ms: '말레이 사람',
      ml: '말라얄람어',
      mt: '몰티즈',
      gv: '맨 섬 사람',
      mi: '마오리족',
      mr: '마라티어',
      mh: '마샬레즈',
      mn: '몽고 어',
      na: '나우루',
      nv: '나바호어; 나바호',
      ng: '은동가',
      ne: '네팔어',
      se: '북부 사미어',
      no: '노르웨이 인',
      oj: '오지브와',
      or: '오리야',
      om: '오로모',
      os: '오세티아어; 오세틱',
      pi: '팔리 어',
      pa: '펀자브어; 펀자브어',
      fa: '페르시아 인',
      pl: '광택',
      ptBr: '포르투갈 인',
      ps: '푸쉬토; 파슈토어',
      qu: '케추아어',
      ro: '루마니아 사람',
      rm: '로만쉬',
      rn: '라운드',
      ru: '러시아인',
      sm: '사모아어',
      sg: 'Sango',
      sa: '산스크리트',
      sc: '사르디니아 사람',
      sr: '세르비아 사람',
      sn: '쇼나',
      ii: '쓰촨 이, 누오쑤',
      sd: '신디어',
      si: '신할라어; 싱할라어',
      sk: '슬로바키아 사람',
      sl: '슬로베니아',
      so: '소말리아',
      st: '소토, 남부',
      es: '스페인의',
      su: '순다어',
      sw: '스와힐리어',
      ss: '스와티어',
      sv: '스웨덴어',
      tl: '타갈로그어',
      ty: '타히티어',
      tg: '타직',
      ta: '타밀 사람',
      tt: '타타르어',
      te: '텔루구어',
      th: '태국어',
      bo: '티베트어',
      ti: '티그리냐',
      to: '통가',
      ts: '총가',
      tn: '츠와나',
      tr: '터키어',
      tk: '투르크멘 말',
      tw: '둘',
      ug: '위구르 위구르어',
      uk: '우크라이나 인',
      ur: '우르두어',
      uz: '우즈벡',
      ve: '판매',
      vi: '베트남어',
      vo: '볼라퓌크',
      wa: '왈론 사람',
      cy: '웨일스 말',
      fy: '서부 프리지아어',
      wo: '월로프',
      xh: '코사',
      yi: '이디시어',
      yo: '요루바어',
      za: '좡, 좡',
      zu: '줄루 족',
    },
  },
  itemSummary: {
    title: '품목 코드 정보',
    batchId: '배치 ID',
    product: '제품',
    sequence: '순서',
    checksum: '체크섬',
    warning: '이 항목에 대해 정품 확인이 활성화되었습니다. 포털에서 액세스하면 후속 스캔 시 상태 업데이트 및 경고와 같은 진위 경고 프로토콜이 트리거될 수 있습니다.',
    hideUrl: 'URL 숨기기',
    showUrl: 'URL 표시',
    copy: '복사',
    itemUrlCopied: '항목 URL이 복사되었습니다.',
    productName: '상품명',
    itemId: '항목 ID',
    itemStatus: '품목 상태',
    itemName: '상품명',
    batchName: '배치 이름',
  },
  updateReportedData: {
    locationTitle: '위치',
    dateTimeTitle: '날짜와 시간',
    dateTimeInteraction: '상호작용 날짜',
    dateTimeReported: '보고 날짜',
    reset: '초기화',
    locationInteraction: '상호 작용 위치',
    locationReported: '보고된 위치',
    reportedData: '보고된 데이터',
  },
};
export default ko;
