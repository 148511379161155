export default {
  apiUrlRest: process.env.VUE_APP_apiUrlRest,
  apiUrlGql: process.env.VUE_APP_apiUrlGql,
  apiUrlFs: process.env.VUE_APP_apiUrlFs,

  gcpPublicBucketUri: process.env.VUE_APP_gcpPublicBucketUri,
  test_jwt: process.env.VUE_APP_test_jwt,
  AUTH0_domain: process.env.VUE_APP_AUTH0_domain || '',
  AUTH0_clientID: process.env.VUE_APP_AUTH0_clientID || '',
  AUTH0_audience: process.env.VUE_APP_AUTH0_audience,
  PUBNUB_subscribeKey: process.env.VUE_APP_PUBNUB_subscribe_key,
  // iframeReportInteractions: process.env.VUE_APP_iframeReportInteractions,
  intercomAppId: process.env.VUE_APP_INTERCOM_appid || '',
  GtagID: process.env.VUE_APP_GtagID || '',
  googleGeoKey: process.env.VUE_APP_GOOGLE_GEO_KEY,
  optimizelyKey: process.env.VUE_APP_optimizelyKey,
  debugNativeAppIntegration: process.env.VUE_APP_debugNativeAppIntegration === '1',
  publicPreviewUrl: process.env.VUE_APP_publicPreviewUrl,
  widgetTimelineStatusUri: process.env.VUE_APP_widgetTimelineStatusBreakdownUri,
  widgetOverallStatusUri: process.env.VUE_APP_widgetOverallStatusBreakdownUri,
  widgetTodayOverallCountUri: process.env.VUE_APP_widgetTodayOverallCountUri,
  widgetAllTimeCountUri: process.env.VUE_APP_widgetAllTimeCountUri,
  widgetTopCountriesUri: process.env.VUE_APP_widgetTopCountriesUri,
  widgetTopTasksUri: process.env.VUE_APP_widgetTopTasksUri,
  widgetTopThingsUri: process.env.VUE_APP_widgetTopThingsUri,
  widgetTopItemsUri: process.env.VUE_APP_widgetTopItemsUri,
  publicPageUrl: process.env.VUE_APP_publicPageUrl,
  googleAutoCompleteKey: process.env.VUE_APP_GOOGLE_AUTOCOMPLETE_KEY,
  portalVersion: process.env.VUE_APP_PORTAL_VERSION,
  gftUrl: process.env.VUE_APP_GFT_URL,
};
