export const accountInfoFragment = `
id
firstName
lastName
image
email
fallbackImage
displayImage
avatar
`;
