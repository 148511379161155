import { accountOrganizationFragment } from './fragments/accountOrganizationFragment';
import { accountInfoFragment } from './fragments/accountInfoFragment';

export const query = `query organizationAccounts($organizationId: String! $page: Int $perPage: Int $sort: String $search: String $filters: String){
    organizationAccounts(organizationId: $organizationId, page:$page, perPage:$perPage, sort:$sort, search:$search, filters: $filters){
        perPage
        totalItems
        totalPages
        items{
            ${accountOrganizationFragment}
            account {
                ${accountInfoFragment}
                metadata
            }
        }
    }
}`;
